import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

const ECFormatSearch = ({ filteredEC, setActivityFormatSelect, setInPersonFormatSelect, resetStateToggle }) => {

  const location = useLocation()

  let onlineCount = 0
  let inPersonResidentialCount = 0
  let inPersonCommutingCount = 0
  let hybridCount = 0

  const [online, setOnline] = useState(false)
  const [residential, setResidential] = useState(false)
  const [commuting, setCommuting] = useState(false)
  const [hybrid, setHybrid] = useState(false)

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  const checkCheckCase = (filterVal) => {
    return filterVal ? true : false
  }

  useEffect(() => {
    setOnline(false);
    setResidential(false);
    setCommuting(false);
    setHybrid(false)
  }, [resetStateToggle])

  useEffect(() => {
    let activityFormats: any = []
    let inPersonFormats: any = []

    if (online === true) {
      activityFormats.push(2)
    }

    if (residential === true) {
      activityFormats.push(1)
      inPersonFormats.push(1)
    }

    if (commuting === true) {
      activityFormats.push(1)
      inPersonFormats.push(2)
    }

    if (hybrid === true) {
      activityFormats.push(3)
    }

    setActivityFormatSelect([...new Set(activityFormats)])
    setInPersonFormatSelect([...new Set(inPersonFormats)])

  }, [online, residential, commuting, hybrid])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let formatParam = params.get('format');

    if (formatParam) {
      let formats = formatParam.split(',').map(res => res);

      if (formats.includes('online')) {
        setOnline(true)
      }

      if (formats.includes('residential')) {
        setResidential(true)
      }

      if (formats.includes('commuting')) {
        setCommuting(true)
      }

      if (formats.includes('hybrid')) {
        setHybrid(true)
      }
    }

  }, [location.search]);

  if (filteredEC && filteredEC.length > 0) {
    onlineCount = filteredEC.filter(item => item.activityFormat && item.activityFormat.includes(2)).length
    inPersonResidentialCount = filteredEC.filter(item => item.activityFormat && item.activityFormat.includes(1) && item.inPersonFormat && item.inPersonFormat.includes(1)).length
    inPersonCommutingCount = filteredEC.filter(item => item.activityFormat && item.activityFormat.includes(1) && item.inPersonFormat && item.inPersonFormat.includes(2)).length
    hybridCount = filteredEC.filter(item => item.activityFormat && item.activityFormat.includes(3)).length
  }


  const formatArray = [
    { name: 'Online', onchange: setOnline, count: onlineCount, check: online },
    { name: 'In-person (residential)', onchange: setResidential, count: inPersonResidentialCount, check: residential },
    { name: 'In-person (commuting)', onchange: setCommuting, count: inPersonCommutingCount, check: commuting },
    { name: 'Hybrid', onchange: setHybrid, count: hybridCount, check: hybrid }
  ]


  return (
    <div className='mt-4 d-flex flex-column' style={{ minWidth: 'fit-content', whiteSpace: 'nowrap' }}>
      <div className='dca-aside-header'>FORMAT</div>
      <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

        {formatArray.map((res, index) => (
          <label key={index} className="d-flex justify-content-between" style={{ cursor: 'pointer', marginTop: index !== 0 ? '4px' : '' }}>
            <div>
              <input
                type="checkbox"
                className='dca-checkbox'
                checked={checkCheckCase(res.check)}
                name={res.name}
                onChange={(event) => (res.onchange(handleCheckChange(event)))}
                style={{ cursor: 'pointer' }}
              />
              <span className='ms-3 me-3'>{res.name}</span>
            </div>

            <span className='text-muted fs-7 fw-bolder'>{res.count}</span>
          </label>
        ))}
      </div>
    </div>
  )
}

export { ECFormatSearch }