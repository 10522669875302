import { LoadingSpinner } from "../LoadingSpinner"
import { titleSpace, returnBulletListFromHyphenSeparatedString } from "../dictionary"

const AdvisoryServices = ({ advisor }) => {

  if (!advisor) {
    return <LoadingSpinner title={'Advisory Services'} />
  }

  // const returnDiscussTopics = (res) => {
  //   if (res) {
  //     let resBullets = res.split('- ').filter((str) => str != '')

  //     return resBullets.map((res, index) =>
  //       <ul>
  //         <li key={index}>{res}</li>
  //       </ul>
  //     )
  //   }

  //   else {
  //     return '-'
  //   }
  // }

  const desktopReturn = (<div className="d-flex flex-row" style={{ marginBottom: '40px' }}>

    <div className="d-flex flex-column" style={{ width: '45%', paddingLeft: '40px', paddingTop: "40px" }}>
      <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px' }}>DISCUSSION TOPICS</div>
      <div>{returnBulletListFromHyphenSeparatedString(advisor.discussTopics)}</div>
    </div>


    <div className="d-flex flex-column" style={{ width: '45%', paddingLeft: '40px', paddingTop: "40px" }}>
      <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px' }}>FEES</div>
      <div>${advisor.sessionFee} ({advisor.sessionDuration} minutes)</div>
    </div>

  </div>)

  const mobileReturn = (<div className="d-flex flex-column" style={{ padding: '40px' }}>

    <div style={{ marginBottom: '40px' }}>
      <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px' }}>DISCUSSION TOPICS</div>
      <div>{returnBulletListFromHyphenSeparatedString(advisor.discussTopics)}</div>
    </div>


    <div style={{ marginBottom: '40px' }}>
      <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px' }}>FEES</div>
      <div>${advisor.sessionFee} ({advisor.sessionDuration} minutes)</div>
    </div>
  </div>)

  return (
    <div className="" style={{ marginTop: '30px' }}>
      <div style={{ fontSize: '23px' }}>Advisory Services</div>

      <div className="card shadow" style={{ marginTop: '15px' }}>

        {/* Use Bootstrap d-md-block to render while 768px or above */}
        <div className='d-none d-md-flex'>
          {desktopReturn}
        </div>
        {/* Mobile Replacement, only shows at below 768px */}
        <div className='d-flex d-md-none'>
          {mobileReturn}
        </div>

      </div>
    </div>
  )
}

export { AdvisoryServices }