// import { useEffect, useRef } from "react";
// import noUiSlider from 'nouislider'
// import { useLocation } from 'react-router-dom';

// const Selectivity = ({ selectivityMin, selectivityMax, setSelectivityMin, setSelectivityMax, setAdmitParams }) => {

//   const location = useLocation()
//   const selectivitySliderRef = useRef(null);

//   useEffect(() => {

//     let params = new URLSearchParams(location.search);
//     let admitParam = params.get('admitRate');

//     if (admitParam) {
//       const admitRateVals = admitParam.split(',').map(admit => (parseInt(admit)))
//       setSelectivityMin(admitRateVals[0])
//       setSelectivityMax(admitRateVals[1])
//     }

//     else {
//       setSelectivityMin(0)
//       setSelectivityMax(100)
//     }

//   }, [location.search]);

//   useEffect(() => {

//     let params = new URLSearchParams(location.search);
//     let minMaxArr: any[] = []

//     if (selectivityMin != 0 || selectivityMax != 100) {
//       minMaxArr = [selectivityMin, selectivityMax]
//       const minMax = minMaxArr.map((val) => val).join(',')
//       params.set('admitRate', minMax)
//     } else {
//       params.delete('admitRate')
//     }

//     setAdmitParams(`${params.toString()}`)

//   }, [selectivityMin, selectivityMax])

//   useEffect(() => {

//     if (!selectivitySliderRef.current) {

//       const slider: any = document.querySelector("#kt_slider_selectivity");
//       let params = new URLSearchParams(location.search);
//       let admitParam = params.get('admitRate');

//       if (admitParam) {
//         const admitRateVals = admitParam.split(',').map(admit => (parseInt(admit)))

//         noUiSlider.create(slider, {
//           start: [admitRateVals[0], admitRateVals[1]],
//           step: 1,
//           range: {
//             "min": 0,
//             "max": 100
//           },
//           tooltips: {
//             to: function (value) {
//               return `${value.toFixed(0)}%`;
//             }
//           },
//         });
//       }

//       else {
//         noUiSlider.create(slider, {
//           start: [0, 100],
//           step: 1,
//           range: {
//             "min": 0,
//             "max": 100
//           },
//           tooltips: {
//             to: function (value) {
//               return `${value.toFixed(0)}%`;
//             }
//           },
//         })
//       };

//       slider.noUiSlider.on("set", function (values, handle) {
//         if (handle === 0) {
//           setSelectivityMin(parseInt(values[0]))
//         }

//         if (handle === 1) {
//           setSelectivityMax(parseInt(values[1]))
//         }
//       });

//       selectivitySliderRef.current = slider;
//     }


//   }, [])

//   return <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
//     <div className='dca-aside-header'>ADMIT RATE</div>
//     <div className="mb-0" style={{ marginTop: '50px', zIndex: '1' }}>
//       <div id="kt_slider_selectivity"></div>
//     </div>
//   </div>

// }

// export { Selectivity }

import { useEffect, useRef } from 'react';
import noUiSlider from 'nouislider';
import { useLocation } from 'react-router-dom';

const Selectivity = ({ selectivityMin, selectivityMax, setSelectivityMin, setSelectivityMax, setAdmitParams, resetStateToggle }) => {

  const location = useLocation();
  const selectivitySliderRef: any = useRef(null);
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (selectivitySliderRef.current && selectivitySliderRef.current.noUiSlider) {
      selectivitySliderRef.current.noUiSlider.set([selectivityMin, selectivityMax]);
    }
  }, [selectivityMin, selectivityMax]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setSelectivityMin(0);
    setSelectivityMax(100);

    if (selectivitySliderRef.current && selectivitySliderRef.current.noUiSlider) {
      selectivitySliderRef.current.noUiSlider.set([0, 100]);
    }
  }, [resetStateToggle]);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let admitParam = params.get('admitRate');

    if (admitParam) {
      const admitRateVals = admitParam.split(',').map(admit => parseInt(admit));
      setSelectivityMin(admitRateVals[0]);
      setSelectivityMax(admitRateVals[1]);
    } else {
      setSelectivityMin(0);
      setSelectivityMax(100);
    }
  }, [location.search, setSelectivityMin, setSelectivityMax]);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let minMaxArr: any = [];

    if (selectivityMin !== 0 || selectivityMax !== 100) {
      minMaxArr = [selectivityMin, selectivityMax];
      const minMax = minMaxArr.join(',');
      params.set('admitRate', minMax);
    } else {
      params.delete('admitRate');
    }

    setAdmitParams(params.toString());
  }, [selectivityMin, selectivityMax, location.search, setAdmitParams]);

  useEffect(() => {
    const sliderElement: any = document.querySelector("#kt_slider_selectivity");

    if (sliderElement && !sliderElement.noUiSlider) {

      let params = new URLSearchParams(location.search);
      let admitParam = params.get('admitRate');
      const startValues = admitParam ? admitParam.split(',').map(admit => parseInt(admit)) : [0, 100];

      noUiSlider.create(sliderElement, {
        start: startValues,
        step: 1,
        range: {
          min: 0,
          max: 100,
        },
        tooltips: {
          to: function (value) {
            return `${value.toFixed(0)}%`;
          },
        },
      });

      sliderElement.noUiSlider.on("set", (values, handle) => {
        if (handle === 0) {
          setSelectivityMin(parseInt(values[0]));
        }
        if (handle === 1) {
          setSelectivityMax(parseInt(values[1]));
        }
      });

      selectivitySliderRef.current = sliderElement;
    }

    return () => {
      if (sliderElement && sliderElement.noUiSlider) {
        sliderElement.noUiSlider.destroy();
      }
    };
  }, [location.search, setSelectivityMin, setSelectivityMax]);

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>ADMIT RATE</div>
      <div className="mb-0" id='dca-college-search-selectivity-mobile' style={{ marginTop: '50px', zIndex: '1' }}>
        <div id="kt_slider_selectivity"></div>
      </div>
    </div>
  );
};

export { Selectivity };

const SelectivityDesktop = ({ selectivityMin, selectivityMax, setSelectivityMin, setSelectivityMax, setAdmitParams, resetStateToggle }) => {

  const location = useLocation();
  const selectivitySliderRef: any = useRef(null);
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (selectivitySliderRef.current && selectivitySliderRef.current.noUiSlider) {
      selectivitySliderRef.current.noUiSlider.set([selectivityMin, selectivityMax]);
    }
  }, [selectivityMin, selectivityMax]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setSelectivityMin(0);
    setSelectivityMax(100);

    if (selectivitySliderRef.current && selectivitySliderRef.current.noUiSlider) {
      selectivitySliderRef.current.noUiSlider.set([0, 100]);
    }
  }, [resetStateToggle]);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let admitParam = params.get('admitRate');

    if (admitParam) {
      const admitRateVals = admitParam.split(',').map(admit => parseInt(admit));
      setSelectivityMin(admitRateVals[0]);
      setSelectivityMax(admitRateVals[1]);
    } else {
      setSelectivityMin(0);
      setSelectivityMax(100);
    }
  }, [location.search, setSelectivityMin, setSelectivityMax]);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let minMaxArr: any = [];

    if (selectivityMin !== 0 || selectivityMax !== 100) {
      minMaxArr = [selectivityMin, selectivityMax];
      const minMax = minMaxArr.join(',');
      params.set('admitRate', minMax);
    } else {
      params.delete('admitRate');
    }

    setAdmitParams(params.toString());
  }, [selectivityMin, selectivityMax, location.search, setAdmitParams]);

  useEffect(() => {
    const sliderElement: any = document.querySelector("#kt_slider_selectivity_desktop");

    if (sliderElement && !sliderElement.noUiSlider) {

      let params = new URLSearchParams(location.search);
      let admitParam = params.get('admitRate');
      const startValues = admitParam ? admitParam.split(',').map(admit => parseInt(admit)) : [0, 100];

      noUiSlider.create(sliderElement, {
        start: startValues,
        step: 1,
        range: {
          min: 0,
          max: 100,
        },
        tooltips: {
          to: function (value) {
            return `${value.toFixed(0)}%`;
          },
        },
      });

      sliderElement.noUiSlider.on("set", (values, handle) => {
        if (handle === 0) {
          setSelectivityMin(parseInt(values[0]));
        }
        if (handle === 1) {
          setSelectivityMax(parseInt(values[1]));
        }
      });

      selectivitySliderRef.current = sliderElement;
    }

    return () => {
      if (sliderElement && sliderElement.noUiSlider) {
        sliderElement.noUiSlider.destroy();
      }
    };
  }, [location.search, setSelectivityMin, setSelectivityMax]);

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>ADMIT RATE</div>
      <div className="mb-0" id='dca-college-search-selectivity-mobile' style={{ marginTop: '50px', zIndex: '1' }}>
        <div id="kt_slider_selectivity_desktop"></div>
      </div>
    </div>
  );
};

export { SelectivityDesktop };