import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { pageRoutes, updateTitleAndDescription, URL } from "../../components/dictionary"
import { StickyNavbar } from "../../components/Marketing/StickyNavbar";
import { ConnectEmailCTA } from '../../components/Marketing/ConnectEmailCTA';
import { HoverCards } from '../../components/Marketing/HoverCards';
import { Testimonials } from '../../components/Marketing/Testimonials';
import { FAQ } from '../../components/Marketing/FAQ';
import { MarketingImageWrapper } from '../../components/Marketing/ImageWrappers';
import axios from 'axios';

import photo1 from '../../../custom_assets/marketing/advisor_network/photo_1.png'
import photo2 from '../../../custom_assets/marketing/advisor_network/photo_2.png'
import photo3 from '../../../custom_assets/marketing/advisor_network/photo_3.png'
import photo4 from '../../../custom_assets/marketing/advisor_network/photo_4.png'

import mobilePhoto1a from '../../../custom_assets/marketing/advisor_network/mobile/photo_1a.png'
import mobilePhoto1b from '../../../custom_assets/marketing/advisor_network/mobile/photo_1b.png'
import mobilePhoto1c from '../../../custom_assets/marketing/advisor_network/mobile/photo_1c.png'

import mobilePhoto2a from '../../../custom_assets/marketing/advisor_network/mobile/photo_2a.png'
import mobilePhoto2b from '../../../custom_assets/marketing/advisor_network/mobile/photo_2b.png'
import mobilePhoto2c from '../../../custom_assets/marketing/advisor_network/mobile/photo_2c.png'

const MarketingAdvisorNetwork = ({ user }) => {

  const [numAdvisors, setNumAdvisors] = useState<any>(undefined)

  const navigate = useNavigate()

  const faqData = [
    { question: 'How do the logistics work?', answer: 'After deciding which advisor you wish to connect with, you will receive an email to coordinate a specific meeting time that is mutually convenient for both you and the advisor (typically sessions are scheduled within one week.) The session will be conducted virtually (via Zoom).' },
    { question: 'How does this offering compare with hiring a "professional" private college counselor?', answer: 'NextFour Advisors are uniquely positioned to provide perspectives based on their own individual journeys through high school and into top-ranked colleges.  Advisors will have a natural depth and recency of experience - with the admissions process, student life at their college, pursuing their major, etc. - that is unlikely to be matched by a professional college counselor. Moreover, this offering is designed to be signifcantly more affordable/accessible than typical private counselor rates.' },
    { question: `Can I meet with an advisor more than once? Can I meet with multiple advisors?`, answer: 'Yes, this service is designed to be highly flexible and with minimal upfront commitment. You start with purchasing a single session for a specific advisor, and can opt for additional sessions if you would like more time with that advisor, or to meet with additional advisors to get a range of perspectives.' }
  ]

  const testimonialData = [
    { statement: `“I learned alot more about the colleges I was applying to by meeting an Advisor than what I found online. The best part is that I could really focus the discussion on what was important to me, and as a result, I'm certain that my "why this college" and "why this major" essays ended up much stronger.”`, source: 'Kate', relation: 'High school student' },
    { statement: `“I really wanted to do the NSLI-Y language immersion summer program but knew it was super competitive. So I used the NextFour network to connect with a student at Columbia University who did NSLI-Y when she was in high school. That discussion was invaluable in understanding the experience, which helped shape my application essay, prepare for the interviews, and ultimately get accepted!”`, source: 'Sam', relation: 'High school student' }
  ]

  // now used only in nav following #816
  const advisorNetworkExploreButton = (nav = false) => {
    return (
      <div
        className={nav ? `dca-marketing-access-duration-purchase-button-nav dca-marketing-access-duration-purchase-button-nav-text-advisor` : `dca-marketing-access-duration-purchase-button dca-marketing-access-duration-purchase-button-text`}
        style={{ margin: nav ? '0px 15px 0px 0px' : '50px 0px 140px 0px', cursor: 'pointer', padding: nav ? '10px 12px' : '' }}
        onClick={() => { navigate(pageRoutes.advisorSearch) }}>Explore
      </div>
    )
  }

  const advisorNetworkExploreButtonInline = (marginTop = '50px', marginBottom = '140px') => {
    return <div className="dca-marketing-access-duration-and-purchase-button"
      style={{ marginTop: marginTop, marginBottom: marginBottom }}>
      <div
        className="d-flex flex-column align-self-center">
        <div
          className="dca-marketing-access-duration-price align-items-center">{typeof numAdvisors === "number" && !isNaN(numAdvisors) ? numAdvisors : "N/A"} Advisors Available</div>
      </div>

      <div className="dca-marketing-access-duration-and-purchase-button-spacer"></div>

      <button
        className='dca-marketing-access-duration-purchase-button'
        onClick={() => { navigate(pageRoutes.advisorSearch) }}
      ><span className="dca-marketing-access-duration-purchase-button-text">Explore</span></button>
    </div>
  }

  const marketingContent = (
    <>

      <div className="dca-marketing-page-headline" style={{ marginTop: '59px' }}>Consult with current college students to benefit from their relevant experiences</div>

      <div className="dca-marketing-sub-headline">Sometimes the advice you really need can best be provided by recent high school graduates who have first-hand experience with the specific extracurricular activities, colleges and majors of interest to you</div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-block'>
        <MarketingImageWrapper
          altText={'advisor_collage'}
          photo={photo1} />
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-block d-md-none'>
        <MarketingImageWrapper
          altText={'advisor_collage_row_1'}
          photo={mobilePhoto1a} />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'advisor_collage_row_2'}
          photo={mobilePhoto1b} />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'advisor_collage_row_3'}
          photo={mobilePhoto1c} />
      </div>

      {/* {advisorNetworkExploreButton()} */}
      {advisorNetworkExploreButtonInline()}

      <div className='dca-marketing-text-image-block' id='TIB-175'>
        <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '55px' }}>
          <div className="dca-marketing-pt-header">Connect with college students to learn from their successful journeys through high school and into college</div>
          <div className="dca-marketing-pt-body">Explore the NextFour network to discover Advisors attending your dream school, majoring in your intended field, and/or who particpated in relevant extracurricular activities while in high school</div>
        </div>


        {/* Use Bootstrap d-md-block to render while 768px or above */}
        <div className='d-none d-md-block'>
          <MarketingImageWrapper
            altText={'advisor_cards'}
            photo={photo2} />
        </div>

        {/* Mobile Replacement, only shows at below 768px */}
        <div className='d-block d-md-none'>
          <MarketingImageWrapper
            altText={'advisor_cards_2'}
            photo={mobilePhoto2a} />

          <div style={{ marginTop: '15px' }}></div>

          <MarketingImageWrapper
            altText={'advisor_cards_3'}
            photo={mobilePhoto2b} />

          <div style={{ marginTop: '15px' }}></div>

          <MarketingImageWrapper
            altText={'advisor_collage_row_2'}
            photo={mobilePhoto2c} />

        </div>

        <div className='dca-marketing-disclaimer-text' style={{ marginBottom: '0px' }}>Note: Images above are meant to be illustrative of the Advisors network and currently available Advisor profiles may vary.</div>
      </div>

      <div className='dca-marketing-text-image-block' id='TIB-120'>
        <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '5px' }}>
          <div className="dca-marketing-pt-header">Receive mentorship in your college planning and application process</div>
          <div className="dca-marketing-pt-body">You will meet (virtually) 1:1 with the advisor(s) you select to discuss key topics that could give you a competitive advantage as you navigate the journey towards college</div>
        </div>

        <MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={photo3}
          wrapper='B' />
      </div>

      <div className='dca-marketing-text-image-block' id='TIB-25'>
        <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '25px' }}>
          <div className="dca-marketing-pt-header">Get access to inspiring students at the top-ranked US colleges</div>
          <div className="dca-marketing-pt-body">NextFour advisors have recently gone through the challenging college planning and application process and were successfully admitted to the most selective colleges. Leverage their relevant experience and insights to help strenghten your own candidancy.</div>
        </div>

        <div style={{ marginRight: '20px' }}><MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={photo4}
          wrapper='D' /></div>
      </div>


      <FAQ faqData={faqData} advisor={true} />

      <ConnectEmailCTA />

      <Testimonials data={testimonialData} />

      {/* {advisorNetworkExploreButton()} */}{advisorNetworkExploreButtonInline('0px', '0px')}

      {/* <HoverCards cardsOption='SpecializedRankings' /> */}
    </>
  )

  useEffect(() => {
    const getNumAdvisors = async () => {
      let res = await axios.get(`${URL}/advisor_profiles_count/`)
      res = res?.data?.numAdvisors
      setNumAdvisors(res)
    }

    updateTitleAndDescription(pageRoutes.advisorNetwork)

    getNumAdvisors()
  }, [])

  return (<div className="d-flex flex-column align-items-center">
    <StickyNavbar user={user} purchaseButton={advisorNetworkExploreButton(true)} advisorNetworkNav={true} />
    {marketingContent}
  </div>)
}

export { MarketingAdvisorNetwork }