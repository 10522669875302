import Chart from 'react-apexcharts'

const StackedColumnChart = ({ chartId, xaxis, earlyAdmitArray, regularAdmitArray }) => {

  return (
    <Chart
      options={{
        chart: {
          id: chartId,
          stacked: true,
          stackType: '100%',
		  toolbar: {
			show: false,
			},
        },
        xaxis: {
          categories: xaxis,
          labels: {
            show: true,
            rotate: -45,
          }
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val) {
              return val.toFixed(0) + "%";
            }
          }
        },
        //colors: ['#98F29B', '#008FFB'],
        colors: ["#FFEF68", "#97E6FF"],
        tooltip: {
          enabled: false,
        },
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#000000'],
          },
          background: {
            enabled: false,
            borderWidth: 0,
            foreColor: '#000',
          },
          formatter: function (val: number, opts) {
            return val.toFixed(1) + "%";
          },

        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            dataLabels: {
              position: 'top',
            },
          }
        }
      }}
      series={[
        {
          name: 'Early Round Admits',
          group: 'Admits',
          data: earlyAdmitArray
        },
        {
          name: 'Regular Round Admits',
          group: 'Admits',
          data: regularAdmitArray
        },
      ]}
      type='bar'
      height={'300'}
    />
  )
}

const StackedColumnChartGender = ({ chartId, xaxis, menAdmittedArray, womenAdmittedArray, otherAdmittedArray, menEnrolledArray, womenEnrolledArray, otherEnrolledArray }) => {
  return (
    <Chart
      options={{
        chart: {
          id: chartId,
          stacked: true,
          stackType: 'normal',
		  toolbar: {
			show: false,
			},
        },
        xaxis: {
          categories: xaxis,
          labels: {
            show: true,
            rotate: -45,
          }
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val) {
              return val.toFixed(0) + "%";
            }
          }
        },
        //colors: ['#98F29B', '#008FFB'],
        //colors: ["#FFEF68", "#97E6FF"],
        colors: ["#90F892", "#FFEF68", "#C5DEF9", "#00E986", "#97E6FF", "767676"],
        tooltip: {
          enabled: true,
        },
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#000000'],
          },
          background: {
            enabled: false,
            borderWidth: 0,
            foreColor: '#000',
          },
          formatter: function (val: number, opts) {
            return val ? val.toFixed(1) + "%" : ''
          },

        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '75%',
            dataLabels: {
              position: 'top',
            },
          }
        }
      }}
      series={otherAdmittedArray.some(item => item != 0 && item != null) && otherEnrolledArray.some(item => item != 0 && item != null) ? [
        {
          name: 'Men Admits',
          group: 'Admits',
          data: menAdmittedArray
        },
        {
          name: 'Women Admits',
          group: 'Admits',
          data: womenAdmittedArray
        },
        {
          name: 'Other Admits',
          group: 'Admits',
          data: otherAdmittedArray
        },
        {
          name: 'Men Enrolled',
          group: 'Enrolled',
          data: menEnrolledArray
        },
        {
          name: 'Women Enrolled',
          group: 'Enrolled',
          data: womenEnrolledArray
        },
        {
          name: 'Other Enrolled',
          group: 'Enrolled',
          data: otherEnrolledArray
        }
      ] : [
        {
          name: 'Men Admits',
          group: 'Admits',
          data: menAdmittedArray
        },
        {
          name: 'Women Admits',
          group: 'Admits',
          data: womenAdmittedArray
        },
        {
          name: 'Men Enrolled',
          group: 'Enrolled',
          data: menEnrolledArray
        },
        {
          name: 'Women Enrolled',
          group: 'Enrolled',
          data: womenEnrolledArray
        }
      ]}
      type='bar'
      height={'300'}
    />
  )
}

export {
  StackedColumnChart,
  StackedColumnChartGender
}
