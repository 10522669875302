// import { useState, useEffect } from "react"
// import { useNavigate } from "react-router"
// import { pageRoutes } from "../dictionary"

// const StickyNavbar = (props) => {

//   const navigate = useNavigate()
//   const [showStickyNav, setShowStickyNav] = useState<any>(false)
//   const [isModalOpen, setIsModalOpen] = useState(false)

//   useEffect(() => {
//     const triggerStickyNav = () => {
//       if (window.scrollY > 80) {
//         setShowStickyNav(true)
//       } else {
//         setShowStickyNav(false)
//       }
//     }

//     window.addEventListener('scroll', triggerStickyNav)

//     return () => {
//       window.removeEventListener('scroll', triggerStickyNav)
//     }
//   }, [])

//   return showStickyNav && (
//     <div className='dca-stickyHeader'>
//       <div
//         className='dca-header-logo'
//         onClick={() => navigate(pageRoutes.home)}>
//         NEXTFOUR
//       </div>

//       {props.purchaseButton && props.purchaseButton}

//     </div>
//   )
// }

// export { StickyNavbar }






import { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import { pageRoutes } from "../dictionary"

const StickyNavbar = (props) => {
  const navigate = useNavigate()
  const [showStickyNav, setShowStickyNav] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const triggerStickyNav = () => {
      if (window.scrollY > 80 && !isModalOpen) {
        setShowStickyNav(true)
      } else {
        setShowStickyNav(false)
      }
    }

    const checkModalState = () => {
      const modalVisible = document.querySelector('.modal.fade.show') !== null
      setIsModalOpen(modalVisible)
    }

    window.addEventListener('scroll', triggerStickyNav)

    const modalObserver = new MutationObserver(checkModalState)
    modalObserver.observe(document.body, { attributes: true, childList: true, subtree: true })

    checkModalState()

    return () => {
      window.removeEventListener('scroll', triggerStickyNav)
      modalObserver.disconnect()
    }
  }, [isModalOpen])

  return showStickyNav && !isModalOpen ? (
    <div className='dca-stickyHeader'>
      <div
        className='dca-header-logo'
        onClick={() => navigate(pageRoutes.home)}>
        NEXTFOUR
      </div>

      {props.purchaseButton && props.purchaseButton}
    </div>
  ) : null
}

export { StickyNavbar }


