import { Name } from './components/Name'
import { Type } from './components/Type'
import { Location } from './components/Location'
import { Setting } from './components/Setting'
import { Size } from './components/Size'
import { Selectivity } from './components/Selectivity'
import { TestPolicy } from './components/TestPolicy'
import { AppDetails } from './components/AppDetails'
import { OnlyInterests } from './components/OnlyInterests'
import { AppDate } from './components/AppDate'
import { useState } from 'react'
import filterIcon from '../../../custom_assets/filter_icon.png'

const CollegeSearchAsideMobile = ({ filteredColleges, selectorNameOptions, selectorLocationOptions, setNameFilter, institutionTypeFilter, setInstitutionTypeFilter, setLocationFilter, setSettingFilter, setSizeFilter, selectivityMin, selectivityMax, setSelectivityMin, setSelectivityMax, setTestPolicyFilter, setEarlyAppOptionFilter, setCommonAppFilter, setCoalitionFilter, setIndividualAppFilter, setConsidersInterestFilter, earlyApplicationOfferedCount, commonAppCount, coalitionCount, individualAppCount, considersInterestCount, earlyAppOptionFilter, commonAppFilter, coalitionFilter, individualAppFilter, considersInterestFilter, collegeSearchLocation, setCollegeSearchLocation, setNameParams, setTypeParams, setLocationParams, setSettingParams, setSizeParams, setAdmitParams, setTestPolicyParams, setApplicationDetailsParams, onlyInterestsSelect, setOnlyInterestsSelect, setOnlyInterestsParams, appDateSelect, setAppDateSelect, setAppDateParams, resetFilterStates, resetStateToggle, filtersActive }) => {

  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)

  const toggleFilterOpen = () => {
    setMobileFilterOpen(!mobileFilterOpen)
  }

  return (
    <div className='dca-college-search-filter-drawer-container'>

      <button
        onClick={toggleFilterOpen}
        className={filtersActive > 0 ? 'dca-college-search-filters-button-bolded' : 'dca-college-search-filters-button'}><img src={filterIcon} alt="bars-filter" className="dca-college-filters-button-icon" />&nbsp;&nbsp;Filters {filtersActive > 0 && <span className='dca-filters-active-icon'>{filtersActive}</span>}</button>

      <div className={`dca-college-search-filter-drawer${mobileFilterOpen ? '-open' : ''}`} style={{ display: mobileFilterOpen ? 'flex' : 'none' }}>
        {/* <div className="dca-college-search-filter-drawer-header">
          <button className="dca-college-search-filter-drawer-close-button" onClick={toggleFilterOpen}>
            &times;
          </button>
        </div> */}

        <div className='dca-college-search-mobile-filters-wrapper'>
          <div className='dca-college-search-filter-drawer-header'>
            <div className='dca-college-search-mobile-filters-title'>Filters</div>

            <div
              className="dca-college-search-filter-drawer-close-button"
              onClick={toggleFilterOpen}>
              &times;
            </div>
          </div>
          <div style={{ padding: '0px 15px 25px 15px', overflowY: 'auto' }}>

            <div style={{ zIndex: 8 }}>
              <Name selectorNameOptions={selectorNameOptions} setNameFilter={setNameFilter} setNameParams={setNameParams} resetStateToggle={resetStateToggle} />
            </div>

            <div style={{ zIndex: 7 }}>
              <Type institutionTypeFilter={institutionTypeFilter} setInstitutionTypeFilter={setInstitutionTypeFilter} setTypeParams={setTypeParams} />
            </div>

            <div style={{ zIndex: 6 }}>
              <Location setLocationFilter={setLocationFilter} selectorLocationOptions={selectorLocationOptions} setLocationParams={setLocationParams} resetStateToggle={resetStateToggle} />
            </div>

            <div style={{ zIndex: 5 }}>
              <Setting setSettingFilter={setSettingFilter} setSettingParams={setSettingParams} resetStateToggle={resetStateToggle} />
            </div>

            <div style={{ zIndex: 4 }}>
              <Size setSizeFilter={setSizeFilter} setSizeParams={setSizeParams} resetStateToggle={resetStateToggle} />
            </div>

            <div style={{ zIndex: 1 }}>
              <Selectivity
                selectivityMin={selectivityMin}
                selectivityMax={selectivityMax}
                setSelectivityMin={setSelectivityMin}
                setSelectivityMax={setSelectivityMax}
                setAdmitParams={setAdmitParams}
                resetStateToggle={resetStateToggle} />
            </div>

            <TestPolicy setTestPolicyFilter={setTestPolicyFilter} setTestPolicyParams={setTestPolicyParams} resetStateToggle={resetStateToggle} />

            <AppDate
              appDateSelect={appDateSelect}
              setAppDateSelect={setAppDateSelect}
              setAppDateParams={setAppDateParams} />

            <AppDetails
              filteredColleges={filteredColleges}
              setEarlyAppOptionFilter={setEarlyAppOptionFilter}
              setCoalitionFilter={setCoalitionFilter}
              setCommonAppFilter={setCommonAppFilter}
              setConsidersInterestFilter={setConsidersInterestFilter}
              setIndividualAppFilter={setIndividualAppFilter}
              earlyApplicationOfferedCount={earlyApplicationOfferedCount}
              commonAppCount={commonAppCount}
              coalitionCount={coalitionCount}
              individualAppCount={individualAppCount}
              considersInterestCount={considersInterestCount}
              earlyAppOptionFilter={earlyAppOptionFilter}
              commonAppFilter={commonAppFilter}
              coalitionFilter={coalitionFilter}
              individualAppFilter={individualAppFilter}
              considersInterestFilter={considersInterestFilter}
              setApplicationDetailsParams={setApplicationDetailsParams}
            />

            <OnlyInterests onlyInterestsSelect={onlyInterestsSelect} setOnlyInterestsSelect={setOnlyInterestsSelect} setOnlyInterestsParams={setOnlyInterestsParams} />
          </div>


          <div className='dca-college-search-mobile-filters-drawer-footer'>
            <span className='dca-college-search-mobile-filters-drawer-footer-clear'
              onClick={() => {
                resetFilterStates()
              }}>Clear all</span>

            <button onClick={toggleFilterOpen} className='dca-college-search-mobile-filters-drawer-footer-view-results'>View Results</button>
          </div>

        </div>
      </div>


    </div>
  )
}

export { CollegeSearchAsideMobile }