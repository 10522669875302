import React, { Suspense } from 'react'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_metronic/partials'
import { Outlet } from 'react-router-dom'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ReactGA from "react-ga4"

const App = () => {

  const GA_measurement_id = process.env.REACT_APP_GA_ID ?? ''

  if (GA_measurement_id != '') {
    ReactGA.initialize(GA_measurement_id)
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LocalizationProvider>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
