import { useState, useEffect } from "react"
import { RankColorRange } from "../../RankColorRange"
import { RankIndicator } from "../../RankIndicator"
import { URL } from "../../dictionary"
import Chart from 'react-apexcharts'
import axios from "axios"
import { useParams } from "react-router"
import { TooltipComponent, TooltipComponentMobile } from "../../Tooltip"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { SafetyAnnualAverageTotalModal } from "./Modals/SafetyAnnualAverageTotalModal"
import { SafetyAnnualAveragePer1KModal } from "./Modals/SafetyAnnualAveragePer1KModal"
import { SafetyAggregateModal } from "./Modals/SafetyAggregateModal"
import { getAuth } from "../../../modules/auth";
import { LoadingIndicator } from "../../LoadingIndicator"

const returnToFixed1 = (value) => {
  if (value || value != null) {
    return value.toFixed(1)
  }

  else {
    return '-'
  }
}

const returnToFixed3 = (value) => {
  if (value || value != null) {
    return value.toFixed(3)
  }

  else {
    return '-'
  }
}

const Safety = ({ college, crimeAnnual, collegeListLength, collegesId, setSelectedTab, setSafetyTotalModalData, setSAModalData, setSafety1KModalData, pageView, mobile = false }) => {

  const returnTotalRankModalTrigger = (modalTitle, modalCategory, rank) => {
    return (<div
      style={{ cursor: 'pointer' }}
      data-bs-toggle="modal"
      data-bs-target="#safety_total_modal"
      onClick={() => {
        setModalTitle(modalTitle);
        setModalCategory(modalCategory);

        setSafetyTotalModalData(prev => ({
          ...prev, modalTitle: modalTitle, modalCategory: modalCategory
        }))
      }}
    >
      <OverlayTrigger
        delay={{ hide: 450, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props}>
            {`${modalTitle}: Annual Average Total Number Of Incidents`}
          </Tooltip>
        )}
        placement="top"
      ><div><RankIndicator rank={rank} clickable={true} /></div>
      </OverlayTrigger>
    </div>)
  }

  const returnPer1KRankModalTrigger = (modalTitle, modalCategory, rank) => {
    return (<div
      style={{ cursor: 'pointer' }}
      data-bs-toggle="modal"
      data-bs-target="#safety_per_1k_modal"
      onClick={() => {
        setModalTitle(modalTitle);
        setModalCategory(modalCategory);

        setSafety1KModalData(prev => ({
          ...prev, modalTitle: modalTitle, modalCategory: modalCategory
        }))
      }}
    >
      <OverlayTrigger
        delay={{ hide: 450, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props}>
            {`${modalTitle}: Annual Average Number Of Incidents Per 1K Students`}
          </Tooltip>
        )}
        placement="top"
      ><div><RankIndicator rank={rank} clickable={true} /></div>
      </OverlayTrigger>
    </div>)
  }

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  if (apiToken === undefined) {
    setSelectedTab('Admission Info')
  }

  // For college ID
  const [crimeAvgTotalData, setCrimeAvgTotal] = useState<any>({})
  const [crimeRankTotalData, setCrimeRankTotal] = useState<any>({})
  const [crimeAvgPer1KData, setCrimeAvgPer1K] = useState<any>({})
  const [crimeRankPer1KData, setCrimeRankPer1K] = useState<any>({})

  // For modals, all colleges
  const [allCrimeAvgTotalData, setAllCrimeAvgTotal] = useState<any>([])
  const [allCrimeRankTotalData, setAllCrimeRankTotal] = useState<any>([])
  const [allCrimeAvgPer1KData, setAllCrimeAvgPer1K] = useState<any>([])
  const [allCrimeRankPer1KData, setAllCrimeRankPer1K] = useState<any>([])
  const [collegesSafetyIndex, setCollegesSafetyIndex] = useState<any>([])

  const [dataLoaded, setDataLoaded] = useState(false)
  const [modalCategory, setModalCategory] = useState('Criminal Offenses')
  const [modalTitle, setModalTitle] = useState('Murder/Non-negligent manslaughter')

  // 0 - 2 index, chronological order
  let year0: any = '-'
  let year1: any = '-'
  let year2: any = '-'

  useEffect(() => {
    const fetchData = async () => {
      try {

        if (dataLoaded === true) {
          setDataLoaded(false);
          setSafety1KModalData(prev => ({
            ...prev, dataLoaded: false
          }));
          setSafetyTotalModalData(prev => ({
            ...prev, dataLoaded: false
          }));
          setSAModalData(prev => ({
            ...prev, dataLoaded: false
          }));
        }

        const crimeAvgTotalAxios = axios.get(`${URL}/crime_average_of_total/${college.id}`)
        const crimeRankTotalAxios = axios.get(`${URL}/crime_rank/${college.id}`)
        const crimeAvgPer1KAxios = axios.get(`${URL}/crime_average_per_1K/${college.id}`)
        const crimeRankPer1KAxios = axios.get(`${URL}/crime_rank_per_1K/${college.id}`)
        const collegesSafetyIndex = axios.get(`${URL}/colleges-safetyIndex/`)

        const allCrimeAvgTotalAxios = axios.get(`${URL}/crime_average_of_total/`)
        const allCrimeRankTotalAxios = axios.get(`${URL}/crime_rank/`)
        const allCrimeAvgPer1KAxios = axios.get(`${URL}/crime_average_per_1K/`)
        const allCrimeRankPer1KAxios = axios.get(`${URL}/crime_rank_per_1K/`)

        const [crimeAvgTotalRes, crimeRankTotalRes, crimeAvgPer1KRes, crimeRankPer1KRes, allCrimeAvgTotalRes, allCrimeRankTotalRes, allCrimeAvgPer1KRes, allCrimeRankPer1KRes, collegesSafetyIndexRes] = await Promise.all([
          crimeAvgTotalAxios,
          crimeRankTotalAxios,
          crimeAvgPer1KAxios,
          crimeRankPer1KAxios,
          allCrimeAvgTotalAxios,
          allCrimeRankTotalAxios,
          allCrimeAvgPer1KAxios,
          allCrimeRankPer1KAxios,
          collegesSafetyIndex
        ]);

        // console.log('college safety index', collegesSafetyIndexRes.data)

        await Promise.all([
          setCrimeAvgTotal(crimeAvgTotalRes.data),
          setCrimeRankTotal(crimeRankTotalRes.data),
          setCrimeAvgPer1K(crimeAvgPer1KRes.data),
          setCrimeRankPer1K(crimeRankPer1KRes.data),

          setAllCrimeAvgTotal(allCrimeAvgTotalRes.data),
          setAllCrimeRankTotal(allCrimeRankTotalRes.data),
          setAllCrimeAvgPer1K(allCrimeAvgPer1KRes.data),
          setAllCrimeRankPer1K(allCrimeRankPer1KRes.data),

          setSafetyTotalModalData(prev => ({
            ...prev, allCrimeAvgTotalData: allCrimeAvgTotalRes.data, allCrimeRankTotalData: allCrimeRankTotalRes.data
          })),
          setSafety1KModalData(prev => ({
            ...prev, allCrimeAvgPer1KData: allCrimeAvgPer1KRes.data, allCrimeRankPer1KData: allCrimeRankPer1KRes.data
          })),

          setSAModalData(prev => ({
            ...prev, collegesSafetyIndex: collegesSafetyIndexRes.data
          })),

          setCollegesSafetyIndex(collegesSafetyIndexRes.data)
        ]);

        setDataLoaded(true);
        setSafety1KModalData(prev => ({
          ...prev, dataLoaded: true
        }));
        setSafetyTotalModalData(prev => ({
          ...prev, dataLoaded: true
        }));
        setSAModalData(prev => ({
          ...prev, dataLoaded: true
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, [college])

  let crimeAvgTotalCheck = false
  let crimeRankTotalCheck = false
  let crimeAvgPer1KCheck = false
  let crimeRankPer1KCheck = false

  if (Object.keys(crimeAvgTotalData).length != 0) {
    crimeAvgTotalCheck = true
  }

  if (Object.keys(crimeRankTotalData).length != 0) {
    crimeRankTotalCheck = true
  }

  if (Object.keys(crimeAvgPer1KData).length !== 0) {
    crimeAvgPer1KCheck = true
  }

  if (Object.keys(crimeRankPer1KData).length !== 0) {
    crimeRankPer1KCheck = true
  }

  let crimeAvg: any = []
  let crimeRank: any = []
  let crimeAvgPer1K: any = []
  let crimeRankPer1K: any = []

  let crimeAnnual1: any = []
  let crimeAnnual2: any = []
  let crimeAnnual3: any = []
  let crimeAnnual4: any = []
  let crimeAnnual5: any = []

  //Charts
  let chartXaxis: any = []

  //Total, CrimeAnnualStats
  let criminalTotalData: number[] = []
  let vawaTotalData: number[] = []
  let arrestsTotalData: number[] = []
  let disciplinaryTotalData: number[] = []
  let hateTotalData: number[] = []

  //Average, Per 1K, Crime Average Per 1K
  let criminalPer1KData: number[] = []
  let vawaPer1KData: number[] = []
  let arrestsPer1KData: number[] = []
  let disciplinaryPer1KData: number[] = []
  let hatePer1KData: number[] = []

  if (college?.crime_annual && college?.crime_average_1k) {

    let crimeAnnualTotal = college?.crime_annual?.filter((item) => item.location == 5)
    crimeAnnualTotal.sort((a, b) => a.year - b.year)

    let i = 0
    if (i < 4) {

      try {
        crimeAnnualTotal.map((res) => {
          chartXaxis.push(res.year)
        })
        i = i++

      } catch (error) {
        console.log(error)
      }

    }

    const returnValues = (data, arrayVariable, fieldName) => {
      let i = 0
      if (i < 4) {

        try {
          data.map((res) => {
            arrayVariable.push(res[fieldName])
          })
          i = i++
        } catch (error) {
          console.log(error)
        }
      }
    }

    const returnValuesPer1K = (numStudents, data, arrayVariable, fieldName) => {
      let i = 0
      if (i < 4) {

        try {
          data.map((res) => {
            let val = res[fieldName] / numStudents
            arrayVariable.push(val.toFixed(3))
          })
          i = i++
        } catch (error) {
          console.log(error)
        }
      }
    }

    //Get AnnualStats Data
    returnValues(crimeAnnualTotal, criminalTotalData, 'criminalTotal')
    returnValues(crimeAnnualTotal, vawaTotalData, 'vawaTotal')
    returnValues(crimeAnnualTotal, arrestsTotalData, 'arrestsTotal')
    returnValues(crimeAnnualTotal, disciplinaryTotalData, 'disciplinaryTotal')
    returnValues(crimeAnnualTotal, hateTotalData, 'hateTotal')

    //Get Average 1K Data
    returnValuesPer1K(college.numStudents, crimeAnnualTotal, criminalPer1KData, 'criminalTotal')
    returnValuesPer1K(college.numStudents, crimeAnnualTotal, vawaPer1KData, 'vawaTotal')
    returnValuesPer1K(college.numStudents, crimeAnnualTotal, arrestsPer1KData, 'arrestsTotal')
    returnValuesPer1K(college.numStudents, crimeAnnualTotal, disciplinaryPer1KData, 'disciplinaryTotal')
    returnValuesPer1K(college.numStudents, crimeAnnualTotal, hatePer1KData, 'hateTotal')

  }

  // Criminal
  let criminalArray: any = [
    {
      'Murder/Non-negligent manslaughter':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Negligent manslaughter':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Rape':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Fondling':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Incest':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Statutory Rape':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Robbery':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Aggravated assault':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Burglary':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Motor vehicle theft':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Arson':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    }
  ]
  let criminalTotal: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
  let [tcTotalAvg, tcTotalRank, tcPer1K, tcPer1KRank]: any = ['-', '-', '-', '-']

  // VAWA
  let vawaArray: any = [
    {
      'Domestic violence':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Dating violence':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Stalking':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    }
  ]
  let vawaTotal: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
  let [vawaTotalAvg, vawaTotalRank, vawaPer1K, vawaPer1KRank]: any = ['-', '-', '-', '-']

  // Arrests
  let arrestsArray: any = [
    {
      'Drug Abuse':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Liquor Abuse':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Weapons':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    }
  ]
  let arrestsTotal: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
  let [arrestsTotalAvg, arrestsTotalRank, arrestsPer1K, arrestsPer1KRank]: any = ['-', '-', '-', '-']

  // Disciplinary
  let disciplinaryArray: any = [
    {
      'Drug Abuse':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Liquor Abuse':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Weapons':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    }
  ]
  let disciplinaryTotal: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
  let [disciplinaryTotalAvg, disciplinaryTotalRank, disciplinaryPer1K, disciplinaryPer1KRank]: any = ['-', '-', '-', '-']

  // Hate
  let hateArray: any = [
    {
      'Aggravated assault':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Arson':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Burglary':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Destruction':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Fondling':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Incest':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Intimidation':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Larceny':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Motor Vehicle Theft':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Murder':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Rape':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Simple Assault':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    },
    {
      'Statutory Rape':
        ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
    }
  ]
  let hateTotal: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']
  let [hateTotalAvg, hateTotalRank, hatePer1K, hatePer1KRank]: any = ['-', '-', '-', '-']

  let safetyStatsOnCampusHousing: any = []
  let safetyStatsOnCampusNonHousing: any = []
  let safetyStatsNonCampus: any = []
  let safetyStatsPublic: any = []


  if ((!college || college && (Array.isArray(college) && college.length == 0)) || (!crimeAnnual || crimeAnnual && Array.isArray(crimeAnnual) && crimeAnnual.length == 0)
  ) {
    return <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: '150px' }}>
      <LoadingIndicator text={"Loading Data ... "} />
    </div>
  }

  if (college && crimeAnnual && crimeAvgTotalCheck && crimeAvgPer1KCheck && crimeRankPer1KCheck) {
    crimeAvg = college.crime_average
    crimeRank = college.crime_rank
    crimeAvgPer1K = college.crime_average_1k
    crimeRankPer1K = college.crime_rank_1k
    // 1 = on campus housing
    // 2 = on campus total
    // 3 = non campus
    // 4 = public 
    // 5 = total

    crimeAnnual1 = crimeAnnual.filter((item) => item.location === 1)
    crimeAnnual2 = crimeAnnual.filter((item) => item.location === 2)
    crimeAnnual3 = crimeAnnual.filter((item) => item.location === 3)
    crimeAnnual4 = crimeAnnual.filter((item) => item.location === 4)
    crimeAnnual5 = crimeAnnual.filter((item) => item.location === 5)

    crimeAnnual1.sort((a, b) => a.year - b.year)
    crimeAnnual2.sort((a, b) => a.year - b.year)
    crimeAnnual3.sort((a, b) => a.year - b.year)
    crimeAnnual4.sort((a, b) => a.year - b.year)
    crimeAnnual5.sort((a, b) => a.year - b.year)

    year0 = crimeAnnual1[0].year
    year1 = crimeAnnual1[1].year
    year2 = crimeAnnual1[2].year


    const calculateTotal = (crimeArray, index) => {
      return crimeArray.reduce((total, item) => {
        const values = item[Object.keys(item)[0]];
        if (values && values.length > 0) {
          (typeof values[index] == 'number') ? total += values[index] : total = total
        }
        return total;
      }, 0);
    }

    //////// Crime Table values ////////
    criminalArray = [
      {
        'Murder/Non-negligent manslaughter':
          [crimeAnnual1[0].murder, crimeAnnual1[1].murder, crimeAnnual1[2].murder, crimeAnnual2[0].murder, crimeAnnual2[1].murder, crimeAnnual2[2].murder, crimeAnnual3[0].murder, crimeAnnual3[1].murder, crimeAnnual3[2].murder, crimeAnnual4[0].murder, crimeAnnual4[1].murder, crimeAnnual4[2].murder, returnToFixed1(crimeAvg.murder), crimeRank.murder, returnToFixed3(crimeAvgPer1K.murder), crimeRankPer1K.murder]
      },
      {
        'Negligent manslaughter':
          [crimeAnnual1[0].negManslaughter, crimeAnnual1[1].negManslaughter, crimeAnnual1[2].negManslaughter, crimeAnnual2[0].negManslaughter, crimeAnnual2[1].negManslaughter, crimeAnnual2[2].negManslaughter, crimeAnnual3[0].negManslaughter, crimeAnnual3[1].negManslaughter, crimeAnnual3[2].negManslaughter, crimeAnnual4[0].negManslaughter, crimeAnnual4[1].negManslaughter, crimeAnnual4[2].negManslaughter, returnToFixed1(crimeAvg.negManslaughter), crimeRank.negManslaughter, returnToFixed3(crimeAvgPer1K.negManslaughter), crimeRankPer1K.negManslaughter]
      },
      {
        'Rape':
          [crimeAnnual1[0].rape, crimeAnnual1[1].rape, crimeAnnual1[2].rape, crimeAnnual2[0].rape, crimeAnnual2[1].rape, crimeAnnual2[2].rape, crimeAnnual3[0].rape, crimeAnnual3[1].rape, crimeAnnual3[2].rape, crimeAnnual4[0].rape, crimeAnnual4[1].rape, crimeAnnual4[2].rape, returnToFixed1(crimeAvg.rape), crimeRank.rape, returnToFixed3(crimeAvgPer1K.rape), crimeRankPer1K.rape]
      },
      {
        'Fondling':
          [crimeAnnual1[0].fondling, crimeAnnual1[1].fondling, crimeAnnual1[2].fondling, crimeAnnual2[0].fondling, crimeAnnual2[1].fondling, crimeAnnual2[2].fondling, crimeAnnual3[0].fondling, crimeAnnual3[1].fondling, crimeAnnual3[2].fondling, crimeAnnual4[0].fondling, crimeAnnual4[1].fondling, crimeAnnual4[2].fondling, returnToFixed1(crimeAvg.fondling), crimeRank.fondling, returnToFixed3(crimeAvgPer1K.fondling), crimeRankPer1K.fondling]
      },
      {
        'Incest':
          [crimeAnnual1[0].incest, crimeAnnual1[1].incest, crimeAnnual1[2].incest, crimeAnnual2[0].incest, crimeAnnual2[1].incest, crimeAnnual2[2].incest, crimeAnnual3[0].incest, crimeAnnual3[1].incest, crimeAnnual3[2].incest, crimeAnnual4[0].incest, crimeAnnual4[1].incest, crimeAnnual4[2].incest, returnToFixed1(crimeAvg.incest), crimeRank.incest, returnToFixed3(crimeAvgPer1K.incest), crimeRankPer1K.incest]
      },
      {
        'Statutory Rape':
          [crimeAnnual1[0].statRape, crimeAnnual1[1].statRape, crimeAnnual1[2].statRape, crimeAnnual2[0].statRape, crimeAnnual2[1].statRape, crimeAnnual2[2].statRape, crimeAnnual3[0].statRape, crimeAnnual3[1].statRape, crimeAnnual3[2].statRape, crimeAnnual4[0].statRape, crimeAnnual4[1].statRape, crimeAnnual4[2].statRape, returnToFixed1(crimeAvg.statRape), crimeRank.statRape, returnToFixed3(crimeAvgPer1K.statRape), crimeRankPer1K.statRape]
      },
      {
        'Robbery':
          [crimeAnnual1[0].robbery, crimeAnnual1[1].robbery, crimeAnnual1[2].robbery, crimeAnnual2[0].robbery, crimeAnnual2[1].robbery, crimeAnnual2[2].robbery, crimeAnnual3[0].robbery, crimeAnnual3[1].robbery, crimeAnnual3[2].robbery, crimeAnnual4[0].robbery, crimeAnnual4[1].robbery, crimeAnnual4[2].robbery, returnToFixed1(crimeAvg.robbery), crimeRank.robbery, returnToFixed3(crimeAvgPer1K.robbery), crimeRankPer1K.robbery]
      },
      {
        'Aggravated assault':
          [crimeAnnual1[0].aggAssault, crimeAnnual1[1].aggAssault, crimeAnnual1[2].aggAssault, crimeAnnual2[0].aggAssault, crimeAnnual2[1].aggAssault, crimeAnnual2[2].aggAssault, crimeAnnual3[0].aggAssault, crimeAnnual3[1].aggAssault, crimeAnnual3[2].aggAssault, crimeAnnual4[0].aggAssault, crimeAnnual4[1].aggAssault, crimeAnnual4[2].aggAssault, returnToFixed1(crimeAvg.aggAssault), crimeRank.aggAssault, returnToFixed3(crimeAvgPer1K.aggAssault), crimeRankPer1K.aggAssault]
      },
      {
        'Burglary':
          [crimeAnnual1[0].burglary, crimeAnnual1[1].burglary, crimeAnnual1[2].burglary, crimeAnnual2[0].burglary, crimeAnnual2[1].burglary, crimeAnnual2[2].burglary, crimeAnnual3[0].burglary, crimeAnnual3[1].burglary, crimeAnnual3[2].burglary, crimeAnnual4[0].burglary, crimeAnnual4[1].burglary, crimeAnnual4[2].burglary, returnToFixed1(crimeAvg.burglary), crimeRank.burglary, returnToFixed3(crimeAvgPer1K.burglary), crimeRankPer1K.burglary]
      },
      {
        'Motor vehicle theft':
          [crimeAnnual1[0].motorVehicleTheft, crimeAnnual1[1].motorVehicleTheft, crimeAnnual1[2].motorVehicleTheft, crimeAnnual2[0].motorVehicleTheft, crimeAnnual2[1].motorVehicleTheft, crimeAnnual2[2].motorVehicleTheft, crimeAnnual3[0].motorVehicleTheft, crimeAnnual3[1].motorVehicleTheft, crimeAnnual3[2].motorVehicleTheft, crimeAnnual4[0].motorVehicleTheft, crimeAnnual4[1].motorVehicleTheft, crimeAnnual4[2].motorVehicleTheft, returnToFixed1(crimeAvg.motorVehicleTheft), crimeRank.motorVehicleTheft, returnToFixed3(crimeAvgPer1K.motorVehicleTheft), crimeRankPer1K.motorVehicleTheft]
      },
      {
        'Arson':
          [crimeAnnual1[0].arson, crimeAnnual1[1].arson, crimeAnnual1[2].arson, crimeAnnual2[0].arson, crimeAnnual2[1].arson, crimeAnnual2[2].arson, crimeAnnual3[0].arson, crimeAnnual3[1].arson, crimeAnnual3[2].arson, crimeAnnual4[0].arson, crimeAnnual4[1].arson, crimeAnnual4[2].arson, returnToFixed1(crimeAvg.arson), crimeRank.arson, returnToFixed3(crimeAvgPer1K.arson), crimeRankPer1K.arson]
      }
    ]
    // A, B, C for years 1, 2, 3
    let [tcA1, tcB1, tcC1, tcA2, tcB2, tcC2, tcA3, tcB3, tcC3, tcA4, tcB4, tcC4]: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']

    tcA1 = calculateTotal(criminalArray, 0)
    tcB1 = calculateTotal(criminalArray, 1)
    tcC1 = calculateTotal(criminalArray, 2)
    tcA2 = calculateTotal(criminalArray, 3)
    tcB2 = calculateTotal(criminalArray, 4)
    tcC2 = calculateTotal(criminalArray, 5)
    tcA3 = calculateTotal(criminalArray, 6)
    tcB3 = calculateTotal(criminalArray, 7)
    tcC3 = calculateTotal(criminalArray, 8)
    tcA4 = calculateTotal(criminalArray, 9)
    tcB4 = calculateTotal(criminalArray, 10)
    tcC4 = calculateTotal(criminalArray, 11)

    criminalTotal = [tcA1, tcB1, tcC1, tcA2, tcB2, tcC2, tcA3, tcB3, tcC3, tcA4, tcB4, tcC4]


    tcTotalAvg = crimeAvgTotalData.criminalTotal.toFixed(1)
    tcTotalRank = crimeRank.criminalTotal
    tcPer1K = crimeAvgPer1K.criminalTotal.toFixed(3)
    tcPer1KRank = crimeRankPer1KData.criminalTotal

    //////// VAWA Table Values ////////
    vawaArray = [
      {
        'Domestic violence':
          [crimeAnnual1[0].domesticViolence, crimeAnnual1[1].domesticViolence, crimeAnnual1[2].domesticViolence, crimeAnnual2[0].domesticViolence, crimeAnnual2[1].domesticViolence, crimeAnnual2[2].domesticViolence, crimeAnnual3[0].domesticViolence, crimeAnnual3[1].domesticViolence, crimeAnnual3[2].domesticViolence, crimeAnnual4[0].domesticViolence, crimeAnnual4[1].domesticViolence, crimeAnnual4[2].domesticViolence, returnToFixed1(crimeAvg.domesticViolence), crimeRank.domesticViolence, returnToFixed3(crimeAvgPer1K.domesticViolence), crimeRankPer1K.domesticViolence]
      },
      {
        'Dating violence':
          [crimeAnnual1[0].datingViolence, crimeAnnual1[1].datingViolence, crimeAnnual1[2].datingViolence, crimeAnnual2[0].datingViolence, crimeAnnual2[1].datingViolence, crimeAnnual2[2].datingViolence, crimeAnnual3[0].datingViolence, crimeAnnual3[1].datingViolence, crimeAnnual3[2].datingViolence, crimeAnnual4[0].datingViolence, crimeAnnual4[1].datingViolence, crimeAnnual4[2].datingViolence, returnToFixed1(crimeAvg.datingViolence), crimeRank.datingViolence, returnToFixed3(crimeAvgPer1K.datingViolence), crimeRankPer1K.datingViolence]
      },
      {
        'Stalking':
          [crimeAnnual1[0].stalking, crimeAnnual1[1].stalking, crimeAnnual1[2].stalking, crimeAnnual2[0].stalking, crimeAnnual2[1].stalking, crimeAnnual2[2].stalking, crimeAnnual3[0].stalking, crimeAnnual3[1].stalking, crimeAnnual3[2].stalking, crimeAnnual4[0].stalking, crimeAnnual4[1].stalking, crimeAnnual4[2].stalking, returnToFixed1(crimeAvg.stalking), crimeRank.stalking, returnToFixed3(crimeAvgPer1K.stalking), crimeRankPer1K.stalking]
      }
    ]
    let [vawaA1, vawaB1, vawaC1, vawaA2, vawaB2, vawaC2, vawaA3, vawaB3, vawaC3, vawaA4, vawaB4, vawaC4]: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']

    vawaA1 = calculateTotal(vawaArray, 0)
    vawaB1 = calculateTotal(vawaArray, 1)
    vawaC1 = calculateTotal(vawaArray, 2)
    vawaA2 = calculateTotal(vawaArray, 3)
    vawaB2 = calculateTotal(vawaArray, 4)
    vawaC2 = calculateTotal(vawaArray, 5)
    vawaA3 = calculateTotal(vawaArray, 6)
    vawaB3 = calculateTotal(vawaArray, 7)
    vawaC3 = calculateTotal(vawaArray, 8)
    vawaA4 = calculateTotal(vawaArray, 9)
    vawaB4 = calculateTotal(vawaArray, 10)
    vawaC4 = calculateTotal(vawaArray, 11)

    vawaTotal = [vawaA1, vawaB1, vawaC1, vawaA2, vawaB2, vawaC2, vawaA3, vawaB3, vawaC3, vawaA4, vawaB4, vawaC4]

    vawaTotalAvg = crimeAvgTotalData.vawaTotal.toFixed(1)
    vawaTotalRank = crimeRank.vawaTotal
    vawaPer1K = crimeAvgPer1K.vawaTotal.toFixed(3)
    vawaPer1KRank = crimeRankPer1KData.vawaTotal


    //////// Arrests Table Values ////////
    arrestsArray = [
      {
        'Drug Abuse':
          [crimeAnnual1[0].arrestsDrugAbuse, crimeAnnual1[1].arrestsDrugAbuse, crimeAnnual1[2].arrestsDrugAbuse, crimeAnnual2[0].arrestsDrugAbuse, crimeAnnual2[1].arrestsDrugAbuse, crimeAnnual2[2].arrestsDrugAbuse, crimeAnnual3[0].arrestsDrugAbuse, crimeAnnual3[1].arrestsDrugAbuse, crimeAnnual3[2].arrestsDrugAbuse, crimeAnnual4[0].arrestsDrugAbuse, crimeAnnual4[1].arrestsDrugAbuse, crimeAnnual4[2].arrestsDrugAbuse, returnToFixed1(crimeAvg.arrestsDrugAbuse), crimeRank.arrestsDrugAbuse, returnToFixed3(crimeAvgPer1K.arrestsDrugAbuse), crimeRankPer1K.arrestsDrugAbuse]
      },
      {
        'Liquor Abuse':
          [crimeAnnual1[0].arrestsLiquorAbuse, crimeAnnual1[1].arrestsLiquorAbuse, crimeAnnual1[2].arrestsLiquorAbuse, crimeAnnual2[0].arrestsLiquorAbuse, crimeAnnual2[1].arrestsLiquorAbuse, crimeAnnual2[2].arrestsLiquorAbuse, crimeAnnual3[0].arrestsLiquorAbuse, crimeAnnual3[1].arrestsLiquorAbuse, crimeAnnual3[2].arrestsLiquorAbuse, crimeAnnual4[0].arrestsLiquorAbuse, crimeAnnual4[1].arrestsLiquorAbuse, crimeAnnual4[2].arrestsLiquorAbuse, returnToFixed1(crimeAvg.arrestsLiquorAbuse), crimeRank.arrestsLiquorAbuse, returnToFixed3(crimeAvgPer1K.arrestsLiquorAbuse), crimeRankPer1K.arrestsLiquorAbuse]
      },
      {
        'Weapons':
          [crimeAnnual1[0].arrestsWeapons, crimeAnnual1[1].arrestsWeapons, crimeAnnual1[2].arrestsWeapons, crimeAnnual2[0].arrestsWeapons, crimeAnnual2[1].arrestsWeapons, crimeAnnual2[2].arrestsWeapons, crimeAnnual3[0].arrestsWeapons, crimeAnnual3[1].arrestsWeapons, crimeAnnual3[2].arrestsWeapons, crimeAnnual4[0].arrestsWeapons, crimeAnnual4[1].arrestsWeapons, crimeAnnual4[2].arrestsWeapons, returnToFixed1(crimeAvg.arrestsWeapons), crimeRank.arrestsWeapons, returnToFixed3(crimeAvgPer1K.arrestsWeapons), crimeRankPer1K.arrestsWeapons]
      }
    ]
    let [arrestsA1, arrestsB1, arrestsC1, arrestsA2, arrestsB2, arrestsC2, arrestsA3, arrestsB3, arrestsC3, arrestsA4, arrestsB4, arrestsC4]: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']

    arrestsA1 = calculateTotal(arrestsArray, 0)
    arrestsB1 = calculateTotal(arrestsArray, 1)
    arrestsC1 = calculateTotal(arrestsArray, 2)
    arrestsA2 = calculateTotal(arrestsArray, 3)
    arrestsB2 = calculateTotal(arrestsArray, 4)
    arrestsC2 = calculateTotal(arrestsArray, 5)
    arrestsA3 = calculateTotal(arrestsArray, 6)
    arrestsB3 = calculateTotal(arrestsArray, 7)
    arrestsC3 = calculateTotal(arrestsArray, 8)
    arrestsA4 = calculateTotal(arrestsArray, 9)
    arrestsB4 = calculateTotal(arrestsArray, 10)
    arrestsC4 = calculateTotal(arrestsArray, 11)

    arrestsTotal = [arrestsA1, arrestsB1, arrestsC1, arrestsA2, arrestsB2, arrestsC2, arrestsA3, arrestsB3, arrestsC3, arrestsA4, arrestsB4, arrestsC4]

    arrestsTotalAvg = crimeAvgTotalData.arrestsTotal.toFixed(1)
    arrestsTotalRank = crimeRank.arrestsTotal
    arrestsPer1K = crimeAvgPer1K.arrestsTotal.toFixed(3)
    arrestsPer1KRank = crimeRankPer1KData.arrestsTotal


    //////// Disciplinary Actions Values ////////
    disciplinaryArray = [
      {
        'Drug Abuse':
          [crimeAnnual1[0].disciplinaryDrugAbuse, crimeAnnual1[1].disciplinaryDrugAbuse, crimeAnnual1[2].disciplinaryDrugAbuse, crimeAnnual2[0].disciplinaryDrugAbuse, crimeAnnual2[1].disciplinaryDrugAbuse, crimeAnnual2[2].disciplinaryDrugAbuse, crimeAnnual3[0].disciplinaryDrugAbuse, crimeAnnual3[1].disciplinaryDrugAbuse, crimeAnnual3[2].disciplinaryDrugAbuse, crimeAnnual4[0].disciplinaryDrugAbuse, crimeAnnual4[1].disciplinaryDrugAbuse, crimeAnnual4[2].disciplinaryDrugAbuse, returnToFixed1(crimeAvg.disciplinaryDrugAbuse), crimeRank.disciplinaryDrugAbuse, returnToFixed3(crimeAvgPer1K.disciplinaryDrugAbuse), crimeRankPer1K.disciplinaryDrugAbuse]
      },
      {
        'Liquor Abuse':
          [crimeAnnual1[0].disciplinaryLiquorAbuse, crimeAnnual1[1].disciplinaryLiquorAbuse, crimeAnnual1[2].disciplinaryLiquorAbuse, crimeAnnual2[0].disciplinaryLiquorAbuse, crimeAnnual2[1].disciplinaryLiquorAbuse, crimeAnnual2[2].disciplinaryLiquorAbuse, crimeAnnual3[0].disciplinaryLiquorAbuse, crimeAnnual3[1].disciplinaryLiquorAbuse, crimeAnnual3[2].disciplinaryLiquorAbuse, crimeAnnual4[0].disciplinaryLiquorAbuse, crimeAnnual4[1].disciplinaryLiquorAbuse, crimeAnnual4[2].disciplinaryLiquorAbuse, returnToFixed1(crimeAvg.disciplinaryLiquorAbuse), crimeRank.disciplinaryLiquorAbuse, returnToFixed3(crimeAvgPer1K.disciplinaryLiquorAbuse), crimeRankPer1K.disciplinaryLiquorAbuse]
      },
      {
        'Weapons':
          [crimeAnnual1[0].disciplinaryWeapons, crimeAnnual1[1].disciplinaryWeapons, crimeAnnual1[2].disciplinaryWeapons, crimeAnnual2[0].disciplinaryWeapons, crimeAnnual2[1].disciplinaryWeapons, crimeAnnual2[2].disciplinaryWeapons, crimeAnnual3[0].disciplinaryWeapons, crimeAnnual3[1].disciplinaryWeapons, crimeAnnual3[2].disciplinaryWeapons, crimeAnnual4[0].disciplinaryWeapons, crimeAnnual4[1].disciplinaryWeapons, crimeAnnual4[2].disciplinaryWeapons, returnToFixed1(crimeAvg.disciplinaryWeapons), crimeRank.disciplinaryWeapons, returnToFixed3(crimeAvgPer1K.disciplinaryWeapons), crimeRankPer1K.disciplinaryWeapons]
      }
    ]
    let [disciplinaryA1, disciplinaryB1, disciplinaryC1, disciplinaryA2, disciplinaryB2, disciplinaryC2, disciplinaryA3, disciplinaryB3, disciplinaryC3, disciplinaryA4, disciplinaryB4, disciplinaryC4]: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']

    disciplinaryA1 = calculateTotal(disciplinaryArray, 0)
    disciplinaryB1 = calculateTotal(disciplinaryArray, 1)
    disciplinaryC1 = calculateTotal(disciplinaryArray, 2)
    disciplinaryA2 = calculateTotal(disciplinaryArray, 3)
    disciplinaryB2 = calculateTotal(disciplinaryArray, 4)
    disciplinaryC2 = calculateTotal(disciplinaryArray, 5)
    disciplinaryA3 = calculateTotal(disciplinaryArray, 6)
    disciplinaryB3 = calculateTotal(disciplinaryArray, 7)
    disciplinaryC3 = calculateTotal(disciplinaryArray, 8)
    disciplinaryA4 = calculateTotal(disciplinaryArray, 9)
    disciplinaryB4 = calculateTotal(disciplinaryArray, 10)
    disciplinaryC4 = calculateTotal(disciplinaryArray, 11)

    disciplinaryTotal = [disciplinaryA1, disciplinaryB1, disciplinaryC1, disciplinaryA2, disciplinaryB2, disciplinaryC2, disciplinaryA3, disciplinaryB3, disciplinaryC3, disciplinaryA4, disciplinaryB4, disciplinaryC4]

    disciplinaryTotalAvg = crimeAvgTotalData.disciplinaryTotal.toFixed(1)
    disciplinaryTotalRank = crimeRank.disciplinaryTotal
    disciplinaryPer1K = crimeAvgPer1K.disciplinaryTotal.toFixed(3)
    disciplinaryPer1KRank = crimeRankPer1KData.disciplinaryTotal

    //////// Hate Table values ////////
    hateArray = [
      {
        'Aggravated assault':
          [crimeAnnual1[0].hateAggAssault, crimeAnnual1[1].hateAggAssault, crimeAnnual1[2].hateAggAssault, crimeAnnual2[0].hateAggAssault, crimeAnnual2[1].hateAggAssault, crimeAnnual2[2].hateAggAssault, crimeAnnual3[0].hateAggAssault, crimeAnnual3[1].hateAggAssault, crimeAnnual3[2].hateAggAssault, crimeAnnual4[0].hateAggAssault, crimeAnnual4[1].hateAggAssault, crimeAnnual4[2].hateAggAssault, returnToFixed1(crimeAvg.hateAggAssault), crimeRank.hateAggAssault, returnToFixed3(crimeAvgPer1K.hateAggAssault), crimeRankPer1K.hateAggAssault]
      },
      {
        'Arson':
          [crimeAnnual1[0].hateArson, crimeAnnual1[1].hateArson, crimeAnnual1[2].hateArson, crimeAnnual2[0].hateArson, crimeAnnual2[1].hateArson, crimeAnnual2[2].hateArson, crimeAnnual3[0].hateArson, crimeAnnual3[1].hateArson, crimeAnnual3[2].hateArson, crimeAnnual4[0].hateArson, crimeAnnual4[1].hateArson, crimeAnnual4[2].hateArson, returnToFixed1(crimeAvg.hateArson), crimeRank.hateArson, returnToFixed3(crimeAvgPer1K.hateArson), crimeRankPer1K.hateArson]
      },
      {
        'Burglary':
          [crimeAnnual1[0].hateBurglary, crimeAnnual1[1].hateBurglary, crimeAnnual1[2].hateBurglary, crimeAnnual2[0].hateBurglary, crimeAnnual2[1].hateBurglary, crimeAnnual2[2].hateBurglary, crimeAnnual3[0].hateBurglary, crimeAnnual3[1].hateBurglary, crimeAnnual3[2].hateBurglary, crimeAnnual4[0].hateBurglary, crimeAnnual4[1].hateBurglary, crimeAnnual4[2].hateBurglary, returnToFixed1(crimeAvg.hateBurglary), crimeRank.hateBurglary, returnToFixed3(crimeAvgPer1K.hateBurglary), crimeRankPer1K.hateBurglary]
      },
      {
        'Destruction':
          [crimeAnnual1[0].hateDestruction, crimeAnnual1[1].hateDestruction, crimeAnnual1[2].hateDestruction, crimeAnnual2[0].hateDestruction, crimeAnnual2[1].hateDestruction, crimeAnnual2[2].hateDestruction, crimeAnnual3[0].hateDestruction, crimeAnnual3[1].hateDestruction, crimeAnnual3[2].hateDestruction, crimeAnnual4[0].hateDestruction, crimeAnnual4[1].hateDestruction, crimeAnnual4[2].hateDestruction, returnToFixed1(crimeAvg.hateDestruction), crimeRank.hateDestruction, returnToFixed3(crimeAvgPer1K.hateDestruction), crimeRankPer1K.hateDestruction]
      },
      {
        'Fondling':
          [crimeAnnual1[0].hateFondling, crimeAnnual1[1].hateFondling, crimeAnnual1[2].hateFondling, crimeAnnual2[0].hateFondling, crimeAnnual2[1].hateFondling, crimeAnnual2[2].hateFondling, crimeAnnual3[0].hateFondling, crimeAnnual3[1].hateFondling, crimeAnnual3[2].hateFondling, crimeAnnual4[0].hateFondling, crimeAnnual4[1].hateFondling, crimeAnnual4[2].hateFondling, returnToFixed1(crimeAvg.hateFondling), crimeRank.hateFondling, returnToFixed3(crimeAvgPer1K.hateFondling), crimeRankPer1K.hateFondling]
      },
      {
        'Incest':
          [crimeAnnual1[0].hateIncest, crimeAnnual1[1].hateIncest, crimeAnnual1[2].hateIncest, crimeAnnual2[0].hateIncest, crimeAnnual2[1].hateIncest, crimeAnnual2[2].hateIncest, crimeAnnual3[0].hateIncest, crimeAnnual3[1].hateIncest, crimeAnnual3[2].hateIncest, crimeAnnual4[0].hateIncest, crimeAnnual4[1].hateIncest, crimeAnnual4[2].hateIncest, returnToFixed1(crimeAvg.hateIncest), crimeRank.hateIncest, returnToFixed3(crimeAvgPer1K.hateIncest), crimeRankPer1K.hateIncest]
      },
      {
        'Intimidation':
          [crimeAnnual1[0].hateIntimidation, crimeAnnual1[1].hateIntimidation, crimeAnnual1[2].hateIntimidation, crimeAnnual2[0].hateIntimidation, crimeAnnual2[1].hateIntimidation, crimeAnnual2[2].hateIntimidation, crimeAnnual3[0].hateIntimidation, crimeAnnual3[1].hateIntimidation, crimeAnnual3[2].hateIntimidation, crimeAnnual4[0].hateIntimidation, crimeAnnual4[1].hateIntimidation, crimeAnnual4[2].hateIntimidation, returnToFixed1(crimeAvg.hateIntimidation), crimeRank.hateIntimidation, returnToFixed3(crimeAvgPer1K.hateIntimidation), crimeRankPer1K.hateIntimidation]
      },
      {
        'Larceny':
          [crimeAnnual1[0].hateLarceny, crimeAnnual1[1].hateLarceny, crimeAnnual1[2].hateLarceny, crimeAnnual2[0].hateLarceny, crimeAnnual2[1].hateLarceny, crimeAnnual2[2].hateLarceny, crimeAnnual3[0].hateLarceny, crimeAnnual3[1].hateLarceny, crimeAnnual3[2].hateLarceny, crimeAnnual4[0].hateLarceny, crimeAnnual4[1].hateLarceny, crimeAnnual4[2].hateLarceny, returnToFixed1(crimeAvg.hateLarceny), crimeRank.hateLarceny, returnToFixed3(crimeAvgPer1K.hateLarceny), crimeRankPer1K.hateLarceny]
      },
      {
        'Motor Vehicle Theft':
          [crimeAnnual1[0].hateMotorVehicleTheft, crimeAnnual1[1].hateMotorVehicleTheft, crimeAnnual1[2].hateMotorVehicleTheft, crimeAnnual2[0].hateMotorVehicleTheft, crimeAnnual2[1].hateMotorVehicleTheft, crimeAnnual2[2].hateMotorVehicleTheft, crimeAnnual3[0].hateMotorVehicleTheft, crimeAnnual3[1].hateMotorVehicleTheft, crimeAnnual3[2].hateMotorVehicleTheft, crimeAnnual4[0].hateMotorVehicleTheft, crimeAnnual4[1].hateMotorVehicleTheft, crimeAnnual4[2].hateMotorVehicleTheft, returnToFixed1(crimeAvg.hateMotorVehicleTheft), crimeRank.hateMotorVehicleTheft, returnToFixed3(crimeAvgPer1K.hateMotorVehicleTheft), crimeRankPer1K.hateMotorVehicleTheft]
      },
      {
        'Murder':
          [crimeAnnual1[0].hateMurder, crimeAnnual1[1].hateMurder, crimeAnnual1[2].hateMurder, crimeAnnual2[0].hateMurder, crimeAnnual2[1].hateMurder, crimeAnnual2[2].hateMurder, crimeAnnual3[0].hateMurder, crimeAnnual3[1].hateMurder, crimeAnnual3[2].hateMurder, crimeAnnual4[0].hateMurder, crimeAnnual4[1].hateMurder, crimeAnnual4[2].hateMurder, returnToFixed1(crimeAvg.hateMurder), crimeRank.hateMurder, returnToFixed3(crimeAvgPer1K.hateMurder), crimeRankPer1K.hateMurder]
      },
      {
        'Rape':
          [crimeAnnual1[0].hateRape, crimeAnnual1[1].hateRape, crimeAnnual1[2].hateRape, crimeAnnual2[0].hateRape, crimeAnnual2[1].hateRape, crimeAnnual2[2].hateRape, crimeAnnual3[0].hateRape, crimeAnnual3[1].hateRape, crimeAnnual3[2].hateRape, crimeAnnual4[0].hateRape, crimeAnnual4[1].hateRape, crimeAnnual4[2].hateRape, returnToFixed1(crimeAvg.hateRape), crimeRank.hateRape, returnToFixed3(crimeAvgPer1K.hateRape), crimeRankPer1K.hateRape]
      },
      {
        'Simple Assault':
          [crimeAnnual1[0].hateSimpleAssault, crimeAnnual1[1].hateSimpleAssault, crimeAnnual1[2].hateSimpleAssault, crimeAnnual2[0].hateSimpleAssault, crimeAnnual2[1].hateSimpleAssault, crimeAnnual2[2].hateSimpleAssault, crimeAnnual3[0].hateSimpleAssault, crimeAnnual3[1].hateSimpleAssault, crimeAnnual3[2].hateSimpleAssault, crimeAnnual4[0].hateSimpleAssault, crimeAnnual4[1].hateSimpleAssault, crimeAnnual4[2].hateSimpleAssault, returnToFixed1(crimeAvg.hateSimpleAssault), crimeRank.hateSimpleAssault, returnToFixed3(crimeAvgPer1K.hateSimpleAssault), crimeRankPer1K.hateSimpleAssault]
      },
      {
        'Statutory Rape':
          [crimeAnnual1[0].hateStatRape, crimeAnnual1[1].hateStatRape, crimeAnnual1[2].hateStatRape, crimeAnnual2[0].hateStatRape, crimeAnnual2[1].hateStatRape, crimeAnnual2[2].hateStatRape, crimeAnnual3[0].hateStatRape, crimeAnnual3[1].hateStatRape, crimeAnnual3[2].hateStatRape, crimeAnnual4[0].hateStatRape, crimeAnnual4[1].hateStatRape, crimeAnnual4[2].hateStatRape, returnToFixed1(crimeAvg.hateStatRape), crimeRank.hateStatRape, returnToFixed3(crimeAvgPer1K.hateStatRape), crimeRankPer1K.hateStatRape]
      }
    ]
    // A, B, C for years 1, 2, 3
    let [hateA1, hateB1, hateC1, hateA2, hateB2, hateC2, hateA3, hateB3, hateC3, hateA4, hateB4, hateC4]: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-']

    hateA1 = calculateTotal(hateArray, 0)
    hateB1 = calculateTotal(hateArray, 1)
    hateC1 = calculateTotal(hateArray, 2)
    hateA2 = calculateTotal(hateArray, 3)
    hateB2 = calculateTotal(hateArray, 4)
    hateC2 = calculateTotal(hateArray, 5)
    hateA3 = calculateTotal(hateArray, 6)
    hateB3 = calculateTotal(hateArray, 7)
    hateC3 = calculateTotal(hateArray, 8)
    hateA4 = calculateTotal(hateArray, 9)
    hateB4 = calculateTotal(hateArray, 10)
    hateC4 = calculateTotal(hateArray, 11)

    hateTotal = [hateA1, hateB1, hateC1, hateA2, hateB2, hateC2, hateA3, hateB3, hateC3, hateA4, hateB4, hateC4]

    hateTotalAvg = crimeAvgTotalData.hateTotal.toFixed(1)
    hateTotalRank = crimeRank.hateTotal
    hatePer1K = crimeAvgPer1K.hateTotal.toFixed(3)
    hatePer1KRank = crimeRankPer1KData.hateTotal

    ////// Safety Statistics By Location //////

    // 1 = on campus housing
    // 2 = on campus total
    // 3 = non campus
    // 4 = public 
    // 5 = total

    let latestOnCampusHousing = crimeAnnual1.reduce((latest, current) => {
      return current.year > latest.year ? current : latest
    })

    let latestOnCampusTotal = crimeAnnual2.reduce((latest, current) => {
      return current.year > latest.year ? current : latest
    })

    let latestNonCampus = crimeAnnual3.reduce((latest, current) => {
      return current.year > latest.year ? current : latest
    })

    let latestPublic = crimeAnnual4.reduce((latest, current) => {
      return current.year > latest.year ? current : latest
    })

    ////// Order of ---
    // ['Criminal Offenses', 'Violence Against Women', 'Arrests', 'Disciplinary Actions', 'Hate Crimes']


    // On Campus Non Housing calculated via on campus total - on campus housing
    let onCampusNonHousingCriminal = latestOnCampusTotal.criminalTotal - latestOnCampusHousing.criminalTotal

    let onCampusNonHousingVawa = latestOnCampusTotal.vawaTotal - latestOnCampusHousing.vawaTotal

    let onCampusNonHousingArrests = latestOnCampusTotal.arrestsTotal - latestOnCampusHousing.arrestsTotal

    let onCampusNonHousingDisciplinary = latestOnCampusTotal.disciplinaryTotal - latestOnCampusHousing.disciplinaryTotal

    let onCampusNonHousingHate = latestOnCampusTotal.hateTotal - latestOnCampusHousing.hateTotal

    safetyStatsOnCampusHousing = [latestOnCampusHousing.criminalTotal, latestOnCampusHousing.vawaTotal, latestOnCampusHousing.arrestsTotal, latestOnCampusHousing.disciplinaryTotal, latestOnCampusHousing.hateTotal]

    safetyStatsOnCampusNonHousing = [onCampusNonHousingCriminal, onCampusNonHousingVawa, onCampusNonHousingArrests, onCampusNonHousingDisciplinary, onCampusNonHousingHate]

    safetyStatsNonCampus = [latestNonCampus.criminalTotal, latestNonCampus.vawaTotal, latestNonCampus.arrestsTotal, latestNonCampus.disciplinaryTotal, latestNonCampus.hateTotal]

    safetyStatsPublic = [latestPublic.criminalTotal, latestPublic.vawaTotal, latestPublic.arrestsTotal, latestPublic.disciplinaryTotal, latestPublic.hateTotal]
  }

  const totalCrimeStatisticsChart = (
    <Chart
      options={{
        chart: {
          id: 'Crime Statistics Chart',
			  toolbar: {
				show: false,
				},
            },
        xaxis: {
          categories: chartXaxis,
          labels: {
            show: true,
            style: {
              colors: '#9AA2B6',
            },
          }
        },
        tooltip: {
          enabled: false,
        },
        yaxis: {
          min: 0.00,
          // max: totalCrimeStatisticsYear1Data[0] * 1.5,
          forceNiceScale: true,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val) {
              return val.toFixed(0);
            }
          }
        },
        //colors: ["#98F29B"],
        colors: ["#90F892", "#FFEF68", "#C5DEF9", "#00E986", "#97E6FF"],
        dataLabels: {
          enabled: true,
          background: {
            borderWidth: 0,
            borderRadius: 5,
            foreColor: '#000',
          }
        },
        fill: {
          colors: ['#DAF8B9'],
          opacity: 0,
          type: "solid",
          /*gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.95,
            stops: [0, 95, 100]
          }*/
        },
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        stroke: {
          curve: 'smooth'
        }
      }}
      series={[
        {
          name: 'Criminal Offenses',
          type: 'area',
          data: criminalTotalData
        },
        {
          name: 'Violence Against Women',
          type: 'area',
          data: vawaTotalData
        },
        {
          name: 'Arrests',
          type: 'area',
          data: arrestsTotalData
        },
        {
          name: 'Disciplinary Actions',
          type: 'area',
          data: disciplinaryTotalData
        },
        {
          name: 'Hate Crimes',
          type: 'area',
          data: hateTotalData
        }
      ]}
      //type='area'
      //width={'450'}
      height={'400'}
    />
  )

  const crimeStatisticsPer1KChart = (
    <Chart
      options={{
        chart: {
          id: 'Crime Statistics Per 1K Chart'
        },
        xaxis: {
          categories: chartXaxis,
          labels: {
            show: true,
            style: {
              colors: '#9AA2B6',
            },
          }
        },
        tooltip: {
          enabled: false,
        },
        yaxis: {
          min: 0.00,
          // max: seriesData[0] * 1.5,
          forceNiceScale: true,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val) {
              return val.toFixed(3);
            }
          }
        },
        colors: ["#98F29B", "#FFED46", "#BFEFFE", "#DDD3FF", "#E2D6C4"],
        dataLabels: {
          enabled: true,
          background: {
            borderWidth: 0,
            foreColor: '#000',
          },
          //formatter: function (val, opts) {
          //  return val.toFixed(3);
          //},
        },
        fill: {
          colors: ['#DAF8B9'],
          opacity: 0,
          type: "solid",
          /*gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.95,
            stops: [0, 95, 100]
          }*/
        },
        stroke: {
          curve: 'smooth'
        }
      }}
      series={[
        {
          name: 'Criminal Offenses',
          type: 'area',
          data: criminalPer1KData
        },
        {
          name: 'Violence Against Women',
          type: 'area',
          data: vawaPer1KData
        },
        {
          name: 'Arrests',
          type: 'area',
          data: arrestsPer1KData
        },
        {
          name: 'Disciplinary Actions',
          type: 'area',
          data: disciplinaryPer1KData
        },
        {
          name: 'Hate Crimes',
          type: 'area',
          data: hatePer1KData
        }
      ]}
      //type='area'
      width={'450'}
      height={'300'}
    />
  )

  const safetyStatisticsByLocationChart = (
    <Chart
      options={{
        chart: {
          id: 'safety_statistics_by_location',
          stacked: true,
          stackType: '100%',
			  toolbar: {
				show: false,
				},
            },
        xaxis: {
          categories: ['Crimes', 'VAWA', 'Arrests', 'Disciplinary', 'Hate'],
          labels: {
            show: true,
            rotate: 0,
            hideOverlappingLabels: false,
            trim: true,
            style: {
              colors: '#9AA2B6',
            },
          }
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val) {
              return val.toFixed(0) + "%";
            }
          }
        },
        //colors: ['#98F29B', '#008FFB'],
        colors: ["#90F892", "#FFEF68", "#C5DEF9", "#97E6FF"],
        tooltip: {
          enabled: false,
        },
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#000000'],
          },
          background: {
            enabled: false,
            borderWidth: 0,
            foreColor: '#000',
          },
          formatter: function (val: number, opts) {
            return val.toFixed(1) + "%";
          },

        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            dataLabels: {
              position: 'top',
            },
          }
        }
      }}
      series={[
        {
          name: 'On-Campus (Housing)',
          group: 'Location',
          data: safetyStatsOnCampusHousing
        },
        {
          name: 'On-Campus (Non-Housing)',
          group: 'Location',
          data: safetyStatsOnCampusNonHousing
        },
        {
          name: 'Non-Campus',
          group: 'Location',
          data: safetyStatsNonCampus
        },
        {
          name: 'Public Property',
          group: 'Location',
          data: safetyStatsPublic
        },
      ]}
      type='bar'
      height={'400'}
    />
  )
  // Table Headers
  const safetyAggregateIndex = (
    <div
      id='dca-mobile-crime-safety-header'
    >

      <div className="d-flex flex-row">
        <div style={{ fontSize: '1.25rem' }} className="me-2 align-self-end">Aggregate safety rank: </div>

        {/* <div className="me-2 align-self-end"><RankIndicator rank={college.safetyIndex} /></div> */}

        <div
          className="me-2 align-self-end"
          style={{ cursor: 'pointer' }}
          data-bs-toggle="modal"
          data-bs-target="#safety_aggregate_modal"
        >
          <RankIndicator rank={college.safetyIndex} clickable={true} />
        </div>

        <div className="me-2 d-flex align-items-center">{mobile ? <TooltipComponentMobile content={`This figure indicates the relative rank of the college's safety based on crime statistics. The aggregate rank is based on both the total incidents as well as the number of incidents per 1K students. The incidents are weighted by severity, with the highest weights allocated to Criminal Offenses and the lowest weights to Disciplinary Actions. Click on the rank indicator to view comparative ranks for aggregate safety score at all colleges.`} /> : <TooltipComponent content={`This figure indicates the relative rank of the college's safety based on crime statistics. The aggregate rank is based on both the total incidents as well as the number of incidents per 1K students. The incidents are weighted by severity, with the highest weights allocated to Criminal Offenses and the lowest weights to Disciplinary Actions. Click on the rank indicator to view comparative ranks for aggregate safety score at all colleges.`} />}</div>
      </div>

      <div className="me-2"><RankColorRange /></div>

    </div>
  )

  const tableHead1 = (
    <thead>
      <tr className="text-center align-middle">
        <th scope="col"></th>
        <th className="dca-crime-safety-header-item" colSpan={3} scope="colgroup">ON-CAMPUS HOUSING</th>
        <th className="dca-crime-safety-header-item" colSpan={3} scope="colgroup">ON-CAMPUS TOTAL</th>
        <th className="dca-crime-safety-header-item" colSpan={3} scope="colgroup">NON-CAMPUS</th>
        <th className="dca-crime-safety-header-item" colSpan={3} scope="colgroup">PUBLIC PROPERTY</th>
        <th className="dca-crime-safety-header-item" colSpan={4} scope="colgroup">ANNUAL AVERAGE (OVER 3 YEARS AND ALL LOCATIONS)</th>
      </tr>
    </thead>
  )

  const tableHead2 = (
    <thead>
      <tr className="text-center align-middle">
        <th scope="col"></th>
        <th scope="col" className="dca-crime-safety-header-item">{year0}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year1}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year2}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year0}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year1}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year2}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year0}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year1}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year2}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year0}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year1}</th>
        <th scope="col" className="dca-crime-safety-header-item">{year2}</th>

        <th scope="col" className="dca-crime-safety-header-item">TOTAL # INCIDENTS</th>
        <th className='d-flex flex-row text-center justify-content-center dca-crime-safety-header-item' scope="col">RANK<div className="ms-2 d-flex align-items-center">{mobile ? <TooltipComponentMobile content={`This column displays, for each crime statistic, the relative rank for ${college.name} compared against all 100 colleges featured on this site based on total number of incidents. Click on any rank indicator to see how all colleges rank for that specific crime statistic.`} /> : <TooltipComponent content={`This column displays, for each crime statistic, the relative rank for ${college.name} compared against all 100 colleges featured on this site based on total number of incidents. Click on any rank indicator to see how all colleges rank for that specific crime statistic.`} />}</div></th>
        <th scope="col" className="dca-crime-safety-header-item"># PER 1K STUDENTS</th>
        <th className='d-flex flex-row text-center justify-content-center dca-crime-safety-header-item' scope="col">RANK<div className="ms-2 d-flex align-items-center">{mobile ? <TooltipComponentMobile content={`This column displays, for each crime statistic, the relative rank for ${college.name} compared against all 100 colleges featured on this site based on number of incidents per 1K students. Click on any rank indicator to see how all colleges rank for that specific crime statistic.`} /> : <TooltipComponent content={`This column displays, for each crime statistic, the relative rank for ${college.name} compared against all 100 colleges featured on this site based on number of incidents per 1K students. Click on any rank indicator to see how all colleges rank for that specific crime statistic.`} />}</div></th>
      </tr>
    </thead>
  )

  // Table Content
  const criminalTable = (
    <div>
      <div className="ms-5" style={{ marginTop: '20px', marginBottom: '15px', fontSize: '1.25rem' }}>Criminal Offenses</div>

      <table className="table table-borderless table-sm table-responsive ms-5 me-5" style={{ width: '85vw' }}>
        {tableHead1}
        {tableHead2}
        <tbody>

          {criminalArray.map((res, index) => (
            <tr
              key={index}
              className="text-center align-middle"
              style={{ borderBottom: '1px solid #EDEDED' }}>
              {/* Font weight override test */}
              {/* <th scope='row' className="text-start fwImport">{Object.keys(res)[0]}</th> */}
              <th scope='row' className="text-start">{Object.keys(res)[0]}</th>
              <td>{criminalArray[index][Object.keys(res)[0]][0]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][1]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][2]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][3]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][4]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][5]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][6]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][7]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][8]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][9]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][10]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][11]}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][12]}</td>
              <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnTotalRankModalTrigger(Object.keys(res)[0], 'Criminal Offenses', criminalArray[index][Object.keys(res)[0]][13])}</td>
              <td>{criminalArray[index][Object.keys(res)[0]][14]}</td>
              <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnPer1KRankModalTrigger(Object.keys(res)[0], 'Criminal Offenses', criminalArray[index][Object.keys(res)[0]][15])}</td>
            </tr>
          ))}
          <tr className="dca-college-details-total-row align-middle">
            <th className="text-start">Total Criminal</th>
            {criminalTotal.map((res, index) => (
              <td key={index}>{res}</td>
            ))}
            <td>{tcTotalAvg}</td>
            <td className="d-flex flex-row justify-content-center" style={{ border: 'none' }}>{returnTotalRankModalTrigger('Total Criminal', 'Criminal Offenses', tcTotalRank)}</td>
            <td>{tcPer1K}</td>
            <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnPer1KRankModalTrigger('Total Criminal', 'Criminal Offenses', tcPer1KRank)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  const vawaTable = (
    <div>
      <div className="ms-5" style={{ marginTop: '80px', marginBottom: '15px', fontSize: '1.25rem' }}>Violence Against Women Act (VAWA) Offenses</div>
      <table className="table table-borderless table-sm table-responsive ms-5 me-5" style={{ width: '85vw' }}>
        {tableHead1}
        {tableHead2}
        <tbody>

          {vawaArray.map((res, index) => (
            <tr
              key={index}
              className="text-center align-middle"
              style={{ borderBottom: '1px solid #EDEDED' }}>
              <th scope='row' className="text-start">{Object.keys(res)[0]}</th>
              <td>{vawaArray[index][Object.keys(res)[0]][0]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][1]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][2]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][3]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][4]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][5]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][6]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][7]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][8]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][9]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][10]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][11]}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][12]}</td>
              <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnTotalRankModalTrigger(Object.keys(res)[0], 'Violence Against Women Act (VAWA) Offenses', vawaArray[index][Object.keys(res)[0]][13])}</td>
              <td>{vawaArray[index][Object.keys(res)[0]][14]}</td>
              <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnPer1KRankModalTrigger(Object.keys(res)[0], 'Violence Against Women Act (VAWA) Offenses', vawaArray[index][Object.keys(res)[0]][15])}</td>
            </tr>
          ))}
          <tr className="dca-college-details-total-row align-middle">
            <th className="text-start">Total VAWA Offenses</th>
            {vawaTotal.map((res, index) => (
              <td key={index}>{res}</td>
            ))}
            <td>{vawaTotalAvg}</td>
            <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnTotalRankModalTrigger('Total VAWA Offenses', 'Violence Against Women Act (VAWA) Offenses', vawaTotalRank)}</td>
            <td>{vawaPer1K}</td>
            <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnPer1KRankModalTrigger('Total VAWA Offenses', 'Violence Against Women Act (VAWA) Offenses', vawaPer1KRank)}</td>
          </tr>
        </tbody>

      </table>
    </div>
  )

  const arrestsTable = (
    <div>
      <div className="ms-5" style={{ marginTop: '80px', marginBottom: '15px', fontSize: '1.25rem' }}>Arrests</div>
      <table className="table table-borderless table-sm table-responsive ms-5 me-5" style={{ width: '85vw' }}>
        {tableHead1}
        {tableHead2}
        <tbody>

          {arrestsArray.map((res, index) => (
            <tr
              key={index}
              className="text-center align-middle"
              style={{ borderBottom: '1px solid #EDEDED' }}>
              <th scope='row' className="text-start">{Object.keys(res)[0]}</th>
              <td>{arrestsArray[index][Object.keys(res)[0]][0]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][1]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][2]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][3]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][4]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][5]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][6]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][7]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][8]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][9]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][10]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][11]}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][12]}</td>
              <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnTotalRankModalTrigger(Object.keys(res)[0], 'Arrests', arrestsArray[index][Object.keys(res)[0]][13])}</td>
              <td>{arrestsArray[index][Object.keys(res)[0]][14]}</td>
              <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnPer1KRankModalTrigger(Object.keys(res)[0], 'Arrests', arrestsArray[index][Object.keys(res)[0]][15])}</td>
            </tr>
          ))}
          <tr className="dca-college-details-total-row align-middle">
            <th className="text-start">Total Arrests</th>
            {arrestsTotal.map((res, index) => (
              <td key={index}>{res}</td>
            ))}
            <td>{arrestsTotalAvg}</td>
            <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnTotalRankModalTrigger('Total Arrests', 'Arrests', arrestsTotalRank)}</td>
            <td>{arrestsPer1K}</td>
            <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnPer1KRankModalTrigger('Total Arrests', 'Arrests', arrestsPer1KRank)}</td>
          </tr>
        </tbody>

      </table>
    </div>
  )

  const disciplinaryTable = (
    <div>
      <div className="ms-5" style={{ marginTop: '80px', marginBottom: '15px', fontSize: '1.25rem' }}>Disciplinary Actions</div>
      <table className="table table-borderless table-sm table-responsive ms-5 me-5" style={{ width: '85vw' }}>
        {tableHead1}
        {tableHead2}
        <tbody>

          {disciplinaryArray.map((res, index) => (
            <tr
              key={index}
              className="text-center align-middle"
              style={{ borderBottom: '1px solid #EDEDED' }}>
              <th scope='row' className="text-start">{Object.keys(res)[0]}</th>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][0]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][1]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][2]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][3]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][4]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][5]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][6]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][7]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][8]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][9]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][10]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][11]}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][12]}</td>
              <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnTotalRankModalTrigger(Object.keys(res)[0], 'Disciplinary Actions', disciplinaryArray[index][Object.keys(res)[0]][13])}</td>
              <td>{disciplinaryArray[index][Object.keys(res)[0]][14]}</td>
              <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnPer1KRankModalTrigger(Object.keys(res)[0], 'Disciplinary Actions', disciplinaryArray[index][Object.keys(res)[0]][15])}</td>
            </tr>
          ))}
          <tr className="dca-college-details-total-row align-middle">
            <th className="text-start">Total disciplinary</th>
            {disciplinaryTotal.map((res, index) => (
              <td key={index}>{res}</td>
            ))}
            <td>{disciplinaryTotalAvg}</td>
            <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnTotalRankModalTrigger('Total Disciplinary', 'Disciplinary Actions', disciplinaryTotalRank)}</td>
            <td>{disciplinaryPer1K}</td>
            <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnPer1KRankModalTrigger('Total Disciplinary', 'Disciplinary Actions', disciplinaryPer1KRank)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  const hateTable = (
    <div>
      <div className="ms-5" style={{ marginTop: '80px', marginBottom: '15px', fontSize: '1.25rem' }}>Hate Crimes</div>
      <table className="table table-borderless table-sm table-responsive ms-5 me-5" style={{ width: '85vw' }}>
        {tableHead1}
        {tableHead2}

        <tbody>

          {hateArray.map((res, index) => (
            <tr
              key={index}
              className="text-center align-middle"
              style={{ borderBottom: '1px solid #EDEDED' }}>
              <th scope='row' className="text-start">{Object.keys(res)[0]}</th>
              <td>{hateArray[index][Object.keys(res)[0]][0]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][1]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][2]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][3]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][4]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][5]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][6]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][7]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][8]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][9]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][10]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][11]}</td>
              <td>{hateArray[index][Object.keys(res)[0]][12]}</td>
              <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnTotalRankModalTrigger(Object.keys(res)[0], 'Hate Crimes', hateArray[index][Object.keys(res)[0]][13])}</td>
              <td>{hateArray[index][Object.keys(res)[0]][14]}</td>
              <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnPer1KRankModalTrigger(Object.keys(res)[0], 'Hate Crimes', hateArray[index][Object.keys(res)[0]][15])}</td>
            </tr>
          ))}
          <tr className="dca-college-details-total-row align-middle">
            <th className="text-start">Total Hate</th>
            {hateTotal.map((res, index) => (
              <td key={index}>{res}</td>
            ))}
            <td>{hateTotalAvg}</td>
            <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnTotalRankModalTrigger('Total Hate', 'Hate Crimes', hateTotalRank)}</td>
            <td>{hatePer1K}</td>
            <td className="d-flex justify-content-center align-items-center" style={{ border: 'none' }}>{returnPer1KRankModalTrigger('Total Hate', 'Hate Crimes', hatePer1KRank)}</td>
          </tr>
        </tbody>

      </table>
    </div>
  )

  return dataLoaded && crimeAvgTotalCheck && crimeAvgPer1KCheck ? (
    <div className={pageView ? "dca-font-M" : "card shadow dca-font-M"}
      style={{ marginTop: pageView ? '' : '40px' }}>

      {safetyAggregateIndex}

      <div id='dca-college-detail-crime-charts-wrapper'>

        <div className='ms-4' id='dca-college-detail-crime-chart'>
          <div style={{ fontSize: '1.35rem' }}>Total Crime Statistics</div>
          {totalCrimeStatisticsChart}
        </div>

        {/* Remove crime stats per 1K for now, ticket #137 */}
        {/* <div>
          <h3>Total Crime Statistics Per 1K Students</h3>
          {crimeStatisticsPer1KChart}
        </div> */}

        <div className='ms-4' id='dca-college-detail-crime-chart'>
          <div style={{ fontSize: '1.35rem' }}>Safety Statistics By Location {year2 != '-' ? `(${year2})` : ''}</div>
          {safetyStatisticsByLocationChart}
        </div>

      </div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex flex-column' style={{ overflowX: 'auto' }}>
        <div style={{ marginTop: '50px' }}>
          {criminalTable}
        </div>
        <div style={{ marginTop: '15px' }}>
          {vawaTable}
        </div>
        <div style={{ marginTop: '15px' }}>
          {arrestsTable}
        </div>
        <div style={{ marginTop: '15px' }}>
          {disciplinaryTable}</div>
        <div style={{ marginTop: '15px' }}>
          {hateTable}
        </div>
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-block d-md-none' style={{ overflowX: 'auto' }}>
        <div style={{ marginTop: '50px' }}>
          {criminalTable}
        </div>
        <div style={{ marginTop: '15px' }}>
          {vawaTable}
        </div>
        <div style={{ marginTop: '15px' }}>
          {arrestsTable}
        </div>
        <div style={{ marginTop: '15px' }}>
          {disciplinaryTable}
        </div>
        <div style={{ marginTop: '15px' }}>
          {hateTable}
        </div>
      </div>

      <span style={{ marginTop: '50px', padding: pageView ? '' : '10px' }}>Source: Office of Postsecondary Education of the U.S. Department of Education.</span>
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: '150px' }}>
      <LoadingIndicator text={"Loading Data ... "} />
    </div>
  )
}

export { Safety }
