import axios from "axios"
import * as dictionary from '../components/dictionary'
import { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import { MajorSearchAside } from "../components/MajorSearch/MajorSearchAside"
import { MajorCard } from "../components/MajorSearch/MajorCard"
import { useAuth } from "../modules/auth"
import { LoadingIndicator } from "../components/LoadingIndicator"

const MajorSearchPage = ({ user, setUser, prompt, setPrompt, navigateTo, setNavigateTo, majorSearchLocation, setMajorSearchLocation }) => {

  const navigate = useNavigate()
  const { currentUser } = useAuth()

  //////// Set states of variables ////////
  const [majorData, setMajorData] = useState<any>([])
  const [majors, setMajors] = useState<any>([])
  const [filteredResults, setFilteredResults] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [programData, setProgramData] = useState<any>([])
  const [fieldOptions, setFieldOptions] = useState<any>([]);
  const [nameOptions, setNameOptions] = useState<any>([]);
  const [collegeOptions, setCollegeOptions] = useState<any>([]);
  const [courseOptions, setCourseOptions] = useState<any>([])
  const [careerOptions, setCareerOptions] = useState<any>([])
  const [careerCIP, setCareerCIP] = useState<any>([])
  const [cipTax, setCipTax] = useState<any>([])
  const [interestCIP, setInterestCIP] = useState<any>([])
  const [sortKey, setSortKey] = useState<any>('numColleges')
  const [sortOrder, setSortOrder] = useState<any>('Descending')
  const [advisorData, setAdvisorData] = useState<any>([])
  const [advisorMajorIDs, setAdvisorMajorIDs] = useState<any>([])

  //Filter states
  const [fieldFilter, setFieldFilter] = useState<any>((item) => {
    return item => item
  })
  const [nameFilter, setNameFilter] = useState<any>((item) => {
    return item => item
  })
  const [collegeFilter, setCollegeFilter] = useState<any>((item) => {
    return item => item
  })
  const [relatedCoursesFilter, setRelatedCoursesFilter] = useState<any>((item) => {
    return item => item
  })
  const [relatedCareersFilter, setRelatedCareersFilter] = useState<any>((item) => {
    return item => item
  })
  const [earningsFilter, setEarningsFilter] = useState<any>((item) => {
    return item => item
  })
  const [popularityFilter, setPopularityFilter] = useState<any>((item) => {
    return item => item
  })
  const [areasFilter, setAreasFilter] = useState<any>((item) => {
    return item => item
  })

  // Show only interests states
  // State of checkbox
  const [onlyInterestsSelect, setOnlyInterestsSelect] = useState(false)
  // State to pass to sort and filter, if true override all card results with a message
  const [guestClickShowOnlyInterest, setGuestClickShowOnlyInterest] = useState(false)

  const [filtersActive, setFiltersActive] = useState(0)

  useEffect(() => {
    const sampleInput = {};
    const individualFilters = [
      fieldFilter,
      areasFilter,
      nameFilter,
      relatedCoursesFilter,
      relatedCareersFilter,
      collegeFilter,
      earningsFilter,
      popularityFilter
    ];

    // const groupedFilters = [
    //   filteredEarlyAppOptions,
    //   filteredCommonApp,
    //   filteredCoalition,
    //   filteredIndividualApp,
    //   filteredConsidersInterest,
    // ];

    const isFilterActive = (filter) => filter(sampleInput) !== sampleInput;

    const activeIndividualFiltersCount = individualFilters.filter(isFilterActive)?.length || 0;
    // const isGroupedFilterActive = groupedFilters.some(isFilterActive) ? 1 : 0;
    // const countOfActive = activeIndividualFiltersCount + isGroupedFilterActive;
    const onlyInterestActive = (onlyInterestsSelect || guestClickShowOnlyInterest) ? 1 : 0
    const countOfActive = activeIndividualFiltersCount + onlyInterestActive

    setFiltersActive(countOfActive);

  }, [
    fieldFilter,
    nameFilter,
    collegeFilter,
    relatedCoursesFilter,
    relatedCareersFilter,
    earningsFilter,
    popularityFilter,
    areasFilter,
    onlyInterestsSelect,
    guestClickShowOnlyInterest
  ])

  const [showOnlyMyInterestsFilter, setShowOnlyMyInterestsFilter] = useState<any>((item) => {
    return item => item
  })

  useEffect(() => {

    const returnShowOnlyInterestFilter = (onlyInterestsSelect) => {

      if (onlyInterestsSelect === true && currentUser?.majorsInterest && currentUser?.majorsInterest.length > 0) {
        setShowOnlyMyInterestsFilter((item) => {
          return item => currentUser?.majorsInterest.some((maj) => maj == item.id
          )
        }
        )
      }

      else if (onlyInterestsSelect === true && (currentUser?.majorsInterest == null || currentUser?.majorsInterest?.length == 0)) {
        setShowOnlyMyInterestsFilter((item) => {
          return item => false
        })
      }

      else {
        setShowOnlyMyInterestsFilter((item) => {
          return item => item
        })
      }

    }
    returnShowOnlyInterestFilter(onlyInterestsSelect)

  }, [onlyInterestsSelect])

  //////// Functions and event handlers ////////
  const getNameOptions = (majors) => {
    let res: any = []

    // majors.forEach((major) => {
    //   res.push({ value: major.name, label: major.name })
    //   setNameOptions(res)
    // });

    majors.forEach((major) => {
      res.push({ value: major.id, label: major.name })
      setNameOptions(res)
    });
  }

  const getFieldOptions = (cipTax, majorData) => {

    // Get unique cip codes list from cip codes in majors table first to trim down # of cip codes to work with on major search page
    let uniqueCips: any = []
    majorData.map(major => {

      if (!uniqueCips.includes(major.cipt_code)) {
        uniqueCips.push(major.cipt_code);
      }

      major.secondaryCIPs?.forEach(secCip => {
        if (!uniqueCips.includes(secCip)) {
          uniqueCips.push(secCip)
        }
      })

    })
    uniqueCips = uniqueCips.sort((a, b) => a.localeCompare(b))

    let res: any = []
    // If the CIP code from cip tax is in the uniqueCIPs list created from the majors table, add to the field option codes list of objects to be passed to the filter component
    cipTax.map((cip) => {
      if (uniqueCips.includes(cip.code)) {
        res.push({ value: cip.code, label: cip.name })
      }
    })

    res = res.sort((a, b) => a.label.localeCompare(b.label))
    setFieldOptions(res)
  }

  const getProgramData = async () => {
    let programRes = await axios.get(`${dictionary.URL}/program_data/`)
    return programRes.data
  }

  const getMajorsData = async () => {
    let res = await axios.get(`${dictionary.URL}/majors/`);
    return res.data
  }

  const getCollegeIdData = async () => {
    let collegeIDRes = await axios.get(`${dictionary.URL}/colleges-id-name/`)
    return collegeIDRes.data
  }

  const getMajorData = async () => {

    // let programCache: string | null = localStorage.getItem('dantech-programData')
    // let majorCache: string | null = localStorage.getItem('dantech-majors')
    // let collegeIDCache: string | null = localStorage.getItem('dantech-collegeID')

    let programCache: any = await dictionary.handleLocalStorageData('dantech-programData', getProgramData)
    let majorCache: any = await dictionary.handleLocalStorageData('dantech-majors', getMajorsData)
    let collegeIDCache: any = await dictionary.handleLocalStorageData('dantech-collegeID', getCollegeIdData)

    setProgramData(programCache)
    setMajorData(majorCache)

    let atLeast1College = majorCache.filter((item) => programCache.filter((prog) => item.id === prog.major_id).length > 0)
    getNameOptions(atLeast1College)

    let collegeRes: any = []
    collegeIDCache.forEach(col => {
      if (programCache.filter((item) => item.college_id === col.id).length > 0) {
        collegeRes.push({ value: col.id, label: col.name })
      }
    });
    collegeRes = collegeRes.sort((a, b) => a.label.localeCompare(b.label));
    setCollegeOptions(collegeRes)
    // try {
    //   if (!programCache && !majorCache && !collegeIDCache) {
    //     // Get and set program data
    //     let programRes = await axios.get(`${dictionary.URL}/program_data/`)
    //     setProgramData(programRes.data)

    //     // Get and set major data
    //     let res = await axios.get(`${dictionary.URL}/majors/`);
    //     let atLeast1College = res.data.filter((item) => programRes.data.filter((prog) => item.id === prog.major_id).length > 0)
    //     setMajorData(res.data);
    //     // setMajors(atLeast1College)
    //     getNameOptions(atLeast1College)

    //     // Get and set the data for just the college id's and names
    //     let collegeIDRes = await axios.get(`${dictionary.URL}/colleges-id-name/`)
    //     let collegeRes: any = []
    //     collegeIDRes.data.forEach(col => {
    //       if (programRes.data.filter((item) => item.college_id === col.id).length > 0) {
    //         collegeRes.push({ value: col.id, label: col.name })
    //       }
    //     });
    //     setCollegeOptions(collegeRes)

    //     // Attempt to store in localStorage
    //     try {
    //       localStorage.setItem('dantech-programData', JSON.stringify(programRes.data))
    //       localStorage.setItem('dantech-majors', JSON.stringify(res.data))
    //       localStorage.setItem('dantech-collegeID', JSON.stringify(collegeIDRes.data))
    //     } catch (error) {
    //       console.log('Error:', error)
    //     }
    //   }

    //   else if (programCache && majorCache && collegeIDCache) {

    //     try {
    //       const programCacheParsed = JSON.parse(programCache)
    //       const majorCacheParsed = JSON.parse(majorCache)
    //       const collegeIDCacheParsed = JSON.parse(collegeIDCache)

    //       // console.log('programCacheParsed', programCacheParsed)
    //       // console.log('majorCacheParsed', majorCacheParsed)
    //       // console.log('collegeIDCacheParsed', collegeIDCacheParsed)

    //       setProgramData(programCacheParsed)

    //       let atLeast1College = majorCacheParsed.filter((item) => programCacheParsed.filter((prog) => item.id === prog.major_id).length > 0)
    //       // console.log('at least 1 college using parsed:', atLeast1College)

    //       setMajorData(majorCacheParsed);
    //       // setMajors(atLeast1College)
    //       getNameOptions(atLeast1College)

    //       let collegeRes: any = []

    //       collegeIDCacheParsed.forEach(col => {
    //         if (programCacheParsed.filter((item) => item.college_id === col.id).length > 0) {
    //           collegeRes.push({ value: col.id, label: col.name })
    //         }
    //       });

    //       setCollegeOptions(collegeRes)

    //     } catch (error) {

    //       console.log('Error grabbing data, using axios instead:', error)

    //       let programRes = await axios.get(`${dictionary.URL}/program_data/`)
    //       setProgramData(programRes.data)

    //       let res = await axios.get(`${dictionary.URL}/majors/`);

    //       let atLeast1College = res.data.filter((item) => programRes.data.filter((prog) => item.id === prog.major_id).length > 0)

    //       setMajorData(res.data);
    //       // setMajors(atLeast1College)
    //       getNameOptions(atLeast1College)

    //       let collegeIDRes = await axios.get(`${dictionary.URL}/colleges-id-name/`)
    //       let collegeRes: any = []

    //       collegeIDRes.data.forEach(col => {
    //         if (programRes.data.filter((item) => item.college_id === col.id).length > 0) {
    //           collegeRes.push({ value: col.id, label: col.name })
    //         }
    //       });

    //       setCollegeOptions(collegeRes)
    //     }
    //   }
    // } catch (error) {
    //   console.log('Error getting Major data:', error)
    // }

    // finally {
    //   // setDataLoaded(true)
    //   console.log('getMajorData finished')
    // }
  }

  const getCIPTaxonomyData = async () => {
    let res = await axios.get(`${dictionary.URL}/cip_taxonomy/`)
    return res.data
  }

  const setCIPTaxonomyData = async () => {
    // let cipTaxCache: string | null = localStorage.getItem('dantech-cipTaxonomy')

    let cipTaxCache: any = await dictionary.handleLocalStorageData('dantech-cipTaxonomy', getCIPTaxonomyData)
    setCipTax(cipTaxCache)

    // try {
    //   if (!cipTaxCache) {
    //     let res = await axios.get(`${dictionary.URL}/cip_taxonomy/`)
    //     setCipTax(res.data)

    //     try {
    //       localStorage.setItem('dantech-cipTaxonomy', JSON.stringify(res.data))
    //     } catch (error) {
    //       console.log('Error:', error)
    //     }
    //   }

    //   else if (cipTaxCache) {
    //     try {
    //       const cipTaxCacheParsed = JSON.parse(cipTaxCache)
    //       setCipTax(cipTaxCacheParsed)
    //     } catch (error) {
    //       console.log('Error grabbing data, using axios instead:', error)
    //       let res = await axios.get(`${dictionary.URL}/cip_taxonomy/`)
    //       setCipTax(res.data)
    //     }
    //   }

    // } catch (error) {
    //   console.log('Error getting CIP Tax data:', error)
    // }
  }

  const getCourseOptions = async () => {

    // let coursesCache: string | null = sessionStorage.getItem('dantech-majors-courseOptions')

    // try {
    //   if (!coursesCache) {
    //     let res = await axios.get(`${dictionary.URL}/interests/`)
    //     let courseRes: any = []
    //     res.data.forEach(course => {
    //       courseRes.push({ value: course.id, label: course.name })
    //     })

    //     setCourseOptions(courseRes)

    //     // Attempt to store in sessionStorage
    //     try {
    //       sessionStorage.setItem('dantech-majors-courseOptions', JSON.stringify(courseRes))
    //     } catch (error) {
    //       console.log('Error:', error)
    //     }
    //   }

    //   else if (coursesCache) {
    //     const coursesCacheParsed = JSON.parse(coursesCache)
    //     setCourseOptions(coursesCacheParsed)
    //   }

    // } catch (error) {
    //   console.log('Error:', error)
    // }

    let res = await axios.get(`${dictionary.URL}/interests/`)
    let courseRes: any = []
    res.data.forEach(course => {
      courseRes.push({ value: course.id, label: course.name })
    })

    setCourseOptions(courseRes)

    let resB = await axios.get(`${dictionary.URL}/interest_cip_map/`)
    setInterestCIP(resB.data)


  }

  const getCareerOptions = async () => {

    let careersCache: string | null = sessionStorage.getItem('dantech-majors-careerOptions')

    try {
      if (!careersCache) {
        let res = await axios.get(`${dictionary.URL}/careers/`)
        let careerRes: any = []
        res.data.forEach(career => {
          careerRes.push({ value: career.code, label: career.name })
        })
        setCareerOptions(careerRes)

        // Attempt to store in sessionStorage
        try {
          sessionStorage.setItem('dantech-majors-careerOptions', JSON.stringify(careerRes))
        } catch (error) {
          console.log('Error:', error)
        }

      }

      else if (careersCache) {
        const careersCacheParsed = JSON.parse(careersCache)
        setCareerOptions(careersCacheParsed)
      }
    } catch (error) {
      console.log('Error:', error)
    }

  }

  const getCareerCIP = async () => {

    const getCareerCIPData = async () => {
      let res = await axios.get(`${dictionary.URL}/cip_careermap/`)
      return res.data
    }

    // let cipCareerCache: string | null = localStorage.getItem('dantech-cipCareer')
    let cipCareerCache: any = await dictionary.handleLocalStorageData('dantech-cipCareer', getCareerCIPData)
    setCareerCIP(cipCareerCache)

    // try {

    //   if (!cipCareerCache) {
    //     let resB = await axios.get(`${dictionary.URL}/cip_careermap/`)
    //     setCareerCIP(resB.data)

    //     try {

    //       localStorage.setItem('dantech-cipCareer', JSON.stringify(resB.data))

    //     } catch (error) {
    //       console.log('Error:', error)
    //     }
    //   }

    //   else if (cipCareerCache) {
    //     try {
    //       const cipCareerParsed = JSON.parse(cipCareerCache);
    //       setCareerCIP(cipCareerParsed)

    //     } catch (error) {

    //       console.log('Error grabbing data, using axios instead:', error);

    //       let resB = await axios.get(`${dictionary.URL}/cip_careermap/`);
    //       setCareerCIP(resB.data)
    //     }
    //   }

    // } catch (error) {
    //   console.log('Error getting cip career data:', error)
    // }

  }

  const handleSetAdvisors = async () => {
    // let advisorCache: string | null = localStorage.getItem('dantech-advisors')
    const getAdvisorData = async () => {
      let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
      return res.data
    }
    let advisorCache: any = await dictionary.handleLocalStorageData('dantech-advisors', getAdvisorData)

    let advisorMajorids: any = []
    advisorCache.map(advisor => {
      if (advisor.major_id) {
        advisor.major_id.forEach(id => advisorMajorids.push(id))
      }
    })
    setAdvisorData(advisorCache)
    setAdvisorMajorIDs([...new Set(advisorMajorids)])

    // if (!advisorCache) {
    //   // axios request to get advisors
    //   let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
    //   let advisorMajorids: any = []
    //   res.data.map(advisor => {
    //     if (advisor.major_id) {
    //       advisor.major_id.forEach(id => advisorMajorids.push(id))
    //     }
    //   })

    //   try {
    //     localStorage.setItem('dantech-advisors-ecActivities', JSON.stringify(res.data))
    //   } catch (error) {
    //     console.log('Error:', error)
    //   }

    //   setAdvisorData(res.data)
    //   setAdvisorMajorIDs([...new Set(advisorMajorids)])

    // } else {
    //   try {

    //     const advisorDataParsed = JSON.parse(advisorCache)
    //     setAdvisorData(advisorDataParsed)

    //     let advisorMajorids: any = []
    //     advisorDataParsed.map(advisor => {
    //       if (advisor.major_id) {
    //         advisor.major_id.forEach(id => advisorMajorids.push(id))
    //       }
    //     })
    //     setAdvisorMajorIDs([...new Set(advisorMajorids)])

    //   } catch (error) {

    //     console.log('Error grabbing data, using axios instead:', error)
    //     let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
    //     let advisorMajorids: any = []
    //     res.data.map(advisor => {
    //       if (advisor.major_id) {
    //         advisor.major_id.forEach(id => advisorMajorids.push(id))
    //       }
    //     })

    //     try {
    //       localStorage.setItem('dantech-advisors-ecActivities', JSON.stringify(res.data))
    //     } catch (error) {
    //       console.log('Error:', error)
    //     }

    //     setAdvisorData(res.data)
    //     setAdvisorMajorIDs([...new Set(advisorMajorids)])

    //   }
    // }
  }

  //////// useEffects //////// 
  useEffect(() => {

    dictionary.updateTitleAndDescription(dictionary.pageRoutes.majorSearch)

    const fetchData = async () => {

      try {

        if (dataLoaded === true) {
          setDataLoaded(false)
        }

        await Promise.all([
          getCareerCIP(),
          getCourseOptions(),
          getCareerOptions(),
          setCIPTaxonomyData(),
          getMajorData(),
          handleSetAdvisors()
        ])

      } catch (error) {

        console.error('Error getting Major Search page data:', error)

      } finally {

        setFilteredResults(true);
        setDataLoaded(true)

      }
    }

    fetchData()

  }, [])

  useEffect(() => {
    if (cipTax.length !== 0 && majorData.length !== 0) {
      getFieldOptions(cipTax, majorData)
    }
  }, [cipTax, majorData])

  useEffect(() => {

    if (dataLoaded == true) {

      const sortAndFilter = (fieldFilter, nameFilter, collegeFilter, relatedCoursesFilter, relatedCareersFilter, earningsFilter, popularityFilter, areasFilter, sortOrder, sortKey) => {

        if (onlyInterestsSelect == true && (!currentUser || !user)) {
          return setGuestClickShowOnlyInterest(true)
        }

        else {
          setGuestClickShowOnlyInterest(false)
        }

        let res: any = majorData

        // console.log('initial res:', res)

        // console.log('coursesFilter:', relatedCoursesFilter)
        // res = res.filter(relatedCoursesFilter)
        // console.log('res after courses filter:', res)

        // console.log('careersFilter:', relatedCareersFilter)
        // res = res.filter(relatedCareersFilter)
        // console.log('res after careers filter:', res)

        // res = res.filter((item) => programData.filter((prog) => item.id === prog.major_id).length > 0)

        res = res
          .filter(fieldFilter)
          .filter(nameFilter)
          .filter(collegeFilter)
          .filter(relatedCoursesFilter)
          .filter(relatedCareersFilter)
          .filter(earningsFilter)
          .filter(popularityFilter)
          .filter(areasFilter)
          .filter(showOnlyMyInterestsFilter)
          .filter((item) => programData.filter((prog) => item.id === prog.major_id).length > 0)

        // console.log('relatedCareersFilter:', relatedCareersFilter)
        // console.log('relatedCoursesFilter:', relatedCoursesFilter)
        // console.log('res after filters:', res)

        // filter again such that results would be any with colleges with major > 0
        if (majorData.length > 0 && res.length === 0) {
          return setFilteredResults(false)
        }

        try {
          if (sortKey === 'numColleges') {
            const expandedSortLogic = (a, b) => {

              return (programData.filter((item) => a.id === item.major_id).length) - (programData.filter((item) => b.id === item.major_id).length)

            }

            let numCollegesSortRes = res.slice()

            sortOrder === 'Descending' ?
              numCollegesSortRes = numCollegesSortRes.sort((a, b) => expandedSortLogic(b, a)) :
              numCollegesSortRes = numCollegesSortRes.sort((a, b) => expandedSortLogic(a, b))

            setMajors(numCollegesSortRes)

          } else {
            sortOrder === 'Descending' ?
              res = res.sort((a, b) => dictionary.sortLogic(b, a, sortKey, 'name')) :
              res = res.sort((a, b) => dictionary.sortLogic(a, b, sortKey, 'name'))

            console.log('sortAndFilter running where sortKey is not numColleges')
            setMajors(res)
          }
        } catch (error) {

          console.log('Error:', error);
          setMajors(res)

        } finally {

          if (filteredResults === false) {
            setFilteredResults(true)
          }

        }

      }

      // console.log('since dataLoaded is:', dataLoaded, 'running sort and filter')
      // console.log('before running sortAndFilter, sortKey is:', sortKey)
      sortAndFilter(fieldFilter, nameFilter, collegeFilter, relatedCoursesFilter, relatedCareersFilter, earningsFilter, popularityFilter, areasFilter, sortOrder, sortKey);
    }


  }, [fieldFilter, nameFilter, collegeFilter, relatedCoursesFilter, relatedCareersFilter, earningsFilter, popularityFilter, areasFilter, sortOrder, sortKey, dataLoaded, showOnlyMyInterestsFilter])

  const returnResults = (filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, majorData) => {
    switch (true) {
      case (onlyInterestsSelect === true && majorData.filter(showOnlyMyInterestsFilter).length === 0 && !filteredResults):
        return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
          <div className='mt-5'>Please save majors to your interest list in order for them to appear here.</div>
        </div>
      case guestClickShowOnlyInterest == true:
        return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
          <div className='mt-5'>Please first log-in to your account in order for your saved colleges to appear here.</div>
        </div>
      case filteredResults == true:
        return <MajorCard
          majors={majors}
          majorData={majorData}
          cipTax={cipTax}
          programData={programData}
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          user={user}
          setUser={setUser}
          collegeOptions={collegeOptions}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          advisorMajorIDs={advisorMajorIDs}
        />
      case !(filteredResults == true):
        return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
          <div className='mt-5'>No results meet your criteria</div>
        </div>
    }
  }

  const majorSearchHeader = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>Explore Majors</span>

        <div className="dca-search-header-bttn-container-double">
          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(dictionary.pageRoutes.overviewAdmissionsAdviceWithHash('selecting-intended-major(s)'))}
            className='dca-search-header-bttn'
          >Advice: Selecting Intended Major(s)</button>

          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(dictionary.pageRoutes.personalizedExpertHelp)}
            className='dca-search-header-bttn'
            style={{ marginLeft: '8px' }}
          >Get Personalized Help</button>
        </div>
      </div>

      <span className="dca-search-description">Discover majors that fit your academic and personal interests by exploring this feature which details the actual majors offered at 25 of the top-ranked colleges.</span>

    </div>
  )

  const majorSearchHeaderMobile = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>Explore Majors</span>

        <span className="dca-search-description">Discover majors that fit your academic and personal interests by exploring this feature which details the actual majors offered at 25 of the top-ranked colleges.</span>

        <MajorSearchAside
          programData={programData}
          interestCIP={interestCIP}
          careerOptions={careerOptions}
          careerCIP={careerCIP}
          fieldOptions={fieldOptions}
          nameOptions={nameOptions}
          collegeOptions={collegeOptions}
          courseOptions={courseOptions}
          setFieldFilter={setFieldFilter}
          setNameFilter={setNameFilter}
          setCollegeFilter={setCollegeFilter}
          setRelatedCoursesFilter={setRelatedCoursesFilter}
          setRelatedCareersFilter={setRelatedCareersFilter}
          setEarningsFilter={setEarningsFilter}
          setPopularityFilter={setPopularityFilter}
          setAreasFilter={setAreasFilter}
          setFilteredResults={setFilteredResults}
          majors={majors}
          majorSearchLocation={majorSearchLocation}
          setMajorSearchLocation={setMajorSearchLocation}
          sortKey={sortKey}
          sortOrder={sortOrder}
          setSortKey={setSortKey}
          setSortOrder={setSortOrder}
          dataLoaded={dataLoaded}
          filtersActive={filtersActive}
          mobile={true}

          onlyInterestsSelect={onlyInterestsSelect}
          setOnlyInterestsSelect={setOnlyInterestsSelect}
          setGuestClickShowOnlyInterest={setGuestClickShowOnlyInterest}
          showOnlyMyInterestsFilter={showOnlyMyInterestsFilter}
          setShowOnlyMyInterestsFilter={setShowOnlyMyInterestsFilter}
        />

        <div>
          <div className='dca-college-search-resources-links-header'>RESOURCES</div>
          <div
            className='dca-college-search-resources-links'
            onClick={() => navigate(dictionary.pageRoutes.overviewAdmissionsAdviceWithHash('selecting-intended-major(s)'))}
          >Advice: Selecting Intended Major(s)</div>
          <div
            className='dca-college-search-resources-links'
            onClick={() => navigate(dictionary.pageRoutes.personalizedExpertHelp)}
          >Get Personalized Help</div>
        </div>
      </div>
    </div>
  )

  return (majors?.length > 0 && dataLoaded) ? (
    // <div>
    //   {majorSearchHeader}

    //   <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
    //     <MajorSearchAside
    //       programData={programData}
    //       interestCIP={interestCIP}
    //       careerOptions={careerOptions}
    //       careerCIP={careerCIP}
    //       fieldOptions={fieldOptions}
    //       nameOptions={nameOptions}
    //       collegeOptions={collegeOptions}
    //       courseOptions={courseOptions}
    //       setFieldFilter={setFieldFilter}
    //       setNameFilter={setNameFilter}
    //       setCollegeFilter={setCollegeFilter}
    //       setRelatedCoursesFilter={setRelatedCoursesFilter}
    //       setRelatedCareersFilter={setRelatedCareersFilter}
    //       setEarningsFilter={setEarningsFilter}
    //       setPopularityFilter={setPopularityFilter}
    //       setAreasFilter={setAreasFilter}
    //       setFilteredResults={setFilteredResults}
    //       majors={majors}
    //       majorSearchLocation={majorSearchLocation}
    //       setMajorSearchLocation={setMajorSearchLocation}
    //       sortKey={sortKey}
    //       sortOrder={sortOrder}
    //       setSortKey={setSortKey}
    //       setSortOrder={setSortOrder}
    //       dataLoaded={dataLoaded}

    //       onlyInterestsSelect={onlyInterestsSelect}
    //       setOnlyInterestsSelect={setOnlyInterestsSelect}
    //       setGuestClickShowOnlyInterest={setGuestClickShowOnlyInterest}
    //       showOnlyMyInterestsFilter={showOnlyMyInterestsFilter}
    //       setShowOnlyMyInterestsFilter={setShowOnlyMyInterestsFilter}
    //     />
    //     {returnResults(filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, majorData)}
    //   </div>
    // </div>
    <div id='dca-college-search-wrapper'>
      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        {majorSearchHeader}
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none'>
        {majorSearchHeaderMobile}
      </div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
          <MajorSearchAside
            programData={programData}
            interestCIP={interestCIP}
            careerOptions={careerOptions}
            careerCIP={careerCIP}
            fieldOptions={fieldOptions}
            nameOptions={nameOptions}
            collegeOptions={collegeOptions}
            courseOptions={courseOptions}
            setFieldFilter={setFieldFilter}
            setNameFilter={setNameFilter}
            setCollegeFilter={setCollegeFilter}
            setRelatedCoursesFilter={setRelatedCoursesFilter}
            setRelatedCareersFilter={setRelatedCareersFilter}
            setEarningsFilter={setEarningsFilter}
            setPopularityFilter={setPopularityFilter}
            setAreasFilter={setAreasFilter}
            setFilteredResults={setFilteredResults}
            majors={majors}
            majorSearchLocation={majorSearchLocation}
            setMajorSearchLocation={setMajorSearchLocation}
            sortKey={sortKey}
            sortOrder={sortOrder}
            setSortKey={setSortKey}
            setSortOrder={setSortOrder}
            dataLoaded={dataLoaded}
            filtersActive={filtersActive}

            onlyInterestsSelect={onlyInterestsSelect}
            setOnlyInterestsSelect={setOnlyInterestsSelect}
            setGuestClickShowOnlyInterest={setGuestClickShowOnlyInterest}
            showOnlyMyInterestsFilter={showOnlyMyInterestsFilter}
            setShowOnlyMyInterestsFilter={setShowOnlyMyInterestsFilter}
          />
          {returnResults(filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, majorData)}
        </div>
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none'>
        {returnResults(filteredResults, guestClickShowOnlyInterest, onlyInterestsSelect, majorData)}
      </div>
    </div>
  ) : (
    // <div>
    //   {majorSearchHeader}

    //   <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
    //     <MajorSearchAside
    //       programData={programData}
    //       interestCIP={interestCIP}
    //       careerOptions={careerOptions}
    //       careerCIP={careerCIP}
    //       nameOptions={nameOptions}
    //       fieldOptions={fieldOptions}
    //       collegeOptions={collegeOptions}
    //       courseOptions={courseOptions}
    //       setFieldFilter={setFieldFilter}
    //       setNameFilter={setNameFilter}
    //       setCollegeFilter={setCollegeFilter}
    //       setRelatedCoursesFilter={setRelatedCoursesFilter}
    //       setRelatedCareersFilter={setRelatedCareersFilter}
    //       setEarningsFilter={setEarningsFilter}
    //       setPopularityFilter={setPopularityFilter}
    //       setAreasFilter={setAreasFilter}
    //       majors={majors}
    //       setFilteredResults={setFilteredResults}
    //       majorSearchLocation={majorSearchLocation}
    //       setMajorSearchLocation={setMajorSearchLocation}
    //       sortKey={sortKey}
    //       sortOrder={sortOrder}
    //       setSortKey={setSortKey}
    //       setSortOrder={setSortOrder}
    //       dataLoaded={dataLoaded}
    //       filtersActive={filtersActive}

    //       onlyInterestsSelect={onlyInterestsSelect}
    //       setOnlyInterestsSelect={setOnlyInterestsSelect}
    //       setGuestClickShowOnlyInterest={setGuestClickShowOnlyInterest}
    //       showOnlyMyInterestsFilter={showOnlyMyInterestsFilter}
    //       setShowOnlyMyInterestsFilter={setShowOnlyMyInterestsFilter}
    //     />

    //     <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
    //       <LoadingIndicator text={"Loading Information On College Majors..."} />
    //     </div>
    //   </div>
    // </div>

    <div id='dca-college-search-wrapper'>
      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        {majorSearchHeader}
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none'>
        {majorSearchHeaderMobile}
      </div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
          <MajorSearchAside
            programData={programData}
            interestCIP={interestCIP}
            careerOptions={careerOptions}
            careerCIP={careerCIP}
            fieldOptions={fieldOptions}
            nameOptions={nameOptions}
            collegeOptions={collegeOptions}
            courseOptions={courseOptions}
            setFieldFilter={setFieldFilter}
            setNameFilter={setNameFilter}
            setCollegeFilter={setCollegeFilter}
            setRelatedCoursesFilter={setRelatedCoursesFilter}
            setRelatedCareersFilter={setRelatedCareersFilter}
            setEarningsFilter={setEarningsFilter}
            setPopularityFilter={setPopularityFilter}
            setAreasFilter={setAreasFilter}
            setFilteredResults={setFilteredResults}
            majors={majors}
            majorSearchLocation={majorSearchLocation}
            setMajorSearchLocation={setMajorSearchLocation}
            sortKey={sortKey}
            sortOrder={sortOrder}
            setSortKey={setSortKey}
            setSortOrder={setSortOrder}
            dataLoaded={dataLoaded}
            filtersActive={filtersActive}

            onlyInterestsSelect={onlyInterestsSelect}
            setOnlyInterestsSelect={setOnlyInterestsSelect}
            setGuestClickShowOnlyInterest={setGuestClickShowOnlyInterest}
            showOnlyMyInterestsFilter={showOnlyMyInterestsFilter}
            setShowOnlyMyInterestsFilter={setShowOnlyMyInterestsFilter}
          />
          <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
            <LoadingIndicator text={"Loading Information On College Majors..."} />
          </div>
        </div>
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none flex-column justify-content-center align-items-center' style={{ marginTop: '40px' }}>
        <LoadingIndicator text={"Loading Information On College Majors..."} />
      </div>
    </div>
  )
}

export { MajorSearchPage }
