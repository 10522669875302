import { FC, useState, useEffect } from 'react'
import axios from 'axios'
import * as dictionary from '../components/dictionary'
import { Link, useParams } from 'react-router-dom'
import { CollegeDetailCardData } from '../components/CollegeDetail/CollegeDetailCardData'
import { CollegeDetailTabs } from '../components/CollegeDetail/CollegeDetailTabs'
import { SaveButtonCollegeInline, SaveButtonInlineNoAuth } from '../components/SaveButton'
import { getAuth } from '../modules/auth'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { ConnectWithAdvisorButton } from '../components/ConnectWithAdvisorButton'
import { collegeMap } from '../components/collegeMap'
import { SafetyAggregateModal } from '../components/CollegeDetail/TabComponents/Modals/SafetyAggregateModal'
import { SafetyAnnualAveragePer1KModal } from '../components/CollegeDetail/TabComponents/Modals/SafetyAnnualAveragePer1KModal'
import { SafetyAnnualAverageTotalModal } from '../components/CollegeDetail/TabComponents/Modals/SafetyAnnualAverageTotalModal'
import { SpecializedRankingModal } from '../components/CollegeDetail/TabComponents/Modals/SpecializedRankingModal'
import { RnDSpendModal } from '../components/CollegeDetail/TabComponents/Modals/R&DSpendModal'

const CollegeDetailPage = ({ user, setUser, userCollegeInterest, setUserCollegeInterest, collegeListLength, prompt, setPrompt, navigateTo, setNavigateTo, setPurchaseID, setPurchaseModalCollegeName, collegesId, selectedTab, setSelectedTab, collegeSearchLocation, idToSend }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  useEffect(() => {
    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
      permissionGroups = getAuth()?.groups
    }

  }, [user])

  // const { collegeId } = useParams()
  const { collegeNameHyphenated } = useParams()
  const [college, setCollege] = useState<any>([])
  const [advisorData, setAdvisorData] = useState<any>([])
  const [advisorCollegeIDs, setAdvisorCollegeIDs] = useState<any>([])

  //// Modal states
  const [rndModalData, setRnDModalData] = useState<any>({
    collegeData: null, dataLoaded: null, modalTitle: null
  })
  const [saModalData, setSAModalData] = useState<any>({ collegesSafetyIndex: null, dataLoaded: null })
  const [safety1KModalData, setSafety1KModalData] = useState<any>({
    allCrimeAvgPer1KData: null, allCrimeRankPer1KData: null, dataLoaded: null, modalTitle: null, modalCategory: null
  })
  const [safetyTotalModalData, setSafetyTotalModalData] = useState<any>({
    allCrimeAvgTotalData: null, allCrimeRankTotalData: null, dataLoaded: null, modalTitle: null, modalCategory: null
  })
  const [srModalData, setSRModalData] = useState<any>({
    collegeData: null, dataLoaded: null, modalTitle: null, latestYear: new Date().getFullYear()
  })

  useEffect(() => {

    const getCollege = async () => {
      // let res = await axios.get(`${dictionary.URL}/colleges/${collegeId}`)

      try {
        // let collegeObj = collegeMap.find(col => col.hyphenated == collegeNameHyphenated)

        // let res = await axios.get(`${dictionary.URL}/colleges/${collegeObj?.id}`)

        let res = await axios.get(`${dictionary.URL}/colleges/${idToSend}`)
        setCollege(res.data)
      } catch (error) {
        console.log('Could not find college info:', error)
        throw error
      }
    }
    getCollege();

    const getData = async () => {
      const handleSetAdvisors = async () => {
        // let advisorCache: string | null = localStorage.getItem('dantech-advisors')

        const getAdvisorsData = async () => {
          let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
          return res.data
        }

        let advisorCache: any = await dictionary.handleLocalStorageData('dantech-advisors', getAdvisorsData)

        let advisorColleges: any = []
        advisorCache.map(advisor => {
          if (advisor.college_id) {
            advisorColleges.push(parseInt(advisor.college_id))
          }
        })

        setAdvisorData(advisorCache)
        setAdvisorCollegeIDs([...new Set(advisorColleges)])

        // if (!advisorCache) {
        //   // axios request to get advisors
        //   let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
        //   let advisorColleges: any = []
        //   res.data.map(advisor => {
        //     if (advisor.college_id) {
        //       advisorColleges.push(parseInt(advisor.college_id))
        //     }
        //   })

        //   try {
        //     localStorage.setItem('dantech-advisors-ecActivities', JSON.stringify(res.data))
        //   } catch (error) {
        //     console.log('Error:', error)
        //   }

        //   setAdvisorData(res.data)
        //   setAdvisorCollegeIDs([...new Set(advisorColleges)])

        // } else {
        //   try {

        //     const advisorDataParsed = JSON.parse(advisorCache)
        //     setAdvisorData(advisorDataParsed)

        //     let advisorColleges: any = []
        //     advisorDataParsed.map(advisor => {
        //       if (advisor.college_id) {
        //         advisorColleges.push(parseInt(advisor.college_id))
        //       }
        //     })
        //     setAdvisorCollegeIDs([...new Set(advisorColleges)])

        //   } catch (error) {

        //     console.log('Error grabbing data, using axios instead:', error)
        //     let res = await axios.get(`${dictionary.URL}/advisor_profiles_connect_with/`);
        //     let advisorColleges: any = []
        //     res.data.map(advisor => {
        //       if (advisor.college_id) {
        //         advisorColleges.push(parseInt(advisor.college_id))
        //       }
        //     })

        //     try {
        //       localStorage.setItem('dantech-advisors-ecActivities', JSON.stringify(res.data))
        //     } catch (error) {
        //       console.log('Error:', error)
        //     }

        //     setAdvisorData(res.data)
        //     setAdvisorCollegeIDs([...new Set(advisorColleges)])

        //   }
        // }
      }

      await handleSetAdvisors()
    }

    getData()

  }, [])

  useEffect(() => {
    dictionary.updateTitleAndDescription(dictionary.pageRoutes.collegeDetailGeneric, college?.name, college?.name)
  }, [college])

  const desktopHeader = (<div className='mt-5 d-flex flex-row align-items-center justify-content-between' style={{ width: '100%' }}>
    <div className='d-flex flex-row justify-content-between align-items-center'>
      <img className="m-3" style={{ width: '75px', height: '75px' }} alt="campus_photo" src={dictionary.collegeLogos[college.id]} />

      <div className='mt-3 d-flex flex-row align-items-baseline'>
        <p className='dca-font-M' style={{ fontSize: '30px' }}>{college.name}</p>

        <p className='ms-4 fw-bold'>{college.locationCity ? `${college.locationCity}, ${college.locationState ? dictionary.stateMap[college.locationState] : 'N/A'}` : 'N/A'}</p>
      </div>
    </div>

    <div className='d-flex flex-row justify-content-between align-items-center'>
      {/* <button type="button" className="me-5 btn btn-sm fw-bold" style={{ background: dictionary.gray }}><i className="bi bi-link-45deg"></i> Connect with {college.name} students</button> */}

      {advisorCollegeIDs.some(collegeID => collegeID == college.id) && <ConnectWithAdvisorButton
        cardID={college.id}
        searchPage={dictionary.pageRoutes.collegeSearch}
        buttonTextParam={college.name}
        inLine={true} />}

      {apiToken != undefined ? <SaveButtonCollegeInline
        list={userCollegeInterest}
        user={user}
        collegeId={college.id}
        setUserCollegeInterest={setUserCollegeInterest}
      /> : <SaveButtonInlineNoAuth setPrompt={setPrompt} setNavigateTo={setNavigateTo} />}
    </div>
  </div>)

  const mobileHeader = (
    <div className='d-flex flex-column' style={{ width: '100%' }}>
      <div className='d-flex flex-row justify-content-between' style={{ marginTop: '20px' }}>
        <div className='d-flex flex-row align-items-center'>
          <img className="m-3" style={{ width: '75px', height: '75px' }} alt="campus_photo" src={dictionary.collegeLogos[college.id]} />
          <p className='dca-font-M' style={{ fontSize: '30px' }}>{college.name}</p>
        </div>

        {apiToken != undefined ? <SaveButtonCollegeInline
          list={userCollegeInterest}
          user={user}
          collegeId={college.id}
          setUserCollegeInterest={setUserCollegeInterest}
          mobile={true}
        /> : <div className='d-flex align-items-center' style={{ height: 'fit-content' }}><SaveButtonInlineNoAuth setPrompt={setPrompt} setNavigateTo={setNavigateTo} mobile={true} /></div>}
      </div>

      {advisorCollegeIDs.some(collegeID => collegeID == college.id) && <ConnectWithAdvisorButton
        cardID={college.id}
        searchPage={dictionary.pageRoutes.collegeSearch}
        buttonTextParam={college.name}
        inLine={true} />}
    </div>)

  return college ? (
    <div style={{ margin: '0px 35px' }}>

      <Link
        className="fw-bold"
        to={`${collegeSearchLocation}`}
        style={{ color: 'black', marginTop: '30px' }}>
        <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black', fontWeight: 'bold', marginTop: '30px' }} ></i>
        Back to Exploring All Colleges</Link>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex' style={{ width: '100%' }}>{desktopHeader}</div>
      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none' style={{ width: '100%' }}>{mobileHeader}</div>

      <div className='mt-5'>

        {/* Use Bootstrap d-md-block to render while 768px or above */}
        <div className='d-none d-md-flex' style={{ width: '100%' }}><CollegeDetailCardData
          college={college}
        /></div>
        {/* Mobile Replacement, only shows at below 768px */}
        <div className='d-flex d-md-none' style={{ width: '100%' }}><CollegeDetailCardData
          college={college}
          mobile={true}
        /></div>

      </div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex' style={{ marginTop: '50px', maxWidth: '94vw', width: '100%' }}>
        <CollegeDetailTabs
          college={college}
          setUser={setUser}
          collegeListLength={collegeListLength}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          setPurchaseID={setPurchaseID}
          setPurchaseModalCollegeName={setPurchaseModalCollegeName}
          collegesId={collegesId}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setRnDModalData={setRnDModalData}
          setSAModalData={setSAModalData}
          setSafety1KModalData={setSafety1KModalData}
          setSafetyTotalModalData={setSafetyTotalModalData}
          setSRModalData={setSRModalData}
        />
      </div>
      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none' style={{ maxWidth: '94vw', width: '100%', marginTop: '20px' }}>
        <CollegeDetailTabs
          college={college}
          setUser={setUser}
          collegeListLength={collegeListLength}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          setPurchaseID={setPurchaseID}
          setPurchaseModalCollegeName={setPurchaseModalCollegeName}
          collegesId={collegesId}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          mobile={true}
          setRnDModalData={setRnDModalData}
          setSAModalData={setSAModalData}
          setSafety1KModalData={setSafety1KModalData}
          setSafetyTotalModalData={setSafetyTotalModalData}
          setSRModalData={setSRModalData}
        />
      </div>

      <RnDSpendModal
        collegeData={rndModalData.collegeData}
        dataLoaded={rndModalData.dataLoaded}
        modalTitle={rndModalData.modalTitle} />

      <SafetyAggregateModal
        collegesSafetyIndex={saModalData.collegesSafetyIndex}
        dataLoaded={saModalData.dataLoaded} />

      <SafetyAnnualAveragePer1KModal
        allCrimeAvgPer1KData={safety1KModalData.allCrimeAvgPer1KData}
        allCrimeRankPer1KData={safety1KModalData.allCrimeRankPer1KData}
        dataLoaded={safety1KModalData.dataLoaded}
        modalTitle={safety1KModalData.modalTitle}
        modalCategory={safety1KModalData.modalCategory} />

      <SafetyAnnualAverageTotalModal
        allCrimeAvgTotalData={safetyTotalModalData.allCrimeAvgTotalData}
        allCrimeRankTotalData={safetyTotalModalData.allCrimeRankTotalData}
        dataLoaded={safetyTotalModalData.dataLoaded}
        modalTitle={safetyTotalModalData.modalTitle}
        modalCategory={safetyTotalModalData.modalCategory} />

      <SpecializedRankingModal
        collegeData={srModalData.collegeData}
        dataLoaded={srModalData.dataLoaded}
        modalTitle={srModalData.modalTitle}
        latestYear={srModalData.latestYear} />

    </div>

  ) : (<div> <LoadingIndicator text={"Loading College Data ... "} /></div>)
}

export { CollegeDetailPage }