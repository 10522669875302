import { closeModalIcon, closeModalFunction, pageRoutes, selectedTabGreen, specializedRankingsPriceID, rdSpendPriceID, safetyPriceID, returnECPremiumPriceID, reactGACheckoutModalController, advisorConnectAccessCode, returnAdvisorPriceID, stripePromiseKey, personalizedExpertHelpProductID, personalizedExpertHelpPriceID } from "../dictionary"
import { getAuth, useAuth } from '../../modules/auth'
import { handleCreateOrAssignGroups } from "../../pages/Auth/UpgradeTierPage"
import { handleUpdateUserTransactions } from "../../pages/Auth/UpdateUserTransaction"
import { specializedRankingsAccessCode, safetyAccessCode, rdSpendAccessCode, ecPremiumAccessCode } from "../dictionary"
import { useNavigate } from "react-router"
import { useState, useEffect } from "react"

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { AdvisorsReactGAHandlers, CollegeReactGAHandlers, ECReactGAHandlers } from "../ReactGAHandlers"
import { LoadingIndicator } from "../LoadingIndicator"
import { extracurricularMap } from "../extracurricularMap"

// const stripePromise = loadStripe("pk_test_51OaR0tF7MQ6RjhDC0nWRzQPB3GvQzNDyAEu4Y9Vx2S33Xr9nlfwUZfUkMoYkFA1awRmoxWPBIvtPqWhXTtAObVuh00wMn17srK");

const stripePromise = loadStripe(stripePromiseKey)

let apiToken: any = undefined

if (getAuth()?.api_token && getAuth()?.groups) {
  apiToken = getAuth()?.api_token
}

const AddCardModal = ({ upgradeUserGroupAccessCode, setSelectedTab, clientSecret, setClientSecret, ecID, ecName, showNavAfterPayment, setShowNavAfterPayment, advisorID }) => {

  const { handleGAEventPurchaseComplete } = CollegeReactGAHandlers()
  const { handleECGAEventPurchaseComplete } = ECReactGAHandlers()
  const { handleAdvisorGAEventPurchaseComplete } = AdvisorsReactGAHandlers()

  const { requestUser } = useAuth()
  let navigate = useNavigate();
  const routeChange = (id) => {
    let matchingEC = extracurricularMap.find(ecObj => parseInt(ecObj.id) == id)
    navigate(pageRoutes.ecPremiumInsightsWithId(matchingEC?.hyphenated))
  }

  const [showAdvisorText, setShowAdvisorText] = useState<any>(false)
  const [showPersonalizedHelpText, setShowPersonalizedHelpText] = useState<any>(false)

  useEffect(() => {
    const modalElement: any = document.getElementById('add_payment_method_modal');

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'style') {
          const style = window.getComputedStyle(modalElement);
          if (style.display == 'none') {
            showNavAfterPayment ? setShowNavAfterPayment(false) : setShowNavAfterPayment(showNavAfterPayment)

            showAdvisorText ? setShowAdvisorText(false) : setShowAdvisorText(showAdvisorText)

            showPersonalizedHelpText ? setShowPersonalizedHelpText(false) : setShowPersonalizedHelpText(showPersonalizedHelpText)
          }
        }
      });
    });

    if (modalElement) {
      observer.observe(modalElement, { attributes: true, attributeFilter: ['style'] });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="modal fade" id="add_payment_method_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div
          className="modal-content"
          style={{ minWidth: 'fit-content', maxWidth: '75vw', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto', maxHeight: '90vh' }}>
          <div style={{ margin: '8px' }}>
            {closeModalIcon('add_payment_method', reactGACheckoutModalController(upgradeUserGroupAccessCode, upgradeUserGroupAccessCode.includes(advisorConnectAccessCode) ? advisorID : undefined))}
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">

            {/* <CheckoutForm /> */}
            <div id="checkout">
              {clientSecret ? (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{
                    clientSecret, onComplete() {

                      const runSubmit = async () => {
                        try {

                          if (getAuth()?.api_token && getAuth()?.groups) {
                            apiToken = getAuth()?.api_token
                          }

                          await handleCreateOrAssignGroups(apiToken, upgradeUserGroupAccessCode)

                          // Update UserTransactions table, pass product/priceID and expiry
                          switch (true) {
                            case upgradeUserGroupAccessCode === specializedRankingsAccessCode:
                              await handleUpdateUserTransactions(apiToken, specializedRankingsPriceID)
                              break;
                            case upgradeUserGroupAccessCode === rdSpendAccessCode:
                              await handleUpdateUserTransactions(apiToken, rdSpendPriceID)
                              break;
                            case upgradeUserGroupAccessCode === safetyAccessCode:
                              await handleUpdateUserTransactions(apiToken, safetyPriceID)
                              break
                            case upgradeUserGroupAccessCode.includes(ecPremiumAccessCode):
                              let ecPremiumPriceID = returnECPremiumPriceID(ecID)

                              if (typeof ecPremiumPriceID == 'string') {
                                await handleUpdateUserTransactions(apiToken, ecPremiumPriceID)
                              }

                              else {
                                console.log('Error updating user transaction')
                              }
                              break;
                            case upgradeUserGroupAccessCode.includes(advisorConnectAccessCode):
                              setShowAdvisorText(true);
                              // If we determine the purchase is for an advisor consultation, get the priceID for this purchase
                              let advisorConnectPriceID = returnAdvisorPriceID(advisorID)

                              // pass the advisorPriceID to the handleUpdateUserTransactions function
                              if (typeof advisorConnectPriceID == 'string') {
                                await handleUpdateUserTransactions(apiToken, advisorConnectPriceID);
                              }

                              else {
                                console.log('Error updating user transaction')
                              }
                              break;
                            case upgradeUserGroupAccessCode.includes(personalizedExpertHelpProductID):
                              setShowPersonalizedHelpText(true);
                              let personalizedExpertHelpPurchase = true
                              await handleUpdateUserTransactions(apiToken, personalizedExpertHelpPriceID, personalizedExpertHelpPurchase);
                              break;
                          }

                          await requestUser(apiToken)

                          // await setClientSecret('')

                          // closeModalFunction("add_payment_method")

                          switch (true) {
                            case upgradeUserGroupAccessCode === specializedRankingsAccessCode:
                              setSelectedTab('Specialized Rankings');
                              handleGAEventPurchaseComplete('Specialized Rankings')
                              break;
                            case upgradeUserGroupAccessCode === rdSpendAccessCode:
                              setSelectedTab('R&D Spend');
                              handleGAEventPurchaseComplete('R&D Spend')
                              break;
                            case upgradeUserGroupAccessCode === safetyAccessCode:
                              setSelectedTab('Crime & Safety');
                              handleGAEventPurchaseComplete('Crime & Safety')
                              break
                            case upgradeUserGroupAccessCode.includes(ecPremiumAccessCode):
                              setShowNavAfterPayment(true);
                              handleGAEventPurchaseComplete(ecID)
                              break;
                            case upgradeUserGroupAccessCode.includes(advisorConnectAccessCode):
                              setShowNavAfterPayment(true);
                              handleAdvisorGAEventPurchaseComplete(advisorID);
                              break
                            case upgradeUserGroupAccessCode.includes(personalizedExpertHelpProductID):
                              setShowNavAfterPayment(true);
                            // handleAdvisorGAEventPurchaseComplete(advisorID)
                          }

                        } catch (error) {

                          console.log(error)
                          throw (error)

                        }
                      }

                      setTimeout(() => {
                        runSubmit()
                      }, 2000)

                    },
                  }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              ) : <div className="d-flex flex-column justify-content-center align-items-center" >
                <LoadingIndicator text={''} />
              </div>}

              {showNavAfterPayment === true && upgradeUserGroupAccessCode.includes(ecPremiumAccessCode) ? <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '30px' }}><a
                role="button"
                className="btn btn-sm fw-bold align-self-center"
                style={{ background: selectedTabGreen }}
                onClick={async () => {
                  await closeModalFunction("add_payment_method");
                  routeChange(ecID)
                }}
              >View Premium Data</a></div> : <div></div>}

              {/* {showNavAfterPayment === true && upgradeUserGroupAccessCode.includes(advisorConnectAccessCode) ? <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '30px' }}>Your purchase transaction for this Advisors consultation is completed. The next step is you will receive an email from NextFour with details for scheduling your meeting. If you have any questions, please contact us at advisors@nextfour.ai.</div> : <div></div>}

              {showNavAfterPayment === true && upgradeUserGroupAccessCode.includes(personalizedExpertHelpProductID) ? <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '30px' }}>Your purchase transaction for the Personalized Expert Help feature is completed. The next step is you will receive a purchase confirmation email from NextFour that will provide details for utilizing this service. If you have any questions, please contact us at support@nextfour.ai.</div> : <div></div>} */}

              {showNavAfterPayment === true && showAdvisorText ? <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '30px' }}>Your purchase transaction for this Advisors consultation is completed. The next step is you will receive an email from NextFour with details for scheduling your meeting. If you have any questions, please contact us at advisors@nextfour.ai.</div> : <div></div>}

              {showNavAfterPayment === true && showPersonalizedHelpText ? <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '30px' }}>Your purchase transaction for the Personalized Expert Help feature is completed. The next step is you will receive a purchase confirmation email from NextFour that will provide details for utilizing this service. If you have any questions, please contact us at support@nextfour.ai.</div> : <div></div>}

            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export { AddCardModal }






