import { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
// import { EditScoreProfile, EditScoreProfileModal } from '../TestScoreStrategy/EditScoreProfile'
import { CollegeDataVizReactGAHandlers } from '../ReactGAHandlers'
import filterIcon from '../../../custom_assets/filter_icon.png'

const Aside = ({ collegeSetOptions, chartOptionsOptions, selectedChart, setSelectedChart, setSelectedType, selectedCollegeSet, setSelectedCollegeSet, user, setUser, filtersActive, mobile = false }) => {

  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)
  const [resetStateToggle, setResetStateToggle] = useState(false)

  const resetFilterStates = () => {

    setSelectedChart(1)
    setSelectedCollegeSet(0)
    setResetStateToggle(!resetStateToggle)

  }

  const toggleFilterOpen = () => {
    setMobileFilterOpen(!mobileFilterOpen)
  }

  const {
    handleGAEventSelectChartOption,
    handleGAEventSelectCollegeSet
  } = CollegeDataVizReactGAHandlers()

  //////// Institution Type ////////

  const [typeSelected, setTypeSelected] = useState(0)

  const handleTypeSelected = (typeSelected) => {
    setTypeSelected(typeSelected)
  }

  const institutionType = (
    <div className='menu-item'>
      <div className='menu-content pb-2'>
        <span className='menu-section fs-7 fw-bolder'>INSTITUTION TYPE</span>
        <div className='d-flex flex-row justify-content-center align-items-center'>
          <button
            type="button"
            onClick={() => {
              handleTypeSelected(0);
              setSelectedType(0)
            }}
            className={`btn btn-sm ${typeSelected === 0 ? 'btn-success' : 'btn-light'}`}
            aria-pressed="true"
            style={{ width: '100%' }}
          >
            All
          </button>
          <button
            type="button"
            onClick={() => {
              handleTypeSelected(1);
              setSelectedType(1)
            }}
            className={`btn btn-sm ${typeSelected === 1 ? 'btn-success' : 'btn-light'}`}
            aria-pressed="true"
            style={{ width: '100%' }}
          >
            Public
          </button>
          <button
            type="button"
            onClick={() => {
              handleTypeSelected(2);
              setSelectedType(2)
            }}
            className={`btn btn-sm ${typeSelected === 2 ? 'btn-success' : 'btn-light'}`}
            aria-pressed="true"
            style={{ width: '100%' }}
          >
            Private
          </button>
        </div>
      </div>
    </div>
  )

  //////// Specific Major ////////

  const specificMajorOptions = [{ value: '1', label: '1' }, { value: '2', label: '2' }]

  const [selectedMajors, setSelectedMajors] = useState<any>([])

  const handleSelectedMajors = (selectedMajors) => {
    setSelectedMajors(selectedMajors)

    // let majorArr: any = []
    // selectedMajors.map((res) =>
    // majorArr.push(res.value))
    // setMajorsSelect(majorArr)
  }

  const specificMajors = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='fw-bold'>SHOW COLLEGES OFFERING SPECIFIC MAJOR</div>
      <Select
        options={specificMajorOptions}
        isMulti
        value={selectedMajors}
        onChange={handleSelectedMajors}
      />
    </div>
  )

  //////// Show Related ////////
  const showRelated = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className="mt-2 d-flex flex-column form-check form-check-sm">

        <label className="mt-2 checkbox d-flex justify-content-between" style={{ cursor: 'pointer' }}>
          <div>
            <input
              type="checkbox"
              className='form-check-input form-check-sm'
              name="Show Related"
              // onChange={(event) => (setBlindSelect(handleCheckChange(event)))}
              style={{ cursor: 'pointer' }}
            />
            <span className='ms-3 me-3 fw-bold'>Include Schools with closely related majors</span>
          </div>
        </label>

      </div>
    </div>
  )

  //////// Edit User Profile ////////

  const editUserProfile = (
    <div>
      <button
        type="button"
        className='fw-bold rounded'
        data-bs-toggle="modal"
        data-bs-target="#editScoreProfile"
        style={{ background: "linear-gradient(rgba(218, 248, 185, 1) 0%, rgba(183, 255, 181, 1) 100%)", border: 'transparent', width: '227px', maxWidth: '227px', height: '47px', maxHeight: '47px', marginTop: '20px' }}>
        Edit User Profile
      </button>
      {/* <div className="modal fade" tabIndex={-1} id="editScoreProfile">
        <div className="modal-dialog">
          <div className="modal-content" style={{ width: '700px', maxWidth: '700px', marginTop: '120px', padding: '30px' }}>

            <div className="modal-body fw-bold d-flex flex-column">
              <p style={{ fontSize: '30px' }} className="align-self-center">Your Score Profile</p>
              <form onSubmit={handleSubmit}>
                {satScores}
                {actScores}


                <div className="d-flex flex-row justify-content-center" style={{ marginTop: '20px' }}>
                  <button
                    type="button"
                    className="btn btn-light fw-bold"
                    data-bs-dismiss="modal"
                    style={{ width: '107px', maxWidth: '107px', height: '50px', maxHeight: '50px', marginTop: '20px' }}
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    className="rounded fw-bold"
                    data-bs-dismiss="modal"
                    style={{ background: "linear-gradient(rgba(218, 248, 185, 1) 0%, rgba(183, 255, 181, 1) 100%)", border: 'transparent', width: '107px', maxWidth: '107px', height: '50px', maxHeight: '50px', marginTop: '20px', marginLeft: '20px' }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div> */}
    </div>
  )

  //////// Chart Options ////////

  const handleChartChange = (event) => {
    let res = event.target.value
    setSelectedChart(parseInt(res));
    handleGAEventSelectChartOption(chartOptionsOptions[res - 1].label)
  }

  // with the other filters removed, wrap select element in a div to set the width
  const chartOptions = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='fw-bold'>CHART OPTION</div>
      <div style={{ width: '100%' }}>
        <select
          className='form-select'
          data-control="select2"
          value={selectedChart}
          onChange={handleChartChange}
          style={{ width: '100%' }}
        >
          {chartOptionsOptions.map((option, index) =>
            <option
              key={index}
              value={option.value}>{option.label}</option>)}
        </select>
      </div>

    </div>
  )

  //////// College Set ////////
  const handleSelectedCollegeSet = (event) => {
    let res = event.target.value

    setSelectedCollegeSet(res);

    let handlerRes = collegeSetOptions.find((opt) => opt.value == res)
    handlerRes && handlerRes.label && handleGAEventSelectCollegeSet(handlerRes.label)
  }

  const collegeSet = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='fw-bold'>COLLEGE SET</div>
      <div style={{ width: '100%' }}>
        <select
          className='form-select'
          data-control="select2"
          value={selectedCollegeSet}
          onChange={handleSelectedCollegeSet}
          style={{ width: '100%' }}
        >
          {collegeSetOptions.map((res, index) =>
            <option key={index} value={res.value}>{res.label}</option>
          )}
        </select>
      </div>
    </div>
  )

  return mobile ? (
    <div className='dca-college-search-filter-drawer-container'>

      <button
        onClick={toggleFilterOpen}
        className={filtersActive > 0 ? 'dca-college-search-filters-button-bolded' : 'dca-college-search-filters-button'}><img src={filterIcon} alt="bars-filter" className="dca-college-filters-button-icon" />&nbsp;&nbsp;Controls {filtersActive > 0 && <span className='dca-filters-active-icon'>{filtersActive}</span>}</button>

      <div className={`dca-college-search-filter-drawer${mobileFilterOpen ? '-open' : ''}`} style={{ display: mobileFilterOpen ? 'flex' : 'none' }}>

        <div className='dca-college-search-mobile-filters-wrapper'>
          <div className='dca-college-search-filter-drawer-header'>
            <div className='dca-college-search-mobile-filters-title'>Controls</div>

            <div
              className="dca-college-search-filter-drawer-close-button"
              onClick={toggleFilterOpen}>
              &times;
            </div>
          </div>
          <div style={{ padding: '0px 15px 25px 15px', overflowY: 'auto' }}>
            {chartOptions}
            {collegeSet}
          </div>


          <div className='dca-college-search-mobile-filters-drawer-footer align-items-center justify-content-center'>
            {/* <span className='dca-college-search-mobile-filters-drawer-footer-clear'
              onClick={() => {
                resetFilterStates()
              }}>Clear all</span> */}

            <button onClick={toggleFilterOpen} className='dca-college-search-mobile-filters-drawer-footer-view-results'>View Chart</button>
          </div>
        </div>


      </div>
    </div>
  ) : (
    <div style={{
      minWidth: 'fit-content',
      // width: '260px', 
      // maxWidth: '260px' 
    }}>
      {chartOptions}
      {collegeSet}
      {/* {institutionType} */}
      {/* {specificMajors} */}
      {/* {showRelated} */}
      {/* {user?.emailAddress && <EditScoreProfileModal user={user} setUser={setUser} />} */}
    </div>
  )
}

export { Aside }