import axios from "axios"
import { URL, FRONTEND_URL, pageRoutes, specializedRankingsPriceID, rdSpendPriceID, safetyPriceID, ecPremiumPriceIDs, advisorPriceIDs, returnItemFromProductID, personalizedExpertHelpPriceID, personalizedExpertHelpProductID } from "../../components/dictionary"
import { getUserByToken } from "../../modules/auth/core/_requests"
import { sendAdvisorConsultationPurchaseAlert } from "../../modules/auth/core/_requests"
import { extracurricularMap } from "../../components/extracurricularMap"

// expiryDate: string removed
// productID in this case is actually the priceID from Stripe, we reference the product via it's priceID as it is used for pulling the value from Stripe. The productID is self defined apart from the personalized expert help 'product' in which we use the productID from Stripe for this purchase
export const handleUpdateUserTransactions = async (apiToken: string, productID: string | undefined, personalizedExpertHelpPurchase = false) => {

  const postRequest = async (userEmail, productID) => {

    const getStripePrice = async (priceID) => {
      let res = await axios.get(`${URL}/get-price/${priceID}`)
      let unitAmount = res.data.unit_amount
      if ((unitAmount / 100) > 0) {
        unitAmount = (unitAmount / 100).toFixed(2)
      }
      return unitAmount
    }

    const handleNav = (priceID) => {

      if (priceID === personalizedExpertHelpPriceID) {
        return `${FRONTEND_URL}${pageRoutes.personalizedExpertHelp}`
      } else if ([specializedRankingsPriceID, rdSpendPriceID, safetyPriceID].includes(priceID)) {

        switch (true) {
          case priceID == specializedRankingsPriceID:
            return `${FRONTEND_URL}${pageRoutes.collegeDetailWithId('massachusetts-institute-of-technology')}`
          case priceID == rdSpendPriceID:
            return `${FRONTEND_URL}${pageRoutes.collegeDetailWithId('massachusetts-institute-of-technology')}`
          case priceID == safetyPriceID:
            return `${FRONTEND_URL}${pageRoutes.collegeDetailWithId('massachusetts-institute-of-technology')}`
        }

      } else {

        try {
          let ecObj = ecPremiumPriceIDs.find(item => item.priceID == priceID)
          let advisorObj = advisorPriceIDs.find(item => item.priceID == priceID)

          if (ecObj) {
            let matchingEC = extracurricularMap.find(ecMapObj => parseInt(ecMapObj.id) == ecObj?.activityID)
            return (`${FRONTEND_URL}${pageRoutes.ecPremiumInsightsWithId(matchingEC?.hyphenated)}`)
          }

          if (advisorObj) {
            return (`${FRONTEND_URL}${pageRoutes.advisorDetailWithId(advisorObj.advisorID)}`)
          }

        } catch (error) {
          console.log('Error converting EC productID to a valid product', error)
        }

      }

    }

    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();

    let purchaseDate = `${month}/${day}/${year}`;
    let purchaseItem = returnItemFromProductID(productID);
    let purchaseTotal = await getStripePrice(productID);
    let purchaseURL = handleNav(productID)
    let advisorText = ""

    if (advisorPriceIDs.some(advisorObj => advisorObj.priceID == productID)) {
      advisorText = "The next step is you will receive an email from NextFour with details for scheduling your meeting. If you have any questions, please contact us at advisors@nextfour.ai."
    }

    let postData = {
      userEmail: userEmail,
      productID: productID,
      purchaseItem: purchaseItem,
      purchaseDate: purchaseDate,
      purchaseTotal: `$${purchaseTotal}`,
      purchaseURL: purchaseURL,
      advisorText: advisorText,
      personalizedExpertHelpPurchase: personalizedExpertHelpPurchase
    }

    await axios.post(`${URL}/user_transactions/create/`, postData)

    // Then, if advisor consult was purchased, send email alert using sendAdvisorConsultationPurchaseAlert
    if (postData.advisorText != "") {

      let advisorObj = advisorPriceIDs.find(item => item.priceID == productID)
      let advisorNamePreview = advisorObj?.description.split(' ').slice(-1)[0] || ''

      if (advisorObj) {
        await sendAdvisorConsultationPurchaseAlert(advisorNamePreview, advisorObj?.advisorID, `$${purchaseTotal}`, `Advisor Consultation: ${advisorObj?.description}`, userEmail)
      }

    }

  }

  try {

    const { data: user } = await getUserByToken(apiToken)

    let userEmail = user.email

    await postRequest(userEmail, productID);

  } catch (error) {

    console.log(error);

    throw (error)
  }

}