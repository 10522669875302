import { useState, useEffect } from 'react'
import Select from 'react-select'
import { useLocation } from 'react-router';

const MajorCareerSearch = ({ careerOptions, setCareerSelect, resetStateToggle }) => {

  const location = useLocation()
  const [selectedCareers, setSelectedCareers] = useState<any>([]);

  const handleCareerSelectChange = (selectedCareers) => {
    setSelectedCareers(selectedCareers);
    // console.log('show selected careers:', selectedCareers)
    let careerArr: any = []
    selectedCareers.map((career) =>
      careerArr.push(career.value))
    setCareerSelect(careerArr)
  }

  useEffect(() => {
    setSelectedCareers([])
  }, [resetStateToggle])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let careersParam = params.get('careers');
    let res: any[] = []

    if (careersParam && careerOptions) {
      const careers = careersParam.split(',')
      careers.map((val) => {
        if (careerOptions.find((obj => obj.value === parseInt(val)))) {
          res.push(careerOptions.find((obj => obj.value === parseInt(val))))
        }
      })

      handleCareerSelectChange(res)
    }

    else {
      setSelectedCareers([])
    }


  }, [location.search, careerOptions]);

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>RELATED CAREERS</div>
      {/* <button onClick={() => console.log(selectedCareers)}>Show state of selectedCareers</button> */}
      <Select
        options={careerOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 100
          })
        }}
        isMulti
        value={selectedCareers}
        onChange={handleCareerSelectChange}
      />
    </div>
  )
}

export { MajorCareerSearch }