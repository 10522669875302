import React from "react";
import { pageRoutes } from "../dictionary";

export { };

export const clearModal = (setPrompt, setNavigateTo) => {
  setPrompt('');
  setNavigateTo('');
}

export const setTestScoreStrategyModal = (apiToken, navigate, setPrompt, setNavigateTo) => {

  if (apiToken != undefined) {
    setPrompt('');
    setNavigateTo('');
    navigate(pageRoutes.testScoreStrategy)
  }

  else {

    //Test Score Strategy sign in / sign up modal prompt
    setPrompt('Log-in required to access personalized recommendations for test scores. Please sign-in to your account (or sign-up for a free account) below:')

    setNavigateTo(pageRoutes.testScoreStrategy)

  }
}

export const setCollegeDetailTabModal = (setPrompt, setNavigateTo, tabToNavigateTo) => {

  setPrompt('Access to this premium content is reserved for qualifying user accounts. To proceed, please first sign-in to your account (or sign-up for an account) below:');
  setNavigateTo(tabToNavigateTo)

}

export const setPurchaseModal = (setPurchaseID, purchaseID) => {

  setPurchaseID(purchaseID)

}

export const setNoAuthSaveButton = (setPrompt, setNavigateTo) => {

  setPrompt('Log-in required to save items to your personalized interest list. Please sign-in to your account (or sign-up for a free account) below:');
  setNavigateTo('')

}

export const setNoAuthAdvisorsConnectButton = (setPrompt, setNavigateTo) => {

  setPrompt('Log-in required to connect with advisors. Please sign-in to your account (or sign-up for a free account) below:');
  setNavigateTo('')

}

export const setNoAuthPersonalizedExpertHelp = (setPrompt, setNavigateTo) => {

  setPrompt('Log-in required to purchase personalized expert help. Please sign-in to your account (or sign-up for a free account) below:');
  setNavigateTo('')
}

