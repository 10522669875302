import { useRef, useEffect } from "react";
import noUiSlider from 'nouislider'
import { useLocation } from "react-router";

const MedianACT = ({ actMin, actMax, setACTMin, setACTMax, resetStateToggle }) => {

  const location = useLocation()
  const actSliderRef: any = useRef(null);
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (actSliderRef.current && actSliderRef.current.noUiSlider) {
      actSliderRef.current.noUiSlider.set([actMin, actMax]);
    }
  }, [actMin, actMax]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setACTMin(0);
    setACTMax(36);

    if (actSliderRef.current && actSliderRef.current.noUiSlider) {
      actSliderRef.current.noUiSlider.set([0, 36]);
    }
  }, [resetStateToggle]);

  useEffect(() => {

    const slider: any = document.querySelector("#kt_slider_act");

    if (slider && !slider.noUiSlider) {

      noUiSlider.create(slider, {
        start: [0, 36],
        // connect: true,
        range: {
          "min": 0,
          "max": 36
        },
        step: 1,
        tooltips: [
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
        ]
      });

      slider.noUiSlider.on("set", function (values, handle) {
        if (handle === 0) {
          setACTMin(parseInt(values[0]))
        }
        if (handle === 1) {
          setACTMax(parseInt(values[1]))
        }

      });

      actSliderRef.current = slider;

    }

    return () => {
      if (slider && slider.noUiSlider) {
        slider.noUiSlider.destroy();
      }
    };

  }, [])

  const medianACTSelect = (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>MEDIAN ACT SCORE</div>
      <div className="mb-0" style={{ marginTop: '50px', zIndex: '1' }}>
        <div id="kt_slider_act"></div>
      </div>
    </div>
  )

  return medianACTSelect
}

export { MedianACT }

const MedianACTMobile = ({ actMin, actMax, setACTMin, setACTMax, resetStateToggle }) => {

  const location = useLocation()
  const actSliderRef: any = useRef(null);
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (actSliderRef.current && actSliderRef.current.noUiSlider) {
      actSliderRef.current.noUiSlider.set([actMin, actMax]);
    }
  }, [actMin, actMax]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setACTMin(0);
    setACTMax(36);

    if (actSliderRef.current && actSliderRef.current.noUiSlider) {
      actSliderRef.current.noUiSlider.set([0, 36]);
    }
  }, [resetStateToggle]);

  useEffect(() => {

    const slider: any = document.querySelector("#kt_slider_act_mobile");

    if (slider && !slider.noUiSlider) {

      noUiSlider.create(slider, {
        start: [0, 36],
        // connect: true,
        range: {
          "min": 0,
          "max": 36
        },
        step: 1,
        tooltips: [
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
        ]
      });

      slider.noUiSlider.on("set", function (values, handle) {
        if (handle === 0) {
          setACTMin(parseInt(values[0]))
        }
        if (handle === 1) {
          setACTMax(parseInt(values[1]))
        }

      });

      actSliderRef.current = slider;

    }

    return () => {
      if (slider && slider.noUiSlider) {
        slider.noUiSlider.destroy();
      }
    };

  }, [])

  const medianACTSelect = (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>MEDIAN ACT SCORE</div>
      <div className="mb-0 d-flex justify-content-center" style={{ marginTop: '50px', zIndex: '1' }}>
        <div id="kt_slider_act_mobile"></div>
      </div>
    </div>
  )

  return medianACTSelect
}

export { MedianACTMobile }