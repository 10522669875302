import { useState, useEffect, useRef } from 'react'
import { EditScoreProfile } from './EditScoreProfile'
import noUiSlider from 'nouislider'
import Select from 'react-select'
import { type } from 'os'
import { TooltipComponent } from '../Tooltip'
import { MedianACT, MedianACTMobile } from './AsideSliders/MedianACT'
import { MedianSAT, MedianSATMobile } from './AsideSliders/MedianSAT'
import filterIcon from '../../../custom_assets/filter_icon.png'
import { OnlyInterests } from '../CollegeSearch/components/OnlyInterests'

const Aside = ({ results, collegeOptions, collegeSetOptions, setCollegeSelect, setCollegeSetSelect, requiredSelect, setRequiredSelect, optionalSelect, setOptionalSelect, blindSelect, setBlindSelect, satMin, satMax, setSATMin, setSATMax, actMin, actMax, setACTMin, setACTMax, userSATVerbal, userSATMath, userSATSuperScore, userActEnglish, userActMath, userActWriting, userActScience, userActReading, userACTBestScore, setUserSATVerbal, setUserSATMath, setUserACTBestScore, setUserActEnglish, setUserActMath, setUserActWriting, setUserActScience, setUserActReading, setUserprofile, setUserSATSuperScore, onlyInterestsSelect, setOnlyInterestsSelect, requiredCount, optionalCount, blindCount, filtersActive, user, setFormValues, mobile = false }) => {

  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)
  const [resetStateToggle, setResetStateToggle] = useState(false)

  const toggleFilterOpen = () => {
    setMobileFilterOpen(!mobileFilterOpen)
  }

  //////// College ////////
  const [selectedColleges, setSelectedColleges] = useState<any>([])

  const handleCollegeSelectChange = (selectedColleges) => {
    setSelectedColleges(selectedColleges);
    let collegeArr: any = []
    selectedColleges.map((college) =>
      collegeArr.push(college.value))
    setCollegeSelect(collegeArr)
  }

  const collegeSelect = (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>COLLEGE</div>
      <Select
        options={collegeOptions}
        isMulti
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 100
          })
        }}
        value={selectedColleges}
        onChange={handleCollegeSelectChange}
      // onInputChange={(inputValue) => {
      //   handleInputNameChange(inputValue)
      // }}
      />
    </div>
  )

  //////// College Set ////////

  const [selectedSet, setSelectedSet] = useState<any>('')

  const handleCollegeSetSelectChange = (event) => {
    const selectedSet = event.target.value
    setSelectedSet(selectedSet)
    setCollegeSetSelect(selectedSet)
  }

  const collegeSetSelect = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>COLLEGE SET</div>
      <select
        className='form-select form-select-solid'
        data-control="select2"
        value={selectedSet}
        onChange={handleCollegeSetSelectChange}
        style={{ cursor: 'pointer' }}
      >
        <option value={''}>All</option>
        {collegeSetOptions.map((res) =>
          <option
            key={res.value}
            value={res.value}>{res.label}</option>
        )}
      </select>
    </div>
  )

  //////// Test Policy ////////
  // let requiredCount: any = '-'
  // let optionalCount: any = '-'
  // let blindCount: any = '-'

  // if (results.length > 0) {
  //   requiredCount = results.filter((item) => item.testPolicy === 1).length
  //   optionalCount = results.filter((item) => item.testPolicy === 2).length
  //   blindCount = results.filter((item) => item.testPolicy === 3).length
  // }

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  const testPolicySelect = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>TEST POLICY <TooltipComponent content={"Test policy reflects each college's approach to testing for the 2024-2025 application cycle (i.e. students starting in Fall of 2025.) Policies may change so we recommend confirming via the college's admissions website when applying."} /></div>
      <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

        <label className="d-flex justify-content-between" style={{ cursor: 'pointer' }}>
          <div>
            <input
              type="checkbox"
              className='dca-checkbox'
              name="Required"
              value={requiredSelect}
              checked={requiredSelect}
              onChange={(event) => (setRequiredSelect(handleCheckChange(event)))}
              style={{ cursor: 'pointer' }}
            />
            <span className='m-3'>Required</span>
          </div>
          <span className='text-muted fs-7 fw-bolder'>{requiredCount}</span>
        </label>

        <label className="d-flex justify-content-between" style={{ cursor: 'pointer', marginTop: '5px' }}>
          <div>
            <input
              type="checkbox"
              className='dca-checkbox'
              name="Optional"
              value={optionalSelect}
              checked={optionalSelect}
              onChange={(event) => (setOptionalSelect(handleCheckChange(event)))}
              style={{ cursor: 'pointer' }}
            />
            <span className='m-3'>Optional</span>
          </div>
          <span className='text-muted fs-7 fw-bolder'>{optionalCount}</span>
        </label>

        <label className="d-flex justify-content-between" style={{ cursor: 'pointer', marginTop: '5px' }}>
          <div>
            <input
              type="checkbox"
              className='dca-checkbox'
              name="Blind"
              value={blindSelect}
              checked={blindSelect}
              onChange={(event) => (setBlindSelect(handleCheckChange(event)))}
              style={{ cursor: 'pointer' }}
            />
            <span className='m-3'>Blind</span>
          </div>
          <span className='text-muted fs-7 fw-bolder'>{blindCount}</span>
        </label>

      </div>
    </div>
  )

  // //////// Median SAT ////////

  // const satSliderRef = useRef(null);

  // useEffect(() => {

  //   if (!satSliderRef.current) {

  //     const slider: any = document.querySelector("#kt_slider_sat");
  //     noUiSlider.create(slider, {
  //       start: [0, 1600],
  //       // connect: true,
  //       range: {
  //         "min": 0,
  //         "max": 1600
  //       },
  //       step: 10,
  //       tooltips: [
  //         {
  //           to: function (value) {
  //             return value.toFixed(0)
  //           }
  //         },
  //         {
  //           to: function (value) {
  //             return value.toFixed(0)
  //           }
  //         },
  //       ]
  //     });

  //     slider.noUiSlider.on("set", function (values, handle) {
  //       if (handle === 0) {
  //         setSATMin(parseInt(values[0]))
  //       }
  //       if (handle === 1) {
  //         setSATMax(parseInt(values[1]))
  //       }

  //     });

  //     satSliderRef.current = slider;

  //   }


  // }, [])

  // const medianSATSelect = (
  //   <div className='mt-4 d-flex flex-column'>
  //     <div className='dca-aside-header'>MEDIAN SAT SCORE</div>
  //     <div className="mb-0" style={{ marginTop: '50px', zIndex: '1' }}>
  //       <div id="kt_slider_sat"></div>
  //     </div>
  //   </div>
  // )

  // //////// Median ACT ////////

  // const actSliderRef = useRef(null);

  // useEffect(() => {

  //   if (!actSliderRef.current) {

  //     const slider: any = document.querySelector("#kt_slider_act");
  //     noUiSlider.create(slider, {
  //       start: [0, 36],
  //       // connect: true,
  //       range: {
  //         "min": 0,
  //         "max": 36
  //       },
  //       step: 1,
  //       tooltips: [
  //         {
  //           to: function (value) {
  //             return value.toFixed(0)
  //           }
  //         },
  //         {
  //           to: function (value) {
  //             return value.toFixed(0)
  //           }
  //         },
  //       ]
  //     });

  //     slider.noUiSlider.on("set", function (values, handle) {
  //       if (handle === 0) {
  //         setACTMin(parseInt(values[0]))
  //       }
  //       if (handle === 1) {
  //         setACTMax(parseInt(values[1]))
  //       }

  //     });

  //     actSliderRef.current = slider;

  //   }


  // }, [])

  // const medianACTSelect = (
  //   <div className='mt-4 d-flex flex-column'>
  //     <div className='dca-aside-header'>MEDIAN ACT SCORE</div>
  //     <div className="mb-0" style={{ marginTop: '50px', zIndex: '1' }}>
  //       <div id="kt_slider_act"></div>
  //     </div>
  //   </div>
  // )

  //////// Show My Interests ////////
  const showMyInterests = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

        <label className="d-flex justify-content-between" style={{ cursor: 'pointer' }}>
          <div>
            <input
              type="checkbox"
              className='dca-checkbox'
              name="Show My Interests"
              onChange={(event) => (setOnlyInterestsSelect(handleCheckChange(event)))}
              style={{ cursor: 'pointer' }}
              value={onlyInterestsSelect}
              checked={onlyInterestsSelect}
            />
            <span className='ms-3 dca-test-score-show-colleges-of-interest' style={{ marginLeft: '0.1rem' }}>Show My Colleges Of Interest</span>
          </div>
        </label>

      </div>
    </div>
  )

  const resetFilterStates = () => {

    setSelectedColleges([]);
    setCollegeSelect([]);

    setSelectedSet('');
    setCollegeSetSelect('');

    setRequiredSelect(false);
    setOptionalSelect(false);
    setBlindSelect(false);
    setOnlyInterestsSelect(false);

    // sat and act handled in the slider components themselves
    setResetStateToggle(!resetStateToggle)
  }

  const desktopReturn = (
    <div style={{ width: '260px', maxWidth: '260px', whiteSpace: 'nowrap' }}>
      {collegeSelect}
      {collegeSetSelect}
      {testPolicySelect}
      {/* {medianSATSelect}
      {medianACTSelect} */}
      <MedianSAT
        satMin={satMin}
        satMax={satMax}
        setSATMin={setSATMin}
        setSATMax={setSATMax}
        resetStateToggle={resetStateToggle}
      />
      <MedianACT
        actMin={actMin}
        actMax={actMax}
        setACTMin={setACTMin}
        setACTMax={setACTMax}
        resetStateToggle={resetStateToggle}
      />
      {/* <EditScoreProfile
        userSATVerbal={userSATVerbal}
        userSATMath={userSATMath}
        userSATSuperScore={userSATSuperScore}
        userActEnglish={userActEnglish}
        userActMath={userActMath}
        userActWriting={userActWriting}
        userActScience={userActScience}
        userActReading={userActReading}
        userACTBestScore={userACTBestScore}
        setUserSATVerbal={setUserSATVerbal}
        setUserSATMath={setUserSATMath}
        setUserACTBestScore={setUserACTBestScore}
        setUserActEnglish={setUserActEnglish}
        setUserActMath={setUserActMath}
        setUserActWriting={setUserActWriting}
        setUserActScience={setUserActScience}
        setUserActReading={setUserActReading}
        setUserprofile={setUserprofile}
        setUserSATSuperScore={setUserSATSuperScore}
      /> */}

      <EditScoreProfile
        user={user}
        setFormValues={setFormValues}
      />
      {showMyInterests}
    </div>
  )

  const mobileReturn = (
    <div className='dca-college-search-filter-drawer-container'>

      <button
        onClick={toggleFilterOpen}
        className={filtersActive > 0 ? 'dca-college-search-filters-button-bolded' : 'dca-college-search-filters-button'}><img src={filterIcon} alt="bars-filter" className="dca-college-filters-button-icon" />&nbsp;&nbsp;Filters {filtersActive > 0 && <span className='dca-filters-active-icon'>{filtersActive}</span>}</button>

      <div className={`dca-college-search-filter-drawer${mobileFilterOpen ? '-open' : ''}`} style={{ display: mobileFilterOpen ? 'flex' : 'none' }}>

        <div className='dca-college-search-mobile-filters-wrapper'>
          <div className='dca-college-search-filter-drawer-header'>
            <div className='dca-college-search-mobile-filters-title'>Filters</div>

            <div
              className="dca-college-search-filter-drawer-close-button"
              onClick={toggleFilterOpen}>
              &times;
            </div>
          </div>
          <div style={{ padding: '0px 15px 25px 15px', overflowY: 'auto' }}>

            {collegeSelect}
            {collegeSetSelect}
            {testPolicySelect}
            <MedianSATMobile
              satMin={satMin}
              satMax={satMax}
              setSATMin={setSATMin}
              setSATMax={setSATMax}
              resetStateToggle={resetStateToggle}
            />
            <MedianACTMobile
              actMin={actMin}
              actMax={actMax}
              setACTMin={setACTMin}
              setACTMax={setACTMax}
              resetStateToggle={resetStateToggle}
            />

            {/* <EditScoreProfile
              userSATVerbal={userSATVerbal}
              userSATMath={userSATMath}
              userSATSuperScore={userSATSuperScore}
              userActEnglish={userActEnglish}
              userActMath={userActMath}
              userActWriting={userActWriting}
              userActScience={userActScience}
              userActReading={userActReading}
              userACTBestScore={userACTBestScore}
              setUserSATVerbal={setUserSATVerbal}
              setUserSATMath={setUserSATMath}
              setUserACTBestScore={setUserACTBestScore}
              setUserActEnglish={setUserActEnglish}
              setUserActMath={setUserActMath}
              setUserActWriting={setUserActWriting}
              setUserActScience={setUserActScience}
              setUserActReading={setUserActReading}
              setUserprofile={setUserprofile}
              setUserSATSuperScore={setUserSATSuperScore}
              mobile={true}
            /> */}
            <EditScoreProfile
              user={user}
              setFormValues={setFormValues}
              mobile={true}
            />

            {showMyInterests}
          </div>
        </div>


        <div className='dca-college-search-mobile-filters-drawer-footer'>
          <span className='dca-college-search-mobile-filters-drawer-footer-clear'
            onClick={() => {
              resetFilterStates()
            }}>Clear all</span>

          <button onClick={toggleFilterOpen} className='dca-college-search-mobile-filters-drawer-footer-view-results'>View Results</button>
        </div>
      </div>


    </div>
  )

  return mobile ? mobileReturn : desktopReturn
}

export { Aside }