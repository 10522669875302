import Chart from 'react-apexcharts'
import { BarChart } from './Charts/BarChart'
import { getAuth } from '../../../modules/auth'
import { AuthReactGAHandlers } from '../../ReactGAHandlers'
import { useNavigate } from 'react-router'
import { pageRoutes } from '../../dictionary'
import { setTestScoreStrategyModal } from '../../Auth/setPrompts'

const ACTScoreData = ({ cds, collegeName, setPrompt, setNavigateTo, mobile = false }) => {

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()
  const navigate = useNavigate()

  let latestYear = cds.length - 1
  let actCompositeScore = (<div></div>)
  let actCompositeScoreDistribution = (<div></div>)
  let actEnglishScoreDistribution = (<div></div>)
  let actMathScoreDistribution = (<div></div>)
  let actReadingScoreDistribution = (<div></div>)
  let actScienceScoreDistribution = (<div></div>)

  //ACT Composite Score
  let actCompositeXAxis: any[] = []
  let actComposite25: any[] = []
  let actComposite50: any[] = []
  let actComposite75: any[] = []

  //ACT Composite Score Distribution
  const actScoreXAxis = ['30-36', '24-29', '18-23', '12-17', '6-11', 'Below 6']
  let [actComposite3036, actComposite2429, actComposite1823, actComposite1217, actComposite611, actCompositeBelow6]: any = [null, null, null, null, null, null]

  let actCompositeScoreDistributionArray = [actComposite3036, actComposite2429, actComposite1823, actComposite1217, actComposite611, actCompositeBelow6]

  //ACT English Score Distribution
  let [actEnglish3036, actEnglish2429, actEnglish1823, actEnglish1217, actEnglish611, actEnglishBelow6
  ]: any = [null, null, null, null, null, null]
  let actEnglishScoreDistributionArray = [actEnglish3036, actEnglish2429, actEnglish1823, actEnglish1217, actEnglish611, actEnglishBelow6
  ]

  //ACT Math Score Distribution
  let [actMath3036, actMath2429, actMath1823, actMath1217, actMath611, actMathBelow6
  ]: any = [null, null, null, null, null, null]
  let actMathScoreDistributionArray = [actMath3036, actMath2429, actMath1823, actMath1217, actMath611, actMathBelow6
  ]

  //ACT Reading Score Distribution
  let [actReading3036, actReading2429, actReading1823, actReading1217, actReading611, actReadingBelow6
  ]: any = [null, null, null, null, null, null]
  let actReadingScoreDistributionArray = [actReading3036, actReading2429, actReading1823, actReading1217, actReading611, actReadingBelow6]

  //ACT Science Score Distribution
  let [actScience3036, actScience2429, actScience1823, actScience1217, actScience611, actScienceBelow6
  ]: any = [null, null, null, null, null, null]
  let actScienceScoreDistributionArray = [actScience3036, actScience2429, actScience1823, actScience1217, actScience611, actScienceBelow6]

  const returnACTScores = (fieldVal) => {
    if (fieldVal != undefined && fieldVal != null) {
      return (fieldVal * 100).toFixed(1)
    }

    else {
      return null
    }
  }

  if (cds != null && cds[latestYear] != null) {

    // ACT Composite Score
    cds.forEach((res) => {
      res.year ? actCompositeXAxis.push(`${res.year} - ${res.year + 1}`) : actCompositeXAxis.push(null);

      res.actComposite25 ? actComposite25.push(res.actComposite25) : actComposite25.push(null);

      res.actComposite50 ? actComposite50.push(res.actComposite50) : actComposite50.push(null);

      res.actComposite75 ? actComposite75.push(res.actComposite75) : actComposite75.push(null)
    })

    // ACT Composite Score Distribution
    actComposite3036 = returnACTScores(cds[latestYear].actComposite3036)
    actComposite2429 = returnACTScores(cds[latestYear].actComposite2429)
    actComposite1823 = returnACTScores(cds[latestYear].actComposite1823)
    actComposite1217 = returnACTScores(cds[latestYear].actComposite1217)
    actComposite611 = returnACTScores(cds[latestYear].actComposite611)
    actCompositeBelow6 = returnACTScores(cds[latestYear].actCompositeBelow6)
    actCompositeScoreDistributionArray = [actComposite3036, actComposite2429, actComposite1823, actComposite1217, actComposite611, actCompositeBelow6]

    // ACT English Score Distribution
    actEnglish3036 = returnACTScores(cds[latestYear].actEnglish3036)
    actEnglish2429 = returnACTScores(cds[latestYear].actEnglish2429)
    actEnglish1823 = returnACTScores(cds[latestYear].actEnglish1823)
    actEnglish1217 = returnACTScores(cds[latestYear].actEnglish1217)
    actEnglish611 = returnACTScores(cds[latestYear].actEnglish611)
    actEnglishBelow6 = returnACTScores(cds[latestYear].actEnglishBelow6)
    actEnglishScoreDistributionArray = [actEnglish3036, actEnglish2429, actEnglish1823, actEnglish1217, actEnglish611, actEnglishBelow6
    ]

    // ACT Math Score Distribution
    actMath3036 = returnACTScores(cds[latestYear].actMath3036)
    actMath2429 = returnACTScores(cds[latestYear].actMath2429)
    actMath1823 = returnACTScores(cds[latestYear].actMath1823)
    actMath1217 = returnACTScores(cds[latestYear].actMath1217)
    actMath611 = returnACTScores(cds[latestYear].actMath611)
    actMathBelow6 = returnACTScores(cds[latestYear].actMathBelow6)
    actMathScoreDistributionArray = [actMath3036, actMath2429, actMath1823, actMath1217, actMath611, actMathBelow6
    ]

    // ACT Reading Score Distribution
    actReading3036 = returnACTScores(cds[latestYear].actReading3036)
    actReading2429 = returnACTScores(cds[latestYear].actReading2429)
    actReading1823 = returnACTScores(cds[latestYear].actReading1823)
    actReading1217 = returnACTScores(cds[latestYear].actReading1217)
    actReading611 = returnACTScores(cds[latestYear].actReading611)
    actReadingBelow6 = returnACTScores(cds[latestYear].actReadingBelow6)
    actReadingScoreDistributionArray = [actReading3036, actReading2429, actReading1823, actReading1217, actReading611, actReadingBelow6
    ]

    // ACT Science Score Distribution
    actScience3036 = returnACTScores(cds[latestYear].actScience3036)
    actScience2429 = returnACTScores(cds[latestYear].actScience2429)
    actScience1823 = returnACTScores(cds[latestYear].actScience1823)
    actScience1217 = returnACTScores(cds[latestYear].actScience1217)
    actScience611 = returnACTScores(cds[latestYear].actScience611)
    actScienceBelow6 = returnACTScores(cds[latestYear].actScienceBelow6)
    actScienceScoreDistributionArray = [actScience3036, actScience2429, actScience1823, actScience1217, actScience611, actScienceBelow6
    ]

  }

  actCompositeScore = (
    <div>
      <div className='fw-bold ms-5'>ACT Composite Score</div>
      <Chart
        series={[
          {
            name: '75th Percentile',
            type: 'line',
            data: actComposite75
          },
          {
            name: '50th Percentile',
            type: 'line',
            data: actComposite50
          },
          {
            name: '25th Percentile',
            type: 'line',
            data: actComposite25
          }
        ]}
        options={{
          chart: {
            id: "ACT Composite Score Distribution",
            type: 'line',
            stacked: false,
			  toolbar: {
				show: false,
				},
            },
          xaxis: {
            categories: actCompositeXAxis,
            labels: {
              show: true,
              style: {
                colors: '#9AA2B6',
              },
            }
          },
          tooltip: {
            enabled: false,
          },
          yaxis: {
            seriesName: '75th Percentile',
            labels: {
              style: {
                colors: ['#9AA2B6'],
              },
              formatter: function (val) {
                return val.toFixed(0);
              }
              // formatter: function (val, opts) {
              //   return val.toLocaleString("en-US");
              // },
            },
            stepSize: 1,
            min: (Math.min(...actComposite25) - 2),
            max: (Math.max(...actComposite75) + 2) < 36 ? (Math.max(...actComposite75) + 2) : 36
          },
          colors: ["#90F892", "#FFEF68", "#97E6FF"],
          dataLabels: {
            enabled: true,
            background: {
              borderWidth: 0,
              borderRadius: 5,
              foreColor: '#000',
            },
            // formatter: function (val, opts) {
            //   return val.toLocaleString("en-US");
            // },
          },
          stroke: {
            curve: 'smooth',
          },
          legend: {
            show: true,
            onItemClick: {
              toggleDataSeries: false
            },
            onItemHover: {
              highlightDataSeries: true
            },
          },
          plotOptions: {
            bar: {
              columnWidth: '40%',
              borderRadius: 0,
              dataLabels: {
                position: 'top',
              }
            }
          }
        }}
        // width={'400'}
        height={'300'}
      />
    </div>
  )

  actCompositeScoreDistribution = (
    <div>
      <div className='fw-bold ms-5'>ACT Composite Score Distribution ({actCompositeXAxis[latestYear]})</div>
      <BarChart
        chartId={"ACT Composite Score Distribution"}
        xaxis={actScoreXAxis}
        seriesName={"Class Rank"}
        seriesData={actCompositeScoreDistributionArray}
      />
    </div>
  )

  actEnglishScoreDistribution = (
    <div>
      <div className='fw-bold ms-5'>ACT English Score Distribution ({actCompositeXAxis[latestYear]})</div>
      <BarChart
        chartId={"ACT English Score Distribution"}
        xaxis={actScoreXAxis}
        seriesName={"ACT English Score"}
        seriesData={actEnglishScoreDistributionArray}
      />
    </div>
  )

  actMathScoreDistribution = (
    <div>
      <div className='fw-bold ms-5'>ACT Math Score Distribution ({actCompositeXAxis[latestYear]})</div>
      <BarChart
        chartId={"ACT Math Score Distribution"}
        xaxis={actScoreXAxis}
        seriesName={"ACT Math Score"}
        seriesData={actMathScoreDistributionArray}
      />
    </div>
  )

  actReadingScoreDistribution = (
    <div>
      <div className='fw-bold ms-5'>ACT Reading Score Distribution ({actCompositeXAxis[latestYear]})</div>
      <BarChart
        chartId={"ACT Reading Score Distribution"}
        xaxis={actScoreXAxis}
        seriesName={"ACT Reading Score"}
        seriesData={actReadingScoreDistributionArray}
      />
    </div>
  )

  actScienceScoreDistribution = (
    <div>
      <div className='fw-bold ms-5'>ACT Science Score Distribution ({actCompositeXAxis[latestYear]})</div>
      <BarChart
        chartId={"ACT Science Score Distribution"}
        xaxis={actScoreXAxis}
        seriesName={"ACT Science Score"}
        seriesData={actScienceScoreDistributionArray}
      />
    </div>
  )

  const returnFootnote = (collegeName, apiToken) => {

    let testScoreGuidance = (
      !apiToken ? <a
        data-bs-toggle={apiToken != undefined ? "" : "modal"}
        href={apiToken != undefined ? "" : "#login_modal"}
        onClick={() => {
          setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
          apiToken == undefined && handleGAEventSignInModalOpen('forced')
        }}
        className='link-primary'
        style={{ cursor: 'pointer', color: 'rgb(7,20,55)' }}
      >Test Score Guidance</a> :
        <span
          onClick={() => navigate(pageRoutes.testScoreStrategy)}
          className='link-primary'
          style={{ cursor: 'pointer' }}
        >Test Score Guidance</span>
    )

    return (
      <div style={{ padding: '30px 60px 30px 60px' }}>
        Additional testing-related information {collegeName && `for ${collegeName}`} is available in the {testScoreGuidance} feature.
      </div>
    )
  }


  return cds.length > 0 ? (
    <div
      className='d-flex flex-column justify-content-between card shadow'
      style={{ marginTop: '40px' }}>
      <div
        className={`d-flex flex-${mobile ? 'column' : 'row'} justify-content-between`}
        style={{ padding: mobile ? '30px' : '30px 60px 30px 60px' }}>

        <div style={{ width: mobile ? '100%' : '50%', marginRight: '30px' }}>{actCompositeScore}</div>

        <div style={{ width: mobile ? '100%' : '50%', marginRight: mobile ? '30px' : '', marginTop: mobile ? '30px' : '' }}>{actCompositeScoreDistribution}</div>
      </div>

      <div
        className={`d-flex flex-${mobile ? 'column' : 'row'} justify-content-between`}
        style={{ padding: mobile ? '0px 30px 30px 30px' : '30px 60px 30px 60px' }}>

        <div style={{ width: mobile ? '100%' : '50%', marginRight: '30px' }}>{actEnglishScoreDistribution}</div>

        <div style={{ width: mobile ? '100%' : '50%', marginRight: mobile ? '30px' : '', marginTop: mobile ? '30px' : '' }}>{actMathScoreDistribution}</div>
      </div>

      <div
        className={`d-flex flex-${mobile ? 'column' : 'row'} justify-content-between`}
        style={{ padding: mobile ? '0px 30px 30px 30px' : '30px 60px 30px 60px' }}>

        <div style={{ width: mobile ? '100%' : '50%', marginRight: '30px' }}>{actReadingScoreDistribution}</div>

        <div style={{ width: mobile ? '100%' : '50%', marginRight: mobile ? '30px' : '', marginTop: mobile ? '30px' : '' }}>{actScienceScoreDistribution}</div>
      </div>

      {returnFootnote(collegeName, apiToken)}

    </div>
  ) : (<div>Loading ACT Data ... </div>)



}



export { ACTScoreData }
