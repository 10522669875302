import { RankColorRange } from "../../RankColorRange"
import { RankIndicator } from "../../RankIndicator"
import { TooltipComponent, TooltipComponentMobile } from "../../Tooltip"

const IndustryRankings = ({ college, mobile = false }) => {

  let metaRank: any = null
  // let currentYear = new Date().getFullYear()
  let [nationalUniversity, liberalArts, forbes, wsj, niche, arwu, qs, times, waMonthlyNational, waMonthlyLiberal]: any = [0, 0, 0, 0, 0, 0, 0, 0]
  let rankingsArray = [
    { 'US News & World Report National Universities (2024)': nationalUniversity },
    { 'US News & World Report National Liberal Arts Colleges (2024)': liberalArts },
    { 'Forbes Top Colleges (2023)': forbes },
    { 'Wall Street Journal Best Colleges In the U.S. (2024)': wsj },
    { 'Niche Best Colleges In America (2024)': niche },
    { 'Academic Ranking of World Universities (2023)': arwu },
    { 'QS World University Rankings (2024)': qs },
    { 'Times Higher Education World University Rankings (2024)': times },
    { 'Washington Monthly National Universities (2023)': waMonthlyNational },
    { 'Washington Monthly Liberal Arts Colleges (2023)': waMonthlyLiberal }
  ]

  if (college) {
    metaRank = college.metaRank
    nationalUniversity = college.usNewsNationalRank
    liberalArts = college.usNewsLiberalArtsRank
    forbes = college.forbesRank
    wsj = college.wsjRank
    niche = college.nicheRank
    arwu = college.arwuRank
    qs = college.qsRank
    times = college.timesHigherEdRank
    waMonthlyNational = college.waMonthlyNationalRank
    waMonthlyLiberal = college.waMonthlyLiberalArtsRank

    rankingsArray = [
      { 'US News & World Report National Universities (2024)': nationalUniversity },
      { 'US News & World Report National Liberal Arts Colleges (2024)': liberalArts },
      { 'Forbes Top Colleges (2023)': forbes },
      { 'Wall Street Journal Best Colleges In the U.S. (2024)': wsj },
      { 'Niche Best Colleges In America (2024)': niche },
      { 'Academic Ranking of World Universities (2023)': arwu },
      { 'QS World University Rankings (2024)': qs },
      { 'Times Higher Education World University Rankings (2024)': times },
      { 'Washington Monthly National Universities (2023)': waMonthlyNational },
      { 'Washington Monthly Liberal Arts Colleges (2023)': waMonthlyLiberal }
    ]
  }


  return (
    <div className="card shadow" style={{ marginTop: '40px' }}>
      <div className="d-flex flex-row align-items-end" style={{ marginLeft: '30px', marginTop: '30px' }}>

        <div className={`d-flex flex-row me-4 align-items-${mobile ? 'center' : 'end'}`}>
          <h3>Meta Rank:</h3>
          <div className="ms-4"><RankIndicator rank={metaRank} modal={true} /></div>
          <div className="d-flex justify-content-center align-items-center align-self-center ms-2">{mobile ? <TooltipComponentMobile content={`Metarank is our computed overall rank for ${college.name} based on aggregation of the industry ranks outlined below.`} /> : <TooltipComponent content={`Metarank is our computed overall rank for ${college.name} based on aggregation of the industry ranks outlined below.`} />}</div>
        </div>

        {!mobile && <div style={{ marginLeft: '50px' }}><RankColorRange /></div>}
      </div>

      {mobile && <div style={{ marginLeft: '30px', marginTop: '15px' }}><RankColorRange /></div>}

      <ul className='d-flex flex-column justify-content-between' style={{ width: mobile ? '90%' : '50vw', marginTop: '30px', marginLeft: '15px' }}>
        <li className="mb-5 d-flex justify-content-end fw-bold" style={{ fontSize: 'smaller' }}>RANK</li>
        {rankingsArray.map((res, index) => (
          <li key={index} className='d-flex flex-column'>
            <div className='d-flex flex-row justify-content-between fw-bold'>
              <div className="align-self-center">{Object.keys(res)[0]}</div>
              <RankIndicator rank={rankingsArray[index][Object.keys(res)[0]]} modal={true} />
            </div>
            <div className={index > rankingsArray.length - 2 ? 'm-2' : 'm-2 separator'}></div>
          </li>
        ))}
      </ul>

      <div style={{ margin: '40px 0px 15px 30px' }}>Ranks based on industry publication data as of July 2024.</div>
    </div>)

}



export { IndustryRankings }







// const desktopHeader = (
//   <div className="d-flex flex-row me-4 align-items-end">

//     <div className="d-flex flex-row">
//       <h3>Meta Rank:</h3>
//       <div className="ms-4"><RankIndicator rank={metaRank} modal={true} /></div>
//       <div className="d-flex justify-content-center align-items-center align-self-center ms-2"><TooltipComponent content={`Metarank is our computed overall rank for ${college.name} based on aggregation of the industry ranks outlined below.`} /></div>
//     </div>

//     <div style={{ marginLeft: '50px' }}><RankColorRange /></div>
//   </div>)

// const mobileHeader = (
//   <div className="d-flex flex-column justify-content-center align-items-center">
//     <div className="d-flex flex-row align-items-center">
//       <h3 style={{ margin: '0px' }}>Meta Rank:</h3>
//       <div className="ms-4"><RankIndicator rank={metaRank} modal={true} /></div>
//       <div className="d-flex justify-content-center align-items-center align-self-center ms-2"><TooltipComponentMobile content={`Metarank is our computed overall rank for ${college.name} based on aggregation of the industry ranks outlined below.`} /></div>
//     </div>

//     <div style={{ marginLeft: '50px' }}><RankColorRange /></div>
//   </div>
// )


// return (
//   <div className="card shadow" style={{ marginTop: '40px' }}>
//     <div className="d-flex flex-row align-items-end" style={{ marginLeft: '30px', marginTop: '30px' }}>

//       {mobile ? mobileHeader : desktopHeader}

//     </div>

//     <ul className='d-flex flex-column justify-content-between' style={{ width: mobile ? '90%' : '40vw', marginTop: '30px', marginLeft: '15px' }}>
//       <li className="mb-5 d-flex justify-content-end fw-bold" style={{ fontSize: 'smaller' }}>RANK</li>
//       {rankingsArray.map((res, index) => (
//         <li key={index} className='d-flex flex-column'>
//           <div className='d-flex flex-row justify-content-between fw-bold'>
//             <div className="align-self-center">{Object.keys(res)[0]}</div>
//             <RankIndicator rank={rankingsArray[index][Object.keys(res)[0]]} modal={true} />
//           </div>
//           <div className={index > rankingsArray.length - 2 ? 'm-2' : 'm-2 separator'}></div>
//         </li>
//       ))}
//     </ul>

//     <div style={{ margin: '40px 0px 15px 30px' }}>Ranks based on industry publication data as of July 2024.</div>
//   </div>)