import { useState, useEffect } from "react";
import Select from 'react-select'
import { useLocation } from "react-router";
import { TooltipComponent, TooltipComponentMobile } from "../../Tooltip";

const MajorFieldOfStudy = ({ fieldOptions, setFieldSelect, incAdjFieldSelect, setIncAdjFieldSelect, resetStateToggle, mobile = false }) => {

  const location = useLocation()
  const [selectedField, setSelectedField] = useState<any>([]);

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  const handleFieldSelectChange = (selectedField) => {
    setSelectedField(selectedField);

    setFieldSelect(selectedField)
  };

  useEffect(() => {
    setSelectedField([])
  }, [resetStateToggle])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let fieldParam = params.get('fieldOfStudy');
    let incAdjFieldParam = params.get('adjacentFields')
    let res: any[] = []

    if (fieldParam && fieldOptions) {
      const field = fieldParam.split(',')
      field.map((val) => {
        if (fieldOptions.find((obj => obj.value === val))) {
          res.push(fieldOptions.find((obj => obj.value === val)))
        }
      })
      handleFieldSelectChange(res)
    }

    else {
      setSelectedField([])
    }

    // // On link back to search page, only set the checkbox to false again if we have the field of study params the checkbox works with
    // (fieldParam && !incAdjFieldParam) ? setIncAdjFieldSelect(false) : setIncAdjFieldSelect(true)

    if (incAdjFieldParam) {
      incAdjFieldParam == 'false' ? setIncAdjFieldSelect(false) : setIncAdjFieldSelect(true)
    }

  }, [location.search, fieldOptions]);

  return (
    <div className='d-flex flex-column'>
      <div className={`dca-aside-header ${mobile ? 'd-flex flex-row' : ''}`}>FIELD OF STUDY {mobile ? <span className="ms-2"><TooltipComponentMobile content={'Use this filter to explore majors within specific fields of study (based on CIP codes). When the checkbox is enabled, the results will also include majors in related fields of study.'} /></span> : <TooltipComponent content={'Use this filter to explore majors within specific fields of study (based on CIP codes). When the checkbox is enabled, the results will also include majors in related fields of study.'} />}</div>
      <Select
        options={fieldOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 100
          })
        }}
        isMulti
        value={selectedField}
        onChange={handleFieldSelectChange}
      />

      <div className='mt-4 d-flex flex-column'>
        <div className="checkbox-list d-flex flex-column">

          <label className="d-flex justify-content-between" style={{ cursor: 'pointer' }}>
            <div>
              <input
                type="checkbox"
                className='dca-checkbox'
                name="Include Adjacent Fields"
                checked={incAdjFieldSelect}
                onChange={(event) => (setIncAdjFieldSelect(handleCheckChange(event)))}
                style={{ cursor: 'pointer' }}
              />
              <span className='ms-2 me-2'>Also Show Majors In Related Fields</span>
            </div>
          </label>

        </div>
      </div>
    </div>
  )
}

export { MajorFieldOfStudy }