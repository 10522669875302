import { Calendar } from 'primereact/calendar';
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"

import { useState } from "react"
import axios from "axios"
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik, Field, ErrorMessage } from 'formik'

import { handleUpdateUserData, handleUpdateUserProfile } from "../../../pages/UserProfile"

const ProfileInfoForm = ({ user, setUser, apiToken, userData, setUserData }) => {

  const [loading, setLoading] = useState(false)
  const [errorStatus, setErrorStatus] = useState('')

  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[a-zA-Z]+$/, 'Names must contain only alphabetical characters')
      .required(),
    lastName: Yup.string()
      .matches(/^[a-zA-Z]+$/, 'Names must contain only alphabetical characters')
      .required(),
    userType: Yup.number()
      .nullable(),
    zipCode: Yup.string()
      .length(5, 'Zipcode must be exactly 5 digits')
      .matches(/^\d{5}$/, 'Zipcode must be a 5-digit number')
      .nullable(),
    hsGradYr: Yup.number()
      .nullable(),
    collegeStartYr: Yup.number()
      .nullable()
  })

  const profileInfoTitleArray = ['Full Name', 'Student/Parent', 'Zipcode', 'High School Graduation Year', 'College Start Year']

  let initialValues = { firstName: userData.firstName, lastName: userData.lastName, userType: userData.userType, zipCode: userData.zipCode, hsGradYr: userData.hsGradYr, collegeStartYr: userData.collegeStartYr }

  const handleDateReturn = (event) => {

    let date = new Date(event.target.value)
    let year = date.getFullYear()

    return year
  }

  const returnYearFromUserData = (userYear) => {
    if (userYear) {
      return new Date(userYear, 0)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        handleUpdateUserData(user, values, setUserData);
        handleUpdateUserProfile(setUser, apiToken)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setErrorStatus(`Error: ${error}`)
        setStatus('Profile details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    }
  })

  const firstNameField = (
    <div className="dca-userProfile-form-field" style={{ width: '100%' }}>
      <input
        placeholder='First Name'
        {...formik.getFieldProps('firstName')}
        className={clsx(
          'form-control form-control-solid',
          { 'is-invalid': formik.touched.firstName && formik.errors.firstName },
          {
            'is-valid': formik.touched.firstName && !formik.errors.firstName,
          }
        )}
        type='string'
        name='firstName'
        autoComplete='off'
      />
      {formik.touched.firstName && formik.errors.firstName && (
        <div className='fv-plugins-message-container'>
          <span role='alert'>{formik.errors.firstName as string}</span>
        </div>
      )}
    </div>
  )

  const lastNameField = (
    <div className="dca-userProfile-form-field" style={{ width: '100%' }}>
      <input
        placeholder='Last Name'
        {...formik.getFieldProps('lastName')}
        className={clsx(
          'form-control form-control-solid',
          { 'is-invalid': formik.touched.lastName && formik.errors.lastName },
          {
            'is-valid': formik.touched.lastName && !formik.errors.lastName,
          }
        )}
        type='string'
        name='lastName'
        autoComplete='off'
      />
      {formik.touched.lastName && formik.errors.lastName && (
        <div className='fv-plugins-message-container'>
          <span role='alert'>{formik.errors.lastName as string}</span>
        </div>
      )}
    </div>
  )

  const inputStyle = { width: '100%', borderRadius: '5px', border: 'transparent', background: '#F9F9F9' }

  const userTypeField = (
    <div className="dca-userProfile-form-field" style={{ width: '100%' }}>
      <select
        className='form-select'
        data-control="select2"
        {...formik.getFieldProps('userType')}
        name="userType"
        style={inputStyle}
      >
        <option value={undefined} hidden>Select User Type</option>
        <option value={1}>Student</option>
        <option value={2}>Parent</option>
      </select>
    </div>
  )

  const zipCodeField = (
    <div className="dca-userProfile-form-field" style={{ width: '100%' }}>
      <input
        placeholder='Zipcode'
        {...formik.getFieldProps('zipCode')}
        className={clsx(
          'form-control form-control-solid',
          { 'is-invalid': formik.touched.zipCode && formik.errors.zipCode },
          {
            'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
          }
        )}
        type='string'
        name='zipCode'
        autoComplete='off'
      />
      {formik.touched.zipCode && formik.errors.zipCode && (
        <div className='fv-plugins-message-container'>
          <span role='alert'>{formik.errors.zipCode as string}</span>
        </div>
      )}
    </div>
  )

  const hsGradYrField = (
    <div className="dca-userProfile-form-field" style={{ width: '100%' }}>
      <Calendar
        style={inputStyle}
        className='dca-Calendar'
        view="year"
        value={returnYearFromUserData(formik.values.hsGradYr)}
        onChange={(event) => {
          formik.setFieldValue('hsGradYr', handleDateReturn(event))
        }}
        dateFormat="yy" />
    </div>
  )

  const collegeStartYrField = (
    <div className="dca-userProfile-form-field" style={{ width: '100%' }}>

      <Calendar
        style={inputStyle}
        className='dca-Calendar'
        view="year"
        value={returnYearFromUserData(formik.values.collegeStartYr)}
        onChange={(event) => {
          formik.setFieldValue('collegeStartYr', handleDateReturn(event))
        }}
        dateFormat="yy" />
    </div>
  )

  return (
    // <div>
    //   <form
    //     onSubmit={formik.handleSubmit}
    //     noValidate
    //     id="profileInfoForm">

    //     <div className='dca-userProfile-form-field-wrapper'>
    //       <div className='dca-userProfile-form-field-column'>
    //         {profileInfoTitleArray.map((title, index) =>
    //           <div key={index} className='dca-userProfile-form-field'>{title}</div>
    //         )}
    //       </div>

    //       <div className='d-flex flex-column' style={{ width: '100%' }}>
    //         <div className='d-flex flex-row justify-content-evenly'>
    //           {firstNameField}
    //           {lastNameField}
    //         </div>

    //         {userTypeField}
    //         {zipCodeField}
    //         {hsGradYrField}
    //         {collegeStartYrField}
    //       </div>
    //     </div>

    //     <div className='separator'></div>

    //     <div className='d-flex flex-row justify-content-end align-items-end'>
    //       <button
    //         className='dca-userProfile-discard-changes-button text-muted'
    //         onClick={(e) => {
    //           e.preventDefault();
    //           formik.setValues({ firstName: userData.firstName, lastName: userData.lastName, userType: userData.userType, zipCode: userData.zipCode, hsGradYr: userData.hsGradYr, collegeStartYr: userData.collegeStartYr })
    //         }}>Discard</button>

    //       <button
    //         type='submit'
    //         id='profileInfoForm_submit'
    //         className='dca-userProfile-save-changes-button'
    //         disabled={formik.isSubmitting || !formik.isValid || !formik.values}
    //       >
    //         {!loading && <span className='indicator-label'>Save Changes</span>}
    //         {loading && (
    //           <span className='indicator-progress' style={{ display: 'block' }}>
    //             Please wait...{' '}
    //             <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //           </span>
    //         )}
    //       </button>
    //     </div>
    //   </form>
    // </div>

    <div>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        id="profileInfoForm"
      >
        <div className="d-flex flex-column" style={{ width: '100%' }}>
          {profileInfoTitleArray.map((title, index) => (
            <div key={index} className="d-flex flex-row justify-content-between align-items-center">

              <div className="dca-font-M" style={{
                textAlign: 'left', fontSize: '19px', padding: '20px', alignItems: 'center', minHeight: 'fit-content', height: '30%', width: '50%'
              }}>
                {title}
              </div>

              <div className="d-flex flex-row justify-content-end" style={{ width: '60%', minWidth: '60%', maxWidth: '60%' }}>
                {index === 0 && <div className='d-flex flex-row justify-content-between' style={{ width: '100%' }}>
                  {firstNameField}
                  {lastNameField}
                </div>}
                {index === 1 && userTypeField}
                {index === 2 && zipCodeField}
                {index === 3 && hsGradYrField}
                {index === 4 && collegeStartYrField}
              </div>
            </div>
          ))}
        </div>

        <div className="separator"></div>

        <div className="d-flex flex-row justify-content-end align-items-end">
          <button
            className="dca-userProfile-discard-changes-button text-muted"
            onClick={(e) => {
              e.preventDefault();
              formik.setValues({
                firstName: userData.firstName,
                lastName: userData.lastName,
                userType: userData.userType,
                zipCode: userData.zipCode,
                hsGradYr: userData.hsGradYr,
                collegeStartYr: userData.collegeStartYr,
              });
            }}
          >
            Discard
          </button>

          <button
            type="submit"
            id="profileInfoForm_submit"
            className="dca-userProfile-save-changes-button"
            disabled={formik.isSubmitting || !formik.isValid || !formik.values}
          >
            {!loading && <span className="indicator-label">Save Changes</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait...{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>

  )
}

export { ProfileInfoForm }

















{/* <form
        onSubmit={formik.handleSubmit}
        noValidate
        id="testscoresForm">

        <div className='dca-userProfile-form-field-wrapper'>
          <div className='dca-userProfile-form-field-column'>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[0][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[1][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[2][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[3][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[4][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[5][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[6][0]}</div>
            <div className='dca-userProfile-form-field'>{testScoreTitleArray[7][0]}</div>
          </div>

          <div className='d-flex flex-column' style={{ width: '100%' }}>
            {testScoreTitleArray.map((arr, index) =>
              <div key={index} className="dca-userProfile-form-field">
                <input
                  placeholder={arr[0]}
                  {...formik.getFieldProps(arr[1])}
                  className={clsx(
                    'form-control form-control-solid',
                    { 'is-invalid': formik.touched[arr[1]] && formik.errors[arr[1]] },
                    {
                      'is-valid': formik.touched[arr[1]] && !formik.errors[arr[1]],
                    }
                  )}
                  type='number'
                  name={arr[1]}
                  autoComplete='off'
                />
                {formik.touched[arr[1]] && formik.errors[arr[1]] && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors[arr[1]]}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className='separator'></div>

        <div className='d-flex flex-row justify-content-end align-items-end'>
          <button
            className='dca-userProfile-discard-changes-button text-muted'
            onClick={(e) => {
              e.preventDefault();
              formik.setValues({
                satVerbal: userData.satVerbal, satMath: userData.satMath, actComposite: userData.actComposite, actReading: userData.actReading, actMath: userData.actMath, actEnglish: userData.actEnglish, actScience: userData.actScience, actWriting: userData.actWriting
              })
            }}>Discard</button>

          <button
            type='submit'
            id='testscoresForm_submit'
            className='dca-userProfile-save-changes-button'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values}
          >
            {!loading && <span className='indicator-label'>Save Changes</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form> */}