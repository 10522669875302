const FakeModal = () => {

  return (<div className="modal fade" style={{ display: 'none' }} id="fake">
    <div className="modal-dialog modal-dialog modal-dialog-centered">
      {/* <div className="modal-dialog modal-dialog"> */}
      <div className="modal-content">

        <div className="modal-body fw-bold d-flex flex-column">

        </div>

      </div>
    </div>
  </div>)
}

export { FakeModal }