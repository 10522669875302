import { useEffect, useState } from "react"
import { gray } from "../dictionary"
import { EditScoreProfile } from "./EditScoreProfile"

const AggregateRecommendation = ({ userSATScoreOutput, userACTScoreOutput, res, userSATVerbal, userSATMath, userSATSuperScore, userActEnglish, userActMath, userActWriting, userActScience, userActReading, userACTBestScore, setUserSATVerbal, setUserSATMath, setUserACTBestScore, setUserActEnglish, setUserActMath, setUserActWriting, setUserActScience, setUserActReading, setUserprofile, setUserSATSuperScore, user, setFormValues }) => {

  const [result, setResult] = useState<any>(6)

  useEffect(() => {

    const recommendationLogic = (userSATScoreOutput, userACTScoreOutput, res) => {

      if (res.testPolicy == 3) {
        // Do not submit
        return setResult(5)
      }

      // CTA to enter score data
      if (userSATScoreOutput === -1 && userACTScoreOutput === -1) {
        return setResult(7)
      }

      // Insufficient college data
      if (userSATScoreOutput === -2 && userACTScoreOutput === -2) {
        return setResult(6)
      }

      if (userSATScoreOutput === -2 && userACTScoreOutput === -1) {
        return setResult(6)
      }

      if (userSATScoreOutput === -1 && userACTScoreOutput === -2) {
        return setResult(6)
      }

      // Testpolicy 1 vs 2:
      switch (true) {
        case res.testPolicy == 1:
          if (userSATScoreOutput > userACTScoreOutput) {
            //Submit SAT
            return setResult(2)
          }
          else if (userSATScoreOutput < userACTScoreOutput) {
            //Submit ACT
            return setResult(3)
          }
          else {
            //Submit either
            return setResult(1)
          }
        case res.testPolicy == 2:
          if (userSATScoreOutput > userACTScoreOutput) {
            if (userSATScoreOutput >= 60) {
              //Submit SAT
              return setResult(2)
            }
            else if ((40 <= userSATScoreOutput) && (userSATScoreOutput < 60)) {
              //Borderline
              return setResult(4)
            }
            else if (userSATScoreOutput < 40) {
              // Do not submit
              return setResult(5)
            }
          }

          if (userSATScoreOutput < userACTScoreOutput) {
            if (userACTScoreOutput >= 60) {
              // Submit ACT 
              return setResult(3)
            }
            else if ((40 <= userACTScoreOutput) && (userACTScoreOutput < 60)) {
              // Borderline
              return setResult(4)
            }
            else if (userACTScoreOutput < 40) {
              //Do not submit
              return setResult(5)
            }
          }

          if (userSATScoreOutput === userACTScoreOutput) {
            if (userSATScoreOutput >= 60) {
              if (res.college_cds[0].submitSATPercent >= res.college_cds[0].submitACTPercent) {
                // Submit SAT
                return setResult(2)
              }
              else if (res.college_cds[0].submitSATPercent < res.college_cds[0].submitACTPercent) {
                // Submit ACT
                return setResult(3)
              }
            }
            if (40 <= userSATScoreOutput && userSATScoreOutput < 60) {
              //Borderline
              return setResult(4)
            }
            if (userSATScoreOutput < 40) {
              // Do not submit
              return setResult(5)
            }
          }
      }
    }

    recommendationLogic(userSATScoreOutput, userACTScoreOutput, res)

  }, [userSATSuperScore, userACTBestScore, res])

  const submitSATandACT = (
    <div>Submit SAT & ACT Scores</div>
  )
  const submitSAT = (
    <div>Submit SAT Score</div>
  )
  const submitACT = (
    <div>Submit ACT Score</div>
  )
  const borderline = (
    <div>Borderline</div>
  )
  const doNotSubmit = (
    <div>Do not submit</div>
  )
  const insufficient = (
    <div>Insufficient College Data</div>
  )

  const returnAggregate = (result) => {
    switch (true) {
      case result === 5:
        return doNotSubmit
      case result === 1:
        return submitSATandACT
      case result === 2:
        return submitSAT
      case result === 3:
        return submitACT
      case result === 4:
        return borderline
      case result === 6:
        return insufficient
      case result === 7:
        // return <EditScoreProfilePill
        //   userSATVerbal={userSATVerbal}
        //   userSATMath={userSATMath}
        //   userSATSuperScore={userSATSuperScore}
        //   userActEnglish={userActEnglish}
        //   userActMath={userActMath}
        //   userActWriting={userActWriting}
        //   userActScience={userActScience}
        //   userActReading={userActReading}
        //   userACTBestScore={userACTBestScore}
        //   setUserSATVerbal={setUserSATVerbal}
        //   setUserSATMath={setUserSATMath}
        //   setUserACTBestScore={setUserACTBestScore}
        //   setUserActEnglish={setUserActEnglish}
        //   setUserActMath={setUserActMath}
        //   setUserActWriting={setUserActWriting}
        //   setUserActScience={setUserActScience}
        //   setUserActReading={setUserActReading}
        //   setUserprofile={setUserprofile}
        //   setUserSATSuperScore={setUserSATSuperScore}
        // />

        return <EditScoreProfile
          user={user}
          setFormValues={setFormValues}
          pill={true}
        />
      default:
        return insufficient
    }
  }

  const returnColor = (result) => {
    switch (result) {
      case 1:
        return 'rgba(184, 238, 255, 1)'
      case 2:
        return 'rgba(255, 237, 70, 1)'
      case 3:
        return 'rgba(226, 214, 196, 1)'
      case 4:
        return 'lavender'
      case 5:
        return gray
      case 6:
        return gray
      case 7:
        return gray
      default:
        return gray
    }
  }

  // const showExtraDetails = (
  //   <div>Test Policy: {res.testPolicy} Result: {result} SAT Score Output {userSATScoreOutput} ACT Score Output {userACTScoreOutput}</div>
  // )

  // return (
  //   <div
  //     className='d-flex justify-content-center align-items-center align-self-center fw-bold rounded'
  //     style={{ backgroundColor: returnColor(result), height: '31px', display: 'inline-block', padding: '10px' }}
  //   > {returnAggregate(result)}
  //   </div>
  // )

  return (
    <div
      className={result === 7 ? `` : `dca-test-score-modal-recommendation-pill`}
      style={{
        backgroundColor: result === 7 ? '' : returnColor(result),
      }}
    > {returnAggregate(result)}
    </div>



  )
}

export { AggregateRecommendation }


