import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { TooltipComponent, TooltipComponentMobile } from '../../Tooltip'

const ECImpactSearch = ({ impactSelect, setImpactSelect, mobile = false }) => {

  const location = useLocation()
  const impactOptions = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Good' },
    { value: 2, label: 'Better' },
    { value: 3, label: 'Best' }
  ]

  const handleImpactSelectChange = (event) => {
    let res = event.target.value
    setImpactSelect(parseInt(res))
  }

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const impactParam = params.get('impact');

    if (impactParam) {
      setImpactSelect(parseInt(impactParam));
    }

    else {
      setImpactSelect(0)
    }
  }, [location.search]);

  return (
    <div className='mt-4 d-flex flex-column'>
      {mobile ? <div className='dca-aside-header d-flex flex-row'>IMPACT <span className='ms-2'><TooltipComponentMobile content={`Impact indicates the estimated strength of an activity from the perspective of the college admissions process. This rating is based on factors such as selectivity, qualification criteria, reputation, academic rigor, alumni credentials, etc. but does not consider personalized fit.`} /></span></div> : <div className='dca-aside-header'>IMPACT <TooltipComponent content={`Impact indicates the estimated strength of an activity from the perspective of the college admissions process. This rating is based on factors such as selectivity, qualification criteria, reputation, academic rigor, alumni credentials, etc. but does not consider personalized fit.`} /></div>}
      <select
        className='form-select form-select-solid'
        data-control="select2"
        value={impactSelect}
        onChange={handleImpactSelectChange}
        style={{ cursor: 'pointer' }}
      >
        {impactOptions.map((res, index) => (
          <option
            key={index}
            value={res.value}>
            {res.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export { ECImpactSearch }