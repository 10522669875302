import { useState, useEffect } from 'react';
import { convertDateFormat } from '../../dictionary';
import { Calendar } from 'primereact/calendar';
import { useLocation } from 'react-router';
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"

const ECDatesSearch = ({ dateSelectMin, dateSelectMax, setDateSelectMin, setDateSelectMax, resetStateToggle }) => {

  const location = useLocation()
  const [dateRange, setDateRange] = useState<any>(null);

  const handleDateChange = (dates) => {

    setDateRange(dates)
    setDateSelectMin(null);
    setDateSelectMax(null)

    if (dates != null) {

      try {
        setDateSelectMin(dates[0]);
        setDateSelectMax(dates[1])

        console.log(dates[0]);
        console.log(dates[1])
      } catch (error) {
        console.log('Error:', error)
      }

    }
  }

  useEffect(() => {
    setDateRange(null)
  }, [resetStateToggle])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let datesParam = params.get('activityDates');
    let res: any[] = []

    if (datesParam) {
      let dates = datesParam.split(',').map(res => res);
      dates.map((date: any) => {
        res.push(new Date(date))
      })
      console.log('res after date conversion:', res)

      handleDateChange(res)

    }

  }, [location.search]);


  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>ACTIVITY DATES</div>
      {/* <button onClick={() => {

        // format of startDate field ==> "2024-06-17"
        let firstDate1 = new Date("2024-06-17".replace(/-/g, '\/'))

        let lastDate1 = new Date("2024-06-28".replace(/-/g, '\/'))

        let comparison1 = firstDate1 >= dateSelectMin
        let comparison2 = lastDate1 <= dateSelectMax

        console.log(`${firstDate} >= ${dateSelectMin} ?: ${comparison1}`)

        console.log(`${lastDate} <= ${dateSelectMax} ?: ${comparison2}`)

            {
            "model": "dantech_college_advisor.ECActivitySessionDate",
            "pk": "265",
            "fields": {
                "ec_activity_id": "359",
                "startDate": "2024-06-17",
                "endDate": "2024-06-28"
            }
        },
        {
            "model": "dantech_college_advisor.ECActivitySessionDate",
            "pk": "266",
            "fields": {
                "ec_activity_id": "359",
                "startDate": "2024-07-01",
                "endDate": "2014-07-12"
            }
        },
        {
            "model": "dantech_college_advisor.ECActivitySessionDate",
            "pk": "267",
            "fields": {
                "ec_activity_id": "359",
                "startDate": "2024-07-15",
                "endDate": "2024-07-26"
            }
        },
        {
            "model": "dantech_college_advisor.ECActivitySessionDate",
            "pk": "268",
            "fields": {
                "ec_activity_id": "359",
                "startDate": "2024-07-29",
                "endDate": "2024-08-09"
            }
        },

      }}>Show date conversion</button> */}
      <Calendar
        value={dateRange}
        className='dca-Calendar'
        dateFormat='mm/dd/y'
        onChange={(e) => {
          handleDateChange(e.value)
        }}
        selectionMode="range"
        showButtonBar
      />
    </div>

  );
};

export { ECDatesSearch }
