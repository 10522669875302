import { TooltipComponent, TooltipComponentMobile } from '../../Tooltip'
import { booleanReturn, convertDateFormat } from '../../dictionary'

const ApplicationParameters = ({ ec, mobile = false }) => {

  const returnAcceptanceRate = (admitRate) => {
    if (admitRate) {
      return `${admitRate * 100}%`
    }

    else {
      return '-'
    }
  }

  const returnDueDateTrue = (ec) => {
    const currentDate = new Date();

    // appDueDate is string date format from model
    const isPastDate = (dateString: string | undefined) => {
      if (!dateString) return false;
      const date = new Date(dateString);
      return date < currentDate;
    };

    return isPastDate(ec.appDueDate) || isPastDate(ec.appDueDate2) || isPastDate(ec.appDueDate3);
  };

  const desktopReturn = (
    <div className='d-flex flex-row card shadow fw-bold' style={{ marginTop: '40px', padding: '40px' }}>
      <div className="d-flex flex-column" style={{ width: '45%' }}>

        <div>
          <div>APPLICATION REQUIRED</div>
          <div>{booleanReturn(ec.appRequired)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION AVAILABILITY</div>
          <div>{convertDateFormat(ec.appAvailability)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION DUE DATE(S)&nbsp;&nbsp;{returnDueDateTrue(ec) && <TooltipComponent content={`Updated application due date(s) may not yet be available. However the prior cycle's deadline(s) are displayed to help with planning as deadline date(s) are typically similar from year to year.`} />}</div>
          <div>{convertDateFormat(ec.appDueDate)}{ec.appDueDate2 && `; ${convertDateFormat(ec.appDueDate2)}`}{ec.appDueDate3 && `; ${convertDateFormat(ec.appDueDate3)}`}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION RESULT DATE</div>
          <div>{ec.appResultDate ? ec.appResultDate : '-'}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>ROLLING ADMISSION</div>
          <div>{booleanReturn(ec.rollingAdmission)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION PROCESS</div>
          <div>{ec.appProcess ? ec.appProcess : '-'}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION FEE</div>
          <div>{ec.appFee ? `$${ec.appFee}` : '-'}</div>
        </div>

      </div>

      <div className="d-flex flex-column" style={{ marginLeft: '20px', width: '45%' }}>

        <div>
          <div>PRE-REQUISITES/REQUIREMENTS</div>
          <div>{ec.requirements ? ec.requirements : '-'}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>ESSAY REQUIRED</div>
          <div>{booleanReturn(ec.essaysRequired)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>RECOMMENDATION(S) REQUIRED</div>
          <div>{booleanReturn(ec.recsRequired)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>INTERVIEW REQUIRED</div>
          <div>{booleanReturn(ec.interviewRequired)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>ACCEPTANCE RATE</div>
          <div>{returnAcceptanceRate(ec.admitRate)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION LINK</div>
          <a href={ec.applicationURL} target="_blank">{ec.applicationURL ? 'Link' : '-'}</a>
        </div>

      </div>
    </div>
  )

  const mobileReturn = (
    <div className='d-flex flex-column card shadow fw-bold' style={{ marginTop: '40px', padding: '40px', width: '100%' }}>

      <div>
        <div>APPLICATION REQUIRED</div>
        <div>{booleanReturn(ec.appRequired)}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>APPLICATION AVAILABILITY</div>
        <div>{convertDateFormat(ec.appAvailability)}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div className='d-flex flex-row' style={{ whiteSpace: 'nowrap', width: 'fit-content' }}>APPLICATION DUE DATE(S)&nbsp;&nbsp;{returnDueDateTrue(ec) && <TooltipComponentMobile content={`Updated application due date(s) may not yet be available. However the prior cycle's deadline(s) are displayed to help with planning as deadline date(s) are typically similar from year to year.`} />}</div>
        <div>{convertDateFormat(ec.appDueDate)}{ec.appDueDate2 && `; ${convertDateFormat(ec.appDueDate2)}`}{ec.appDueDate3 && `; ${convertDateFormat(ec.appDueDate3)}`}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>APPLICATION RESULT DATE</div>
        <div>{ec.appResultDate ? ec.appResultDate : '-'}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>ROLLING ADMISSION</div>
        <div>{booleanReturn(ec.rollingAdmission)}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>APPLICATION PROCESS</div>
        <div>{ec.appProcess ? ec.appProcess : '-'}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>APPLICATION FEE</div>
        <div>{ec.appFee ? `$${ec.appFee}` : '-'}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>PRE-REQUISITES/REQUIREMENTS</div>
        <div>{ec.requirements ? ec.requirements : '-'}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>ESSAY REQUIRED</div>
        <div>{booleanReturn(ec.essaysRequired)}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>RECOMMENDATION(S) REQUIRED</div>
        <div>{booleanReturn(ec.recsRequired)}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>INTERVIEW REQUIRED</div>
        <div>{booleanReturn(ec.interviewRequired)}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>ACCEPTANCE RATE</div>
        <div>{returnAcceptanceRate(ec.admitRate)}</div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>APPLICATION LINK</div>
        <a href={ec.applicationURL} target="_blank">{ec.applicationURL ? 'Link' : '-'}</a>
      </div>

    </div>
  )


  return mobile ? mobileReturn : desktopReturn
}

export { ApplicationParameters }