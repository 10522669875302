import { LoadingSpinner } from "../../LoadingSpinner";
import { titleSpace } from "../../dictionary";
import { collegeMap } from "../../collegeMap";
import { fullCollegeMap } from "../../fullCollegeMap";

const CollegeMatriculation = ({ result }) => {

  //When result is not set, inform that it is loading
  if (!result || !result.insights || !result.insights['Individual College Data']) {
    return <LoadingSpinner title={'College Matriculation'} />;
  }

  let collegeData: any = result.insights['Individual College Data']

  collegeData = collegeData.sort((a, b) => b.number - a.number)

  const returnCollege = (id, collegeMap) => {
    if (!id || !collegeMap) {
      return '-'
    }

    let collegeName = collegeMap.filter((item) => parseInt(item.id) == id)[0]?.name

    return collegeName ? collegeName : '-'
  }

  const returnNumber = (num) => {

    if (!num) {
      return '-'
    }

    return num

  }

  const returnPercent = (res) => {

    if (!res) {
      return '-'
    }

    return `${((res) * 100).toFixed(1)}%`
  }

  return (

    <div style={{ marginTop: '40px' }}>
      <div className='card shadow'>
        <div className='dca-font-M' style={{ fontSize: '26px', padding: '40px 40px 0px 40px' }}>Matriculation Outcomes For Activity Alumni - By College</div>
        <div>
          <table className="table table-borderless table-sm table-responsive" style={{ width: '85%', marginLeft: '40px', marginBottom: '20px', marginTop: '30px', borderSpacing: '0 15px', borderCollapse: 'separate' }}>
            <thead>
              <tr className="dca-font-M">
                <th scope="col" style={{ letterSpacing: titleSpace }}>COLLEGE</th>
                <th scope="col" style={{ letterSpacing: titleSpace }}># STUDENTS</th>
                <th scope="col" style={{ letterSpacing: titleSpace }}>% STUDENTS</th>
              </tr>
            </thead>
            <tbody>

              {collegeData.map((res, index) => (
                <tr key={index} style={{ borderBottom: '1px solid rgba(207,207,207,1)' }}>
                  <th scope='row' className="text-start dca-font-M">{returnCollege(res.collegeID, fullCollegeMap)}</th>
                  <td className="dca-font-M">{returnNumber(res.number)}</td>
                  <td className="dca-font-M">{returnPercent(res.percent)}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

}

export { CollegeMatriculation }
