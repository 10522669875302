import * as dictionary from '../dictionary'
import { zipcodeMap } from '../zipcodeMap'
import { TooltipComponent, TooltipComponentMobile } from '../Tooltip'

const dateReturn = (sessions) => {
  if (sessions && sessions.length > 1) {
    return `${dictionary.convertDateFormat(sessions[0].startDate)} - ${dictionary.convertDateFormat(sessions[0].endDate)} + ${sessions.length - 1} more`
  }

  else if (sessions && sessions.length === 1) {
    return `${dictionary.convertDateFormat(sessions[0].startDate)} - ${dictionary.convertDateFormat(sessions[0].endDate)}`
  }

  else {
    return '-'
  }
}

const locationReturn = (zipcodes) => {

  if (zipcodes && zipcodes.length > 0) {

    if (zipcodes.length > 1) {

      const zipcode0Found = zipcodeMap.find((item) => item.zipcode == zipcodes[0])

      if (zipcode0Found) {
        return `${zipcode0Found.cityState} + ${zipcodes.length - 1} more`
      }
    }

    else {
      const zipcode0Found = zipcodeMap.find((item) => item.zipcode == zipcodes[0])

      if (zipcode0Found) {
        return `${zipcode0Found.cityState}`
      }
    }

  }

  else {
    return '-'
  }
}

const formatFieldReturn = (val) => {
  if (val && val.length > 1) {
    let ec: any = []
    val.map((act, index) => {
      if (index === val.length - 1) {
        ec.push(dictionary.activityFormat[act])
      }
      else {
        ec.push(`${dictionary.activityFormat[act]}, `)
      }
    })
    return ec
  }
  else if (val) {
    return dictionary.activityFormat[val[0]]
  }
  else {
    return '-'
  }
}

const costReturn = (ec) => {
  if (ec && ec.specificCost !== null) {
    return `$${dictionary.numberConverter(ec.specificCost)}`
  }

  else if (ec && ec.minCost && ec.maxCost) {
    return `$${dictionary.numberConverter(ec.minCost)} - $${dictionary.numberConverter(ec.maxCost)}`
  }

  else {
    return '-'
  }
}

const durationReturn = (duration) => {
  if (duration) {
    return `${duration} Weeks`
  }

  else {
    return '-'
  }
}

const inPersonFormatReturn = (inPersonFormat) => {

  if (inPersonFormat && inPersonFormat.length > 1) {
    let res: any = []

    inPersonFormat.map((format, index) => {
      if (index === 0) {
        if (format === 1) {
          res.push(`Residential`)
        }

        else if (format === 2) {
          res.push(`Commuting`)
        }
      }

      else {
        if (format === 1) {
          res.push(` Residential`)
        }

        else if (format === 2) {
          res.push(` Commuting`)
        }
      }
    }
    )

    return `${res}`
  }

  else if (inPersonFormat && inPersonFormat.length == 1) {

    if (inPersonFormat[0] === 1) {
      return `Residential`
    }

    else if (inPersonFormat[0] === 2) {
      return `Commuting`
    }
  }

  else {
    return '-'
  }
}

const schoolLevelReturn = (schoolLevel) => {
  if (schoolLevel) {
    let res: any = []

    schoolLevel.map((lvl, index) => {
      if (index === 0) {
        if (lvl === 1) {
          res.push(`High school`)
        }

        else if (lvl === 2) {
          res.push(`Middle school`)
        }

        else if (lvl === 3) {
          res.push(`Elementary school`)
        }
      }

      else {
        if (lvl === 1) {
          res.push(` High school`)
        }

        else if (lvl === 2) {
          res.push(` Middle school`)
        }

        else if (lvl === 3) {
          res.push(` Elementary school`)
        }
      }
    })

    return `${res}`
  }

  else {
    return '-'
  }
}

const impactReturn = (impact) => {
  if (impact) {

    switch (true) {
      case impact === 1:
        return 'Good'
      case impact === 2:
        return 'Better'
      case impact === 3:
        return 'Best'
    }

  }

  else {
    return '-'
  }
}

const ECDetailCardData = ({ ec, returnPastEndDateTrue }) => {

  return (<div
    className="d-flex card shadow fw-bold"
    style={{ minWidth: 'fit-content', width: '45vw' }}
  >
    <div className="d-flex flex-row align-self-center justify-content-between" style={{ width: '90%', marginLeft: '20px', marginRight: '20px', marginTop: '25px', paddingBottom: '20px' }}>
      <div className="d-flex flex-column">
        <div>ACTIVITY TYPE</div>
        <div>{dictionary.returnValueInArrayOfObjects(ec.activityType, dictionary.activityType)}</div>
      </div>

      <div className="d-flex flex-column">
        <div>SCHOOL LEVEL</div>
        <div>{schoolLevelReturn(ec.schoolLevel)}</div>
      </div>

      <div className="d-flex flex-column">
        <div>IMPACT</div>
        <div>{impactReturn(ec.impactIndex)}</div>
      </div>
    </div>

    <div className="d-flex flex-row align-self-center justify-content-between" style={{ width: '90%', marginLeft: '20px', marginRight: '20px', marginTop: '25px', paddingBottom: '20px' }}>

      <div className="d-flex flex-column">
        <div>DATE(S)&nbsp;&nbsp;{returnPastEndDateTrue(ec.ec_activity_session_dates) && <TooltipComponent content={`Updated activity date(s) may not yet be available. However dates from prior sessions are displayed to help with planning as session dates are typically similar from year to year.`} />}</div>
        <div>{dateReturn(ec.ec_activity_session_dates)}</div>
      </div>

      <div className="d-flex flex-column">
        <div>DURATION</div>
        <div>{durationReturn(ec.duration)}</div>
      </div>

      <div className="d-flex flex-column">
        <div>COST</div>
        <div>{costReturn(ec)}</div>
      </div>

      <div className="d-flex flex-column">
        <div>WEBSITE</div>
        <a href={ec.homepageURL} target="_blank">Link</a>
      </div>

    </div>

    <div className="d-flex flex-row align-self-center justify-content-between" style={{ width: '90%', marginLeft: '20px', marginRight: '20px', marginTop: '25px', paddingBottom: '20px' }}>

      <div className="d-flex flex-column">
        <div>ACTIVITY FORMAT(S)</div>
        <div>{formatFieldReturn(ec.activityFormat)}</div>
      </div>

      <div className="d-flex flex-column">
        <div>IN-PERSON FORMAT(S)</div>
        <div>{inPersonFormatReturn(ec.inPersonFormat)}</div>
      </div>

      <div className="d-flex flex-column">
        <div>LOCATION(S)</div>
        <div>{locationReturn(ec.locations)}</div>
      </div>

    </div>
  </div>)

}

export { ECDetailCardData }


const ECDetailCardDataMobile = ({ ec, returnPastEndDateTrue }) => {

  const dataColumn = (text, content, column) => {
    return <div className='d-flex flex-column' style={{ width: column === 'A' ? '30%' : '50%' }}>
      <div>{text}</div>
      <div>{content}</div>
    </div>
  }

  return (
    <div
      className="d-flex card shadow fw-bold"
      style={{ padding: '30px 30px 20px 40px', width: '100%', marginBottom: '20px' }}
    >
      <div className="d-flex flex-column" style={{ width: '100%' }}>

        {/* Row 1 */}
        <div className="d-flex flex-row justify-content-between" style={{ marginBottom: '15px', width: '100%' }}>

          {dataColumn('ACTIVITY TYPE', dictionary.returnValueInArrayOfObjects(ec.activityType, dictionary.activityType), 'B')}

          {dataColumn('SCHOOL LEVEL', schoolLevelReturn(ec.schoolLevel), 'B')}
        </div>

        {/* Row 2 */}
        <div className="d-flex flex-row justify-content-between" style={{ marginBottom: '15px', width: '100%' }}>

          {dataColumn('IMPACT', impactReturn(ec.impactIndex), 'A')}

          <div className='d-flex flex-column' style={{ width: '30%' }}>
            <div className='d-flex flex-row'>DATE(S)&nbsp;&nbsp;{returnPastEndDateTrue(ec.ec_activity_session_dates) && <TooltipComponentMobile content={`Updated activity date(s) may not yet be available. However dates from prior sessions are displayed to help with planning as session dates are typically similar from year to year.`} />}</div>
            <div>{dateReturn(ec.ec_activity_session_dates)}</div>
          </div>

          {/* data group */}
          {dataColumn('DURATION', durationReturn(ec.duration), 'A')}
        </div>

        {/* Row 3 */}
        <div className="d-flex flex-row justify-content-between" style={{ marginBottom: '15px', width: '100%' }}>

          {dataColumn('ACTIVITY FORMAT(S)', formatFieldReturn(ec.activityFormat), 'B')}

          {dataColumn('IN-PERSON FORMAT(S)', inPersonFormatReturn(ec.inPersonFormat), 'B')}

        </div>

        {/* Row 4 */}
        <div className="d-flex flex-row justify-content-between" style={{ marginBottom: '15px', width: '100%' }}>

          {dataColumn('LOCATION(S)', locationReturn(ec.locations), 'A')}

          {dataColumn('COST', costReturn(ec), 'A')}

          <div className='d-flex flex-column' style={{ width: '30%' }}>
            <div>WEBSITE</div>
            <a href={ec.homepageURL} target="_blank">Link</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ECDetailCardDataMobile }
