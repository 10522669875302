import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import * as dictionary from '../components/dictionary'
import { Aside } from '../components/CollegeDataVisualization/Aside'
import { CollegeDataChart } from '../components/CollegeDataVisualization/CollegeDataChart'
import { useNavigate } from 'react-router'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { useLocation } from 'react-router'
import { returnParameterString, concatLocation } from '../components/searchPageURLManager'

const CollegeDataVisualization = ({ user, setUser, collegesId, prompt, setPrompt, navigateTo, setNavigateTo, collegeDataVisLocation, setCollegeDataVisLocation }) => {

  const location = useLocation()
  const navigate = useNavigate()
  const collegeListLength = collegesId.length
  // let today = new Date();
  // let latestYear = (today.getFullYear() - 1)

  // Now #580, update to 2023 - 2024 as latest year / primary data
  let latestYear = 2023

  const chartOptionsOptions = [
    { value: 1, label: 'GPA vs SAT Composite' },
    { value: 2, label: 'GPA vs ACT Composite' },
    { value: 3, label: 'Class Rank vs SAT Composite' },
    { value: 4, label: 'Class Rank vs ACT Composite' },
    { value: 5, label: 'SAT Math vs SAT Reading/Writing' },
    { value: 6, label: 'ACT Math vs ACT English' },
    { value: 7, label: 'SAT Submission vs ACT Submission' }
  ]

  const getURLParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(param)
  }

  const [colleges, setColleges] = useState<any>([])
  const [cdsAvailableSet, setCDSAvailableSet] = useState<any>([])
  const [allOlderDataSet, setAllOlderDataSet] = useState<any>([])
  const [allInsufficientDataSet, setAllInsufficientDataSet] = useState<any>([])
  const [insufficientDataSet, setInsufficientDataSet] = useState<any>([])
  const [olderDataSet, setOlderDataSet] = useState<any>([])
  const [collegeSetOptions, setCollegeSetOptions] = useState<any>([])
  const [majors, setMajors] = useState<any>([])
  const [loadingComplete, setLoadingComplete] = useState(false)
  const [filtersActive, setFiltersActive] = useState(0)

  // For College Data Chart component, renders chart when arrays are set
  const [dataLoaded, setDataLoaded] = useState(false);

  // Aside states
  const [selectedChart, setSelectedChart] = useState(() => {
    const initialParam = getURLParam('chart');
    return (initialParam && Number(initialParam) <= 7) ? Number(initialParam) : 1;
  });
  const [selectedCollegeSet, setSelectedCollegeSet] = useState(() => {
    const initialParam = getURLParam('collegeSet');
    return (initialParam && Number(initialParam) <= 7) ? Number(initialParam) : 0;
  });
  const [selectedType, setSelectedType] = useState<any>(0)
  const [selectedMajor, setSelectedMajor] = useState<any>('')

  // Aside filters
  const [collegeSetFilter, setCollegeSetFilter] = useState<any>((item) => {
    return item => item
  })
  const [institutionTypeFilter, setInstitutionTypeFilter] = useState<any>((item) => {
    return item => item
  })
  const [majorFilter, setMajorFilter] = useState<any>((item) => {
    return item => item
  })
  const [showRelatedMajors, setShowRelatedMajors] = useState<any>(false)

  useEffect(() => {
    dictionary.updateTitleAndDescription(dictionary.pageRoutes.collegeDataVisualization)
  }, [])

  useEffect(() => {

    const fetchData = async () => {

      try {

        if (loadingComplete === true) {
          setLoadingComplete(false)
        }

        // const collegesAxios = axios.get(`${dictionary.URL}/colleges/0-to-${collegeListLength}`)
        const collegesAxios = axios.get(`${dictionary.URL}/colleges/all`)
        const collegeSetAxios = axios.get(`${dictionary.URL}/collegeset/`)
        const majorsAxios = axios.get(`${dictionary.URL}/majors/`)

        const [collegeRes, collegesetRes, majorsRes] = await Promise.all([
          collegesAxios,
          collegeSetAxios,
          majorsAxios
        ])

        //All colleges == collegeRes.data
        let sufficient = collegeRes.data.filter((item) =>
          item.college_cds != null &&
          item.college_cds[0] != null
        );

        // Applies a few more checks for the default chart GPA vs SAT
        let resForCDSAvailable = sufficient.filter((item) => item.college_cds[0]['averageGPA'] != null &&
          (
            item.college_cds[0]['satComposite50'] != null ||
            (
              item.college_cds[0]['satComposite25'] != null &&
              item.college_cds[0]['satComposite75'] != null
            ) ||
            (
              item.college_cds[0]['satEvidence50'] != null &&
              item.college_cds[0]['satMath50'] != null
            )
          ))

        let insufficient = sufficient.filter((item) => !resForCDSAvailable.includes(item))

        // let olderData = resForCDSAvailable.filter((item) => (
        //   (item.college_cds[0].year !== latestYear)
        // ))

        // Given update to CDS, set older data to anything that does not have latest year
        let olderData = resForCDSAvailable.filter((item) => (
          (item.college_cds[0].year < latestYear)
        ))

        let collegeSetArr: any = []
        // collegeSetArr.push({ value: [], label: 'All' })
        // collegesetRes.data.forEach((res) => {
        //   collegeSetArr.push({ value: res.colleges, label: res.name })
        // });
        collegeSetArr.push({ value: 0, label: 'All' })
        collegesetRes.data.forEach((res, index) => {
          collegeSetArr.push({ value: index + 1, label: res.name, colleges: res.colleges })
        });

        await Promise.all([
          setInsufficientDataSet(insufficient),
          setAllInsufficientDataSet(insufficient),
          setColleges(sufficient),
          setCDSAvailableSet(resForCDSAvailable),
          setOlderDataSet(olderData),
          setAllOlderDataSet(olderData),
          setCollegeSetOptions(collegeSetArr),
          setMajors(majorsRes.data)
        ])

        setLoadingComplete(true)

      } catch (error) {
        console.error('Error grabbing College Data Visualization data:', error)
      }
    }

    fetchData()

  }, [collegesId])

  useEffect(() => {
    const returnTypeFilter = (selectedType) => {
      switch (selectedType) {
        case 0:
          setInstitutionTypeFilter((item) => {
            return item => item
          })
          break;

        case 1:
          setInstitutionTypeFilter((item) => {
            return item => item.type === 1
          })
          break;

        case 2:
          setInstitutionTypeFilter((item) => {
            return item => [2, 3].includes(item.type)
          })
          break;

        default:
          setInstitutionTypeFilter((item) => {
            return item => item
          })
      };
    };
    returnTypeFilter(selectedType)

    const returnMajorFilter = (selectedMajor) => { }
    returnMajorFilter(selectedMajor)

  }, [selectedType, selectedMajor])

  useEffect(() => {

    if (loadingComplete === true) {
      setLoadingComplete(false)
    }

    // college == sufficient from all colleges 
    let res: any = colleges

    switch (true) {
      // GPA vs SAT Composite
      case selectedChart == 1:
        res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
          (
            item.college_cds[0]['satComposite50'] != null ||
            (
              item.college_cds[0]['satComposite25'] != null &&
              item.college_cds[0]['satComposite75'] != null
            ) ||
            (
              item.college_cds[0]['satEvidence50'] != null &&
              item.college_cds[0]['satMath50'] != null
            )
          ));
        break;

      // GPA vs ACT Composite
      case selectedChart == 2:
        res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
          (
            item.college_cds[0]['actComposite50'] != null ||
            (
              item.college_cds[0]['actComposite25'] != null &&
              item.college_cds[0]['actComposite75'] != null
            )
          ) &&
          item.college_cds[0]['totalAdmitRate'] != null
        );
        break;

      // Class Rank vs SAT
      case selectedChart == 3:
        res = res.filter((item) => item.college_cds[0]['classRankTopTenth'] != null &&
          (
            item.college_cds[0]['satComposite50'] != null ||
            (
              item.college_cds[0]['satComposite25'] != null &&
              item.college_cds[0]['satComposite75'] != null
            ) ||
            (
              item.college_cds[0]['satEvidence50'] != null &&
              item.college_cds[0]['satMath50'] != null
            )
          ) &&
          item.college_cds[0]['totalAdmitRate'] != null);
        break;

      // Class Rank vs ACT
      case selectedChart == 4:
        res = res.filter((item) => item.college_cds[0]['classRankTopTenth'] != null &&
          (
            item.college_cds[0]['actComposite50'] != null ||
            (
              item.college_cds[0]['actComposite25'] != null &&
              item.college_cds[0]['actComposite75'] != null
            )
          ) &&
          item.college_cds[0]['totalAdmitRate'] != null
        );
        break;

      // SAT Math vs SAT Reading/Writing
      case selectedChart == 5:
        res = res.filter((item) => (item.college_cds[0]['satMath50'] != null && item.college_cds[0]['satEvidence50'] != null) || (item.college_cds[0]['satMath25'] != null && item.college_cds[0]['satMath75'] != null && item.college_cds[0]['satEvidence25'] != null && item.college_cds[0]['satEvidence75'] != null));
        break;

      // ACT Math vs ACT English
      case selectedChart == 6:
        res = res.filter((item) => (item.college_cds[0]['actMath50'] != null && item.college_cds[0]['actEnglish50'] != null) || (item.college_cds[0]['actMath25'] != null && item.college_cds[0]['actMath75'] != null && item.college_cds[0]['actEnglish25'] != null && item.college_cds[0]['actEnglish75'] != null));
        break;

      // SAT submit vs ACT submit
      case selectedChart == 7:
        res = res.filter((item) => (item.college_cds[0]['submitSATPercent'] != null && item.college_cds[0]['submitACTPercent'] != null));
        break;

      default:
        res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
          (
            item.college_cds[0]['satComposite50'] != null ||
            (
              item.college_cds[0]['satComposite25'] != null &&
              item.college_cds[0]['satComposite75'] != null
            )
          ) &&
          item.college_cds[0]['totalAdmitRate'] != null);
    }

    let insufficient = colleges.filter((item) => !res.includes(item))

    insufficient = insufficient.filter(institutionTypeFilter).filter(majorFilter).filter(collegeSetFilter)

    res = res.filter(institutionTypeFilter).filter(majorFilter).filter(collegeSetFilter)

    // let olderData = res.filter((item) => (
    //   (item.college_cds[0].year !== latestYear)
    // ))

    // Per #165, part 2:
    let olderData = res.filter((item) => (
      (item.college_cds[0].year < latestYear)
    ))

    setInsufficientDataSet(insufficient)
    setOlderDataSet(olderData)
    setCDSAvailableSet(res)
    setLoadingComplete(true)

  }, [institutionTypeFilter, majorFilter, collegeSetFilter, selectedChart, showRelatedMajors, collegeSetOptions])

  const [chartOptionParams, setChartOptionParams] = useState('')
  const [collegeSetParams, setCollegeSetParams] = useState('')

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    params.set('chart', `${selectedChart}`)

    setChartOptionParams(`${params.toString()}`)

  }, [selectedChart])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    params.set('collegeSet', `${selectedCollegeSet}`)

    setCollegeSetParams(`${params.toString()}`)

    const returnCollegeSetFilter = (selectedCollegeSet) => {

      // if (selectedCollegeSet.length > 0) {
      if (selectedCollegeSet > 0) {

        let collegesFromSelectedCollegeSet = collegeSetOptions.find(item => item.value == selectedCollegeSet)

        // setCollegeSetFilter((item) => {
        //   return item => selectedCollegeSet.includes(item.id)
        // })

        setCollegeSetFilter((item) => {
          return item => collegesFromSelectedCollegeSet?.colleges.includes(item.id)
        })

        // //update the older and insufficient data arrays in the notes section
        // setOlderDataSet(allOlderDataSet.filter((item) => selectedCollegeSet.includes(item.id)))

        // setInsufficientDataSet(allInsufficientDataSet.filter((item) => selectedCollegeSet.includes(item.id)))

        //update the older and insufficient data arrays in the notes section
        setOlderDataSet(allOlderDataSet.filter((item) => collegesFromSelectedCollegeSet?.colleges.includes(item.id)))

        setInsufficientDataSet(allInsufficientDataSet.filter((item) => collegesFromSelectedCollegeSet?.colleges.includes(item.id)))
      }

      else {
        setCollegeSetFilter((item) => {
          return item => item
        })

        //update the older and insufficient data arrays in the notes section to original older and insufficient arrays
        setOlderDataSet(allOlderDataSet)
        setInsufficientDataSet(allInsufficientDataSet)
      }
    }
    returnCollegeSetFilter(selectedCollegeSet)

  }, [selectedCollegeSet, collegeSetOptions])

  useEffect(() => {

    let params: string[] = []
    params = [
      returnParameterString(chartOptionParams, 'chart'),
      returnParameterString(collegeSetParams, 'collegeSet')
    ]

    setCollegeDataVisLocation(`${dictionary.pageRoutes.collegeDataVisualization}${concatLocation(params)}`)

  }, [chartOptionParams, collegeSetParams])

  useEffect(() => {

    window.history.replaceState({}, '', collegeDataVisLocation)

  }, [collegeDataVisLocation])

  const desktopHeader = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>College Data Scattergrams</span>

        <div className="dca-search-header-bttn-container-double">
          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(dictionary.pageRoutes.personalizedExpertHelp)}
            className='dca-search-header-bttn'
          >Get Personalized Help</button>

          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(dictionary.pageRoutes.collegeSearch)}
            className='dca-search-header-bttn'
            style={{ marginLeft: '8px' }}
          >Explore More College Data</button>

        </div>
      </div>

      <span className="dca-search-description">Explore benchmark values for the most important quantitative admissions considerations at the top-ranked colleges.</span>

    </div>
  )

  const mobileHeader = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>College Data Scattergrams</span>

        <span className="dca-search-description">Explore benchmark values for the most important quantitative admissions considerations at the top-ranked colleges.</span>

        <Aside
          collegeSetOptions={collegeSetOptions}
          chartOptionsOptions={chartOptionsOptions}
          selectedChart={selectedChart}
          setSelectedChart={setSelectedChart}
          setSelectedType={setSelectedType}
          selectedCollegeSet={selectedCollegeSet}
          setSelectedCollegeSet={setSelectedCollegeSet}
          user={user}
          setUser={setUser}
          filtersActive={filtersActive}
          mobile={true}
        />

        <div>
          <div className='dca-college-search-resources-links-header'>RESOURCES</div>
          <div
            className='dca-college-search-resources-links'
            onClick={() => navigate(dictionary.pageRoutes.personalizedExpertHelp)}
          >Get Personalized Help</div>
          <div
            className='dca-college-search-resources-links'
            onClick={() => navigate(dictionary.pageRoutes.collegeSearch)}
          >Explore More College Data</div>
        </div>
      </div>
    </div>
  )

  return loadingComplete ? (
    <div id='dca-college-search-wrapper'>
      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        {desktopHeader}
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none'>
        {mobileHeader}
      </div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex' style={{ width: '100%', maxWidth: '100%', margin: '0 auto' }}>
        <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px', width: '90%' }}>
          <Aside
            collegeSetOptions={collegeSetOptions}
            chartOptionsOptions={chartOptionsOptions}
            selectedChart={selectedChart}
            setSelectedChart={setSelectedChart}
            setSelectedType={setSelectedType}
            selectedCollegeSet={selectedCollegeSet}
            setSelectedCollegeSet={setSelectedCollegeSet}
            user={user}
            setUser={setUser}
            filtersActive={filtersActive}
          />
          <CollegeDataChart
            cdsAvailableSet={cdsAvailableSet}
            selectedChart={selectedChart}
            olderDataSet={olderDataSet}
            insufficientDataSet={insufficientDataSet}
            latestYear={latestYear}
            loadingComplete={loadingComplete}
            dataLoaded={dataLoaded}
            setDataLoaded={setDataLoaded}
          />
        </div>
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none'>
        <CollegeDataChart
          cdsAvailableSet={cdsAvailableSet}
          selectedChart={selectedChart}
          olderDataSet={olderDataSet}
          insufficientDataSet={insufficientDataSet}
          latestYear={latestYear}
          loadingComplete={loadingComplete}
          dataLoaded={dataLoaded}
          setDataLoaded={setDataLoaded}
          mobile={true}
        />
      </div>
    </div>
  ) : (<div id='dca-college-search-wrapper'>
    {/* Use Bootstrap d-md-block to render while 768px or above */}
    <div className='d-none d-md-flex'>
      {desktopHeader}
    </div>

    {/* Mobile Replacement, only shows at below 768px */}
    <div className='d-flex d-md-none' style={{ marginBottom: '30px' }}>
      {mobileHeader}
    </div>

    {/* Use Bootstrap d-md-block to render while 768px or above */}
    <div className='d-none d-md-flex'>
      <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
        <Aside
          collegeSetOptions={collegeSetOptions}
          chartOptionsOptions={chartOptionsOptions}
          selectedChart={selectedChart}
          setSelectedChart={setSelectedChart}
          setSelectedType={setSelectedType}
          selectedCollegeSet={selectedCollegeSet}
          setSelectedCollegeSet={setSelectedCollegeSet}
          user={user}
          setUser={setUser}
          filtersActive={filtersActive}
        />
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
          <LoadingIndicator text={"Loading College Data Scattergrams..."} />
        </div>
      </div>
    </div>

    {/* Mobile Replacement, only shows at below 768px */}
    <div className='d-flex d-md-none justify-content-center align-items-center'>
      <LoadingIndicator text={"Loading College Data Scattergrams..."} />
    </div>
  </div>
  )
}

export { CollegeDataVisualization }