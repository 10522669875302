import { useState, useEffect } from 'react'
import { useLocation } from 'react-router';
import Select from 'react-select'

const ECCollegeSearch = ({ collegeOptions, setCollegeSelect, resetStateToggle }) => {

  const location = useLocation()
  const [selectedColleges, setSelectedColleges] = useState<any>([]);

  const handleCollegeSelectChange = (selectedColleges) => {
    setSelectedColleges(selectedColleges);
    let collegeArr: any = []
    selectedColleges.map((college) =>
      collegeArr.push(parseInt(college.value)))
    setCollegeSelect(collegeArr)
  }

  const setChosenOptions = (cols, options) => {
    let selected: any[] = [];

    cols.forEach((intStr) => {
      let optionObj: any = options.find((opt) => opt.value.toString() === intStr);
      if (optionObj) {
        selected.push(optionObj);
      }
    });

    return selected;
  };

  useEffect(() => {
    setSelectedColleges([])
  }, [resetStateToggle])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let collegeParam = params.get('college');

    if (collegeParam) {
      let collegeStrs = collegeParam.split(',').map(res => res);
      let res = setChosenOptions(collegeStrs, collegeOptions)
      handleCollegeSelectChange(res)
    }

  }, [location.search, collegeOptions]);

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>COLLEGE</div>
      <Select
        options={collegeOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          })
        }}
        isMulti
        value={selectedColleges}
        onChange={handleCollegeSelectChange}
      />
    </div>
  )
}

export { ECCollegeSearch }