import { useState, useEffect } from 'react'
import * as dictionary from '../../dictionary'
import Select from 'react-select'
import { useLocation } from 'react-router'

const ECInterestAreaSearch = ({ filteredEC, setInterestAreaSelect, resetStateToggle }) => {

  const location = useLocation()
  const [interestOptions, setInterestOptions] = useState<any>([]);
  const [selectedInterests, setSelectedInterests] = useState<any>([]);

  const getInterestOptions = (filteredEC) => {
    let res: any = []

    dictionary.relatedInterestAreas.forEach((interest) => {
      res.push({ value: Object.keys(interest), label: Object.values(interest) })
      setInterestOptions(res)
    });
  }

  const handleInterestSelectChange = (selectedInterests) => {
    console.log('selectedInterests', selectedInterests)
    setSelectedInterests(selectedInterests);
    let interestArr: any = []
    selectedInterests.map((interest) => {
      if (interest !== null) {
        interestArr.push(parseInt(interest.value))
      }
    })
    setInterestAreaSelect(interestArr)
  }

  useEffect(() => {
    setSelectedInterests([])
  }, [resetStateToggle])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let interestsParam = params.get('interestAreas');

    if (interestsParam) {
      let interests = interestsParam.split(',').map(res => res);

      let res: any = []

      interests.forEach((interest) => {
        let interestObj: any = dictionary.relatedInterestAreas.find((obj) => Object.keys(obj)[0] === interest);

        if (interestObj) {
          res.push({ value: Object.keys(interestObj), label: Object.values(interestObj) })
        }

      });

      if (res.length > 0) {
        handleInterestSelectChange(res)
      }
    }

    else {
      setSelectedInterests([])
    }

  }, [location.search]);

  useEffect(() => {
    getInterestOptions(filteredEC)
  }, [filteredEC])

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>INTEREST AREAS</div>
      <Select
        options={interestOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          })
        }}
        isMulti
        value={selectedInterests}
        onChange={handleInterestSelectChange}
      />
    </div>
  )
}

export { ECInterestAreaSearch }