import { selectedTabGreen, closeModalIcon, specializedRankingsAccessCode, rdSpendAccessCode, safetyAccessCode, ecPremiumAccessCode, specializedRankingsPriceID, rdSpendPriceID, safetyPriceID, pageRoutes, URL, returnECPremiumPriceID, returnAdvisorPriceID, reactGAPurchaseModalController, advisorConnectAccessCode, personalizedExpertHelpPriceID, personalizedExpertHelpProductID } from "../dictionary"
import axios from "axios"
import { useState, useEffect } from "react"
import { AdvisorsReactGAHandlers, CollegeReactGAHandlers, ECReactGAHandlers } from "../ReactGAHandlers"

export const PurchaseDescriptionModal = ({ purchaseID, collegeName, collegeListLength, ecID, ecName, ecNumAlumni, setUpgradeUserGroupAccessCode, clientSecret, setClientSecret, advisorID, currentUser }) => {

  const {
    handleGAEventPurchaseModalPurchase,
    handleGAEventPurchasePreviewOpen
  } = CollegeReactGAHandlers()

  const {
    handleECGAEventPurchasePreviewOpen,
    handleECGAEventPurchaseModalPurchase
  } = ECReactGAHandlers()

  const { handleAdvisorGAEventConsultPurchaseButtonClick } = AdvisorsReactGAHandlers()

  const [ECInsight, setECInsight] = useState<any>(null)
  const getStripePrice = async (priceID) => {
    let res = await axios.get(`${URL}/get-price/${priceID}`)
    let unitAmount = res.data.unit_amount
    if ((unitAmount / 100) > 0) {
      unitAmount = (unitAmount / 100).toFixed(2)
    }
    return unitAmount
  }
  const [priceToRender, setPriceToRender] = useState<any>('')

  const [advisorProfile, setAdvisorProfile] = useState<any>([])
  const [advisorECReports, setAdvisorECReports] = useState<any>([])

  // Modal text describing the item(s) to be purchased
  const specializedRankingsDescriptionArray = [
    `This premium content module includes specialized rankings for ${collegeName}, as well as for all of the ${collegeListLength} leading U.S. colleges featured on the site.`,
    `Specialized rankings indicate how ${collegeName} compares with the rest of the colleges in the following categories: Academic Awards, Research/Innovation, Alumni Success In Business, Alumni Success In Politics, Alumni Winning Scholarships, and Earnings/Wealth.
    `,
    `Through proprietary analysis, each college is rated and relatively ranked across a total of 17 metrics across the categories mentioned above.`,
    `This module provides data-driven insights on how each college stacks up across multiple dimensions, enabling you to go beyond generic rankings and consider the attributes most important to you in your college evaluation process.`
  ]

  const RnDSpendDescriptionArray = [
    `This premium content module provides detailed data on R&D expenditures for ${collegeName}, as well as for all of the ${collegeListLength} leading U.S. colleges featured on the site.`,
    `The R&D spend data includes annual spend data for each of the last three years of available data (2020 - 2022) and the corresponding 3-year annual average.`,
    `R&D spend data is comprehensive across Science, Engineering, and Non-Science/Engineering and includes detail on 47 fields of study (i.e. chemistry, anthropology, mechanical engineering, etc.)
    `,
    `Each college is rated and relatively ranked for each of the 47 data-points.`,
    `This module provides data-driven insights on how each college compares in terms of research spend per field, which is a proxy for the overall strength of research by field and may correlate with opportunities for students interested in specific fields at that college.  Therefore it may be a helpful lens to consider in your college evaluation process.
    `
  ]

  const SafetyDescriptionArray = [
    `This premium content module provides detailed data on safety and crime statistics for ${collegeName}, as well as for all of the ${collegeListLength} leading U.S. colleges featured on the site.`,
    `The safety and crime data includes annual incident data for each of the last three years of available data (2020 - 2022) and the corresponding 3-year annual average, for both the total number of incidents as well as incidents per one thousand students. Data includes a breakdown of incidents by location (i.e. on-campus housing, on-campus total, non-campus, and private property.)`,
    `This module illustrates how ${collegeName} compares with the rest of the colleges in the following categories: Criminal Offenses, Violence Against Women Act (VAWA) Offenses, Arrests, Disciplinary Actions, and Hate Crimes. 
    `,
    `Each college is rated and relatively ranked across a total of 38 metrics across the categories mentioned above.
    `,
    `This module also includes an aggregate safety index for ${collegeName} which provides a relative rank of safety for this institution, based on a proprietary analysis of the incident data. `,
    `This module provides data-driven insights on how each college compares in terms of crime statistics, which is an important indicator of campus safety, and therefore may be a helpful lens to consider in your college evaluation process.`
  ]

  const AdvisorDescriptionArray = [
    `This service provides for a (${advisorProfile.sessionDuration ? advisorProfile.sessionDuration : '-'} minutes) 1:1 session with ${advisorProfile.firstName ? advisorProfile.firstName : '-'}. The session will be conducted virtually (via Zoom). 
    `,
    `Upon successful completion of this purchase, you will receive an email to coordinate a specific meeting time that is mutually convenient for both you and the advisor. Typically, sessions are scheduled within one week.
    `,
    `During the session, you should guide the conversation to the specific aspects of your college preparation/application journey where you would like to get the advisor's perspective. Common discussion topics include the advisor's college, major(s), extracurricular activities while in high school, and their overall application process. Please refer to the Discussion Topics in the advisor's profile for more information.`,
    `This service provides a unique opportunity to connect with recent high school graduates who have first-hand experience with the college admissions journey and are willing to share their knowledge and insights.`
  ]

  const PersonalizedExpertHelpArray = [
    `This personalized Q&A feature provides access to NextFour experts who will answer your specific questions relating to the holistic college admissions process.`,
    `Upon successful completion of this transaction, you are entitled to single-use access to this service and will immediately receive a confirmation email which will provide instructions for using this service. You will be provided an email address where you can send a question (or a few questions related to the same topic.)`,
    `Your question will be reviewed and responded to by NextFour within 24 hours. This purchase covers all discussion related to the initial topic, including additional email correspondence (as necessary) to reach resolution on the topic. Please note additional questions on non-adjacent topics will require a separate transaction.`
  ]

  const addPaymentMethodModalButton = (name) => {
    return (
      <button
        className='fw-bold align-self-end'
        data-bs-target={`#add_payment_method_modal`}
        onClick={async () => {

          if (clientSecret != '') {
            await setClientSecret('')
          }

          await setUpgradeUserGroupAccessCode(purchaseID);

          handleGAEventPurchaseModalPurchase(name)

          // Depending on the purchaseID, make a fetch request to a particular checkout session with a price/product ID
          switch (true) {
            case purchaseID === specializedRankingsAccessCode:
              await fetch(`${URL}/create-checkout-session/${specializedRankingsPriceID}`, {
                method: "POST",
              }).then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret)).catch((error) => {
                  console.error('Error:', error);
                });
              break;
            case purchaseID === rdSpendAccessCode:
              await fetch(`${URL}/create-checkout-session/${rdSpendPriceID}`, {
                method: "POST",
              }).then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret)).catch((error) => {
                  console.error('Error:', error);
                });
              break;

            case purchaseID === safetyAccessCode:
              await fetch(`${URL}/create-checkout-session/${safetyPriceID}`, {
                method: "POST",
              }).then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret)).catch((error) => {
                  console.error('Error:', error);
                });
              break;
          }

        }}
        data-bs-toggle="modal"
        style={{ borderRadius: '8px', background: selectedTabGreen, border: 'transparent', width: '110px', marginTop: '40px', padding: '10px' }}>Purchase</button>

    )
  }

  const emailVerificationRequiredButton = (
    <button
      className='fw-bold align-self-end'
      data-bs-target={`#email_verification_request_modal`}
      data-bs-toggle="modal"
      onClick={() => {
        if (purchaseID === advisorConnectAccessCode) {
          handleAdvisorGAEventConsultPurchaseButtonClick(advisorID)
        }
      }}
      style={{
        borderRadius: '8px',
        background: selectedTabGreen,
        border: 'transparent',
        width: '110px',
        marginTop: '40px',
        padding: '10px'
      }}>Purchase</button>
  )

  // Function to create the modal's content
  const createModalContent = (name, description, previewTitle) => {

    return (
      <div className="d-flex flex-column" style={{ marginTop: '10px' }}>
        <div className="d-flex flex-row justify-content-between" style={{ fontSize: '26px' }}>
          <div>{name}</div>
          <div style={{ marginLeft: '18px' }}>
            <span className="align-top" style={{ fontSize: '12px' }}>$</span>{priceToRender}
          </div>
        </div>

        <div style={{ marginTop: '30px' }}>
          <ul>
            {description.map((item, index) =>
              <li key={index} className="mt-3">{item}</li>)}
            <li className="mt-3">Purchase enables access for one calendar year and includes all updates for this content module published during that term. Content is limited to private use within your individual household per the <a className="dca-card-item-text" href={pageRoutes.termsOfService} target="_blank" onClick={(e) => e.stopPropagation()}>Terms Of Service</a>.</li>
          </ul>
        </div>

        <div className="d-flex flex-row justify-content-between fw-bold">
          {purchaseID != advisorConnectAccessCode && <a
            className="dca-card-item-text align-self-center"
            style={{ marginTop: '40px', padding: '10px', cursor: 'pointer', fontWeight: 400 }}
            onClick={() => handleGAEventPurchasePreviewOpen(previewTitle)}
            data-bs-toggle="modal"
            data-bs-target="#premium_preview_modal">Preview Sample {previewTitle} Report</a>}

          {(currentUser && currentUser.validatedEmail) ? addPaymentMethodModalButton(name) : emailVerificationRequiredButton}
        </div>

      </div>
    )

  }

  const specializedRankingPurchaseReturn = createModalContent("Specialized Rankings", specializedRankingsDescriptionArray, "Specialized Rankings")

  const rdPurchaseReturn = createModalContent("Research & Development (R&D) Spend", RnDSpendDescriptionArray, "R&D Spend")

  const safetyPurchaseReturn = createModalContent("Safety & Crime Ratings", SafetyDescriptionArray, "Crime & Safety")

  useEffect(() => {

    const getPrices = async () => {

      let res: any = 0
      switch (true) {
        case purchaseID === specializedRankingsAccessCode:
          res = await getStripePrice(specializedRankingsPriceID)
          break;
        case purchaseID === rdSpendAccessCode:
          res = await getStripePrice(rdSpendPriceID)
          break;
        case purchaseID === safetyAccessCode:
          res = await getStripePrice(safetyPriceID)
          break;
        case purchaseID === personalizedExpertHelpPriceID:
          res = await getStripePrice(personalizedExpertHelpPriceID)
          break;
      }

      setPriceToRender(res)
    }

    getPrices()

  }, [purchaseID])

  useEffect(() => {

    if (ecID != null) {
      const getECInsights = async () => {

        try {
          let res = await axios.get(`${URL}/ec_insights/${ecID}`)
          setECInsight(res.data)
        } catch (error) {
          console.log('error:', error)
          setECInsight(null)
        }
      }
      getECInsights()

      const getAdvisorECReports = async () => {
        let res = await axios.get(`${URL}/advisor_ec_reports/${ecID}`)
        setAdvisorECReports(res.data)
      }
      getAdvisorECReports()
    }

  }, [ecID])

  useEffect(() => {

    const getECPrice = async () => {
      let res: any = 0
      let ecPremiumPriceID = returnECPremiumPriceID(ecID)

      if (purchaseID === ecPremiumAccessCode) {
        res = await getStripePrice(ecPremiumPriceID)
        setPriceToRender(res)
      }
    }

    getECPrice()

  }, [ecName])

  useEffect(() => {

    if (advisorID != null) {

      const getAdvisorPrice = async () => {
        let res: any = 0
        let advisorPriceID = returnAdvisorPriceID(advisorID)

        if (purchaseID === advisorConnectAccessCode) {
          res = await getStripePrice(advisorPriceID)
          setPriceToRender(res)
        }
      }

      const getAdvisorProfile = async () => {
        let res = await axios.get(`${URL}/advisor_profiles/${advisorID}`)
        setAdvisorProfile(res.data)
      }

      getAdvisorPrice();
      getAdvisorProfile()
    }

  }, [advisorID])

  const getNotesText1 = (ECInsight) => {
    if (ECInsight != null) {
      let notesText1 = ''

      if (ECInsight.insights && ECInsight.insights['Core Data'] && ECInsight.insights['Core Data'][0]) {

        if (ECInsight.insights['Core Data'][0].notesText1 != '') {
          notesText1 = ECInsight.insights['Core Data'][0].notesText1
        }
      }

      if (notesText1 != '') {
        return (
          <li className="mt-3">{notesText1}</li>
        )
      }
    }
  }

  const premiumInsightsPurchaseReturn = (
    <div className="d-flex flex-column" style={{ marginTop: '10px' }}>
      <div className="d-flex flex-row justify-content-between" style={{ fontSize: '26px' }}>
        <div>Premium Insights for {ecName}</div>
        <div style={{ marginLeft: '18px' }}><span className="align-top" style={{ fontSize: '12px' }}>$</span>{priceToRender}</div>
      </div>

      <ul className='d-flex flex-column'>
        <li className="mt-3">This premium content module provides detailed data about {ecName} based on proprietary analysis of {ecNumAlumni} participating alumni.</li>

        {getNotesText1(ECInsight)}

        <li className="mt-3">Content includes analysis of Admission Correlation which indicates the college that alumni for this program are now attending (or recently attended.) Data is provided per specific college, as well as as by groups (i.e. Ivy League, top 20 ranked colleges, etc.)</li>

        <li className="mt-3">
          Module also includes data on the field(s) of study for activity alumni based on their declared college major(s). Data is provided by major area (i.e. electrical engineering, neuroscience, etc.) and by major family (i.e. humanities, engineering, social sciences, etc.)</li>

        <li className="mt-3">
          This module also indicates the location (U.S. state or international) of alumni as well as their grade level when participating.  </li>

        {/* #484 Disable mentions of Inside Scoop content */}
        {/* {(advisorECReports.length > 0) && <li className="mt-3">The report also includes insider perspectives from {advisorECReports.length} former participant{advisorECReports.length == 1 ? '' : 's'} who share{advisorECReports.length == 1 ? 's' : ''} insights from their personal experiences with this activity.</li>} */}

        <li className="mt-3">
          These data-driven insights help to provide additional clarity on the prestige/impact and potential fit of {ecName}, which are important considerations as you build your extracurriculars profile.</li>

        <li className="mt-3">Purchase enables access for one calendar year and includes all updates for this content module published during that term. Content is limited to private use within your individual household per the <a className="dca-card-item-text" href={pageRoutes.termsOfService} target="_blank" onClick={(e) => e.stopPropagation()}>Terms Of Service</a>.</li>

      </ul>

      <div className="d-flex flex-row justify-content-between fw-bold">
        <a
          className="dca-card-item-text align-self-center"
          style={{ marginTop: '40px', padding: '10px', cursor: 'pointer', fontWeight: 400 }}
          onClick={() => handleECGAEventPurchasePreviewOpen(ecID)}
          data-bs-toggle="modal"
          data-bs-target="#premium_preview_modal">Preview Sample EC Activity Premium Insights Report</a>

        {(currentUser && currentUser.validatedEmail) ? <button
          className='fw-bold align-self-end'
          data-bs-target={`#add_payment_method_modal`}
          onClick={async () => {

            if (clientSecret != '') {
              await setClientSecret('')
            }

            await setUpgradeUserGroupAccessCode(`${purchaseID}${ecID}`)

            let ecPremiumPriceID = returnECPremiumPriceID(ecID)

            if (ecPremiumPriceID) {
              await fetch(`${URL}/create-checkout-session/${ecPremiumPriceID}`, {
                method: "POST",
              }).then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret)).catch((error) => {
                  console.error('Error:', error);
                });
            }

            handleECGAEventPurchaseModalPurchase(ecID)

          }}
          data-bs-toggle="modal"
          style={{
            borderRadius: '8px',
            background: selectedTabGreen,
            border: 'transparent',
            width: '110px',
            marginTop: '40px',
            padding: '10px'
          }}>Purchase</button> : emailVerificationRequiredButton} </div>
    </div>
  )

  const advisorConnectPurchaseReturn = (
    <div className="d-flex flex-column" style={{ marginTop: '10px' }}>
      <div className="d-flex flex-row justify-content-between" style={{ fontSize: '26px' }}>
        <div>Live Consultation With {advisorProfile.firstName ? advisorProfile.firstName : '-'}</div>
        <div style={{ marginLeft: '18px' }}><span className="align-top" style={{ fontSize: '12px' }}>$</span>{priceToRender}</div>
      </div>

      <div style={{ marginTop: '30px' }}>
        <ul>
          {AdvisorDescriptionArray.map((item, index) =>
            <li key={index} className="mt-3">{item}</li>)}
          <li className="mt-3">All advisor consultations are subject to our <a className="dca-card-item-text" id="terms-of-service-font-mobile" href={pageRoutes.termsOfService} target="_blank" onClick={(e) => e.stopPropagation()}>Terms Of Service</a>.</li>
        </ul>
      </div>

      {(currentUser && currentUser.validatedEmail) ? <button
        className='fw-bold align-self-end'
        data-bs-target={`#add_payment_method_modal`}
        onClick={async () => {

          handleAdvisorGAEventConsultPurchaseButtonClick(advisorProfile.id)

          if (clientSecret != '') {
            await setClientSecret('')
          }

          await setUpgradeUserGroupAccessCode(`${purchaseID}${advisorID}`)

          let advisorPriceID = returnAdvisorPriceID(advisorID)

          if (advisorPriceID) {
            await fetch(`${URL}/create-checkout-session/${advisorPriceID}`, {
              method: "POST",
            }).then((res) => res.json())
              .then((data) => setClientSecret(data.clientSecret)).catch((error) => {
                console.error('Error:', error);
              });
          }

          //// Create and connect advisor ga event purchase modal 
          // handleAdvisorGAEventPurchaseModalPurchase(advisorID)

        }}
        data-bs-toggle="modal"
        style={{ borderRadius: '8px', background: selectedTabGreen, border: 'transparent', width: '110px', marginTop: '40px', padding: '10px' }}>Purchase</button> : emailVerificationRequiredButton}
    </div>
  )

  const personalizedExpertHelpReturn = (
    <div className="d-flex flex-column" style={{ marginTop: '10px' }}>
      <div className="d-flex flex-row justify-content-between" style={{ fontSize: '26px' }}>
        <div>Personalized Expert Help</div>
        <div style={{ marginLeft: '18px' }}><span className="align-top" style={{ fontSize: '12px' }}>$</span>{priceToRender ? priceToRender : 'N/A'}</div>
      </div>

      <div style={{ marginTop: '30px' }}>
        <ul>
          {PersonalizedExpertHelpArray.map((item, index) =>
            <li key={index} className="mt-3">{item}</li>)}
          <li className="mt-3">All advisor consultations are subject to our <a className="dca-card-item-text" id="terms-of-service-font-mobile" href={pageRoutes.termsOfService} target="_blank" onClick={(e) => e.stopPropagation()}>Terms Of Service</a>.</li>
        </ul>
      </div>

      {(currentUser && currentUser.validatedEmail) ? <button
        className='fw-bold align-self-end'
        data-bs-target={`#add_payment_method_modal`}
        onClick={async () => {

          console.log('Request to purchase, set up stripe and go to checkout')

          // ga event ---
          // handleAdvisorGAEventConsultPurchaseButtonClick(advisorProfile.id)

          if (clientSecret != '') {
            await setClientSecret('')
          }

          // upgrade code ---
          await setUpgradeUserGroupAccessCode(personalizedExpertHelpProductID)

          // set up stripe checkout ---
          await fetch(`${URL}/create-checkout-session/${personalizedExpertHelpPriceID}`, {
            method: "POST",
          }).then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret)).catch((error) => {
              console.error('Error:', error);
            });

          // Create and connect advisor ga event purchase modal 
          // handleAdvisorGAEventPurchaseModalPurchase(advisorID)

        }}
        data-bs-toggle="modal"
        style={{ borderRadius: '8px', background: selectedTabGreen, border: 'transparent', width: '110px', marginTop: '40px', padding: '10px' }}>Purchase</button> : emailVerificationRequiredButton}
    </div>
  )

  const returnPurchaseContent = (purchaseID) => {

    switch (true) {
      case purchaseID === specializedRankingsAccessCode:
        return specializedRankingPurchaseReturn
      case purchaseID === rdSpendAccessCode:
        return rdPurchaseReturn
      case purchaseID === safetyAccessCode:
        return safetyPurchaseReturn
      case purchaseID === ecPremiumAccessCode:
        return premiumInsightsPurchaseReturn
      case purchaseID === advisorConnectAccessCode:
        return advisorConnectPurchaseReturn
      case purchaseID === personalizedExpertHelpPriceID:
        return personalizedExpertHelpReturn
    }

  }

  return (
    <div className="modal fade" id='purchase_modal'>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ minWidth: '75vw', maxWidth: '75vw', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto', maxHeight: '90vh' }}>
          {closeModalIcon('purchase_modal', reactGAPurchaseModalController(purchaseID, purchaseID === advisorConnectAccessCode ? advisorID : undefined))}

          {returnPurchaseContent(purchaseID)}
        </div>
      </div>
    </div>
  )
}
