import { useState, useEffect } from 'react'
import { useLocation } from 'react-router';
import Select from 'react-select'

const ECLocationSearch = ({ setLocationSelect, locationOptions, resetStateToggle }) => {

  const location = useLocation()
  const [selectedLocations, setSelectedLocations] = useState<any>([]);

  const handleLocationSelectChange = (selectedLocations) => {
    setSelectedLocations(selectedLocations);
    let locationArr: any = []
    selectedLocations.map((location) => {
      if (Array.isArray(location.value)) {
        location.value.forEach((loc) => locationArr.push(loc))
      }

      else {
        locationArr.push(location.value)
      }
    })
    setLocationSelect(locationArr)
  }

  const setChosenOptions = (locs, options) => {
    let selected: any[] = [];
    let selectedReduced: any[] = []

    locs.forEach((intStr) => {
      let optionObj: any = options.find((opt => opt.value?.some(res => res.toString() === intStr)));
      if (optionObj) {
        selected.push(optionObj);
      }
    });

    if (selected.length > 0) {
      selectedReduced = selected.filter((obj, index, self) => index === self.findIndex((res) => res.label === obj.label));
    }

    return selectedReduced;
  };

  useEffect(() => {
    setSelectedLocations([])
  }, [resetStateToggle])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let locationParam = params.get('location');
    let res: any[] = []

    if (locationParam) {
      const locationStrs = locationParam.split(',').map(loc => loc);
      res = setChosenOptions(locationStrs, locationOptions)
      handleLocationSelectChange(res)
    }

    else {
      setSelectedLocations([])
    }

  }, [location.search, locationOptions]);

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header' onClick={() => { console.log(locationOptions) }}>LOCATION</div>
      <Select
        options={locationOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 100
          })
        }}
        isMulti
        value={selectedLocations}
        onChange={handleLocationSelectChange}
      />
    </div>
  )
}

export { ECLocationSearch } 