import Select from 'react-select'
import { titleSpace } from '../../dictionary'
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { AdvisorsReactGAHandlers } from '../../ReactGAHandlers';

const Major = ({ cip, setMajorSelect, resetStateToggle }) => {

  const location = useLocation()
  const [selectedMajors, setSelectedMajors] = useState<any>([]);
  const [options, setOptions] = useState<any>([])

  const { handleAdvisorGAEventFieldOfStudyFilter } = AdvisorsReactGAHandlers()

  const handleMajorSelectChange = (majorSelect) => {
    setSelectedMajors(majorSelect);
    setMajorSelect(majorSelect)
  }

  useEffect(() => {
    setSelectedMajors([])
  }, [resetStateToggle])

  useEffect(() => {
    let optionsArr: any = []

    cip.map((res) => {
      optionsArr.push({ value: res.code, label: res.name })
    })

    setOptions(optionsArr)
  }, [cip])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let majorParams = params.get('fieldOfStudy');
    let res: any[] = []

    if (majorParams) {
      const majors = majorParams.split(',')
      majors.map((val) => {
        let majorOption = options.find((obj => obj.value === (val)))
        if (majorOption) {
          res.push(majorOption)
        }
      })
      console.log('res', res)
      handleMajorSelectChange(res)
    }

    else {
      setMajorSelect([])
    }

  }, [location.search, options]);

  if (!cip) {
    return <div>Loading Major Search ... </div>
  }

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='fw-bold' style={{ letterSpacing: titleSpace }}>FIELD OF STUDY</div>
      <div onClick={() => {
        handleAdvisorGAEventFieldOfStudyFilter()
      }}>
        <Select
          options={options}
          isMulti
          value={selectedMajors}
          onChange={handleMajorSelectChange}
        />
      </div>
    </div>
  )
}

export { Major }