import { LoadingSpinner } from "../../LoadingSpinner";
import Chart from 'react-apexcharts'

const DistributionByGrade = ({ result, returnAgeGroupByID, mobile = false }) => {

  //When result is not set, inform that it is loading
  if (!result || !result.insights || !result.insights['Age Data']) {
    return <LoadingSpinner title={'Distribution by Grade'} />;
  }

  let ageData: any = result.insights['Age Data']

  //Sort Individual Major Data
  ageData.sort((a, b) => b.number - a.number)

  // Set treemap data
  let ageGroupValues = { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0 }
  let chartData: any = [0, 0, 0, 0, 0, 0]
  ageData.forEach(res => {
    ageGroupValues[res.ageGroupID] = parseInt(res.number)
  });
  chartData = [ageGroupValues["1"], ageGroupValues["2"], ageGroupValues["3"], ageGroupValues["4"], ageGroupValues["5"], ageGroupValues["6"]]

  return (
    <div style={{ marginTop: '40px' }}>
      <div className='card shadow'>
        <div className='dca-font-M' style={{ fontSize: '26px', padding: '40px 40px 0px 40px' }}>Grade Level Of Activity Alumni When Participating</div>
        <div style={{ marginLeft: '40px', marginRight: '40px', marginBottom: '40px' }}>
          <Chart
            options={{
               chart: {
                id: 'Distribution by Grade',
			  toolbar: {
				show: false,
				},
            },
              xaxis: {
                categories: [returnAgeGroupByID(Object.keys(ageGroupValues)[0]), returnAgeGroupByID(Object.keys(ageGroupValues)[1]), returnAgeGroupByID(Object.keys(ageGroupValues)[2]), returnAgeGroupByID(Object.keys(ageGroupValues)[3]), returnAgeGroupByID(Object.keys(ageGroupValues)[4]), returnAgeGroupByID(Object.keys(ageGroupValues)[5])],
                labels: {
                  //rotate: mobile ? -40 : undefined,
                  rotate: -45,
                }
              },
              yaxis: {
                min: 0,
                max: (Math.max(...chartData) * 1.15),
                labels: {
                  style: {
                    colors: ['#9AA2B6'],
                  }
                }
              },
              colors: ["#90F892"],
              tooltip: {
                enabled: false,
              },
              dataLabels: {
                enabled: true,
                style: {
                  colors: ['#000000'],
                },
                background: {
                  borderWidth: 0,
                  foreColor: '#000',
                }
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  borderRadius: 5,
                  borderRadiusApplication: 'end',
                  dataLabels: {
                    position: 'top',
                  },
                }
              }
            }}
            series={[
              {
                name: 'Distribution by Grade',
                data: chartData
              }
            ]}
            type='bar'
            width={'100%'}
            height={'550'}
          />
        </div>
      </div>
    </div>
  )

}

export { DistributionByGrade }
