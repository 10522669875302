import { relatedInterestAreas, returnArrayOfValues, schoolLevel } from '../../dictionary'
import { collegeMap } from '../../collegeMap'

const ActivityInformation = ({ ec, mobile = false }) => {

  const gradesArray = [
    { 1: '1st' },
    { 2: '2nd' },
    { 3: '3rd' },
    { 4: '4th' },
    { 5: '5th' },
    { 6: '6th' },
    { 7: '7th' },
    { 8: '8th' },
    { 9: '9th/Rising Sophmore' },
    { 10: '10th/Rising Junior' },
    { 11: '11th/Rising Senior' },
    { 12: '12th' }]

  const formatResults = (inputs, array) => {

    let res: any = returnArrayOfValues(inputs, array)
    let resB: any = []
    res.forEach((interest, index) => {
      if (index !== res.length - 1) {
        resB.push(`${interest}, `)
      }

      else {
        resB.push(interest)
      }
    });

    return resB
  }

  const agesReturn = (input) => {

    if (input == null) {
      return '-'
    }

    else {
      let res: any = []

      input.map((inp, index) => {
        if (index !== input.length - 1) {
          res.push(`${inp}, `)
        }

        else {
          res.push(inp)
        }
      })

      return res

    }
  }

  const booleanReturn = (input) => {
    if (input !== null) {
      return input === true ? 'Yes' : 'No'
    }

    else {
      return '-'
    }
  }

  const sponsorReturn = (input) => {
    if (input !== null && collegeMap) {
      let res: any = collegeMap.filter((item) => item.id == input)

      if (res.length > 0) {
        return res[0].name
      }

      else {
        return '-'
      }

    }

    else {
      return '-'
    }
  }

  const desktopInnerProgramDetails = (
    <div className='d-flex flex-row' style={{ padding: '20px 40px 40px 40px' }}>

      <div style={{ width: '45%' }}>
        <div>
          <div>SPONSOR</div>
          <div>{sponsorReturn(ec.sponsorCollegeID)}</div>
        </div>

        <div style={{ marginTop: '25px' }}>
          <div>RELATED INTEREST AREAS</div>
          <div>{ec.relatedInterestAreas ? formatResults(ec.relatedInterestAreas, relatedInterestAreas) : '-'}</div>
        </div>

        <div style={{ marginTop: '25px' }}>
          <div>PROGRAM SIZE</div>
          <div>{ec.size ? ec.size : '-'}</div>
        </div>

        <div style={{ marginTop: '25px' }}>
          <div>COLLEGE CREDIT:</div>
          <div>{booleanReturn(ec.collegeCredit)}</div>
        </div>

        <div style={{ marginTop: '25px' }}>
          <div>YEAR STARTED:</div>
          <div>{ec.yearStarted ? ec.yearStarted : '-'}</div></div>

      </div>

      <div style={{ width: '45%', marginLeft: '20px' }}>
        <div>
          <div>TRACKS</div>
          <div>{ec.tracks ? ec.tracks : '-'}</div>
        </div>

        <div style={{ marginTop: '25px' }}>
          <div>TARGETS DISADVANTAGED GROUPS:</div>
          <div>{booleanReturn(ec.targetsGroups)}</div>
        </div>

        <div style={{ marginTop: '25px' }}>
          <div>WEBSITE</div>
          <a href={ec.homepageURL} target="_blank">Link</a>
        </div>

        <div style={{ marginTop: '25px' }}>
          <div>MORE INFO</div>
          {ec.moreInfoURL ? <a href={ec.moreInfoURL} target="_blank">Link</a> : <div>-</div>}
        </div>

        <div style={{ marginTop: '25px' }}>
          <div>COURSE CATALOG</div>
          {ec.courseCatalogURL ? <a href={ec.courseCatalogURL} target="_blank">Link</a> : <div>-</div>}
        </div>
      </div>
    </div>
  )

  const mobileInnerProgramDetails = (
    <div className='d-flex flex-column' style={{ padding: '20px 40px 40px 40px' }}>

      <div>
        <div>SPONSOR</div>
        <div>{sponsorReturn(ec.sponsorCollegeID)}</div>
      </div>

      <div style={{ marginTop: '25px' }}>
        <div>RELATED INTEREST AREAS</div>
        <div>{ec.relatedInterestAreas ? formatResults(ec.relatedInterestAreas, relatedInterestAreas) : '-'}</div>
      </div>

      <div style={{ marginTop: '25px' }}>
        <div>PROGRAM SIZE</div>
        <div>{ec.size ? ec.size : '-'}</div>
      </div>

      <div style={{ marginTop: '25px' }}>
        <div>COLLEGE CREDIT:</div>
        <div>{booleanReturn(ec.collegeCredit)}</div>
      </div>

      <div style={{ marginTop: '25px' }}>
        <div>YEAR STARTED:</div>
        <div>{ec.yearStarted ? ec.yearStarted : '-'}</div>
      </div>

      <div style={{ marginTop: '25px' }}>
        <div>TRACKS</div>
        <div>{ec.tracks ? ec.tracks : '-'}</div>
      </div>

      <div style={{ marginTop: '25px' }}>
        <div>TARGETS DISADVANTAGED GROUPS:</div>
        <div>{booleanReturn(ec.targetsGroups)}</div>
      </div>

      <div style={{ marginTop: '25px' }}>
        <div>WEBSITE</div>
        <a href={ec.homepageURL} target="_blank">Link</a>
      </div>

      <div style={{ marginTop: '25px' }}>
        <div>MORE INFO</div>
        {ec.moreInfoURL ? <a href={ec.moreInfoURL} target="_blank">Link</a> : <div>-</div>}
      </div>

      <div style={{ marginTop: '25px' }}>
        <div>COURSE CATALOG</div>
        {ec.courseCatalogURL ? <a href={ec.courseCatalogURL} target="_blank">Link</a> : <div>-</div>}
      </div>
    </div>
  )

  const programDetails = (
    <div style={{ marginTop: '40px' }}>
      <div className='fw-bold card shadow d-flex flex-column'>

        <p style={{ fontSize: '26px', paddingTop: '30px', paddingLeft: '25px' }}>Program Details</p>

        {mobile ? mobileInnerProgramDetails : desktopInnerProgramDetails}
      </div>
    </div>
  )

  const desktopInnerEligibility = (<div className='d-flex flex-row' style={{ padding: '20px 40px 40px 40px' }}>

    <div style={{ width: '45%' }}>
      <div>
        <div>LEVEL</div>
        <div>{ec.schoolLevel ? formatResults(ec.schoolLevel, schoolLevel) : '-'}</div>
      </div>

      <div style={{ marginTop: '25px' }}>
        <div style={{ marginTop: '20px' }}>ELIGIBLE AGES</div>
        <div>{agesReturn(ec.eligibleAges)}</div>
      </div>
    </div>

    <div style={{ width: '45%', marginLeft: '20px' }}>
      <div>
        <div>ELIGIBLE GRADES</div>
        <div>{ec.eligibleGrades ? formatResults(ec.eligibleGrades, gradesArray) : '-'}</div>
      </div>

      <div style={{ marginTop: '25px' }}>
        <div style={{ marginTop: '20px' }}>INTERNATIONAL STUDENTS</div>
        <div>{booleanReturn(ec.acceptsIntls)}</div>
      </div>
    </div>
  </div>)

  const mobileInnerEligibility = (<div className='d-flex flex-column' style={{ padding: '20px 40px 40px 40px' }}>

    <div>
      <div>LEVEL</div>
      <div>{ec.schoolLevel ? formatResults(ec.schoolLevel, schoolLevel) : '-'}</div>
    </div>

    <div style={{ marginTop: '25px' }}>
      <div>ELIGIBLE AGES</div>
      <div>{agesReturn(ec.eligibleAges)}</div>
    </div>

    <div style={{ marginTop: '25px' }}>
      <div>ELIGIBLE GRADES</div>
      <div>{ec.eligibleGrades ? formatResults(ec.eligibleGrades, gradesArray) : '-'}</div>
    </div>

    <div style={{ marginTop: '25px' }}>
      <div>INTERNATIONAL STUDENTS</div>
      <div>{booleanReturn(ec.acceptsIntls)}</div>
    </div>
  </div>)

  const eligibility = (
    <div style={{ marginTop: '40px' }}>
      <div className='fw-bold card shadow d-flex flex-column'>

        <p style={{ fontSize: '26px', paddingTop: '30px', paddingLeft: '25px' }}>Eligibility</p>

        {mobile ? mobileInnerEligibility : desktopInnerEligibility}
      </div>
    </div>
  )

  return (
    <div>
      {programDetails}
      {eligibility}
    </div>
  )
}

export { ActivityInformation }