import { useEffect } from "react"
import { useLocation } from "react-router"

const MajorOnlyInterests = ({ onlyInterestsSelect, setOnlyInterestsSelect, resetStateToggle }) => {

  const location = useLocation()

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  useEffect(() => {
    setOnlyInterestsSelect(false)
  }, [resetStateToggle])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let onlyInterestsParam = params.get('onlyInterests');

    if (onlyInterestsParam) {
      onlyInterestsParam == 'true' ? setOnlyInterestsSelect(true) : setOnlyInterestsSelect(false)
    }

  }, [location.search]);

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>INTEREST LIST</div>
      <div className='d-flex flex-column'>
        <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

          <label className="d-flex justify-content-between" style={{ cursor: 'pointer' }}>
            <div>
              <input
                type="checkbox"
                className='dca-checkbox'
                name="Show My Interests"
                checked={onlyInterestsSelect}
                onChange={(event) => (setOnlyInterestsSelect(handleCheckChange(event)))}
                style={{ cursor: 'pointer' }}
              />
              <span className='ms-2 me-2'>Show Only Saved Majors</span>
            </div>
          </label>

        </div>
      </div>
    </div>
  )
}

export { MajorOnlyInterests }