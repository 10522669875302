import { useState, useEffect } from "react";
import { LoadingSpinner } from "../LoadingSpinner"
import { gray, pageRoutes, selectedTabGreen, URL, separatorReturn } from "../dictionary"
import { useNavigate } from "react-router-dom";
import Select from 'react-select'
import axios from "axios";

const returnColor = (value) => {
  switch (value) {
    case 1:
      return gray
    case 2:
      return selectedTabGreen
    case 3:
      return 'rgb(224,248,255)'
    case 4:
      return 'rgb(245,242,255)'
    default:
      return gray
  }
}
const options = [
  { value: 1, label: 'Indicate Fit', icon: <i className="bi bi-chevron-compact-down"></i> },
  { value: 2, label: 'Reach', icon: <i className="bi bi-chevron-compact-down"></i> },
  { value: 3, label: 'Target', icon: <i className="bi bi-chevron-compact-down"></i> },
  { value: 4, label: 'Safety', icon: <i className="bi bi-chevron-compact-down"></i> }
]

const returnLabel = (fitVal) => {
  switch (fitVal) {
    case 1:
      return 'Indicate Fit'
    case 2:
      return 'Reach'
    case 3:
      return 'Target'
    case 4:
      return 'Safety'
    default:
      return 'Indicate Fit'
  }
}

const selectStyles = (fitVal) => {
  return {
    control: (provided, state) => ({
      ...provided,
      border: 'transparent',
      borderRadius: '10px',
      background: returnColor(fitVal)
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none'
    })
  }
}

const CollegesOfInterest = ({ user, setUser, userCollegeInterest, setUserCollegeInterest, collegesId, userData, mobile = false }) => {

  let navigate = useNavigate();

  const [saveError, setSaveError] = useState('')

  const returnCollegeName = (collegeId) => {
    return (collegeId && collegesId) ? collegesId.find((item) => item.id == collegeId).name : '-'
  }

  const handleRemoveCollege = async (userId, collegeId) => {
    try {
      const collegeObjectToRemove = userCollegeInterest.find((item) => item.college_id == collegeId && item.user_profile_id == userId)

      const pk = collegeObjectToRemove.id

      const del = await axios.delete(`${URL}/user_college_interest/delete/${pk}`)

      console.log('College removed from list,', del.data)

      const res = await axios.get(`${URL}/user_college_interest`)

      res.data.filter((item) => item.user_profile_id === userData.user_id)

      setUserCollegeInterest(res.data.filter((item) => item.user_profile_id === userData.user_id))

      console.log('User college list retrieved,', res.data)
    } catch (error) {
      console.log(error)
      setSaveError(`Cannot remove ${returnCollegeName(collegeId)} at this time. Try again later.`)

      throw (error)
    }
  }

  const handleFitSelect = async (selectedOption, pk) => {

    try {
      const patch = await axios.patch(`${URL}/user_college_interest/${pk}`, { fit: selectedOption.value })

      console.log('Patch successful:', patch.data)

      const res = await axios.get(`${URL}/user_college_interest`)

      res.data.filter((item) => item.user_profile_id === userData.user_id)

      setUserCollegeInterest(res.data.filter((item) => item.user_profile_id === userData.user_id))

      console.log('User college list retrieved,', res.data)

    } catch (error) {
      console.log(error)
      alert(`Encountered an error updating the college fit value. Please try again later.`)
      throw (error)
    }

  }

  return userData && userCollegeInterest ? (

    <div className="dca-userProfile-form-card" style={{ width: '100%' }}>
      <div className={mobile ? 'd-flex flex-column' : 'd-flex flex-row justify-content-between align-items-center'}>
        <div className="dca-userProfile-form-title">Colleges of Interest</div>
        {mobile ? (
          <div
            className='dca-college-search-resources-links'
            style={{ paddingLeft: '35px', paddingBottom: '10px' }}
            onClick={() => navigate(pageRoutes.collegeSearch)}
          >Add Colleges</div>) : <button
            type="button"
            style={{ marginRight: '25px' }}
            className='dca-userProfile-save-changes-button'
            onClick={() => navigate(pageRoutes.collegeSearch)}
          >
          Add Colleges
        </button>}
      </div>
      <div className="separator"></div>

      <div className="d-flex justify-content-center">
        {saveError != '' && <span style={{ margin: '15px 15px', color: 'red' }}>{saveError}</span>}
      </div>

      <div
        className="d-flex flex-wrap">
        {userCollegeInterest.map((res, index) =>
          <div
            key={index}
            style={{ width: mobile ? '100%' : '50%' }}>
            <div className="dca-userProfile-interest-field">
              <div>{returnCollegeName(res.college_id)}</div>

              <div className="d-flex flex-row">
                <Select
                  value={{ value: res.fit, label: returnLabel(res.fit) }}
                  options={options}
                  onChange={(selectedOption) =>
                    handleFitSelect(selectedOption, res.id)
                  }
                  styles={selectStyles(res.fit)} />
                <i className="bi bi-x align-self-center" style={{ marginLeft: '10px', cursor: 'pointer' }}
                  onClick={() => {
                    handleRemoveCollege(res.user_profile_id, res.college_id)
                  }}></i>
              </div>

            </div>

            <div className={separatorReturn(index, userCollegeInterest)} style={{ width: '95%' }}></div>
          </div>
        )}
      </div>

    </div >
  ) : <LoadingSpinner title={'Colleges of Interest'} />
}

export { CollegesOfInterest }