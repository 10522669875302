import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getStripePrice, pageRoutes, personalizedExpertHelpPriceID, updateTitleAndDescription, URL } from "../../components/dictionary"
import { StickyNavbar } from "../../components/Marketing/StickyNavbar";
import { getAuth } from '../../modules/auth';
import { ConnectEmailCTA } from '../../components/Marketing/ConnectEmailCTA';
import { Testimonials } from '../../components/Marketing/Testimonials';
import { FAQ } from '../../components/Marketing/FAQ';
import { MarketingImageWrapper } from '../../components/Marketing/ImageWrappers';
import { AuthReactGAHandlers } from '../../components/ReactGAHandlers';
import axios from 'axios';

import photo1 from '../../../custom_assets/marketing/personalized_expert_help/photo_1.png'
import photo2 from '../../../custom_assets/marketing/personalized_expert_help/photo_2.png'
import photo3 from '../../../custom_assets/marketing/personalized_expert_help/photo_3.png'
import photo4 from '../../../custom_assets/marketing/personalized_expert_help/photo_4.png'

import mobilePhoto1a from '../../../custom_assets/marketing/personalized_expert_help/mobile/photo_1a.png'
import mobilePhoto1b from '../../../custom_assets/marketing/personalized_expert_help/mobile/photo_1b.png'
import { PurchaseButtonPersonalizedExpertHelp, PurchaseButtonPersonalizedExpertHelpNav } from '../../components/Marketing/PurchaseButton';

const MarketingPersonalizedExpertHelp = ({ user, setNavigateTo, setPurchaseID }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  const navigate = useNavigate()

  const [premiumContentPrice, setPremiumContentPrice] = useState<any>(undefined)
  const [latestPurchaseDate, setLatestPurchaseDate] = useState<any>(undefined)
  const [prevPurchaseCount, setPrevPurchaseCount] = useState<any>(undefined)

  const getPrice = async () => {
    let price = await getStripePrice(personalizedExpertHelpPriceID)
    price ? setPremiumContentPrice(price) : setPremiumContentPrice(undefined)
  }

  const faqData = [
    { question: 'How does this feature work?', answer: 'This personalized Q&A feature provides access to NextFour experts who will answer your specific questions relating to the college admissions process. To optimize for convenience and efficiency, communication is done through email. After completing a purchase, you will receive a dedicated email address to send your question and should expect to receive a response within 24 hours.' },
    { question: 'What is the scope of this service?', answer: 'Users of this service are able to ask a question (or a few questions related to the same topic area) and will receive a personalized response from a NextFour staff member (an actual human!) with relevant expertise. Typical topic areas include high school academics, extracurriculars, colleges, intended majors, standardized test scores, general application strategy, etc.' },
    { question: `Why should I trust NextFour?`, answer: `NextFour is commited to helping high school families be better informed, and therefore more confident and effective in their decision-making, as they navigate the increasingly complex college admissions process. We take an innovative data-intensive approach (as reflected throughout our website) and offer this Q&A service to provide an incremental level of personalized support. We are confident that NextFour's collective data and expertise will help you and offer a money-back guarantee if not satisfied with your experience.` }
  ]

  const testimonialData = [
    { statement: `“Getting to a strong college application by senior year really comes down to making good, strategic decisions along the way...it was super helpful to get NextFour's guidance on some key questions to help in our decision-making.”`, source: 'Simone', relation: 'Parent of college students' },
    { statement: `“I did a ton of research myself, but sometimes I still had a hard time making sense of everything. This NextFour Q&A service was by far the easiest and least expensive path I've found to get expert, trustworthy help with my college planning process.”`, source: 'Anya', relation: 'High school student' }
  ]

  useEffect(() => {
    updateTitleAndDescription(pageRoutes.personalizedExpertHelp)

    getPrice()
  }, [])

  useEffect(() => {
    const fetchUserPurchases = async () => {
      if (!user) {
        setLatestPurchaseDate(undefined)
        setPrevPurchaseCount(undefined)
      }

      else {
        try {
          let res = await axios.get(`${URL}/user_transactions/${user.user_id}`);

          let purchases = res.data.filter(item => item.productID == personalizedExpertHelpPriceID) || []

          let updatedPurchases = await Promise.all(
            purchases.map(async (purchase) => {

              purchase.purchaseDateTime = new Date(purchase.purchaseDateTime);
              let month = purchase.purchaseDateTime.getMonth() + 1;
              let day = purchase.purchaseDateTime.getDate();
              let year = purchase.purchaseDateTime.getFullYear();

              month = month < 10 ? `0${month}` : month;
              day = day < 10 ? `0${day}` : day;

              purchase.purchaseDateTime = `${month}/${day}/${year}`;

              return purchase;
            })
          );

          setLatestPurchaseDate(updatedPurchases[updatedPurchases.length - 1].purchaseDateTime)
          setPrevPurchaseCount(updatedPurchases.length)
        } catch (error) {
          console.error('Error fetching user purchases:', error);
        }
      }
    };

    fetchUserPurchases();
  }, [user]);

  // const authButton = (nav = false) => {
  //   return <button
  //     type="button"
  //     data-bs-toggle="modal"
  //     data-bs-target="#purchase_modal"
  //     className={nav ? `btn btn-sm  dca-marketing-access-duration-purchase-button-nav dca-marketing-access-duration-purchase-button-nav-text` : `btn btn-sm  dca-marketing-access-duration-purchase-button dca-marketing-access-duration-purchase-button-text`}
  //     style={{ margin: nav ? '0px 15px 0px 0px' : '50px 0px 140px 0px', cursor: 'pointer', padding: nav ? '10px 12px' : '' }}
  //     onClick={(e) => {
  //       e.stopPropagation();
  //       setPurchaseID(personalizedExpertHelpPriceID)
  //     }}>Purchase
  //   </button>
  // }

  // const guestButton = (nav = false) => {
  //   return <button
  //     type="button"
  //     data-bs-toggle="modal"
  //     data-bs-target="#login_modal"
  //     className={nav ? `btn btn-sm dca-marketing-access-duration-purchase-button-nav dca-marketing-access-duration-purchase-button-nav-text` : `btn btn-sm  dca-marketing-access-duration-purchase-button dca-marketing-access-duration-purchase-button-text`}
  //     style={{ margin: nav ? '0px 15px 0px 0px' : '50px 0px 140px 0px', cursor: 'pointer', padding: nav ? '10px 12px' : '' }}
  //     onClick={(e) => {
  //       e.stopPropagation();
  //       setNoAuthPersonalizedExpertHelp(setPrompt, setNavigateTo);
  //       handleGAEventSignInModalOpen('forced');
  //     }}
  //   >Purchase
  //   </button>
  // }

  const marketingContent = (
    <>
      <div className="dca-marketing-page-headline" style={{ marginTop: '59px' }}>Get personalized help to support your journey towards college</div>

      <div className="dca-marketing-sub-headline">We recognize that planning for college admissions can be challenging, and so we've made it convenient and cost-effective to get personalized advice in near real-time</div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-block'>
        <MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={photo1} />
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-block d-md-none'>
        <MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={mobilePhoto1a} />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={mobilePhoto1b} />
      </div>

      {/* {(apiToken != undefined && user?.emailAddress) ?
        authButton() : guestButton()} */}

      {premiumContentPrice && <PurchaseButtonPersonalizedExpertHelp
        user={user}
        priceID={personalizedExpertHelpPriceID}
        setPurchaseID={setPurchaseID}
        premiumContentPrice={premiumContentPrice}
        setNavigateTo={setNavigateTo}
        topMargin={'50px'}
        bottomMargin={'140px'}
        prevPurchaseCount={prevPurchaseCount}
        latestPurchaseDate={latestPurchaseDate}
      />}

      <div className='dca-marketing-text-image-block' id='TIB-175'>
        <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '55px' }}>
          <div className="dca-marketing-pt-header">Receive strategic guidance from NextFour's expert staff in near real-time</div>
          <div className="dca-marketing-pt-body">NextFour experts leverage our proprietary data analytics platform to provide data-driven strategic responses to your pressing questions. Responses delivered via email within 24 hours to seamlessly support your decision-making.</div>
        </div>

        <div style={{ width: '90%' }}>
          <MarketingImageWrapper
            altText={'photoSpecializedRankings'}
            photo={photo2}
          />
        </div>
      </div>

      <div className='dca-marketing-text-image-block' id='TIB-120'>
        <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '5px' }}>
          <div className="dca-marketing-pt-header">Get input on the key decisions you encounter through high school</div>
          <div className="dca-marketing-pt-body">The most successful college applications typically reflect thoughtful planning and strategic decision-making throughout the high school years. NextFour's experts will answer your questions about high school course selection, selecting impactful extracurriculars, building a balanced college list, identifying intended majors, and more.</div>
        </div>

        <div className='d-none d-md-block' style={{ width: '57vw', marginTop: '20px' }}>
          <MarketingImageWrapper
            altText={'photoSpecializedRankings'}
            photo={photo3}
          />
        </div>

        <div className='d-block d-md-none' style={{ width: '80vw', marginTop: '20px' }}>
          <MarketingImageWrapper
            altText={'photoSpecializedRankings'}
            photo={photo3}
          />
        </div>
      </div>

      <div className='dca-marketing-text-image-block' id='TIB-25'>
        <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '25px' }}>
          <div className="dca-marketing-pt-header">Leverage the extensive experience and insights provided by NextFour experts</div>
          <div className="dca-marketing-pt-body">The NextFour expert community has helped support high school students who successfully received admission to the top-ranked US colleges and we utilize this knowledge base to help strengthen your application profile.</div>
        </div>

        <div className='d-none d-md-block' style={{ width: '54vw' }}>
          <MarketingImageWrapper
            altText={'photoSpecializedRankings'}
            photo={photo4}
          />
        </div>

        <div className='d-block d-md-none' style={{ width: '80vw' }}>
          <MarketingImageWrapper
            altText={'photoSpecializedRankings'}
            photo={photo4}
          />
        </div>

        {/* <div className='dca-marketing-disclaimer-text'>Note: Images above are meant to be illustrative only and do not represent the actual data for any specific college.</div> */}
      </div>


      <FAQ faqData={faqData} />

      <ConnectEmailCTA />

      <Testimonials data={testimonialData} />

      {/* {(apiToken != undefined && user?.emailAddress) ?
        authButton() : guestButton()} */}

      {premiumContentPrice && <PurchaseButtonPersonalizedExpertHelp
        user={user}
        priceID={personalizedExpertHelpPriceID}
        setPurchaseID={setPurchaseID}
        premiumContentPrice={premiumContentPrice}
        setNavigateTo={setNavigateTo}
        topMargin={'0px'}
        bottomMargin={'0px'}
        prevPurchaseCount={prevPurchaseCount}
        latestPurchaseDate={latestPurchaseDate}
      />}

      {/* <HoverCards cardsOption='SpecializedRankings' /> */}
    </>
  )

  // return (<div className="d-flex flex-column align-items-center">
  //   <StickyNavbar user={user} purchaseButton={(apiToken != undefined && user?.emailAddress) ?
  //     authButton(true) : guestButton(true)} advisorNetworkNav={true} />
  //   {marketingContent}
  // </div>)

  return (<div className="d-flex flex-column align-items-center">
    <StickyNavbar user={user} purchaseButton={premiumContentPrice && <PurchaseButtonPersonalizedExpertHelpNav
      user={user}
      priceID={personalizedExpertHelpPriceID}
      setPurchaseID={setPurchaseID}
      setNavigateTo={setNavigateTo}
    />} />
    {marketingContent}
  </div>)
}

export { MarketingPersonalizedExpertHelp }