import { useState, useEffect } from 'react'
import axios from 'axios'
import { advisorJPGs, URL, returnItemFromProductID, updateTitleAndDescription, pageRoutes, getStripePrice, titleSpace, selectedTabGreen, advisorConnectAccessCode, advisorPriceIDs, convertDateFormat } from '../components/dictionary'
import avatar from "../../custom_assets/avatar.png"
import { Link, useParams, useNavigate } from 'react-router-dom'
import { ConnectButtonNoAuth } from '../components/SaveButton'
import { getAuth } from '../modules/auth'
import { AdvisorDetailTabContent } from '../components/AdvisorDetail/AdvisorDetailTabContent'
//Testing User image
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { LoadingIndicator } from '../components/LoadingIndicator'
import { AdvisorsReactGAHandlers } from '../components/ReactGAHandlers'
import { returnParameterString, concatLocation } from '../components/searchPageURLManager'
import { collegeMap } from '../components/collegeMap'

const AdvisorDetailPage = ({ user, setPrompt, setNavigateTo, setPurchaseID, setAdvisorID, advisorSearchLocation }) => {

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const { advisorId } = useParams()
  const {
    handleGAEventPurchaseModalOpen,
    handleAdvisorGAEventDetailConnectClick,
    handleAdvisorGAEventSelectProfileInfo,
    handleAdvisorGAEventSelectQualifications,
    handleAdvisorGAEventSelectServices
  } = AdvisorsReactGAHandlers()
  const navigate = useNavigate()
  const [advisor, setAdvisor] = useState<any>([])
  const [majors, setMajors] = useState<any>([])
  const [userPurchases, setUserPurchases] = useState<any>([])
  const [loadingComplete, setLoadingComplete] = useState(false)

  const [collegeParams, setCollegeParams] = useState('')
  const [fieldOfStudyParams, setFieldOfStudyParams] = useState('')
  const [extracurricularsParams, setExtracurricularsParams] = useState('')
  const [sortParams, setSortParams] = useState('')

  const [includeCollege, setIncludeCollege] = useState(false)
  const [includeField, setIncludeField] = useState(false)
  const [includeEC, setIncludeEC] = useState(false)

  useEffect(() => {

    const getMajors = async () => {
      let res = await axios.get(`${URL}/majors/`)
      setMajors(res.data)
    }

    const getAdvisor = async () => {
      let res = await axios.get(`${URL}/advisor_profiles/${advisorId}`)
      setAdvisor(res.data)
    };

    try {

      if (loadingComplete == true) {
        setLoadingComplete(false)
      }

      getMajors();
      getAdvisor();

    } catch (error) {

      console.log('Error loading Advisor Detail data', error);
      throw error;

    } finally {

      setLoadingComplete(true)

    }

  }, [])

  useEffect(() => {

    let query = advisorSearchLocation.split('?')[1]
    let params = new URLSearchParams(query)

    let collegeQuery = params.get('college')
    let fieldOfStudyQuery = params.get('fieldOfStudy')
    let extracurricularsQuery = params.get('extracurriculars')
    let sortQuery = params.get('sort')

    if (collegeQuery) {
      setCollegeParams(collegeQuery)
    }

    if (fieldOfStudyQuery) {
      setFieldOfStudyParams(fieldOfStudyQuery)
    }

    if (extracurricularsQuery) {
      setExtracurricularsParams(extracurricularsQuery)
    }

    if (sortQuery) {
      setSortParams(sortQuery)
    }

  }, [advisorSearchLocation])

  const customBackRoute = () => {

    let paramsArray: string[] = []

    if (collegeParams != '' && includeCollege) {
      paramsArray.push(`college=${collegeParams}`)
    }

    if (fieldOfStudyParams != '' && includeField) {
      paramsArray.push(`fieldOfStudy=${fieldOfStudyParams}`)
    }

    if (extracurricularsParams != '' && includeEC) {
      paramsArray.push(`extracurriculars=${extracurricularsParams}`)
    }

    paramsArray.push(`sort=${sortParams}`)

    // Set the location string using the base pageRoute and the return from the concat location function
    navigate(`${pageRoutes.advisorSearch}${concatLocation(paramsArray)}`)

  }

  const collegeReturn = (id) => {
    if (!id) {
      return '-'
    }

    let collegeName = collegeMap.filter((item) => item.id == id)[0]?.name

    return collegeName ? collegeName : '-'
  }

  const majorReturn = (id, arr, advisor) => {
    let res: any = []

    if (!id || !arr) {
      return (advisor && advisor.majors_text) ? advisor.majors_text : '-'
    }

    else if (id && id.length == 1 && arr) {
      return arr.filter((item) => item.id == id)[0]?.name ? `${arr.filter((item) => item.id == id)[0].name}` : '-'
    }

    else if (id && id.length > 1 && arr) {

      id.map((i, index) => {
        let majorName = arr.filter((item) => item.id == i)[0]?.name

        if (index === id.length - 1 && majorName) {
          res.push(majorName)
        }
        else if (majorName) {
          res.push(`${majorName}, `)
        }
      })
    }

    return res
  }

  const dateReturn = (input) => {
    if (!input) {
      return '-'
    }

    const date = new Date(input)

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    let year = date.getFullYear();
    let month = months[date.getMonth()]

    return `${month} ${year}`
  }

  const tabOptions = ['Profile Info', 'Advisor Qualifications', 'Advisory Services']
  const [selectedTab, setSelectedTab] = useState(tabOptions[0])

  const handleSelectedTab = (val, mobile = false) => {

    switch (val) {
      case tabOptions[0]:
        handleAdvisorGAEventSelectProfileInfo(advisorId)
        break;
      case tabOptions[1]:
        handleAdvisorGAEventSelectQualifications(advisorId)
        break;
      case tabOptions[2]:
        handleAdvisorGAEventSelectServices(advisorId)
        break;
    }
    setSelectedTab(val)
  }

  const handleSelectedTabMobile = (event) => {

    let selectedTab = event?.target.value
    switch (selectedTab) {
      case tabOptions[0]:
        handleAdvisorGAEventSelectProfileInfo(advisorId)
        break;
      case tabOptions[1]:
        handleAdvisorGAEventSelectQualifications(advisorId)
        break;
      case tabOptions[2]:
        handleAdvisorGAEventSelectServices(advisorId)
        break;
    }
    setSelectedTab(selectedTab)
  }

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  const checkCheckCase = (filterVal) => {
    if (filterVal === true) {
      return true
    }

    else {
      return false
    }
  }

  const includeArray = [
    { name: 'College', check: includeCollege, onChange: setIncludeCollege },
    { name: 'Field of Study', check: includeField, onChange: setIncludeField },
    { name: 'Extracurriculars', check: includeEC, onChange: setIncludeEC }
  ]

  useEffect(() => {

    if (advisor && majors) {
      updateTitleAndDescription(pageRoutes.advisorDetailGeneric, { firstName: advisor.firstName, schoolName: collegeReturn(advisor.college_id), majors: majorReturn(advisor.major_id, majors, advisor) }, advisor.firstName)
    }

    else if (advisor) {
      updateTitleAndDescription(pageRoutes.advisorDetailGeneric, { firstName: advisor.firstName, schoolName: collegeReturn(advisor.college_id) }, advisor.firstName)
    }

  }, [advisor, majors])

  useEffect(() => {
    const fetchUserPurchases = async () => {

      try {
        let res = await axios.get(`${URL}/user_transactions/${user.user_id}`);
        let purchases = res.data || [];

        let updatedPurchases = await Promise.all(
          purchases.map(async (purchase) => {
            let originalProductID = purchase.productID;

            purchase.purchaseDateTime = new Date(purchase.purchaseDateTime).toISOString().split('T')[0];

            purchase.productID = originalProductID;

            return purchase;
          })
        );
        setUserPurchases(updatedPurchases);
      } catch (error) {
        console.error('Error fetching user purchases:', error);
      }
    };

    fetchUserPurchases();
  }, [user]);

  const returnUserPurchaseHistoryText = (user, userPurchases, advisorFirstName, advisorId, mobile = false) => {

    let multiple = false
    let specificAdvisorPurchases: any = []

    userPurchases.map((purchase) => {
      if (advisorPriceIDs.some((advisorPriceObj) => advisorPriceObj.priceID == purchase.productID && advisorPriceObj.advisorID == advisorId)) {
        specificAdvisorPurchases.push(purchase)
      }
    })

    // If user is logged in and has no purchases for this advisor
    if (specificAdvisorPurchases?.length == 0) {
      return <></>
    }

    // If there are multiple advisor purchases, set multiple to true and sort by the purchase dates
    if (specificAdvisorPurchases && specificAdvisorPurchases.length > 1) {
      multiple = true;
      // Sort via localeCompare since purchaseDatetime is string format
      specificAdvisorPurchases = specificAdvisorPurchases.sort((a, b) => b?.purchaseDateTime?.localeCompare(a?.purchaseDateTime))
    }

    let recentPurchase: any = specificAdvisorPurchases[0] || ''

    return (<div style={{ margin: mobile ? '0px 5px' : '15px 0px', color: 'red' }}>{multiple ? `You purchased multiple consultations with ${advisorFirstName}, most recently on ${recentPurchase?.purchaseDateTime ? convertDateFormat(recentPurchase.purchaseDateTime) : 'N/A'}.` : `You purchased a consultation with ${advisorFirstName} on ${recentPurchase?.purchaseDateTime ? convertDateFormat(recentPurchase.purchaseDateTime) : 'N/A'}.`}</div>)
  }

  const desktopHeaderData = (
    <div className='d-flex flex-row'>
      <img
        src={advisorJPGs[advisor.id] != null ? advisorJPGs[advisor.id] as string : avatar}
        alt='avatar'
        style={{ height: '200px', padding: '30px', borderRadius: '40px' }} />

      <div className='d-flex flex-column' style={{ marginTop: '30px' }}>
        <div className='d-flex flex-row justify-content-between' style={{ height: '40%' }}>
          <div className='dca-advisor-detail-advisor-name'>{advisor.firstName}</div>

          {apiToken != undefined && user?.emailAddress ? <div style={{ marginLeft: '40px' }}><button
            type="button"
            className="btn btn-sm dca-connect-button-card-detail"
            data-bs-toggle="modal"
            data-bs-target="#purchase_modal"
            onClick={(e) => {
              e.stopPropagation();
              setPurchaseID(advisorConnectAccessCode)
              setAdvisorID(advisorId)
              handleGAEventPurchaseModalOpen(advisorId);
              handleAdvisorGAEventDetailConnectClick(advisorId)
            }}
          >
            Connect
          </button></div> : <div style={{ marginLeft: '40px' }}><ConnectButtonNoAuth setPrompt={setPrompt} setNavigateTo={setNavigateTo} biLock={false} advisorID={advisor.id} /></div>}
        </div>

        <div className='d-flex flex-row'>
          <div>
            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>COLLEGE</div>
            <div>{collegeReturn(advisor.college_id)}</div>
          </div>
          <div style={{ marginLeft: '40px' }}>
            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>CLASS</div>
            <div>{advisor.collegeGradYear}</div>
          </div>
          <div style={{ marginLeft: '40px' }}>
            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>MAJOR(S)</div>
            <div>{majorReturn(advisor.major_id, majors, advisor)}</div>
          </div>
          <div style={{ marginLeft: '40px' }}>
            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>JOINED</div>
            <div>{dateReturn(advisor.joinDate)}</div>
          </div>
        </div>

        {user?.emailAddress && returnUserPurchaseHistoryText(user, userPurchases, advisor.firstName, advisorId)}
      </div>


    </div>
  )

  // const mobileHeaderData = (
  //   <div className='d-flex flex-row' style={{ width: '100%', marginBottom: '10px' }}>
  //     <div className='d-flex flex-column justify-content-center'>
  //       <img
  //         src={advisorJPGs[advisor.id] != null ? advisorJPGs[advisor.id] as string : avatar}
  //         alt='avatar'
  //         style={{ height: '200px', padding: '30px', borderRadius: '40px' }} />

  //       {apiToken != undefined && user?.emailAddress ? <button
  //         type="button"
  //         className="btn btn-sm dca-connect-button-card-detail"
  //         data-bs-toggle="modal"
  //         data-bs-target="#purchase_modal"
  //         style={{ width: '140px' }}
  //         onClick={(e) => {
  //           e.stopPropagation();
  //           setPurchaseID(advisorConnectAccessCode)
  //           setAdvisorID(advisorId)
  //           handleGAEventPurchaseModalOpen(advisorId);
  //           handleAdvisorGAEventDetailConnectClick(advisorId)
  //         }}
  //       >
  //         Connect
  //       </button> : <ConnectButtonNoAuth setPrompt={setPrompt} setNavigateTo={setNavigateTo} biLock={false} advisorID={advisor.id} customWidth='140px' />}

  //     </div>

  //     <div className='d-flex flex-column' style={{ marginLeft: '20px', height: '100%', width: '100%' }}>

  //       <div className='dca-advisor-detail-advisor-name' style={{ paddingTop: '25px' }}>{advisor.firstName}</div>

  //       <div className='d-flex flex-column' style={{ width: '100%' }}>

  //         <div className='d-flex flex-row justify-content-between'>
  //           <div style={{ width: '50%', marginRight: '5px' }}>
  //             <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>COLLEGE</div>
  //             <div>{collegeReturn(advisor.college_id)}</div>
  //           </div>
  //           <div style={{ width: '50%' }}>
  //             <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>CLASS</div>
  //             <div>{advisor.collegeGradYear}</div>
  //           </div>
  //         </div>

  //         <div className='d-flex flex-row justify-content-between' style={{ marginTop: '15px', marginBottom: '15px' }}>
  //           <div style={{ width: '50%', marginRight: '5px' }}>
  //             <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>MAJOR(S)</div>
  //             <div>{majorReturn(advisor.major_id, majors, advisor)}</div>
  //           </div>
  //           <div style={{ width: '50%' }}>
  //             <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>JOINED</div>
  //             <div>{dateReturn(advisor.joinDate)}</div>
  //           </div>
  //         </div>

  //       </div>

  //       {user?.emailAddress && returnUserPurchaseHistoryText(user, userPurchases, advisor.firstName, advisorId)}
  //     </div>


  //   </div>)

  const mobileHeaderData = (
    <div className='d-flex flex-column' style={{ width: '100%', marginBottom: '10px' }}>

      <div className='d-flex flex-row'>
        <div className='d-flex flex-column justify-content-center'>
          <img
            src={advisorJPGs[advisor.id] != null ? advisorJPGs[advisor.id] as string : avatar}
            alt='avatar'
            className='dca-advisor-detail-avatar'
          // style={{ height: '200px', padding: '30px', borderRadius: '40px' }} 
          />
        </div>

        <div className='d-flex flex-column' style={{ marginLeft: '20px', height: '100%', width: '100%' }}>

          <div className='dca-advisor-detail-advisor-name' style={{ paddingTop: '25px' }}>{advisor.firstName}</div>

          <div className='d-flex flex-column' style={{ width: '100%' }}>

            <div className='d-flex flex-row justify-content-between'>
              <div style={{ width: '50%', marginRight: '5px' }}>
                <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>COLLEGE</div>
                <div>{collegeReturn(advisor.college_id)}</div>
              </div>
              <div style={{ width: '50%' }}>
                <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>CLASS</div>
                <div>{advisor.collegeGradYear}</div>
              </div>
            </div>

            <div className='d-flex flex-row justify-content-between' style={{ marginTop: '15px', marginBottom: '15px' }}>
              <div style={{ width: '50%', marginRight: '5px' }}>
                <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>MAJOR(S)</div>
                <div>{majorReturn(advisor.major_id, majors, advisor)}</div>
              </div>
              <div style={{ width: '50%' }}>
                <div className="dca-card-item-text" style={{ letterSpacing: titleSpace }}>JOINED</div>
                <div>{dateReturn(advisor.joinDate)}</div>
              </div>
            </div>

          </div>

          {/* {user?.emailAddress && returnUserPurchaseHistoryText(user, userPurchases, advisor.firstName, advisorId)} */}
        </div>
      </div>

      <div className='d-flex flex-row'>
        <div style={{ padding: '0px 15px 0px 15px' }}>{apiToken != undefined && user?.emailAddress ? <button
          type="button"
          className="btn btn-sm dca-connect-button-card-detail"
          data-bs-toggle="modal"
          data-bs-target="#purchase_modal"
          // style={{ width: '140px' }}
          style={{ width: '100px' }}
          onClick={(e) => {
            e.stopPropagation();
            setPurchaseID(advisorConnectAccessCode)
            setAdvisorID(advisorId)
            handleGAEventPurchaseModalOpen(advisorId);
            handleAdvisorGAEventDetailConnectClick(advisorId)
          }}
        >
          Connect
        </button> : <ConnectButtonNoAuth setPrompt={setPrompt} setNavigateTo={setNavigateTo} biLock={false} advisorID={advisor.id} customWidth='100px' />}</div>
        {/* customwidth 140px */}

        {user?.emailAddress && returnUserPurchaseHistoryText(user, userPurchases, advisor.firstName, advisorId, true)}
      </div>


    </div>)

  return advisor && loadingComplete ? (
    <div className='me-5' style={{ marginLeft: '35px' }}>

      <div className='d-flex flex-column'>
        <Link
          className="fw-bold"
          to={advisorSearchLocation}
          style={{ color: 'black' }}>
          <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black', fontWeight: 'bold', marginTop: '30px' }}></i>
          Back to Advisors Network
        </Link>
      </div>

      <div className='card shadow d-flex flex-column' style={{ width: '100%', marginTop: '30px' }}>

        {/* Use Bootstrap d-md-block to render while 768px or above */}
        <div className='d-none d-md-flex'>
          {desktopHeaderData}
        </div>
        {/* Mobile Replacement, only shows at below 768px */}
        <div className='d-flex d-md-none'>
          {mobileHeaderData}
        </div>

        {/* Use Bootstrap d-md-block to render while 768px or above */}
        <div className='d-none d-md-flex' style={{ width: '100%' }}>
          <div className='d-flex flex-row' style={{ marginLeft: '30px' }}>

            {tabOptions.map((tab, index) => (
              <div
                key={tab}
                className={`${selectedTab === tabOptions[index] ? 'fw-bold' : ''} dca-advisor-detail-tabs`}
                style={{
                  background: selectedTab === tabOptions[index] ? selectedTabGreen : '',
                }}
                onClick={() => handleSelectedTab(tabOptions[index])}
              >
                <div className='text-nowrap' style={{ padding: '10px' }}>
                  {tab}
                </div>
                <div
                  style={{
                    color: selectedTab === tabOptions[index] ? '#98F29B' : '',
                    width: '80%',
                  }}></div>
              </div>))}

          </div>
        </div>

        {/* <div className='d-flex flex-row' style={{ marginLeft: '30px' }}>

          {tabOptions.map((tab, index) => (
            <div
              key={tab}
              className={`${selectedTab === tabOptions[index] ? 'fw-bold' : ''} dca-advisor-detail-tabs`}
              style={{
                background: selectedTab === tabOptions[index] ? selectedTabGreen : '',
              }}
              onClick={() => handleSelectedTab(tabOptions[index])}
            >
              <div className='text-nowrap' style={{ padding: '10px' }}>
                {tab}
              </div>
              <div
                style={{
                  color: selectedTab === tabOptions[index] ? '#98F29B' : '',
                  width: '80%',
                }}></div>
            </div>))}

        </div> */}

        {/* {apiToken != undefined && user?.emailAddress ? <button
          type="button"
          className="btn btn-sm dca-connect-button-card"
          data-bs-toggle="modal"
          data-bs-target="#purchase_modal"
          onClick={(e) => {
            e.stopPropagation();
            setPurchaseID(advisorConnectAccessCode)
            setAdvisorID(advisorId)
            handleGAEventPurchaseModalOpen(advisorId)
          }}
        >
          Connect
        </button> : <ConnectButtonInCardNoAuth setPrompt={setPrompt} setNavigateTo={setNavigateTo} />} */}
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none' style={{ width: '100%', marginTop: '30px' }}>
        <select
          className='form-select align-bottom'
          data-control="select2"
          value={selectedTab}
          onChange={(e) => {
            handleSelectedTabMobile(e)
          }}
          style={{ cursor: 'pointer', width: 'auto' }}
        >{tabOptions.map((tab, index) => <option value={tab} key={index}>{tab}</option>)}
        </select>
      </div>

      <AdvisorDetailTabContent
        advisor={advisor}
        selectedTab={selectedTab}
        tabOptions={tabOptions}
      />

    </div>

  ) : (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
      <LoadingIndicator text={"Getting Advisor Data ... "} />
    </div>
  )
}

export { AdvisorDetailPage }