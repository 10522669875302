import { ACTBubbleChart, ACTBubbleChartClassRank } from './Charts/ACTBubbleChart'
import { BubbleChart, BubbleChartClassRank } from './Charts/BubbleChart'
import { SATMathReadingChart, ACTMathEnglishChart } from './Charts/MathReadingChart';
import { SubmitChart } from './Charts/SubmitChart';
import { useEffect, useState } from 'react';
import { LoadingIndicator } from '../LoadingIndicator';

const CollegeDataChart = ({ cdsAvailableSet, insufficientDataSet, selectedChart, olderDataSet, latestYear, loadingComplete, dataLoaded, setDataLoaded, mobile = false }) => {

  let weightedGPASet: any = []
  let unweightedGPASet: any = []
  let rankSet: any = []

  let insufficientArray: any = []
  let olderArray: any = []
  // Per #165, set current year as 2023
  // let currentYear = new Date().getFullYear()
  let currentYear = 2023

  // GPA SAT
  let weightedSATSeries: any = []
  let weightedSATSeriesNames: any = []
  let unweightedSATSeries: any = []
  let unweightedSATSeriesNames: any = []

  // GPA ACT
  let weightedACTSeries: any = []
  let weightedACTSeriesNames: any = []
  let unweightedACTSeries: any = []
  let unweightedACTSeriesNames: any = []

  // Class Rank SAT
  let rankSATSeries: any = []
  let rankSATSeriesNames: any = []

  // Class Rank ACT
  let rankACTSeries: any = []
  let rankACTSeriesNames: any = []

  // SAT Math vs SAT Reading/Writing
  let satMathReadingSeries: any = []
  let satMathReadingSeriesNames: any = []

  // ACT Math vs ACT English
  let actMathEnglishSeries: any = []
  let actMathEnglishSeriesNames: any = []

  // SAT Submission vs ACT Submission
  let submissionSeries: any = []
  let submissionSeriesNames: any = []

  try {

    if (cdsAvailableSet.length > 0) {

      // Weighted / Unweighted -- For GPA AVG Type == 1:
      // if (selectedChart === 1 || selectedChart === 2) {
      weightedGPASet = cdsAvailableSet.filter((item) => item.college_cds[0].gpaAverageType === 1)

      unweightedGPASet = cdsAvailableSet.filter((item) => ((item.college_cds[0].gpaAverageType !== 1) || (item.college_cds[0].gpaAverageType === null)))

      // Create SAT series data
      weightedGPASet.forEach((res) => {
        if (res.college_cds[0].satComposite50 !== null) {
          weightedSATSeries.push({
            x: res.college_cds[0].averageGPA,
            y: res.college_cds[0].satComposite50,
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          weightedSATSeriesNames.push(res.name)
        }

        else if (res.college_cds[0].satComposite25 != null && res.college_cds[0].satComposite75 != null) {
          weightedSATSeries.push({
            x: res.college_cds[0].averageGPA,
            y: ((parseInt(res.college_cds[0].satComposite25) + parseInt(res.college_cds[0].satComposite75)) / 2),
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          weightedSATSeriesNames.push(res.name)
        }

        else {
          weightedSATSeries.push({
            x: res.college_cds[0].averageGPA,
            y: (parseInt(res.college_cds[0].satEvidence50) + parseInt(res.college_cds[0].satMath50)),
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })
          weightedSATSeriesNames.push(res.name)
        }

      })

      // Create SAT series data
      unweightedGPASet.forEach((res) => {
        if (res.college_cds[0].satComposite50 !== null) {
          unweightedSATSeries.push({
            x: res.college_cds[0].averageGPA,
            y: res.college_cds[0].satComposite50,
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          unweightedSATSeriesNames.push(res.name)
        }

        else if (res.college_cds[0].satComposite25 != null && res.college_cds[0].satComposite75 != null) {
          unweightedSATSeries.push({
            x: res.college_cds[0].averageGPA,
            y: ((parseInt(res.college_cds[0].satComposite25) + parseInt(res.college_cds[0].satComposite75)) / 2),
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          unweightedSATSeriesNames.push(res.name)
        }

        else {
          unweightedSATSeries.push({
            x: res.college_cds[0].averageGPA,
            y: (parseInt(res.college_cds[0].satEvidence50) + parseInt(res.college_cds[0].satMath50)),
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          unweightedSATSeriesNames.push(res.name)
        }
      })

      // Create ACT series data
      weightedGPASet.forEach((res) => {
        if (res.college_cds[0].actComposite50 !== null) {
          weightedACTSeries.push({
            x: res.college_cds[0].averageGPA,
            y: res.college_cds[0].actComposite50,
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          weightedACTSeriesNames.push(res.name)
        }

        else {
          weightedACTSeries.push({
            x: res.college_cds[0].averageGPA,
            y: ((parseInt(res.college_cds[0].actComposite25) + parseInt(res.college_cds[0].actComposite75)) / 2),
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          weightedACTSeriesNames.push(res.name)
        }
      })

      // Create ACT series data
      unweightedGPASet.forEach((res) => {
        if (res.college_cds[0].actComposite50 !== null) {
          unweightedACTSeries.push({
            x: res.college_cds[0].averageGPA,
            y: res.college_cds[0].actComposite50,
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          unweightedACTSeriesNames.push(res.name)
        }

        else {
          unweightedACTSeries.push({
            x: res.college_cds[0].averageGPA,
            y: ((parseInt(res.college_cds[0].actComposite25) + parseInt(res.college_cds[0].actComposite75)) / 2),
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          unweightedACTSeriesNames.push(res.name)
        }
      })
      // }

      // Class Rank data sets 
      // if (selectedChart === 3 || selectedChart === 4) {
      rankSet = cdsAvailableSet.filter((item) => item.college_cds[0].classRankTopTenth)

      // Create Class rank SAT series data
      rankSet.forEach((res) => {
        if (res.college_cds[0].satComposite50 !== null) {
          rankSATSeries.push({
            x: (res.college_cds[0].classRankTopTenth * 100),
            y: res.college_cds[0].satComposite50,
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          rankSATSeriesNames.push(res.name)
        }

        else if (res.college_cds[0].satComposite25 !== null && res.college_cds[0].satComposite75 !== null) {
          rankSATSeries.push({
            x: (res.college_cds[0].classRankTopTenth * 100),
            y: ((parseInt(res.college_cds[0].satComposite25) + parseInt(res.college_cds[0].satComposite75)) / 2),
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          rankSATSeriesNames.push(res.name)
        }

        else {
          rankSATSeries.push({
            x: (res.college_cds[0].classRankTopTenth * 100),
            y: (parseInt(res.college_cds[0].satEvidence50) + parseInt(res.college_cds[0].satMath50)),
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          rankSATSeriesNames.push(res.name)
        }
      })

      // Create Class rank ACT series data
      rankSet.forEach((res) => {
        if (res.college_cds[0].actComposite50 !== null) {
          rankACTSeries.push({
            x: (res.college_cds[0].classRankTopTenth * 100),
            y: res.college_cds[0].actComposite50,
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          rankACTSeriesNames.push(res.name)
        }

        else {
          rankACTSeries.push({
            x: (res.college_cds[0].classRankTopTenth * 100),
            y: ((parseInt(res.college_cds[0].actComposite25) + parseInt(res.college_cds[0].actComposite75)) / 2),
            z: res.college_cds[0].totalAdmitRate,
            name: res.name,
            metaRank: res.metaRank
          })

          rankACTSeriesNames.push(res.name)
        }
      })

      // }

      // SAT Math vs SAT Reading/Writing
      // if (selectedChart === 5) {
      cdsAvailableSet.forEach((item) => {
        if (item.college_cds[0]['satMath50'] != null && item.college_cds[0]['satEvidence50'] != null) {
          satMathReadingSeries.push({
            x: item.college_cds[0].satMath50,
            y: item.college_cds[0].satEvidence50,
            z: item.college_cds[0].totalAdmitRate,
            name: item.name,
            metaRank: item.metaRank
          })

          satMathReadingSeriesNames.push(item.name)
        }

        else if (item.college_cds[0]['satMath25'] != null && item.college_cds[0]['satMath75'] != null && item.college_cds[0]['satEvidence25'] != null && item.college_cds[0]['satEvidence75'] != null) {
          satMathReadingSeries.push({
            x: ((parseInt(item.college_cds[0].satMath25) + parseInt(item.college_cds[0].satMath75)) / 2),
            y: ((parseInt(item.college_cds[0].satEvidence25) + parseInt(item.college_cds[0].satEvidence75)) / 2),
            z: item.college_cds[0].totalAdmitRate,
            name: item.name,
            metaRank: item.metaRank
          })

          satMathReadingSeriesNames.push(item.name)
        }
      })
      // }

      // ACT Math vs ACT Reading/Writing
      // if (selectedChart === 6) {
      cdsAvailableSet.forEach((item) => {
        if (item.college_cds[0]['actMath50'] != null && item.college_cds[0]['actEnglish50'] != null) {
          actMathEnglishSeries.push({
            x: item.college_cds[0].actMath50,
            y: item.college_cds[0].actEnglish50,
            z: item.college_cds[0].totalAdmitRate,
            name: item.name,
            metaRank: item.metaRank
          })

          actMathEnglishSeriesNames.push(item.name)
        }

        else if (item.college_cds[0]['actMath25'] != null && item.college_cds[0]['actMath75'] != null && item.college_cds[0]['actEnglish25'] != null && item.college_cds[0]['actEnglish75'] != null) {
          actMathEnglishSeries.push({
            x: ((parseInt(item.college_cds[0].actMath25) + parseInt(item.college_cds[0].actMath75)) / 2),
            y: ((parseInt(item.college_cds[0].actEnglish25) + parseInt(item.college_cds[0].actEnglish75)) / 2),
            z: item.college_cds[0].totalAdmitRate,
            name: item.name,
            metaRank: item.metaRank
          })

          actMathEnglishSeriesNames.push(item.name)
        }


      })
      // }

      // SAT Submission vs ACT Submission
      // if (selectedChart === 7) {
      cdsAvailableSet.forEach((item) => {
        if (item.college_cds[0]['submitSATPercent'] != null && item.college_cds[0]['submitACTPercent'] != null) {
          submissionSeries.push({
            x: item.college_cds[0].submitSATPercent,
            y: item.college_cds[0].submitACTPercent,
            z: item.college_cds[0].totalAdmitRate,
            name: item.name,
            metaRank: item.metaRank
          })

          submissionSeriesNames.push(item.name)
        }
      })
      // }

    }

    if (olderDataSet.length > 0) {
      olderDataSet.forEach((res, index) => {
        if (index === olderDataSet.length - 1) {
          olderArray.push(res.name)
        }
        else {
          olderArray.push(`${res.name}, `)
        }
      })
    }

    if (insufficientDataSet.length > 0) {

      let arr = insufficientDataSet

      arr.forEach((res, index) => {
        if (index === arr.length - 1) {
          insufficientArray.push(res.name)
        }

        else {
          insufficientArray.push(`${res.name}, `)
        }
      })
    }

  } catch (error) {
    console.error('Error setting the data for the College Data Visualization Charts:', error)
  }

  useEffect(() => {
    if (cdsAvailableSet.length > 0 && insufficientDataSet && olderDataSet && loadingComplete) {
      setDataLoaded(true);
    } else {
      setDataLoaded(false);
    }
  }, [cdsAvailableSet, insufficientDataSet, olderDataSet, loadingComplete]);

  const returnChart = (selectedChart) => {

    switch (true) {
      case selectedChart === 1:
        return (
          <BubbleChart
            chartId={'GPA vs SAT Chart'}
            seriesAName={'Weighted GPA'}
            seriesA={weightedSATSeries}
            seriesBName={'Unweighted GPA'}
            seriesB={unweightedSATSeries}
            yMin={1100}
            yMax={1600}
          />
        )
      case selectedChart === 2:
        return (
          <ACTBubbleChart
            chartId={'GPA vs ACT Chart'}
            seriesAName={'Weighted GPA'}
            seriesA={weightedACTSeries}
            seriesBName={'Unweighted GPA'}
            seriesB={unweightedACTSeries}
            yMin={22}
            yMax={36}
          />
        )
      case selectedChart === 3:
        return (
          <BubbleChartClassRank
            chartId={'Class Rank vs SAT'}
            seriesName={'Class Rank SAT'}
            seriesData={rankSATSeries}
            yMin={1100}
            yMax={1600}
          />
        )
      case selectedChart === 4:
        return (
          <ACTBubbleChartClassRank
            chartId={'Class Rank vs ACT'}
            seriesName={'Class Rank ACT'}
            seriesData={rankACTSeries}
            yMin={22}
            yMax={36}
          />
        )
      case selectedChart === 5:
        return (
          <SATMathReadingChart
            seriesData={satMathReadingSeries}
          />
        )
      case selectedChart === 6:
        return (
          <ACTMathEnglishChart
            seriesData={actMathEnglishSeries}
          />
        )
      case selectedChart === 7:
        return (
          <SubmitChart
            seriesData={submissionSeries}
          />
        )
      // default:
      //   return (
      //     <BubbleChart
      //       chartId={'GPAvs SAT Chart'}
      //       seriesAName={'Weighted GPA'}
      //       seriesA={weightedSATSeries}
      //       seriesBName={'Unweighted GPA'}
      //       seriesB={unweightedSATSeries}
      //       yMin={1100}
      //       yMax={1600}
      //     />
      //   )
    }
  }

  let insufficientDiv = (noteNumber) => {
    return (
      <>
        <div style={{ marginTop: '15px' }}>{noteNumber}. The following colleges were excluded from the chart due to insufficient published data: </div>
        <div className='dca-italic-text'>{insufficientArray.length > 0 ? insufficientArray : 'N/A'}</div>
      </>
    )
  }

  let olderDiv = (noteNumber) => {
    return (
      <>
        <div style={{ marginTop: '15px' }}>{noteNumber}. Values displayed are based on most recent published data, primarily for 2023-2024. Data from earlier years is used for these colleges due to data availability:</div>
        <div className='dca-italic-text'>{olderDataSet.length > 0 ? olderArray : 'N/A'}</div>
      </>
    )
  }

  let percentileDiv = (noteNumber) => {
    return (
      <div style={{ marginTop: '15px' }}>{noteNumber}. For colleges not publishing a 50% percentile value for SAT composite score, it is estimated as the midpoint of the 25th and 75th percentile values, or as the sum of the 50th percentile values of the SAT Evidence and SAT Math section scores.</div>
    )
  }

  let percentileDivACT = (noteNumber) => {
    return (
      <div style={{ marginTop: '15px' }}>{noteNumber}. For colleges not publishing a 50% percentile value for ACT composite score, it is estimated as the midpoint of the 25th and 75th percentile values, where available.</div>
    )
  }

  let percentileDivSATMathReading = (noteNumber) => {
    return (
      <div style={{ marginTop: '15px' }}>{noteNumber}. For colleges not publishing a 50% percentile value for SAT section score(s), it is estimated as the midpoint of the 25th and 75th percentile values, where available.</div>
    )
  }

  let percentileDivACTMathEnglish = (noteNumber) => {
    return (
      <div style={{ marginTop: '15px' }}>{noteNumber}. For colleges not publishing a 50% percentile value for ACT section score(s), it is estimated as the midpoint of the 25th and 75th percentile values, where available.</div>
    )
  }

  let gpaDiv = (noteNumber) => {
    return (
      <div style={{ marginTop: '15px' }}>{noteNumber}. GPAs displayed as reported by the college. Note that colleges may have varying methodologies for computing/reporting GPAs and therefore it is not always possible to directly compare GPAs between schools, or to your own GPA (especially as it relates to weighted GPAs.) In cases where colleges did not explicitly indicate whether they reported weighted or unweighted GPA, best efforts were used to classify them.</div>
    )
  }

  const notesOld = (
    <>
      <div className='fw-bold' style={{ marginTop: '15px', textDecoration: 'underline' }}>Notes:</div>

      {insufficientDiv(1)}

      {/* <div style={{ marginTop: '30px' }}>2. Values displayed are based primarily on {latestYear} - {currentYear} data. Prior years data used for these colleges due to data availability: </div>
      <div>{olderArray}</div> */}

      {olderDiv(2)}

      {gpaDiv(3)}

      {percentileDiv(4)}
    </>
  )

  const returnNotes = (selectedChart) => {

    let notesContent: any = (<></>)

    switch (true) {
      // GPA vs SAT
      case selectedChart === 1:
        notesContent = (
          <>
            {insufficientDiv(1)}

            {olderDiv(2)}

            {gpaDiv(3)}

            {percentileDiv(4)}
          </>
        )
        break;

      // GPA vs ACT
      case selectedChart === 2:
        notesContent = (
          <>
            {insufficientDiv(1)}

            {olderDiv(2)}

            {gpaDiv(3)}

            {percentileDivACT(4)}
          </>
        )
        break;

      // College Rank vs SAT
      case selectedChart === 3:
        notesContent = (
          <>
            {insufficientDiv(1)}

            {olderDiv(2)}

            {percentileDiv(3)}
          </>
        )
        break;

      // College Rank vs ACT
      case selectedChart === 4:
        notesContent = (
          <>
            {insufficientDiv(1)}

            {olderDiv(2)}

            {percentileDivACT(3)}
          </>
        )
        break;

      // SAT Math vs SAT Reading/Writing
      case selectedChart === 5:
        notesContent = (
          <>
            {insufficientDiv(1)}

            {olderDiv(2)}

            {percentileDivSATMathReading(3)}
          </>
        )
        break;

      // ACT Math vs ACT English
      case selectedChart === 6:
        notesContent = (
          <>
            {insufficientDiv(1)}

            {olderDiv(2)}

            {percentileDivACTMathEnglish(3)}
          </>
        )
        break;

      // SAT submit vs ACT submit
      case selectedChart === 7:
        notesContent = (
          <>
            {insufficientDiv(1)}

            {olderDiv(2)}
          </>
        )
        break;

      default:
        notesContent = (
          <>
            <LoadingIndicator text={"Loading College Data Scattergrams Notes..."} />
          </>
        )
    }

    return (
      <>
        <div className='fw-bold' style={{ marginTop: '15px', textDecoration: 'underline' }}>Notes:</div>

        {notesContent}
      </>
    )
  }

  const returnTestList = (
    <div style={{ marginLeft: '120px' }}>
      Series Data:
      <ul>
        <li style={{ listStyleType: 'none' }}>--GPA vs SAT--</li>
        <li><span className='fw-bold'>Weighted SAT Series:</span> {weightedSATSeriesNames}</li>
        <li><span className='fw-bold'>Unweighted SAT Series:</span> {unweightedSATSeriesNames}</li>
        <li style={{ listStyleType: 'none' }}>--GPA vs ACT--</li>
        <li><span className='fw-bold'>Weighted ACT Series:</span> {weightedACTSeriesNames}</li>
        <li><span className='fw-bold'>Unweighted ACT Series:</span> {unweightedACTSeriesNames}</li>
        <li style={{ listStyleType: 'none' }}>--Rank--</li>
        <li><span className='fw-bold'>Rank SAT:</span> {rankSATSeriesNames}</li>
        <li><span className='fw-bold'>Rank ACT:</span> {rankACTSeriesNames}</li>
      </ul>
    </div>)

  if (mobile) {
    return dataLoaded ? (
      <div>
        <div className='d-flex flex-column card shadow' style={{ marginRight: '20px' }}>
          {returnChart(selectedChart)}
        </div>

        <div style={{ marginRight: '25px' }}>
          {returnNotes(selectedChart)}
        </div>
      </div>
    ) : (
      <div
        className='d-flex flex-column card shadow' style={{
          width: '90vw',
          height: '600px',
          position: 'relative'
        }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
          <LoadingIndicator text={"Loading College Data Scattergrams..."} />
        </div>
      </div>
  
    )
  }

  return dataLoaded ? (
    <div style={{ width: '100%'}}>
      <div className='d-flex flex-column card shadow' style={{ marginLeft: '60px', marginRight: '20px'}}>
        {returnChart(selectedChart)}

        {/* {selectedChart === 1 && <BubbleChart
            chartId={'GPAvs SAT Chart'}
            seriesAName={'Weighted GPA'}
            seriesA={weightedSATSeries}
            seriesBName={'Unweighted GPA'}
            seriesB={unweightedSATSeries}
            yMin={1100}
            yMax={1600}
          />}

          {selectedChart === 2 && <ACTBubbleChart
            chartId={'GPA vs ACT Chart'}
            seriesAName={'Weighted GPA'}
            seriesA={weightedACTSeries}
            seriesBName={'Unweighted GPA'}
            seriesB={unweightedACTSeries}
            yMin={22}
            yMax={36}
          />}

          {selectedChart == 3 && <BubbleChartClassRank
            chartId={'Class Rank vs SAT'}
            seriesName={'Class Rank SAT'}
            seriesData={rankSATSeries}
            yMin={1100}
            yMax={1600}
          />} */}
      </div>

      {/* {returnTestList} */}

      <div style={{ marginLeft: '65px', marginRight: '25px' }}>
        {returnNotes(selectedChart)}
      </div>
    </div>
  ) : (
    <div
      className='d-flex flex-column card shadow' style={{
        marginLeft: '120px',
        padding: '100px',
        width: '800px',
        height: '600px',
        position: 'relative'
      }}>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
        <LoadingIndicator text={"Loading College Data Scattergrams..."} />
      </div>
    </div>

  )
}

export { CollegeDataChart }

// import { ACTBubbleChart, ACTBubbleChartClassRank } from './Charts/ACTBubbleChart'
// import { BubbleChart, BubbleChartClassRank } from './Charts/BubbleChart'
// import { SATMathReadingChart, ACTMathEnglishChart } from './Charts/MathReadingChart';
// import { SubmitChart } from './Charts/SubmitChart';
// import { useEffect, useState } from 'react';
// import { LoadingIndicator } from '../LoadingIndicator';

// const CollegeDataChart = ({ cdsAvailableSet, insufficientDataSet, selectedChart, olderDataSet, latestYear, loadingComplete, dataLoaded, setDataLoaded }) => {


//   // Per #165, set current year as 2023
//   // let currentYear = new Date().getFullYear()
//   let currentYear = 2023

//   const [weightedGPASet, setweightedGPASet] = useState<any>([])
//   const [unweightedGPASet, setunweightedGPASet] = useState<any>([])
//   const [rankSet, setrankSet] = useState<any>([])
//   const [insufficientArray, setinsufficientArray] = useState<any>([])
//   const [olderArray, setolderArray] = useState<any>([])

// // GPA SAT
//   const [weightedSATSeries, setweightedSATSeries] = useState<any>([])
//   const [weightedSATSeriesNames, setweightedSATSeriesNames] = useState<any>([])
//   const [unweightedSATSeries, setunweightedSATSeries] = useState<any>([])
//   const [unweightedSATSeriesNames, setunweightedSATSeriesNames] = useState<any>([])

//   // GPA ACT
//   const [weightedACTSeries, setweightedACTSeries] = useState<any>([])
//   const [weightedACTSeriesNames, setweightedACTSeriesNames] = useState<any>([])
//   const [unweightedACTSeries, setunweightedACTSeries] = useState<any>([])
//   const [unweightedACTSeriesNames, setunweightedACTSeriesNames] = useState<any>([])

//   // Class Rank SAT
//   const [rankSATSeries, setrankSATSeries] = useState<any>([])
//   const [rankSATSeriesNames, setrankSATSeriesNames] = useState<any>([])

//     // Class Rank ACT
//   const [rankACTSeries, setrankACTSeries] = useState<any>([])
//   const [rankACTSeriesNames, setrankACTSeriesNames] = useState<any>([])

// // SAT Math vs SAT Reading/Writing
//   const [satMathReadingSeries, setsatMathReadingSeries] = useState<any>([])
//   const [satMathReadingSeriesNames, setsatMathReadingSeriesNames] = useState<any>([])

//   // ACT Math vs ACT English
//   const [actMathEnglishSeries, setactMathEnglishSeries] = useState<any>([])
//   const [actMathEnglishSeriesNames, setactMathEnglishSeriesNames] = useState<any>([])

// // SAT Submission vs ACT Submission
//   const [submissionSeries, setsubmissionSeries] = useState<any>([])
//   const [submissionSeriesNames, setsubmissionSeriesNames] = useState<any>([])

//   useEffect(() => {

//     const setChartArrays = (cdsAvailableSet) => {



//       try {

//         if (cdsAvailableSet.length > 0) {
    
//           // Weighted / Unweighted -- For GPA AVG Type == 1:
//           // if (selectedChart === 1 || selectedChart === 2) {
//           let weightedGPASet = cdsAvailableSet.filter((item) => item.college_cds[0].gpaAverageType === 1)
    
//           let unweightedGPASet = cdsAvailableSet.filter((item) => ((item.college_cds[0].gpaAverageType !== 1) || (item.college_cds[0].gpaAverageType === null)))
    
//           // Create SAT series data
//           weightedGPASet.forEach((res) => {
//             if (res.college_cds[0].satComposite50 !== null) {
//               weightedSATSeries.push({
//                 x: res.college_cds[0].averageGPA,
//                 y: res.college_cds[0].satComposite50,
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               weightedSATSeriesNames.push(res.name)
//             }
    
//             else if (res.college_cds[0].satComposite25 != null && res.college_cds[0].satComposite75 != null) {
//               weightedSATSeries.push({
//                 x: res.college_cds[0].averageGPA,
//                 y: ((parseInt(res.college_cds[0].satComposite25) + parseInt(res.college_cds[0].satComposite75)) / 2),
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               weightedSATSeriesNames.push(res.name)
//             }
    
//             else {
//               weightedSATSeries.push({
//                 x: res.college_cds[0].averageGPA,
//                 y: (parseInt(res.college_cds[0].satEvidence50) + parseInt(res.college_cds[0].satMath50)),
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
//               weightedSATSeriesNames.push(res.name)
//             }
    
//           })
    
//           // Create SAT series data
//           unweightedGPASet.forEach((res) => {
//             if (res.college_cds[0].satComposite50 !== null) {
//               unweightedSATSeries.push({
//                 x: res.college_cds[0].averageGPA,
//                 y: res.college_cds[0].satComposite50,
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               unweightedSATSeriesNames.push(res.name)
//             }
    
//             else if (res.college_cds[0].satComposite25 != null && res.college_cds[0].satComposite75 != null) {
//               unweightedSATSeries.push({
//                 x: res.college_cds[0].averageGPA,
//                 y: ((parseInt(res.college_cds[0].satComposite25) + parseInt(res.college_cds[0].satComposite75)) / 2),
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               unweightedSATSeriesNames.push(res.name)
//             }
    
//             else {
//               unweightedSATSeries.push({
//                 x: res.college_cds[0].averageGPA,
//                 y: (parseInt(res.college_cds[0].satEvidence50) + parseInt(res.college_cds[0].satMath50)),
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               unweightedSATSeriesNames.push(res.name)
//             }
//           })
    
//           // Create ACT series data
//           weightedGPASet.forEach((res) => {
//             if (res.college_cds[0].actComposite50 !== null) {
//               weightedACTSeries.push({
//                 x: res.college_cds[0].averageGPA,
//                 y: res.college_cds[0].actComposite50,
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               weightedACTSeriesNames.push(res.name)
//             }
    
//             else {
//               weightedACTSeries.push({
//                 x: res.college_cds[0].averageGPA,
//                 y: ((parseInt(res.college_cds[0].actComposite25) + parseInt(res.college_cds[0].actComposite75)) / 2),
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               weightedACTSeriesNames.push(res.name)
//             }
//           })
    
//           // Create ACT series data
//           unweightedGPASet.forEach((res) => {
//             if (res.college_cds[0].actComposite50 !== null) {
//               unweightedACTSeries.push({
//                 x: res.college_cds[0].averageGPA,
//                 y: res.college_cds[0].actComposite50,
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               unweightedACTSeriesNames.push(res.name)
//             }
    
//             else {
//               unweightedACTSeries.push({
//                 x: res.college_cds[0].averageGPA,
//                 y: ((parseInt(res.college_cds[0].actComposite25) + parseInt(res.college_cds[0].actComposite75)) / 2),
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               unweightedACTSeriesNames.push(res.name)
//             }
//           })
//           // }
    
//           // Class Rank data sets 
//           // if (selectedChart === 3 || selectedChart === 4) {
//           let rankSet = cdsAvailableSet.filter((item) => item.college_cds[0].classRankTopTenth)
    
//           // Create Class rank SAT series data
//           rankSet.forEach((res) => {
//             if (res.college_cds[0].satComposite50 !== null) {
//               rankSATSeries.push({
//                 x: (res.college_cds[0].classRankTopTenth * 100),
//                 y: res.college_cds[0].satComposite50,
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               rankSATSeriesNames.push(res.name)
//             }
    
//             else if (res.college_cds[0].satComposite25 !== null && res.college_cds[0].satComposite75 !== null) {
//               rankSATSeries.push({
//                 x: (res.college_cds[0].classRankTopTenth * 100),
//                 y: ((parseInt(res.college_cds[0].satComposite25) + parseInt(res.college_cds[0].satComposite75)) / 2),
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               rankSATSeriesNames.push(res.name)
//             }
    
//             else {
//               rankSATSeries.push({
//                 x: (res.college_cds[0].classRankTopTenth * 100),
//                 y: (parseInt(res.college_cds[0].satEvidence50) + parseInt(res.college_cds[0].satMath50)),
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               rankSATSeriesNames.push(res.name)
//             }
//           })
    
//           // Create Class rank ACT series data
//           rankSet.forEach((res) => {
//             if (res.college_cds[0].actComposite50 !== null) {
//               rankACTSeries.push({
//                 x: (res.college_cds[0].classRankTopTenth * 100),
//                 y: res.college_cds[0].actComposite50,
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               rankACTSeriesNames.push(res.name)
//             }
    
//             else {
//               rankACTSeries.push({
//                 x: (res.college_cds[0].classRankTopTenth * 100),
//                 y: ((parseInt(res.college_cds[0].actComposite25) + parseInt(res.college_cds[0].actComposite75)) / 2),
//                 z: res.college_cds[0].totalAdmitRate,
//                 name: res.name,
//                 metaRank: res.metaRank
//               })
    
//               rankACTSeriesNames.push(res.name)
//             }
//           })
    
//           // }
    
//           // SAT Math vs SAT Reading/Writing
//           // if (selectedChart === 5) {
//           cdsAvailableSet.forEach((item) => {
//             if (item.college_cds[0]['satMath50'] != null && item.college_cds[0]['satEvidence50'] != null) {
//               satMathReadingSeries.push({
//                 x: item.college_cds[0].satMath50,
//                 y: item.college_cds[0].satEvidence50,
//                 z: item.college_cds[0].totalAdmitRate,
//                 name: item.name,
//                 metaRank: item.metaRank
//               })
    
//               satMathReadingSeriesNames.push(item.name)
//             }
    
//             else if (item.college_cds[0]['satMath25'] != null && item.college_cds[0]['satMath75'] != null && item.college_cds[0]['satEvidence25'] != null && item.college_cds[0]['satEvidence75'] != null) {
//               satMathReadingSeries.push({
//                 x: ((parseInt(item.college_cds[0].satMath25) + parseInt(item.college_cds[0].satMath75)) / 2),
//                 y: ((parseInt(item.college_cds[0].satEvidence25) + parseInt(item.college_cds[0].satEvidence75)) / 2),
//                 z: item.college_cds[0].totalAdmitRate,
//                 name: item.name,
//                 metaRank: item.metaRank
//               })
    
//               satMathReadingSeriesNames.push(item.name)
//             }
//           })
//           // }
    
//           // ACT Math vs ACT Reading/Writing
//           // if (selectedChart === 6) {
//           cdsAvailableSet.forEach((item) => {
//             if (item.college_cds[0]['actMath50'] != null && item.college_cds[0]['actEnglish50'] != null) {
//               actMathEnglishSeries.push({
//                 x: item.college_cds[0].actMath50,
//                 y: item.college_cds[0].actEnglish50,
//                 z: item.college_cds[0].totalAdmitRate,
//                 name: item.name,
//                 metaRank: item.metaRank
//               })
    
//               actMathEnglishSeriesNames.push(item.name)
//             }
    
//             else if (item.college_cds[0]['actMath25'] != null && item.college_cds[0]['actMath75'] != null && item.college_cds[0]['actEnglish25'] != null && item.college_cds[0]['actEnglish75'] != null) {
//               actMathEnglishSeries.push({
//                 x: ((parseInt(item.college_cds[0].actMath25) + parseInt(item.college_cds[0].actMath75)) / 2),
//                 y: ((parseInt(item.college_cds[0].actEnglish25) + parseInt(item.college_cds[0].actEnglish75)) / 2),
//                 z: item.college_cds[0].totalAdmitRate,
//                 name: item.name,
//                 metaRank: item.metaRank
//               })
    
//               actMathEnglishSeriesNames.push(item.name)
//             }
    
    
//           })
//           // }
    
//           // SAT Submission vs ACT Submission
//           // if (selectedChart === 7) {
//           cdsAvailableSet.forEach((item) => {
//             if (item.college_cds[0]['submitSATPercent'] != null && item.college_cds[0]['submitACTPercent'] != null) {
//               submissionSeries.push({
//                 x: item.college_cds[0].submitSATPercent,
//                 y: item.college_cds[0].submitACTPercent,
//                 z: item.college_cds[0].totalAdmitRate,
//                 name: item.name,
//                 metaRank: item.metaRank
//               })
    
//               submissionSeriesNames.push(item.name)
//             }
//           })
//           // }
    
//         }
    
//         if (olderDataSet.length > 0) {
//           olderDataSet.forEach((res, index) => {
//             if (index === olderDataSet.length - 1) {
//               olderArray.push(res.name)
//             }
//             else {
//               olderArray.push(`${res.name}, `)
//             }
//           })
//         }
    
//         if (insufficientDataSet.length > 0) {
    
//           let arr = insufficientDataSet
    
//           arr.forEach((res, index) => {
//             if (index === arr.length - 1) {
//               insufficientArray.push(res.name)
//             }
    
//             else {
//               insufficientArray.push(`${res.name}, `)
//             }
//           })
//         }
    
//       } catch (error) {
//         console.error('Error setting the data for the College Data Visualization Charts:', error)
//       }
//     }

//     setChartArrays(cdsAvailableSet)

//   }, [cdsAvailableSet])

  

//   useEffect(() => {
//     if (cdsAvailableSet.length > 0 && insufficientDataSet && olderDataSet && loadingComplete) {
//       setDataLoaded(true);
//     } else {
//       setDataLoaded(false);
//     }
//   }, [cdsAvailableSet, insufficientDataSet, olderDataSet, loadingComplete]);

//   const returnChart = (selectedChart) => {

//     switch (true) {
//       case selectedChart === 1:
//         return (
//           <BubbleChart
//             chartId={'GPA vs SAT Chart'}
//             seriesAName={'Weighted GPA'}
//             seriesA={weightedSATSeries}
//             seriesBName={'Unweighted GPA'}
//             seriesB={unweightedSATSeries}
//             yMin={1100}
//             yMax={1600}
//           />
//         )
//       case selectedChart === 2:
//         return (
//           <ACTBubbleChart
//             chartId={'GPA vs ACT Chart'}
//             seriesAName={'Weighted GPA'}
//             seriesA={weightedACTSeries}
//             seriesBName={'Unweighted GPA'}
//             seriesB={unweightedACTSeries}
//             yMin={22}
//             yMax={36}
//           />
//         )
//       case selectedChart === 3:
//         return (
//           <BubbleChartClassRank
//             chartId={'Class Rank vs SAT'}
//             seriesName={'Class Rank SAT'}
//             seriesData={rankSATSeries}
//             yMin={1100}
//             yMax={1600}
//           />
//         )
//       case selectedChart === 4:
//         return (
//           <ACTBubbleChartClassRank
//             chartId={'Class Rank vs ACT'}
//             seriesName={'Class Rank ACT'}
//             seriesData={rankACTSeries}
//             yMin={22}
//             yMax={36}
//           />
//         )
//       case selectedChart === 5:
//         return (
//           <SATMathReadingChart
//             seriesData={satMathReadingSeries}
//           />
//         )
//       case selectedChart === 6:
//         return (
//           <ACTMathEnglishChart
//             seriesData={actMathEnglishSeries}
//           />
//         )
//       case selectedChart === 7:
//         return (
//           <SubmitChart
//             seriesData={submissionSeries}
//           />
//         )
//       // default:
//       //   return (
//       //     <BubbleChart
//       //       chartId={'GPAvs SAT Chart'}
//       //       seriesAName={'Weighted GPA'}
//       //       seriesA={weightedSATSeries}
//       //       seriesBName={'Unweighted GPA'}
//       //       seriesB={unweightedSATSeries}
//       //       yMin={1100}
//       //       yMax={1600}
//       //     />
//       //   )
//     }
//   }

//   let insufficientDiv = (noteNumber) => {
//     return (
//       <>
//         <div style={{ marginTop: '15px' }}>{noteNumber}. The following colleges were excluded from the chart due to insufficient published data: </div>
//         <div className='dca-italic-text'>{insufficientArray.length > 0 ? insufficientArray : 'N/A'}</div>
//       </>
//     )
//   }

//   let olderDiv = (noteNumber) => {
//     return (
//       <>
//         <div style={{ marginTop: '15px' }}>{noteNumber}. Values displayed are based on most recent published data, primarily for 2023-2024. Data from earlier years is used for these colleges due to data availability:</div>
//         <div className='dca-italic-text'>{olderDataSet.length > 0 ? olderArray : 'N/A'}</div>
//       </>
//     )
//   }

//   let percentileDiv = (noteNumber) => {
//     return (
//       <div style={{ marginTop: '15px' }}>{noteNumber}. For colleges not publishing a 50% percentile value for SAT composite score, it is estimated as the midpoint of the 25th and 75th percentile values, or as the sum of the 50th percentile values of the SAT Evidence and SAT Math section scores.</div>
//     )
//   }

//   let percentileDivACT = (noteNumber) => {
//     return (
//       <div style={{ marginTop: '15px' }}>{noteNumber}. For colleges not publishing a 50% percentile value for ACT composite score, it is estimated as the midpoint of the 25th and 75th percentile values, where available.</div>
//     )
//   }

//   let percentileDivSATMathReading = (noteNumber) => {
//     return (
//       <div style={{ marginTop: '15px' }}>{noteNumber}. For colleges not publishing a 50% percentile value for SAT section score(s), it is estimated as the midpoint of the 25th and 75th percentile values, where available.</div>
//     )
//   }

//   let percentileDivACTMathEnglish = (noteNumber) => {
//     return (
//       <div style={{ marginTop: '15px' }}>{noteNumber}. For colleges not publishing a 50% percentile value for ACT section score(s), it is estimated as the midpoint of the 25th and 75th percentile values, where available.</div>
//     )
//   }

//   let gpaDiv = (noteNumber) => {
//     return (
//       <div style={{ marginTop: '15px' }}>{noteNumber}. GPAs displayed as reported by the college. Note that colleges may have varying methodologies for computing/reporting GPAs and therefore it is not always possible to directly compare GPAs between schools, or to your own GPA (especially as it relates to weighted GPAs.) In cases where colleges did not explicitly indicate whether they reported weighted or unweighted GPA, best efforts were used to classify them.</div>
//     )
//   }

//   const notesOld = (
//     <>
//       <div className='fw-bold' style={{ marginTop: '15px', textDecoration: 'underline' }}>Notes:</div>

//       {insufficientDiv(1)}

//       {/* <div style={{ marginTop: '30px' }}>2. Values displayed are based primarily on {latestYear} - {currentYear} data. Prior years data used for these colleges due to data availability: </div>
//       <div>{olderArray}</div> */}

//       {olderDiv(2)}

//       {gpaDiv(3)}

//       {percentileDiv(4)}
//     </>
//   )

//   const returnNotes = (selectedChart) => {

//     let notesContent: any = (<></>)

//     switch (true) {
//       // GPA vs SAT
//       case selectedChart === 1:
//         notesContent = (
//           <>
//             {insufficientDiv(1)}

//             {olderDiv(2)}

//             {gpaDiv(3)}

//             {percentileDiv(4)}
//           </>
//         )
//         break;

//       // GPA vs ACT
//       case selectedChart === 2:
//         notesContent = (
//           <>
//             {insufficientDiv(1)}

//             {olderDiv(2)}

//             {gpaDiv(3)}

//             {percentileDivACT(4)}
//           </>
//         )
//         break;

//       // College Rank vs SAT
//       case selectedChart === 3:
//         notesContent = (
//           <>
//             {insufficientDiv(1)}

//             {olderDiv(2)}

//             {percentileDiv(3)}
//           </>
//         )
//         break;

//       // College Rank vs ACT
//       case selectedChart === 4:
//         notesContent = (
//           <>
//             {insufficientDiv(1)}

//             {olderDiv(2)}

//             {percentileDivACT(3)}
//           </>
//         )
//         break;

//       // SAT Math vs SAT Reading/Writing
//       case selectedChart === 5:
//         notesContent = (
//           <>
//             {insufficientDiv(1)}

//             {olderDiv(2)}

//             {percentileDivSATMathReading(3)}
//           </>
//         )
//         break;

//       // ACT Math vs ACT English
//       case selectedChart === 6:
//         notesContent = (
//           <>
//             {insufficientDiv(1)}

//             {olderDiv(2)}

//             {percentileDivACTMathEnglish(3)}
//           </>
//         )
//         break;

//       // SAT submit vs ACT submit
//       case selectedChart === 7:
//         notesContent = (
//           <>
//             {insufficientDiv(1)}

//             {olderDiv(2)}
//           </>
//         )
//         break;

//       default:
//         notesContent = (
//           <>
//             <LoadingIndicator text={"Loading College Data Scattergrams Notes..."} />
//           </>
//         )
//     }

//     return (
//       <>
//         <div className='fw-bold' style={{ marginTop: '15px', textDecoration: 'underline' }}>Notes:</div>

//         {notesContent}
//       </>
//     )
//   }

//   const returnTestList = (
//     <div style={{ marginLeft: '120px' }}>
//       Series Data:
//       <ul>
//         <li style={{ listStyleType: 'none' }}>--GPA vs SAT--</li>
//         <li><span className='fw-bold'>Weighted SAT Series:</span> {weightedSATSeriesNames}</li>
//         <li><span className='fw-bold'>Unweighted SAT Series:</span> {unweightedSATSeriesNames}</li>
//         <li style={{ listStyleType: 'none' }}>--GPA vs ACT--</li>
//         <li><span className='fw-bold'>Weighted ACT Series:</span> {weightedACTSeriesNames}</li>
//         <li><span className='fw-bold'>Unweighted ACT Series:</span> {unweightedACTSeriesNames}</li>
//         <li style={{ listStyleType: 'none' }}>--Rank--</li>
//         <li><span className='fw-bold'>Rank SAT:</span> {rankSATSeriesNames}</li>
//         <li><span className='fw-bold'>Rank ACT:</span> {rankACTSeriesNames}</li>
//       </ul>
//     </div>)

//   return dataLoaded ? (
//     <div>
//       <div className='d-flex flex-column card shadow' style={{ marginLeft: '60px', marginRight: '20px' }}>
//         {returnChart(selectedChart)}

//         {/* {selectedChart === 1 && <BubbleChart
//             chartId={'GPAvs SAT Chart'}
//             seriesAName={'Weighted GPA'}
//             seriesA={weightedSATSeries}
//             seriesBName={'Unweighted GPA'}
//             seriesB={unweightedSATSeries}
//             yMin={1100}
//             yMax={1600}
//           />}

//           {selectedChart === 2 && <ACTBubbleChart
//             chartId={'GPA vs ACT Chart'}
//             seriesAName={'Weighted GPA'}
//             seriesA={weightedACTSeries}
//             seriesBName={'Unweighted GPA'}
//             seriesB={unweightedACTSeries}
//             yMin={22}
//             yMax={36}
//           />}

//           {selectedChart == 3 && <BubbleChartClassRank
//             chartId={'Class Rank vs SAT'}
//             seriesName={'Class Rank SAT'}
//             seriesData={rankSATSeries}
//             yMin={1100}
//             yMax={1600}
//           />} */}
//       </div>

//       {/* {returnTestList} */}

//       <div style={{ marginLeft: '65px', marginRight: '25px' }}>
//         {returnNotes(selectedChart)}
//       </div>
//     </div>
//   ) : (
//     <div
//       className='d-flex flex-column card shadow' style={{
//         marginLeft: '120px',
//         padding: '100px',
//         width: '800px',
//         height: '600px',
//         position: 'relative'
//       }}>
//       <div
//         className="d-flex flex-column justify-content-center align-items-center"
//         style={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)'
//         }}>
//         <LoadingIndicator text={"Loading College Data Scattergrams..."} />
//       </div>
//     </div>

//   )
// }

// export { CollegeDataChart }