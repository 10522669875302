import { useState, useEffect } from 'react'
import { selectedTabGreen, unselectedTabGray } from '../dictionary'
import { Summary } from './TabContent/Summary'
import { ScoreSubmission } from './TabContent/ScoreSubmission'
import { SATScoreData } from './TabContent/SATScoreData'
import { ACTScoreData } from './TabContent/ACTScoreData'
import { TestScoreStrategyReactGAHandlers } from '../ReactGAHandlers'

const OverlayTabs = ({ modalData, userSATScoreOutput, userACTScoreOutput }) => {

  const { handleGAEventTabClick } = TestScoreStrategyReactGAHandlers()

  let satScore = userSATScoreOutput
  let actScore = userACTScoreOutput

  const tabOptions = ['Summary', 'Score Submission Data', 'SAT Score Data', 'ACT Score Data']
  const [selectedTab, setSelectedTab] = useState(tabOptions[0])

  useEffect(() => {
    const modalElement: any = document.getElementById('overlay');

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'style') {
          const style = window.getComputedStyle(modalElement);
          if (style.display == 'none') {
            setSelectedTab(tabOptions[0])
          }
        }
      });
    });

    if (modalElement) {
      observer.observe(modalElement, { attributes: true, attributeFilter: ['style'] });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSelectedTab = (val) => {
    setSelectedTab(val);
    handleGAEventTabClick(modalData.id, val)
  }

  const handleSelectedTabSelectComp = (event) => {
    const selectedTab = event.target.value
    console.log('chose:', selectedTab, typeof (selectedTab))
    console.log('tabOptions[0]', tabOptions[0], typeof (tabOptions[0]))
    setSelectedTab(selectedTab)
  }

  const returnTabContent = (selectedTab) => {
    switch (selectedTab) {
      case tabOptions[0]:
        return <Summary modalData={modalData} />
      case tabOptions[1]:
        return <ScoreSubmission modalData={modalData} />
      case tabOptions[2]:
        return <SATScoreData modalData={modalData} />
      case tabOptions[3]:
        return <ACTScoreData modalData={modalData} />
      default:
        return <Summary modalData={modalData} />
    }
  }

  // return (satScore != -2) && (actScore != -2) ? (
  //   <div style={{ marginTop: '30px' }}>

  //     <div className='d-flex flex-row'>

  //       {tabOptions.map((tab, index) => (
  //         <div
  //           key={tab}
  //           className="d-flex rounded rounded-3 fw-bold align-items-center"
  //           style={{ backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray, height: '70px', cursor: 'pointer', border: '1px rgba(219, 223, 233, 0.4) dashed', borderRadius: '50%', display: 'inline-block', marginLeft: index === 0 ? '0px' : '15px' }}
  //           onClick={() => {
  //             handleSelectedTab(tabOptions[index])
  //           }}
  //         >
  //           <span className='m-3 text-nowrap'>{tab}</span>
  //         </div>))}

  //     </div>

  //     {returnTabContent(selectedTab)}

  //   </div>
  // ) : (
  //   <div style={{ marginTop: '30px' }}>

  //     <div className='d-flex flex-row'>

  //       <div
  //         className="d-flex rounded rounded-3 fw-bold align-items-center"
  //         style={{
  //           backgroundColor: selectedTabGreen,
  //           height: '70px', cursor: 'pointer', border: '1px rgba(219, 223, 233, 0.4) dashed', borderRadius: '50%', display: 'inline-block'
  //         }}

  //       >
  //         <span className='m-3 text-nowrap'>{tabOptions[0]}</span>
  //       </div>

  //     </div>

  //     {returnTabContent(tabOptions[0])}

  //   </div>
  // )
  // return (
  //   <div style={{ marginTop: '30px' }}>

  //     <div className='d-flex flex-row'>

  //       {tabOptions.map((tab, index) => (
  //         <div
  //           key={tab}
  //           className="d-flex rounded rounded-3 fw-bold align-items-center"
  //           style={{ backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray, height: '70px', cursor: 'pointer', border: '1px rgba(219, 223, 233, 0.4) dashed', borderRadius: '50%', display: 'inline-block', marginLeft: index === 0 ? '0px' : '15px' }}
  //           onClick={() => {
  //             handleSelectedTab(tabOptions[index])
  //           }}
  //         >
  //           <span className='m-3 text-nowrap'>{tab}</span>
  //         </div>))}

  //     </div>

  //     {returnTabContent(selectedTab)}

  //   </div>
  // )


  return (
    <div style={{ marginTop: '30px' }}>

      <div className='d-flex flex-row'>

        {/* Use Bootstrap d-md-block to render while 768px or above */}
        <div className='d-none d-md-flex'>
          {tabOptions.map((tab, index) => (
            <div
              key={tab}
              className="d-flex rounded rounded-3 fw-bold align-items-center"
              style={{ backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray, height: '70px', cursor: 'pointer', border: '1px rgba(219, 223, 233, 0.4) dashed', borderRadius: '50%', display: 'inline-block', marginLeft: index === 0 ? '0px' : '15px' }}
              onClick={() => {
                handleSelectedTab(tabOptions[index])
              }}
            >
              <span className='m-3 text-nowrap'>{tab}</span>
            </div>))}
        </div>

        {/* Mobile Replacement, only shows at below 768px */}
        <div className='d-flex d-md-none' style={{ width: 'fit-content' }}>
          <select
            className='form-select align-bottom'
            data-control="select2"
            value={selectedTab}
            onChange={handleSelectedTabSelectComp}
            style={{ cursor: 'pointer', width: 'auto', minWidth: 'fit-content' }}
          >
            {tabOptions.map((tab) =>
              <option key={tab} value={tab}>{tab}</option>
            )}
          </select>
        </div>



      </div>

      {returnTabContent(selectedTab)}

    </div>
  )
}

export { OverlayTabs }


{/* <select
          className='form-select w-25 align-bottom'
          data-control="select2"
          value={props.sortKey}
          onChange={props.handleSortKeyChange}
          style={{ cursor: 'pointer' }}
        >
          <option value={props.sortKeyOptions[Object.keys(props.sortKeyOptions)[0]]}>{Object.keys(props.sortKeyOptions)[0]}</option>
          <option value={props.sortKeyOptions[Object.keys(props.sortKeyOptions)[1]]}>{Object.keys(props.sortKeyOptions)[1]}</option>
          <option value={props.sortKeyOptions[Object.keys(props.sortKeyOptions)[2]]}>{Object.keys(props.sortKeyOptions)[2]}</option>
          <option value={props.sortKeyOptions[Object.keys(props.sortKeyOptions)[3]]}>{Object.keys(props.sortKeyOptions)[3]}</option>
          <option value={props.sortKeyOptions[Object.keys(props.sortKeyOptions)[4]]}>{Object.keys(props.sortKeyOptions)[4]}</option>
        </select> */}