import { mix } from 'polished'

const RankIndicator = ({ rank, modal = false, clickable = false }) => {

  if (typeof rank !== 'number' || rank === null) {
    return (<div
      className='d-flex justify-content-center align-items-center fw-bold rounded'
      style={{ width: '30px', height: '30px' }}> - </div>)
  }

  else {
    const getColor = (rank: number) => {
      const green = 'rgba(135, 233, 138, 1)';
      const yellow = 'rgba(255, 241, 111, 1)';
      const red = 'rgba(243, 72, 115, 1)';

      const normalizedRank = (rank - 1) / 100;

      if (rank > 100) {
        return red
      }
      else if (normalizedRank <= 0.5) {
        return mix(normalizedRank * 2, yellow, green);
      } else {
        return mix((normalizedRank - 0.5) * 2, red, yellow);
      }
    }

    const rankColor = getColor(rank);

    return (
      <div
        className='d-flex justify-content-center align-items-center align-self-center rounded'
        style={{
          backgroundColor: rankColor,
          width: '30px',
          height: '30px'
        }}
      >
        <div className='d-flex flex-column justify-content-center dca-font-M' style={{ whiteSpace: 'nowrap' }}>
          <div style={{ alignSelf: 'center', textDecoration: !modal ? 'underline' : undefined, color: clickable ? '#004D9B' : '' }}>{rank}</div>
        </div>

      </div>
    );
  }
};

export { RankIndicator }