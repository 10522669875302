import { useState, useEffect } from "react";
import { LoadingSpinner } from "../LoadingSpinner"
import { pageRoutes, selectedTabGreen, separatorReturn, URL } from "../dictionary"
import { useNavigate } from "react-router-dom";
import axios from "axios";

const MajorsOfInterest = ({ user, majors, setUser, userData, setUserData, mobile = false }) => {

  let navigate = useNavigate();
  const [saveError, setSaveError] = useState('')

  const returnMajorName = (id) => {
    return userData && userData.majorsInterest && majors ? majors.find((item) => item.id == id).name : '-'
  }

  const removeMajorFromUserList = async (id) => {

    try {

      let majorsList = [...userData.majorsInterest]

      const index = majorsList.indexOf(id)

      if (index > -1) {
        majorsList.splice(index, 1)
      }

      let patchData = { majorsInterest: majorsList }

      const patch = await axios.patch(`${URL}/user_profiles/${userData.user_id}`, patchData)

      console.log('Patch successful', patch.data)

      const res = await axios.get(`${URL}/user_profiles/${userData.user_id}`)
      setUser(res.data)
      setUserData(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      // console.log(error)
      // alert(`Cannot remove ${returnMajorName(id)} at this time. Try again later.`)
      setSaveError(`Cannot remove ${returnMajorName(id)} at this time. Try again later.`)
      // throw (error);
    }


  }

  return userData ? (

    <div className="dca-userProfile-form-card" style={{ width: '100%' }}>
      <div className={mobile ? 'd-flex flex-column' : 'd-flex flex-row justify-content-between align-items-center'}>
        <div className="dca-userProfile-form-title">Majors of Interest</div>
        {mobile ? (
          <div
            className='dca-college-search-resources-links'
            style={{ paddingLeft: '35px', paddingBottom: '10px' }}
            onClick={() => navigate(pageRoutes.majorSearch)}
          >Add Majors</div>) : <button
            type="button"
            style={{ marginRight: '25px' }}
            className='dca-userProfile-save-changes-button'
            onClick={() => navigate(pageRoutes.majorSearch)}
          >
          Add Majors
        </button>}
      </div>
      <div className="separator"></div>

      <div className="d-flex justify-content-center">
        {saveError != '' && <span style={{ margin: '15px 15px', color: 'red' }}>{saveError}</span>}
      </div>

      {/* Conditional if activities interest is null or not */}
      <div className="d-flex flex-wrap">
        {userData.majorsInterest && userData.majorsInterest.length > 0 ? userData.majorsInterest.map((res, index) =>
          <div key={index} style={{ width: mobile ? '100%' : '50%' }}>
            <div className="dca-userProfile-interest-field">
              <div>{returnMajorName(res)}</div>

              <i className="bi bi-x align-self-center" style={{ marginLeft: '10px', cursor: 'pointer' }}
                onClick={() => {
                  removeMajorFromUserList(res)
                }}></i>

            </div>

            <div className={separatorReturn(index, userData.majorsInterest)} style={{ width: '95%' }}></div>
          </div>
        ) : <div></div>}
      </div>

    </div>
  ) : <LoadingSpinner title={'Majors of Interest'} />
}

export { MajorsOfInterest }