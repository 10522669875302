import { collegeLogos, gray, numberConverter } from "../dictionary"
import Logo from '../../../custom_assets/ec_logo.png'
import { SaveButtonInCard } from "../SaveButton"
import { PopularityIndex } from "./PopularityIndex"
import { useEffect, useState } from "react"
import { TooltipComponent, TooltipComponentMobile, TooltipComponentMobileInline } from "../Tooltip"
import { SaveButtonCardNoAuth, SaveButtonMajorCard } from "../SaveButton"
import { MajorReactGAHandlers } from "../ReactGAHandlers"
import { LoadingIndicator } from "../LoadingIndicator"
import { MobileSortSelect } from "../CollegeSearch/components/MobileSortSelect"
import mobileSortIcon from '../../../custom_assets/noun-sort.png'


const Cards = ({ results, colleges, majors, sortOrder, sortKey, sortKeys, setSortKey, handleSortKeyChange, sortKeyReturn, handleSortOrderChange, apiToken, user, setUser, setPrompt, setNavigateTo, collegesData, sortKeysMobile, mobile = false }) => {

  const { handleGAEventExtLink } = MajorReactGAHandlers()

  if (!results) {
    return (
      <div className='dca-card-container'>

        {/* HEADER */}
        <div
          className="d-flex flex-row justify-content-between align-items-end">

          <div className="d-flex flex-row">
            <div className="me-3 align-bottom dca-card-items-found-text">Displaying {results.length} programs</div>
            <div
              onClick={handleSortOrderChange}
              style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
              className='text-muted fs-7 fw-bolder align-self-end'
            >by {sortOrder} {sortKeyReturn(sortKey)} {sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
              {sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
            </div>
          </div>


          <select
            className='form-select w-25 align-bottom'
            data-control="select2"
            value={sortKey}
            onChange={handleSortKeyChange}
            style={{ cursor: 'pointer' }}
          >
            {sortKeys.map((res, id) => (
              <option key={id} value={Object.values(res)}>{Object.keys(res)}</option>
            ))}
          </select>
        </div>

        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: '30px' }}>
          <LoadingIndicator text={"Getting Data ... "} />
        </div>

      </div>)
  }

  const collegeIdConversion = (majorId, collegeId) => {
    let major: any = '-'
    let college: any = '-'
    // let metaRank: any = '-'

    if (majors.length > 0 && colleges.length > 0) {
      major = majors.filter((item) => { return parseInt(item.id) == majorId })[0].name

      college = colleges.filter((item) => { return parseInt(item.id) == collegeId })[0].name

      // metaRank = colleges.filter((item) => { return item.id == collegeId })[0].metaRank
    }

    return `${major} at ${college}`
  }

  // const returnTooltip = (sizeIndex, collegeId, numEnrollees, degreesConferred) => {

  //   if (sizeIndex === null || sizeIndex === undefined) {
  //     return <TooltipComponent content={"There is insufficient data from the college to compute the popularity index for this major."} />
  //   }

  //   if (!colleges || colleges.length === 0) {
  //     return <TooltipComponent content={`College data loading...`} />;
  //   }

  //   let college = colleges.find((item) => parseInt(item.id) === collegeId);
  //   let collegeData = collegesData.find((item) => parseInt(item.id) === collegeId)

  //   if (!college) {
  //     return <TooltipComponent content={`College ID ${collegeId} data could not be found`} />;
  //   }

  //   if (college && collegeData && collegeData.majorSizeMetric) {
  //     switch (true) {
  //       case collegeData.majorSizeMetric === 1:
  //         return (
  //           <TooltipComponent
  //             content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${degreesConferred} degrees conferred in this major in ${collegeData.majorSizeTimeframe}.`}
  //           />
  //         );
  //       case collegeData.majorSizeMetric === 2:
  //         return (
  //           <TooltipComponent
  //             content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${numEnrollees} students enrolled in this major in ${collegeData.majorSizeTimeframe}.`}
  //           />
  //         )
  //     }
  //   }

  //   return <TooltipComponent content={`There is insufficient data from the college to compute the popularity index for this major.`} />;

  // };

  // const returnTooltipMobile = (sizeIndex, collegeId, numEnrollees, degreesConferred) => {

  //   if (sizeIndex === null || sizeIndex === undefined) {
  //     return <TooltipComponentMobile content={"There is insufficient data from the college to compute the popularity index for this major."} />
  //   }

  //   if (!colleges || colleges.length === 0) {
  //     return <TooltipComponentMobile content={`College data loading...`} />;
  //   }

  //   let college = colleges.find((item) => parseInt(item.id) === collegeId);
  //   let collegeData = collegesData.find((item) => parseInt(item.id) === collegeId)

  //   if (!college) {
  //     return <TooltipComponentMobile content={`College ID ${collegeId} data could not be found`} />;
  //   }

  //   if (college && collegeData && collegeData.majorSizeMetric) {
  //     switch (true) {
  //       case collegeData.majorSizeMetric === 1:
  //         return (
  //           <TooltipComponentMobile
  //             content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${degreesConferred} degrees conferred in this major in ${collegeData.majorSizeTimeframe}.`}
  //           />
  //         );
  //       case collegeData.majorSizeMetric === 2:
  //         return (
  //           <TooltipComponentMobile
  //             content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${numEnrollees} students enrolled in this major in ${collegeData.majorSizeTimeframe}.`}
  //           />
  //         )
  //     }
  //   }

  //   return <TooltipComponentMobile content={`There is insufficient data from the college to compute the popularity index for this major.`} />;

  // };

  const returnTooltip = (sizeIndex, collegeId, numEnrollees, degreesConferred) => {

    if (sizeIndex === null || sizeIndex === undefined) {
      // return <TooltipComponent content={"There is insufficient data from the college to compute the popularity index for this major."} />
      return <div className="dca-card-item-header">POPULARITY INDEX <span className="ms-1"><TooltipComponent content={"There is insufficient data from the college to compute the popularity index for this major."} /></span></div>
    }

    if (!colleges || colleges.length === 0) {
      // return <TooltipComponent content={`College data loading...`} />;
      return <div className="dca-card-item-header">POPULARITY INDEX <span className="ms-1"><TooltipComponent content={`College data loading...`} /></span></div>
    }

    let college = colleges.find((item) => parseInt(item.id) === collegeId);
    let collegeData = collegesData.find((item) => parseInt(item.id) === collegeId)

    if (!college) {
      // return <TooltipComponent content={`College ID ${collegeId} data could not be found`} />;
      return <div className="dca-card-item-header">POPULARITY INDEX <span className="ms-1"><TooltipComponent content={`College ID ${collegeId} data could not be found`} /></span></div>
    }

    if (college && collegeData && collegeData.majorSizeMetric) {
      switch (true) {
        case collegeData.majorSizeMetric === 1:
          // return (
          //   <TooltipComponent
          //     content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${degreesConferred} degrees conferred in this major in ${collegeData.majorSizeTimeframe}.`}
          //   />
          // );
          return <div className="dca-card-item-header">POPULARITY INDEX <span className="ms-1"><TooltipComponent content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${degreesConferred} degrees conferred in this major in ${collegeData.majorSizeTimeframe}.`} /></span></div>
        case collegeData.majorSizeMetric === 2:
          // return (
          //   <TooltipComponent
          //     content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${numEnrollees} students enrolled in this major in ${collegeData.majorSizeTimeframe}.`}
          //   />
          // )
          return <div className="dca-card-item-header">POPULARITY INDEX <span className="ms-1"><TooltipComponent content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${numEnrollees} students enrolled in this major in ${collegeData.majorSizeTimeframe}.`} /></span></div>
      }
    }

    // return <TooltipComponent content={`There is insufficient data from the college to compute the popularity index for this major.`} />;

    return <div className="dca-card-item-header">POPULARITY INDEX <span className="ms-1"><TooltipComponent content={`There is insufficient data from the college to compute the popularity index for this major.`} /></span></div>

  };

  const returnTooltipMobile = (sizeIndex, collegeId, numEnrollees, degreesConferred) => {

    if (sizeIndex === null || sizeIndex === undefined) {
      // return <TooltipComponent content={"There is insufficient data from the college to compute the popularity index for this major."} />
      return <div className="dca-card-item-header d-flex flex-row"><TooltipComponentMobileInline inlineText={'POPULARITY INDEX'} content={"There is insufficient data from the college to compute the popularity index for this major."} /></div>
    }

    if (!colleges || colleges.length === 0) {
      // return <TooltipComponent content={`College data loading...`} />;
      return <div className="dca-card-item-header d-flex flex-row"><TooltipComponentMobileInline inlineText={'POPULARITY INDEX'} content={`College data loading...`} /></div>
    }

    let college = colleges.find((item) => parseInt(item.id) === collegeId);
    let collegeData = collegesData.find((item) => parseInt(item.id) === collegeId)

    if (!college) {
      // return <TooltipComponent content={`College ID ${collegeId} data could not be found`} />;
      return <div className="dca-card-item-header d-flex flex-row"><TooltipComponentMobileInline inlineText={'POPULARITY INDEX'} content={`College ID ${collegeId} data could not be found`} /></div>
    }

    if (college && collegeData && collegeData.majorSizeMetric) {
      switch (true) {
        case collegeData.majorSizeMetric === 1:
          // return (
          //   <TooltipComponent
          //     content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${degreesConferred} degrees conferred in this major in ${collegeData.majorSizeTimeframe}.`}
          //   />
          // );
          return <div className="dca-card-item-header d-flex flex-row"><TooltipComponentMobileInline inlineText={'POPULARITY INDEX'} content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${degreesConferred} degrees conferred in this major in ${collegeData.majorSizeTimeframe}.`} /></div>
        case collegeData.majorSizeMetric === 2:
          // return (
          //   <TooltipComponent
          //     content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${numEnrollees} students enrolled in this major in ${collegeData.majorSizeTimeframe}.`}
          //   />
          // )
          return <div className="dca-card-item-header d-flex flex-row"><TooltipComponentMobileInline inlineText={'POPULARITY INDEX'} content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${numEnrollees} students enrolled in this major in ${collegeData.majorSizeTimeframe}.`} /></div>
      }
    }

    // return <TooltipComponent content={`There is insufficient data from the college to compute the popularity index for this major.`} />;

    return <div className="dca-card-item-header d-flex flex-row"><TooltipComponentMobileInline inlineText={'POPULARITY INDEX'} content={`There is insufficient data from the college to compute the popularity index for this major.`} /></div>

  };

  let cardContentState = false

  // Using conditional to only render the card content when the major and college variables of the result object are not equal to '-'. Will otherwise render the cards before the text is assigned and will appear as '- at -'. Then after a short duration, all text will render correctly

  const cardContent = mobile ? (
    <div>
      {results.map((res, index) => {
        const major = majors.find((item) => item.id === res.major_id)?.name || '-';
        const college = colleges.find((item) => parseInt(item.id) === res.college_id)?.name || '-';

        if (major !== '-' && college !== '-') {
          cardContentState = true
          return (
            <div
              key={res.id}
              className="mt-5 hover-elevate-up dca-card-item">

              {apiToken != undefined ? <SaveButtonMajorCard
                user={user}
                majorId={res.major_id}
                setUser={setUser}
                programID={res.id}
                mobile={true}
              /> : <SaveButtonCardNoAuth setPrompt={setPrompt}
                setNavigateTo={setNavigateTo}
                mobile={true} />}

              <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px', width: '100%' }}>
                <img style={{ width: '75px', height: '75px', margin: '10px 0 10px 0' }} alt="college_logo" src={collegeLogos[res.college_id]} />

                {/* <h3 className="mt-5 d-flex">{collegeIdConversion(res.major_id, res.college_id)}</h3> */}

                <span className="dca-card-item-nameField-major">{collegeIdConversion(res.major_id, res.college_id)}</span>

              </div>

              <div className="d-flex justify-content-center">
                <span
                  className="align-self-center"
                  style={{ width: '90%', marginTop: '20px' }}>{res.programDescription ? res.programDescription : '-'}</span>
              </div>

              <div className="d-flex flex-column justify-content-center" style={{ width: '100%', marginTop: '17px' }}>
                <div className="d-flex flex-row align-self-center justify-content-between" style={{ width: '90%', margin: '20px' }}>

                  <div className="d-flex flex-column" style={{ marginRight: '10px' }}>
                    {/* <div className="dca-card-item-header d-flex flex-row">
                      <div className="d-flex flex-row">POPULARITY INDEX <div className="ms-1">{returnTooltipMobile(res.sizeIndex, res.college_id, res.numEnrollees, res.degreesConferred)}</div>
                      </div>
                    </div> */}
                    {returnTooltipMobile(res.sizeIndex, res.college_id, res.numEnrollees, res.degreesConferred)}
                    <div className="d-flex flex-row align-items-center">
                      <PopularityIndex index={res.sizeIndex} />
                    </div>
                  </div>

                  <div className="d-flex flex-column" style={{ marginRight: '10px' }}>
                    {/* <div className="dca-card-item-header d-flex flex-row">MEDIAN ANNUAL EARNINGS {res.mdn4yrEarnings ? <span className="ms-1"><TooltipComponentMobile content={`This is the median annual income (at 4 years after program completion) for working graduates from ${college} with this major.`} /></span> : <span className="ms-1"><TooltipComponentMobile content={`Earnings data for ${college} graduates with this major is not currently available.`} /></span>}</div> */}
                    <div className="dca-card-item-header d-flex flex-row">
                      {res.mdn4yrEarnings ? <TooltipComponentMobileInline inlineText={'MEDIAN ANNUAL EARNINGS'} content={`This is the median annual income (at 4 years after program completion) for working graduates from ${college} with this major.`} /> : <TooltipComponentMobileInline inlineText={'MEDIAN ANNUAL EARNINGS'} content={`Earnings data for ${college} graduates with this major is not currently available.`} />}
                    </div>


                    <div className="dca-card-item-text">{res.mdn4yrEarnings ? `$${numberConverter(res.mdn4yrEarnings)}` : '-'}</div>
                  </div>

                  <div className="d-flex flex-column">
                    <div className="dca-card-item-header">MORE INFO</div>
                    <a
                      className="dca-card-item-text"
                      href={res.overviewURL}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGAEventExtLink(res.id)
                      }}>Link</a>
                  </div>

                </div>
              </div>
            </div>
          )
        }

        else {

          return null
        }
      })}

    </div>
  ) : (
    <div>
      {results.map((res, index) => {
        const major = majors.find((item) => item.id === res.major_id)?.name || '-';
        const college = colleges.find((item) => parseInt(item.id) === res.college_id)?.name || '-';

        if (major !== '-' && college !== '-') {
          cardContentState = true
          return (
            <div
              key={res.id}
              className="mt-5 hover-elevate-up dca-card-item">

              {apiToken != undefined ? <SaveButtonMajorCard
                user={user}
                majorId={res.major_id}
                setUser={setUser}
                programID={res.id}
              /> : <SaveButtonCardNoAuth setPrompt={setPrompt}
                setNavigateTo={setNavigateTo} />}

              <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px', width: '100%' }}>
                <img style={{ width: '75px', height: '75px', margin: '10px 0 10px 0' }} alt="college_logo" src={collegeLogos[res.college_id]} />

                {/* <h3 className="mt-5 d-flex">{collegeIdConversion(res.major_id, res.college_id)}</h3> */}

                <span className="dca-card-item-nameField-major">{collegeIdConversion(res.major_id, res.college_id)}</span>

              </div>

              <div className="d-flex justify-content-center">
                <span
                  className="align-self-center"
                  style={{ width: '90%', marginTop: '20px' }}>{res.programDescription ? res.programDescription : '-'}</span>
              </div>

              <div className="d-flex flex-column justify-content-center" style={{ marginTop: '17px', width: '100%', minWidth: '100% !important' }}>
                <div className="d-flex flex-row align-self-center justify-content-between" style={{ width: '90%', margin: '20px' }}>

                  <div className="d-flex flex-column">
                    {/* <div className="dca-card-item-header">
                      <div className="d-flex flex-row">POPULARITY INDEX <div className="ms-1">{returnTooltip(res.sizeIndex, res.college_id, res.numEnrollees, res.degreesConferred)}</div></div>
                    </div> */}
                    {returnTooltip(res.sizeIndex, res.college_id, res.numEnrollees, res.degreesConferred)}
                    <div className="d-flex flex-row align-items-center">
                      <PopularityIndex index={res.sizeIndex} />
                    </div>
                  </div>

                  <div className="d-flex flex-column">
                    <div className="dca-card-item-header">MEDIAN ANNUAL EARNINGS {res.mdn4yrEarnings ? <span className="ms-1"><TooltipComponent content={`This is the median annual income (at 4 years after program completion) for working graduates from ${college} with this major.`} /></span> : <span className="ms-1"><TooltipComponent content={`Earnings data for ${college} graduates with this major is not currently available.`} /></span>}</div>
                    <div className="dca-card-item-text">{res.mdn4yrEarnings ? `$${numberConverter(res.mdn4yrEarnings)}` : '-'}</div>
                  </div>

                  <div className="d-flex flex-column">
                    <div className="dca-card-item-header">MORE INFO</div>
                    <a
                      className="dca-card-item-text"
                      href={res.overviewURL}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGAEventExtLink(res.id)
                      }}>Link</a>
                  </div>

                </div>
              </div>
            </div>
          )
        }

        else {

          return null
        }
      })}

    </div>
  )

  const cardContentIfFalse = (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ maxWidth: '1129px', marginTop: '80px' }}>
      <div className='mt-5'>No results meet your criteria</div>
    </div>
  )

  const desktopReturn = (
    <div className='dca-card-container' style={{ minWidth: '628px' }}>

      {/* HEADER */}
      <div className="d-flex flex-row justify-content-between align-items-end">

        <div className="d-flex flex-row">
          <div className="me-3 align-bottom dca-card-items-found-text">Displaying {results.length} programs</div>
          <div
            onClick={handleSortOrderChange}
            style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
            className='text-muted fs-7 fw-bolder align-self-end'
          >by {sortOrder} {sortKeyReturn(sortKey)} {sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
            {sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
          </div>
        </div>

        <select
          className='form-select w-25 align-bottom'
          data-control="select2"
          value={sortKey}
          onChange={handleSortKeyChange}
        >
          {sortKeys.map((res, id) => (
            <option key={id} value={Object.values(res)}>{Object.keys(res)}</option>
          ))}
        </select>
      </div>
      {/* END HEADER */}

      {cardContentState ? cardContent : cardContentIfFalse}

    </div>
  )

  const mobileReturn = (
    <div className='dca-card-container'>

      {/* HEADER */}
      <div className='dca-college-search-mobile-display-sort'>

        <div className='dca-college-search-mobile-display-sort-content'>
          <div className='d-flex flex-column'>
            <div className='align-bottom dca-card-items-found-text'>Displaying {results.length} programs</div>

            <div onClick={handleSortOrderChange} style={{ cursor: 'pointer' }}>
              <span className='dca-font-M' style={{ textDecoration: 'underline', width: 'max-content' }}>{sortOrder}</span>
              {sortOrder === 'Ascending' ? <img src={mobileSortIcon} alt='sort-carat' className='dca-college-search-sort-carat-a'></img> : <img src={mobileSortIcon} alt='sort-carat' className='dca-college-search-sort-carat-b'></img>}
            </div>
          </div>
        </div>

        <MobileSortSelect
          sortKeyOptions={sortKeysMobile}
          sortKey={sortKey}
          setSortKey={setSortKey} />
      </div>
      {/* END HEADER */}

      {cardContentState ? cardContent : cardContentIfFalse}

    </div>
  )

  // return (
  //   <div className='dca-card-container'>

  //     {/* HEADER */}
  //     <div className="d-flex flex-row justify-content-between align-items-end">

  //       <div className="d-flex flex-row">
  //         <div className="me-3 align-bottom dca-card-items-found-text">Displaying {results.length} programs</div>
  //         <div
  //           onClick={handleSortOrderChange}
  //           style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
  //           className='text-muted fs-7 fw-bolder align-self-end'
  //         >by {sortOrder} {sortKeyReturn(sortKey)} {sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
  //           {sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
  //         </div>
  //       </div>

  //       <select
  //         className='form-select w-25 align-bottom'
  //         data-control="select2"
  //         value={sortKey}
  //         onChange={handleSortKeyChange}
  //       >
  //         {sortKeys.map((res, id) => (
  //           <option key={id} value={Object.values(res)}>{Object.keys(res)}</option>
  //         ))}
  //       </select>
  //     </div>
  //     {/* END HEADER */}

  //     {cardContentState ? cardContent : cardContentIfFalse}

  //   </div>
  // )

  return (
    <div>
      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex'>
        {desktopReturn}
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex d-md-none'>
        {mobileReturn}
      </div>
    </div>
  )
}

export { Cards }
