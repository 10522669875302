import { useRef, useEffect } from "react";
import noUiSlider from 'nouislider'
import { useLocation } from "react-router";

const MedianSAT = ({ satMin, satMax, setSATMin, setSATMax, resetStateToggle }) => {

  const location = useLocation()
  const satSliderRef: any = useRef(null);
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (satSliderRef.current && satSliderRef.current.noUiSlider) {
      satSliderRef.current.noUiSlider.set([satMin, satMax]);
    }
  }, [satMin, satMax]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setSATMin(0);
    setSATMax(1600);

    if (satSliderRef.current && satSliderRef.current.noUiSlider) {
      satSliderRef.current.noUiSlider.set([0, 1600]);
    }
  }, [resetStateToggle]);

  useEffect(() => {
    const slider: any = document.querySelector("#kt_slider_sat");

    if (slider && !slider.noUiSlider) {

      noUiSlider.create(slider, {
        start: [0, 1600],
        // connect: true,
        range: {
          "min": 0,
          "max": 1600
        },
        step: 10,
        tooltips: [
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
        ]
      });

      slider.noUiSlider.on("set", function (values, handle) {
        if (handle === 0) {
          setSATMin(parseInt(values[0]))
        }
        if (handle === 1) {
          setSATMax(parseInt(values[1]))
        }

      });

      satSliderRef.current = slider;

    }

    return () => {
      if (slider && slider.noUiSlider) {
        slider.noUiSlider.destroy();
      }
    };


  }, [])

  const medianSATSelect = (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>MEDIAN SAT SCORE</div>
      <div className="mb-0" style={{ marginTop: '50px', zIndex: '1' }}>
        <div id="kt_slider_sat"></div>
      </div>
    </div>
  )

  return medianSATSelect
}


export { MedianSAT }

const MedianSATMobile = ({ satMin, satMax, setSATMin, setSATMax, resetStateToggle }) => {

  const location = useLocation()
  const satSliderRef: any = useRef(null);
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (satSliderRef.current && satSliderRef.current.noUiSlider) {
      satSliderRef.current.noUiSlider.set([satMin, satMax]);
    }
  }, [satMin, satMax]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setSATMin(0);
    setSATMax(1600);

    if (satSliderRef.current && satSliderRef.current.noUiSlider) {
      satSliderRef.current.noUiSlider.set([0, 1600]);
    }
  }, [resetStateToggle]);

  useEffect(() => {
    const slider: any = document.querySelector("#kt_slider_sat_mobile");

    if (slider && !slider.noUiSlider) {

      noUiSlider.create(slider, {
        start: [0, 1600],
        // connect: true,
        range: {
          "min": 0,
          "max": 1600
        },
        step: 10,
        tooltips: [
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
        ]
      });

      slider.noUiSlider.on("set", function (values, handle) {
        if (handle === 0) {
          setSATMin(parseInt(values[0]))
        }
        if (handle === 1) {
          setSATMax(parseInt(values[1]))
        }

      });

      satSliderRef.current = slider;

    }

    return () => {
      if (slider && slider.noUiSlider) {
        slider.noUiSlider.destroy();
      }
    };


  }, [])

  const medianSATSelect = (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>MEDIAN SAT SCORE</div>
      <div className="mb-0 d-flex justify-content-center" style={{ marginTop: '50px', zIndex: '1' }}>
        <div id="kt_slider_sat_mobile"></div>
      </div>
    </div>
  )

  return medianSATSelect
}


export { MedianSATMobile }