import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react"
import axios from "axios";
import Select from 'react-select'

import { URL, getStripePrice, pageRoutes, safetyAccessCode, updateTitleAndDescription, safetyPriceID, collegeLogos } from "../../components/dictionary"
import { CollegeReactGAHandlers, ECReactGAHandlers } from "../../components/ReactGAHandlers"
import { PurchaseButton, PurchaseButtonNav } from "../../components/Marketing/PurchaseButton";
import { StickyNavbar } from "../../components/Marketing/StickyNavbar";
import { getAuth } from '../../modules/auth';
import { collegeMap } from '../../components/collegeMap';
import { Safety } from '../../components/CollegeDetail/TabComponents/Safety';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { FAQ } from '../../components/Marketing/FAQ';
import { ConnectEmailCTA } from '../../components/Marketing/ConnectEmailCTA';
import { Testimonials } from '../../components/Marketing/Testimonials';
import { HoverCards } from '../../components/Marketing/HoverCards';
import { MarketingImageWrapper } from '../../components/Marketing/ImageWrappers';
import { CollegeSelectComponent } from '../../components/Marketing/CollegeSelectComponent';

import diamond from '../../../custom_assets/marketing/hovercard-diamond.png'
import photo1 from '../../../custom_assets/marketing/crime_safety/photo_1.png'
import photo2 from '../../../custom_assets/marketing/crime_safety/photo_2.png'
import photo3 from '../../../custom_assets/marketing/crime_safety/photo_3.png'
import photo4 from '../../../custom_assets/marketing/crime_safety/photo_4.png'

import mobilePhoto1 from '../../../custom_assets/marketing/crime_safety/mobile/photo_1.png'
import mobilePhoto2 from '../../../custom_assets/marketing/crime_safety/mobile/photo_2.png'
import mobilePhoto3 from '../../../custom_assets/marketing/crime_safety/mobile/photo_3.png'
import { SafetyAggregateModal } from '../../components/CollegeDetail/TabComponents/Modals/SafetyAggregateModal';
import { SafetyAnnualAveragePer1KModal } from '../../components/CollegeDetail/TabComponents/Modals/SafetyAnnualAveragePer1KModal';
import { SafetyAnnualAverageTotalModal } from '../../components/CollegeDetail/TabComponents/Modals/SafetyAnnualAverageTotalModal';

const MarketingCrimeSafety = ({ user, clientSecret, setClientSecret, setUpgradeUserGroupAccessCode, setSelectedTab, collegesId, setNavigateTo }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const { handleGAEventPurchaseModalPurchase } = CollegeReactGAHandlers()
  const { handleECGAEventPurchaseModalPurchase } = ECReactGAHandlers()

  const navigate = useNavigate()
  const location = useLocation()

  const [nameOptions, setNameOptions] = useState<any>([])
  const [selectedCollege, setSelectedCollege] = useState<any>(undefined);
  const [collegeData, setCollegeData] = useState<any>(undefined)
  const [crimeAnnual, setCrimeAnnual] = useState<any>(undefined)
  const [premiumContentPrice, setPremiumContentPrice] = useState<any>(undefined)

  const [saModalData, setSAModalData] = useState<any>({ collegesSafetyIndex: null, dataLoaded: null })
  const [safety1KModalData, setSafety1KModalData] = useState<any>({
    allCrimeAvgPer1KData: null, allCrimeRankPer1KData: null, dataLoaded: null, modalTitle: null, modalCategory: null
  })
  const [safetyTotalModalData, setSafetyTotalModalData] = useState<any>({
    allCrimeAvgTotalData: null, allCrimeRankTotalData: null, dataLoaded: null, modalTitle: null, modalCategory: null
  })

  const handleCollegeSelectChange = (selectedOption) => {
    selectedOption ? navigate(pageRoutes.premiumCrimeSafetyWithId(selectedOption.hyphenated)) : navigate(pageRoutes.premiumCrimeSafety)
  };

  const getSelectedCollegeData = async (selectedCollege) => {
    let college: any = undefined
    try {
      let res = await axios.get(`${URL}/colleges/${selectedCollege?.value}`)
      college = res.data
      setCollegeData(res.data)
    } catch (error) {
      console.log('Could not find college info:', error)
      throw error
    }

    try {
      let crimeArr: any[] = []
      if (college && college.crime_annual) {
        college.crime_annual.forEach((res, index) => {
          crimeArr.sort((a, b) => b.year - a.year)
          crimeArr.sort((a, b) => b.location - a.location)
          crimeArr.push(res)
          setCrimeAnnual(crimeArr)
        })
      }
    } catch (error) {
      console.log('Error generating the crime annual data for college')
    }
  }

  const getPrice = async () => {
    let price = await getStripePrice(safetyPriceID)
    price ? setPremiumContentPrice(price) : setPremiumContentPrice(undefined)
  }

  useEffect(() => {
    let names: any = []
    collegeMap.forEach((college) => {
      names.push({ value: college.id, label: college.name, hyphenated: college.hyphenated })
    })
    names = names.sort((a, b) => a.label.localeCompare(b.label))
    setNameOptions(names)

    updateTitleAndDescription(pageRoutes.premiumCrimeSafety)

    getPrice()
  }, [])

  useEffect(() => {
    let preselectedCollege: string | undefined = undefined
    preselectedCollege = location.pathname.split(`/`)[1]
    preselectedCollege = preselectedCollege.split(`-crime-and-safety-report`)[0]

    if (preselectedCollege) {
      setSelectedCollege(nameOptions.find(opt => opt.hyphenated == preselectedCollege))
    }

    else {
      setSelectedCollege(undefined)
    }

  }, [location.pathname, nameOptions]);

  useEffect(() => {
    if (selectedCollege) {
      getSelectedCollegeData(selectedCollege);
    }

    else {
      setCollegeData(undefined);
    }
  }, [selectedCollege])

  const faqData = [
    { question: 'Where is this data sourced from?', answer: 'Colleges are required by The Clery Act to report campus crime data and those statistics are made available by the US Department of Education. Building upon that raw data, NextFour has compiled and analyzed the information to provide exclusive access to relative rankings and actionable insights via an intuitive, easy-to-use display.' },
    { question: 'How many colleges are covered in this report?', answer: <span>This report provides access to crime and safety statistics (broken out by 38 incident types) for all of the top 100 U.S. colleges (according to NextFour's metarank). The full set of included colleges are listed in the <a href={pageRoutes.collegeSearch} className='dca-marketing-college-explorer-link' target="_blank">College Explorer</a>. Importantly, these 100 colleges are stack-ranked against one another (by specific crime statistic) so you can quickly see how they stack up in the areas of greatest concern.</span> },
    { question: `Why isn't this report free?`, answer: 'While nearly all of the content, tools and services on NextFour are made freely available, this report is offered as a premium service (with a small fee) because of the significant investment required by NextFour to complete the proprietary analyses underlying the report.' }
  ]

  const testimonialData = [
    { statement: `“For my wife and I, as we were thinking ahead to where our daughter would be applying for college, the most important consideration was where she would be happy, safe and secure. And while of course you can't know for certain, reviewing this data and using it to help inform her college list gave us tremendous peace of mind.”`, source: 'Steve', relation: 'Parent of high school senior' },
    { statement: `“We heard about some schools having a reputation for being 'dangerous' and others were thought to be 'safe' but it wasn't until we saw the eye-opening data in this report that we really knew, with confidence, the reality of the situation across college campuses.”`, source: 'Kaitlyn', relation: 'Parent of high school junior' }
  ]

  const marketingContent = (
    <>

      <div className="dca-marketing-page-premium-content-label" style={{ marginTop: '30px' }}><img src={diamond}></img> PREMIUM CONTENT</div>

      <div className="dca-marketing-page-headline" style={{ width: '80vw' }}>The only place to easily understand comprehensive college safety data</div>

      <div className="dca-marketing-sub-headline">We believe campus safety and security are critical aspects of student life, and so we've made it easier for families to factor crime statistics into their college evaluation process.</div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-block'>
        <MarketingImageWrapper
          altText={'photoCrimeSafety'}
          photo={photo1}
        />
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-block d-md-none'>
        <MarketingImageWrapper
          altText={'photoCrimeSafety'}
          photo={mobilePhoto1}
        />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'photoCrimeSafety'}
          photo={mobilePhoto2}
        />
      </div>

      {premiumContentPrice && <PurchaseButton
        user={user}
        priceID={safetyPriceID}
        groupAccessCode={safetyAccessCode}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        premiumProductName={'Crime Safety'}
        premiumContentPrice={premiumContentPrice}
        topMargin={'70px'}
        bottomMargin={'70px'}
        page={'Crime'}
        clickLocation={'Middle'}
        activityID={undefined}
        setNavigateTo={setNavigateTo}
      />}

      <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '22px' }}>
        <div className="dca-marketing-pt-header">Deep-dive on campus safety statistics for your colleges of interest</div>
        <div className="dca-marketing-pt-body">Explore annual crime and safety metrics for each of the top 100 US colleges, with a comprehensive analysis covering 38 incident types over three years, all consolidated onto a single webpage for easy access</div>
      </div>

      <MarketingImageWrapper
        altText={'photoCrimeSafety'}
        photo={photo2} />

      <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '18px', marginTop: '140px' }}>
        <div className="dca-marketing-pt-header">Exclusive visualizations to help understand high-level trends and insights from the data</div>
        <div className="dca-marketing-pt-body">We make sense of the raw data by putting it into context. Each data-point is displayed with a color-coded ranking indicator to intuively indicate how a specific college's crime metric compares against the other top 100 colleges.</div>
      </div>

      <div className='d-none d-md-block'>
        <MarketingImageWrapper
          altText={'photoCrimeSafety'}
          photo={photo3} />
      </div>

      <div className='d-block d-md-none'>
        <MarketingImageWrapper
          altText={'photoCrimeSafety'}
          photo={mobilePhoto3}
        />
      </div>

      <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '18px', marginTop: '140px' }}>
        <div className="dca-marketing-pt-header">Compare colleges holistically with our proprietary aggregate safety index</div>
        <div className="dca-marketing-pt-body">Access the aggregate safety index, which factors in all the source data (accounting for incident frequency, type/severity, and campus population) to compute a single bottom-line indicator of criminal statistics and yields a stack ranking of safety and security among the top 100 colleges.</div>
      </div>

      <MarketingImageWrapper
        altText={'photoCrimeSafety'}
        photo={photo4}
        wrapper='B' />

      <div className='dca-marketing-disclaimer-text'>Note: Images above are meant to be illustrative only and do not represent the actual data for any specific college.</div>

      <FAQ faqData={faqData} />

      <ConnectEmailCTA />

      <Testimonials data={testimonialData} />

      {premiumContentPrice && <PurchaseButton
        user={user}
        priceID={safetyPriceID}
        groupAccessCode={safetyAccessCode}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        premiumProductName={'Crime Safety'}
        premiumContentPrice={premiumContentPrice}
        topMargin={'50px'}
        bottomMargin={'140px'}
        page={'Crime'}
        clickLocation={'Bottom'}
        activityID={undefined}
        setNavigateTo={setNavigateTo}
      />}

      <HoverCards cardsOption='CrimeSafety' />
    </>
  )

  const accessContent = (collegeData && crimeAnnual) ? (<div className='d-flex flex-column align-items-center'>
    <div className="dca-marketing-page-premium-content-label"><img src={diamond}></img> PREMIUM CONTENT</div>
    <div className="dca-marketing-page-premium-content-headline">Crime & Safety</div>
    <CollegeSelectComponent options={nameOptions}
      value={selectedCollege}
      onChange={handleCollegeSelectChange} />
    <div
      // className='dca-marketing-page-college-logo-and-name-wrapper'
      style={{ width: '85vw' }}>
      <div className='dca-marketing-page-college-logo-and-name'>
        <img
          alt="collegeLogo"
          className='dca-marketing-page-college-logo-img'
          src={collegeLogos[selectedCollege?.value]} />
        {selectedCollege ? selectedCollege?.label : 'N/A'}
      </div>
    </div>
    <div className="container-fluid d-flex justify-content-center" style={{ width: '100%' }}>
      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex justify-content-center' style={{ width: '85vw', maxWidth: '85vw' }}>
        <Safety college={collegeData} crimeAnnual={crimeAnnual} collegeListLength={collegeMap.length} collegesId={collegesId} setSelectedTab={setSelectedTab} setSafetyTotalModalData={setSafetyTotalModalData} setSAModalData={setSAModalData} setSafety1KModalData={setSafety1KModalData} pageView={true} />
      </div>
      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-md-none' style={{ width: '85vw', maxWidth: '85vw' }}>
        <Safety college={collegeData} crimeAnnual={crimeAnnual} collegeListLength={collegeMap.length} collegesId={collegesId} setSelectedTab={setSelectedTab} setSafetyTotalModalData={setSafetyTotalModalData} setSAModalData={setSAModalData} setSafety1KModalData={setSafety1KModalData} pageView={true} mobile={true} />
      </div>
      {/* <div style={{ width: '85vw' }}>
        <Safety college={collegeData} crimeAnnual={crimeAnnual} collegeListLength={collegeMap.length} collegesId={collegesId} setSelectedTab={setSelectedTab} setSafetyTotalModalData={setSafetyTotalModalData} setSAModalData={setSAModalData} setSafety1KModalData={setSafety1KModalData} />
      </div> */}
    </div>

    <div className='dca-marketing-college-hover-cards-spacer'></div>

    <HoverCards cardsOption='CrimeSafety' hyphenated={selectedCollege?.hyphenated} />

    <SafetyAggregateModal
      collegesSafetyIndex={saModalData.collegesSafetyIndex}
      dataLoaded={saModalData.dataLoaded} />

    <SafetyAnnualAveragePer1KModal
      allCrimeAvgPer1KData={safety1KModalData.allCrimeAvgPer1KData}
      allCrimeRankPer1KData={safety1KModalData.allCrimeRankPer1KData}
      dataLoaded={safety1KModalData.dataLoaded}
      modalTitle={safety1KModalData.modalTitle}
      modalCategory={safety1KModalData.modalCategory} />

    <SafetyAnnualAverageTotalModal
      allCrimeAvgTotalData={safetyTotalModalData.allCrimeAvgTotalData}
      allCrimeRankTotalData={safetyTotalModalData.allCrimeRankTotalData}
      dataLoaded={safetyTotalModalData.dataLoaded}
      modalTitle={safetyTotalModalData.modalTitle}
      modalCategory={safetyTotalModalData.modalCategory} />
  </div>) : (<div className='d-flex flex-column align-items-center'>
    <div className="dca-marketing-page-premium-content-label"><img src={diamond}></img> PREMIUM CONTENT</div>
    <div className="dca-marketing-page-premium-content-headline">Crime & Safety</div>
    <div className='d-flex justify-content-center'><CollegeSelectComponent options={nameOptions}
      value={selectedCollege}
      onChange={handleCollegeSelectChange} /></div>

    <div className='dca-marketing-page-no-select-text'>Select a college to display its Crime & Safety Report</div>
  </div>)

  return permissionGroups?.includes(safetyAccessCode) ? (
    accessContent
  ) : (
    <div className="d-flex flex-column align-items-center">
      <StickyNavbar user={user} purchaseButton={premiumContentPrice && <PurchaseButtonNav
        user={user}
        priceID={safetyPriceID}
        groupAccessCode={safetyAccessCode}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        premiumProductName={'Crime Safety'}
        premiumContentPrice={premiumContentPrice}
        page={'Crime'}
        clickLocation={'Header'}
        activityID={undefined}
        setNavigateTo={setNavigateTo}
      />} />
      {marketingContent}
    </div>
  )
}

export { MarketingCrimeSafety }