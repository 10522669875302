import { URL } from "../dictionary"
import axios from "axios"
import { getAuth, useAuth } from "../../modules/auth"
import { TestScoreStrategyReactGAHandlers } from "../ReactGAHandlers"
let apiToken: any = undefined

if (getAuth()?.api_token && getAuth()?.groups) {
  apiToken = getAuth()?.api_token
}

const editScoreModalFetchData = async (user, currentUser, setFormValues) => {

  try {

    let userID = user?.user_id || currentUser?.user_id

    const userDataAxios = axios.get(`${URL}/user_profiles/${userID}`)

    const [userDataRes] = await Promise.all([
      userDataAxios
    ])

    await Promise.all([
      setFormValues({
        satVerbal: userDataRes.data.satVerbal,
        satMath: userDataRes.data.satMath,
        actComposite: userDataRes.data.actComposite,
        actEnglish: userDataRes.data.actEnglish,
        actMath: userDataRes.data.actMath,
        actWriting: userDataRes.data.actWriting,
        actScience: userDataRes.data.actScience,
        actReading: userDataRes.data.actReading
      })
    ])

  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const EditScoreProfile = ({ user, setFormValues, mobile = false, pill = false }) => {

  const {
    handleGAEventEditScoreProfile
  } = TestScoreStrategyReactGAHandlers()

  const { currentUser } = useAuth()

  return pill ? (<button
    type="button"
    className='dca-test-score-modal-enter-scores-pill'
    data-bs-toggle="modal"
    data-bs-target="#editScoreProfile"
    onClick={() => {
      editScoreModalFetchData(user, currentUser, setFormValues);
      handleGAEventEditScoreProfile('card')
    }
    }
  >
    Enter Score(s) For Your Recommendation
  </button>) : (
    <div className={mobile ? 'd-flex justify-content-center' : ''}>
      <button
        type="button"
        className='dca-test-score-edit-your-score-profile-button'
        onClick={() => {
          editScoreModalFetchData(user, currentUser, setFormValues);
          handleGAEventEditScoreProfile('aside')
        }}
        data-bs-toggle="modal"
        data-bs-target="#editScoreProfile"
      >
        Edit Your Score Profile
      </button>
    </div>
  )
}

// export const EditScoreProfilePill = ({ user, setFormValues, mobile = false }) => {

//   const {
//     handleGAEventEditScoreProfile
//   } = TestScoreStrategyReactGAHandlers()

//   const { currentUser } = useAuth()

//   return (
//     <button
//       type="button"
//       className='dca-test-score-modal-enter-scores-pill'
//       data-bs-toggle="modal"
//       data-bs-target="#editScoreProfile"
//       onClick={() => {
//         editScoreModalFetchData(user, currentUser, setFormValues);
//         handleGAEventEditScoreProfile('card')
//       }
//       }
//     >
//       Enter Score(s) For Your Recommendation
//     </button>
//   )
// }

// export const EditScoreProfile = ({ userSATVerbal, userSATMath, userSATSuperScore, userActEnglish, userActMath, userActWriting, userActScience, userActReading, userACTBestScore, setUserSATVerbal, setUserSATMath, setUserACTBestScore, setUserActEnglish, setUserActMath, setUserActWriting, setUserActScience, setUserActReading, setUserprofile, setUserSATSuperScore, mobile = false }) => {

//   const {
//     handleGAEventEditScoreProfile,
//     handleGAEventOverlayButton
//   } = TestScoreStrategyReactGAHandlers()

//   const { currentUser } = useAuth()
//   const [formvalues, setFormValues] = useState<any>({ satVerbal: userSATVerbal, satMath: userSATMath, actComposite: userACTBestScore, actEnglish: userActEnglish, actMath: userActMath, actWriting: userActWriting, actScience: userActScience, actReading: userActReading })

//   const handleOnChange = (event) => {
//     setFormValues({ ...formvalues, [event.target.name]: event.target.value })
//     console.log(`${[event.target.name]}: ${event.target.value}`)
//   }

//   const handleOnChangeNull = (targetName) => {
//     setFormValues({ ...formvalues, [targetName]: "" })
//     console.log(targetName, ":", formvalues.targetName)
//   }

//   const fetchData = async () => {

//     try {

//       const userDataAxios = axios.get(`${URL}/user_profiles/${currentUser?.user_id}`)

//       const [userDataRes] = await Promise.all([
//         userDataAxios
//       ])

//       // userDataRes
//       console.log('user data from call:', userDataRes.data)

//       await Promise.all([
//         setFormValues({
//           satVerbal: userDataRes.data.satVerbal,
//           satMath: userDataRes.data.satMath,
//           actComposite: userDataRes.data.actComposite,
//           actEnglish: userDataRes.data.actEnglish,
//           actMath: userDataRes.data.actMath,
//           actWriting: userDataRes.data.actWriting,
//           actScience: userDataRes.data.actScience,
//           actReading: userDataRes.data.actReading
//         })
//       ])

//     } catch (error) {
//       console.error('Error fetching data:', error)
//     }
//   }


//   const handleSubmit = (event, userId, formValues) => {
//     event.preventDefault();

//     let cleanedFormValues = formValues

//     for (const key in cleanedFormValues) {
//       if (cleanedFormValues.hasOwnProperty(key) && cleanedFormValues[key] === '') {
//         cleanedFormValues[key] = null;
//       }
//     }

//     console.log('submitting these values:', cleanedFormValues)

//     const updateUserProfileTestScores = async () => {
//       const res = await axios.patch(`${URL}/user_profiles/${userId}`, cleanedFormValues)

//       console.log('Patch request successful:', res.data)
//     }

//     const updateStates = async () => {
//       let res = await axios.get(`${URL}/user_profiles/${userId}`)
//       setUserprofile(res.data)
//       console.log('Retrieved and updated user profile:', res.data)

//       setUserSATVerbal(res.data.satVerbal)
//       setUserSATMath(res.data.satMath)
//       setUserSATSuperScore(res.data.satVerbal + res.data.satMath)
//       setUserActEnglish(res.data.actEnglish)
//       setUserActMath(res.data.actMath)
//       setUserActWriting(res.data.actWriting)
//       setUserActScience(res.data.actScience)
//       setUserActReading(res.data.actReading)
//       setUserACTBestScore(res.data.actComposite)
//     }

//     try {

//       updateUserProfileTestScores().then(updateStates)
//       // // updateStates()
//       closeModalFunction('editScoreProfile')
//     } catch (error) {
//       console.log('Error updating user profile test scores:', error);
//       throw error
//     } finally {
//       handleGAEventOverlayButton("Submit")
//     }
//   }

//   const satScores = (
//     <div className="align-items-start">
//       <p className='dca-testscore-editscore-sat-section-title'>SAT Scores</p>
//       <div className="d-flex flex-row justify-content-between">
//         <div>
//           <label className="form-label dca-testscore-editscore-field-title">Best SAT Reading Score</label>
//           <input
//             type="number"
//             name="satVerbal"
//             min={200}
//             max={800}
//             className="form-control dca-testscore-editscore-input"
//             // placeholder={userSATVerbal}
//             value={formvalues.satVerbal}
//             onChange={(event) => handleOnChange(event)}
//           />
//           <i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("satVerbal")}></i>
//         </div>

//         <div>
//           <label className="form-label dca-testscore-editscore-field-title">Best SAT Math Score</label>
//           <input
//             type="number"
//             name="satMath"
//             min={200}
//             max={800}
//             className="form-control dca-testscore-editscore-input"
//             // placeholder={userSATMath}
//             value={formvalues.satMath}
//             onChange={(event) => handleOnChange(event)}
//           /><i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("satMath")}></i>
//         </div>
//       </div>
//       {/* <div>
//         <label className="form-label fw-bold" style={{ fontSize: '18px', marginTop: '15px' }}>SAT Total SuperScore</label>
//         <input
//           type="number"
//           disabled
//           min={400}
//           max={1600}
//           className="form-control"
//           placeholder={userSATSuperScore}
//           // value={satCompositeInput}
//           // onChange={(e) => setSatCompositeInput(e.target.value)}
//           style={{ background: gray, border: 'transparent' }}
//         />
//       </div> */}
//     </div>
//   )

//   const actScores = (
//     <div>
//       <p className='dca-testscore-editscore-act-section-title'>ACT Scores</p>

//       <div className="d-flex flex-row justify-content-between">
//         <div>
//           <label className="form-label dca-testscore-editscore-field-title">Best ACT Composite Score</label>
//           <input
//             type="number"
//             name="actComposite"
//             min={1}
//             max={36}
//             className="form-control dca-testscore-editscore-input"
//             // placeholder={userACTBestScore}
//             value={formvalues.actComposite}
//             onChange={(event) => handleOnChange(event)}
//           />
//           <i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actComposite")}></i>
//         </div>

//         <div>
//           <label className="form-label dca-testscore-editscore-field-title">Best ACT Reading Score</label>
//           <input
//             type="number"
//             name="actReading"
//             min={1}
//             max={36}
//             className="form-control dca-testscore-editscore-input"
//             // placeholder={userActReading}
//             value={formvalues.actReading}
//             onChange={(event) => handleOnChange(event)}
//           />
//           <i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actReading")}></i>
//         </div>
//       </div>

//       <div className="d-flex flex-row justify-content-between">
//         <div>
//           <label className="form-label dca-testscore-editscore-field-title2">Best ACT Math Score</label>
//           <input
//             type="number"
//             name="actMath"
//             min={1}
//             max={36}
//             className="form-control dca-testscore-editscore-input"
//             // placeholder={userActMath}
//             value={formvalues.actMath}
//             onChange={(event) => handleOnChange(event)}
//           />
//           <i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actMath")}></i>
//         </div>

//         <div>
//           <label className="form-label dca-testscore-editscore-field-title2">Best ACT English Score</label>
//           <input
//             type="number"
//             name="actEnglish"
//             min={1}
//             max={36}
//             className="form-control dca-testscore-editscore-input"
//             // placeholder={userActEnglish}
//             value={formvalues.actEnglish}
//             onChange={(event) => handleOnChange(event)}
//           /><i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actEnglish")}></i>
//         </div>
//       </div>

//       <div className="d-flex flex-row justify-content-between">
//         <div>
//           <label className="form-label dca-testscore-editscore-field-title2">Best ACT Science Score</label>
//           <input
//             type="number"
//             name="actScience"
//             min={1}
//             max={36}
//             className="form-control dca-testscore-editscore-input"
//             // placeholder={userActScience}
//             value={formvalues.actScience}
//             onChange={(event) => handleOnChange(event)}
//           /><i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actScience")}></i>
//         </div>

//         <div>
//           <label className="form-label dca-testscore-editscore-field-title2">Best ACT Writing Score</label>
//           <input
//             type="number"
//             name="actWriting"
//             min={2}
//             max={12}
//             className="form-control dca-testscore-editscore-input"
//             // placeholder={userActWriting}
//             value={formvalues.actWriting}
//             onChange={(event) => handleOnChange(event)}
//           />
//           <i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actWriting")}></i>
//         </div>
//       </div>

//     </div>
//   )

//   // const desktopReturn = (
//   //   <div>
//   //     <button
//   //       type="button"
//   //       className='dca-test-score-edit-your-score-profile-button'
//   //       onClick={() => {
//   //         fetchData();
//   //         handleGAEventEditScoreProfile('aside')
//   //       }}
//   //       data-bs-toggle="modal"
//   //       data-bs-target="#editScoreProfile"
//   //     >
//   //       Edit Your Score Profile
//   //     </button>
//   //     <div className="modal fade" tabIndex={-1} id="editScoreProfile">
//   //       <div className="modal-dialog">
//   //         <div className="modal-content" style={{ width: '700px', maxWidth: '700px', marginTop: '120px', padding: '30px' }}>

//   //           <div className="modal-body fw-bold d-flex flex-column">
//   //             {/* <p style={{ fontSize: '30px' }} className="align-self-center">Your Score Profile</p> */}
//   //             <p className="dca-testscore-editscore-title">Your Score Profile</p>

//   //             {currentUser ? <form onSubmit={(event) => handleSubmit(event, currentUser?.user_id, formvalues)}>
//   //               {closeModalIcon('editScoreProfile', reactGATestStrategyController())}
//   //               {satScores}
//   //               {actScores}


//   //               <div className="d-flex flex-row justify-content-center" style={{ marginTop: '45px' }}>
//   //                 <button
//   //                   type="button"
//   //                   className="btn btn-light dca-testscore-editscore-discard-bttn"
//   //                   data-bs-dismiss="modal"
//   //                   id="closeModalIcon"
//   //                   onClick={() => handleGAEventOverlayButton("Discard")}
//   //                 >
//   //                   Discard
//   //                 </button>
//   //                 <button
//   //                   type="submit"
//   //                   className="rounded dca-testscore-editscore-submit-bttn"
//   //                 >
//   //                   Submit
//   //                 </button>
//   //               </div>
//   //             </form> : <div>Loading User Data ...</div>}
//   //           </div>

//   //         </div>
//   //       </div>
//   //     </div>
//   //   </div>
//   // )

//   // const mobileReturn = (
//   //   <div className="d-flex justify-content-center">
//   //     <button
//   //       type="button"
//   //       className='dca-test-score-edit-your-score-profile-button'
//   //       onClick={() => {
//   //         fetchData();
//   //         handleGAEventEditScoreProfile('aside')
//   //       }}
//   //       data-bs-toggle="modal"
//   //       data-bs-target="#editScoreProfile"
//   //     >
//   //       Edit Your Score Profile
//   //     </button>
//   //     <div className="modal fade" tabIndex={-1} id="editScoreProfile">
//   //       <div className="modal-dialog">
//   //         <div className="modal-content" style={{ width: '700px', maxWidth: '700px', marginTop: '120px', padding: '30px' }}>

//   //           <div className="modal-body fw-bold d-flex flex-column">
//   //             {/* <p style={{ fontSize: '30px' }} className="align-self-center">Your Score Profile</p> */}
//   //             <p className="dca-testscore-editscore-title">Your Score Profile</p>

//   //             {currentUser ? <form onSubmit={(event) => handleSubmit(event, currentUser?.user_id, formvalues)}>
//   //               {closeModalIcon('editScoreProfile', reactGATestStrategyController())}
//   //               {satScores}
//   //               {actScores}


//   //               <div className="d-flex flex-row justify-content-center" style={{ marginTop: '45px' }}>
//   //                 <button
//   //                   type="button"
//   //                   className="btn btn-light dca-testscore-editscore-discard-bttn"
//   //                   data-bs-dismiss="modal"
//   //                   id="closeModalIcon"
//   //                   onClick={() => handleGAEventOverlayButton("Discard")}
//   //                 >
//   //                   Discard
//   //                 </button>
//   //                 <button
//   //                   type="submit"
//   //                   className="rounded dca-testscore-editscore-submit-bttn"
//   //                 >
//   //                   Submit
//   //                 </button>
//   //               </div>
//   //             </form> : <div>Loading User Data ...</div>}
//   //           </div>

//   //         </div>
//   //       </div>
//   //     </div>
//   //   </div>
//   // )

//   // return mobile ? mobileReturn : desktopReturn

//   return (
//     <div className={mobile ? 'd-flex justify-content-center' : ''}>
//       <button
//         type="button"
//         className='dca-test-score-edit-your-score-profile-button'
//         onClick={() => {
//           fetchData();
//           handleGAEventEditScoreProfile('aside')
//         }}
//         data-bs-toggle="modal"
//         data-bs-target="#editScoreProfile"
//       >
//         Edit Your Score Profile
//       </button>
//       <div className="modal fade" tabIndex={-1} id="editScoreProfile">
//         <div className="modal-dialog">
//           <div className="modal-content" style={{ width: '700px', maxWidth: '700px', marginTop: '120px', padding: '30px' }}>

//             <div className="modal-body fw-bold d-flex flex-column">
//               {/* <p style={{ fontSize: '30px' }} className="align-self-center">Your Score Profile</p> */}
//               <p className="dca-testscore-editscore-title">Your Score Profile</p>

//               {currentUser ? <form onSubmit={(event) => handleSubmit(event, currentUser?.user_id, formvalues)}>
//                 {closeModalIconTestScoreGuidance(handleGAEventOverlayButton)}
//                 {satScores}
//                 {actScores}


//                 <div className="d-flex flex-row justify-content-center" style={{ marginTop: '45px' }}>
//                   <button
//                     type="button"
//                     className="btn btn-light dca-testscore-editscore-discard-bttn"
//                     data-bs-dismiss="modal"
//                     id="closeModalIcon"
//                     onClick={() => handleGAEventOverlayButton("Discard")}
//                   >
//                     Discard
//                   </button>
//                   <button
//                     type="submit"
//                     className="rounded dca-testscore-editscore-submit-bttn"
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </form> : <div>Loading User Data ...</div>}
//             </div>

//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export const EditScoreProfilePill = ({ userSATVerbal, userSATMath, userSATSuperScore, userActEnglish, userActMath, userActWriting, userActScience, userActReading, userACTBestScore, setUserSATVerbal, setUserSATMath, setUserACTBestScore, setUserActEnglish, setUserActMath, setUserActWriting, setUserActScience, setUserActReading, setUserprofile, setUserSATSuperScore }) => {

//   const { currentUser } = useAuth()

//   const {
//     handleGAEventEditScoreProfile,
//     handleGAEventOverlayButton
//   } = TestScoreStrategyReactGAHandlers()

//   // const [formvalues, setFormValues] = useState<any>({ satVerbal: userSATVerbal, satMath: userSATMath, satComposite: userSATSuperScore, actComposite: userACTBestScore, actEnglish: userActEnglish, actMath: userActMath, actWriting: userActWriting, actScience: userActScience, actReading: userActReading })

//   const [formvalues, setFormValues] = useState<any>({ satVerbal: userSATVerbal, satMath: userSATMath, actComposite: userACTBestScore, actEnglish: userActEnglish, actMath: userActMath, actWriting: userActWriting, actScience: userActScience, actReading: userActReading })

//   const fetchData = async () => {

//     try {

//       const userDataAxios = axios.get(`${URL}/user_profiles/${currentUser?.user_id}`)

//       const [userDataRes] = await Promise.all([
//         userDataAxios
//       ])

//       // userDataRes
//       console.log('user data from call:', userDataRes.data)

//       await Promise.all([
//         setFormValues({
//           satVerbal: userDataRes.data.satVerbal,
//           satMath: userDataRes.data.satMath,
//           actComposite: userDataRes.data.actComposite,
//           actEnglish: userDataRes.data.actEnglish,
//           actMath: userDataRes.data.actMath,
//           actWriting: userDataRes.data.actWriting,
//           actScience: userDataRes.data.actScience,
//           actReading: userDataRes.data.actReading
//         })
//       ])

//     } catch (error) {
//       console.error('Error fetching data:', error)
//     }
//   }

//   const handleOnChange = (event) => {
//     setFormValues({ ...formvalues, [event.target.name]: event.target.value === "" ? null : event.target.value })
//     console.log(`${[event.target.name]}: ${event.target.value}`)
//   }

//   const handleOnChangeNull = (targetName) => {
//     setFormValues({ ...formvalues, [targetName]: null })
//     console.log(targetName, ":", formvalues.targetName)
//   }

//   const handleSubmit = (event, userId, formValues) => {
//     event.preventDefault();

//     const updateUserProfileTestScores = async () => {
//       const res = await axios.patch(`${URL}/user_profiles/${userId}`, formValues)

//       console.log('Patch request successful:', res.data)
//     }

//     const updateStates = async () => {
//       let res = await axios.get(`${URL}/user_profiles/${userId}`)
//       setUserprofile(res.data)
//       console.log('Retrieved and updated user profile:', res.data)

//       setUserSATVerbal(res.data.satVerbal)
//       setUserSATMath(res.data.satMath)
//       setUserSATSuperScore(res.data.satVerbal + res.data.satMath)
//       setUserActEnglish(res.data.actEnglish)
//       setUserActMath(res.data.actMath)
//       setUserActWriting(res.data.actWriting)
//       setUserActScience(res.data.actScience)
//       setUserActReading(res.data.actReading)
//       setUserACTBestScore(res.data.actComposite)
//     }

//     try {

//       updateUserProfileTestScores().then(updateStates)
//       // // updateStates()
//       closeModalFunction('editScoreProfile')
//     } catch (error) {
//       console.log('Error updating user profile test scores:', error);
//       throw error
//     } finally {
//       handleGAEventOverlayButton("Submit")
//     }
//   }

//   const satScores = (
//     <div className="align-items-start">
//       <p className='dca-testscore-editscore-section-title'>SAT Scores</p>
//       <div className="d-flex flex-row justify-content-between">
//         <div>
//           <label className="form-label dca-testscore-editscore-field-title">Best SAT Reading Score</label>
//           <input
//             type="number"
//             name="satVerbal"
//             min={200}
//             max={800}
//             className="form-control dca-testscore-editscore-input"
//             value={formvalues.satVerbal}
//             onChange={(event) => handleOnChange(event)}
//           />
//           <i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("satVerbal")}></i>
//         </div>

//         <div>
//           <label className="form-label dca-testscore-editscore-field-title">Best SAT Math Score</label>
//           <input
//             type="number"
//             name="satMath"
//             min={200}
//             max={800}
//             className="form-control dca-testscore-editscore-input"
//             value={formvalues.satMath}
//             onChange={(event) => handleOnChange(event)}
//           /><i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("satMath")}></i>
//         </div>
//       </div>
//     </div>
//   )

//   const actScores = (
//     <div>
//       <p className='dca-testscore-editscore-act-section-title'>ACT Scores</p>

//       <div className="d-flex flex-row justify-content-between">
//         <div>
//           <label className="form-label dca-testscore-editscore-field-title">Best ACT Composite Score</label>
//           <input
//             type="number"
//             name="actComposite"
//             min={1}
//             max={36}
//             className="form-control dca-testscore-editscore-input"
//             value={formvalues.actComposite}
//             onChange={(event) => handleOnChange(event)}
//           />
//           <i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actComposite")}></i>
//         </div>

//         <div>
//           <label className="form-label dca-testscore-editscore-field-title">Best ACT Reading Score</label>
//           <input
//             type="number"
//             name="actReading"
//             min={1}
//             max={36}
//             className="form-control dca-testscore-editscore-input"
//             value={formvalues.actReading}
//             onChange={(event) => handleOnChange(event)}
//           />
//           <i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actReading")}></i>
//         </div>
//       </div>

//       <div className="d-flex flex-row justify-content-between">
//         <div>
//           <label className="form-label dca-testscore-editscore-field-title2">Best ACT Math Score</label>
//           <input
//             type="number"
//             name="actMath"
//             min={1}
//             max={36}
//             className="form-control dca-testscore-editscore-input"
//             value={formvalues.actMath}
//             onChange={(event) => handleOnChange(event)}
//           />
//           <i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actMath")}></i>
//         </div>

//         <div>
//           <label className="form-label dca-testscore-editscore-field-title2">Best ACT English Score</label>
//           <input
//             type="number"
//             name="actEnglish"
//             min={1}
//             max={36}
//             className="form-control dca-testscore-editscore-input"
//             value={formvalues.actEnglish}
//             onChange={(event) => handleOnChange(event)}
//           /><i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actEnglish")}></i>
//         </div>
//       </div>

//       <div className="d-flex flex-row justify-content-between">
//         <div>
//           <label className="form-label dca-testscore-editscore-field-title2">Best ACT Science Score</label>
//           <input
//             type="number"
//             name="actScience"
//             min={1}
//             max={36}
//             className="form-control dca-testscore-editscore-input"
//             value={formvalues.actScience}
//             onChange={(event) => handleOnChange(event)}
//           /><i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actScience")}></i>
//         </div>

//         <div>
//           <label className="form-label dca-testscore-editscore-field-title2">Best ACT Writing Score</label>
//           <input
//             type="number"
//             name="actWriting"
//             min={2}
//             max={12}
//             className="form-control dca-testscore-editscore-input"
//             value={formvalues.actWriting}
//             onChange={(event) => handleOnChange(event)}
//           />
//           <i
//             className="bi bi-x-circle"
//             style={{ cursor: 'pointer' }}
//             onClick={() => handleOnChangeNull("actWriting")}></i>
//         </div>
//       </div>

//     </div>
//   )

//   return (
//     <div>
//       <button
//         type="button"
//         className='dca-test-score-modal-enter-scores-pill'
//         data-bs-toggle="modal"
//         data-bs-target="#editScoreProfile"
//         onClick={() => {
//           fetchData();
//           handleGAEventEditScoreProfile('card')
//         }
//         }
//       >
//         Enter Score(s) For Your Recommendation
//       </button>
//       <div className="modal fade" tabIndex={-1} id="editScoreProfile">
//         <div className="modal-dialog">
//           <div className="modal-content" style={{ width: '700px', maxWidth: '700px', marginTop: '120px', padding: '30px' }}>

//             <div className="modal-body fw-bold d-flex flex-column">
//               <p className="dca-testscore-editscore-title">Your Score Profile</p>
//               <form onSubmit={(event) => handleSubmit(event, currentUser?.user_id, formvalues)}>
//                 <div style={{ display: 'none' }}>{closeModalIconTestScoreGuidance(handleGAEventOverlayButton)}</div>
//                 {satScores}
//                 {actScores}


//                 <div className="d-flex flex-row justify-content-center" style={{ marginTop: '45px' }}>
//                   <button
//                     type="button"
//                     className="btn btn-light fw-bold dca-testscore-editscore-discard-bttn"
//                     data-bs-dismiss="modal"
//                     id="closeModalIcon"
//                     onClick={() => handleGAEventOverlayButton("Discard")}
//                   >
//                     Discard
//                   </button>
//                   <button
//                     type="submit"
//                     className="rounded dca-testscore-editscore-submit-bttn"
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </form>
//             </div>

//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export const EditScoreProfileModal = ({ user, setUser }) => {

//   const [loading, setLoading] = useState(false)

//   const testScoreTitleArray = [
//     ['Best SAT reading score', 'satVerbal'],
//     ['Best SAT math score', 'satMath'],
//     ['Best SAT Total Score', 'satComposite'],
//     ['Best ACT composite score', 'actComposite'],
//     ['Best ACT reading score', 'actReading'],
//     ['Best ACT math score', 'actMath'],
//     ['Best ACT english score', 'actEnglish'],
//     ['Best ACT science score', 'actScience'],
//     ['Best ACT writing score', 'actWriting']
//   ]

//   const formSchema = Yup.object().shape({
//     satVerbal: Yup.number()
//       .min(200, 'Score cannot be lower than 200')
//       .max(800, 'Score cannot exceed 800'),
//     satMath: Yup.number()
//       .min(200, 'Score cannot be lower than 200')
//       .max(800, 'Score cannot exceed 800'),
//     satComposite: Yup.number()
//       .min(400, 'Score cannot be lower than 400')
//       .max(1600, 'Score cannot exceed 1600'),
//     actComposite: Yup.number()
//       .min(1, 'Score cannot be lower than 1')
//       .max(36, 'Score cannot exceed 36'),
//     actReading: Yup.number()
//       .min(1, 'Score cannot be lower than 1')
//       .max(36, 'Score cannot exceed 36'),
//     actMath: Yup.number()
//       .min(1, 'Score cannot be lower than 1')
//       .max(36, 'Score cannot exceed 36'),
//     actEnglish: Yup.number()
//       .min(1, 'Score cannot be lower than 1')
//       .max(36, 'Score cannot exceed 36'),
//     actScience: Yup.number()
//       .min(1, 'Score cannot be lower than 1')
//       .max(36, 'Score cannot exceed 36'),
//     actWriting: Yup.number()
//       .min(2, 'Score cannot be lower than 2')
//       .max(12, 'Score cannot exceed 12'),
//   })

//   let initialValues = {
//     satVerbal: user.satVerbal, satMath: user.satMath, satComposite: user.satComposite, actComposite: user.actComposite, actReading: user.actReading, actMath: user.actMath, actEnglish: user.actEnglish, actScience: user.actScience, actWriting: user.actWriting
//   }

//   const formik = useFormik({
//     initialValues: initialValues,
//     validationSchema: formSchema,
//     onSubmit: async (values, { setStatus, setSubmitting }) => {
//       setLoading(true)
//       try {
//         handleUpdateUserData2(user, values);
//         handleUpdateUserProfile(setUser, apiToken)
//         setLoading(false)
//       } catch (error) {
//         console.error(error)
//         alert('Error encountered')
//         setStatus('The test score details are incorrect')
//         setSubmitting(false)
//         setLoading(false)
//       }
//     }
//   })

//   return (

//     <div>
//       <button
//         type="button"
//         className='fw-bold rounded'
//         data-bs-toggle="modal"
//         data-bs-target="#editScoreProfile"
//         style={{ background: "linear-gradient(rgba(218, 248, 185, 1) 0%, rgba(183, 255, 181, 1) 100%)", border: 'transparent', width: '227px', maxWidth: '227px', height: '47px', maxHeight: '47px', marginTop: '20px' }}>
//         Edit Your Score Profile
//       </button>
//       <div className="modal fade" tabIndex={-1} id="editScoreProfile">
//         <div className="modal-dialog">
//           <div className="modal-content" style={{ width: '700px', maxWidth: '700px', marginTop: '120px', padding: '30px' }}>

//             {closeModalIcon('editScoreProfile')}

//             <div className="modal-body fw-bold d-flex flex-column">
//               <p style={{ fontSize: '30px' }} className="align-self-center">Your Score Profile</p>
//               <form
//                 onSubmit={formik.handleSubmit}
//                 noValidate
//                 id="testscoresForm">
//                 {testScoreTitleArray.map((arr, index) =>
//                   <div key={index} className="d-flex flex-row justify-content-between" style={{ padding: '20px' }}>
//                     {arr[0]}
//                     <input
//                       placeholder={arr[0]}
//                       {...formik.getFieldProps(arr[1])}
//                       className={clsx(
//                         'form-control bg-transparent',
//                         { 'is-invalid': formik.touched[arr[1]] && formik.errors[arr[1]] },
//                         {
//                           'is-valid': formik.touched[arr[1]] && !formik.errors[arr[1]],
//                         }
//                       )}
//                       type='number'
//                       name={arr[1]}
//                       autoComplete='off'
//                     />
//                     {formik.touched[arr[1]] && formik.errors[arr[1]] && (
//                       <div className='fv-plugins-message-container'>
//                         <span role='alert'>{formik.errors[arr[1]]}</span>
//                       </div>
//                     )}
//                   </div>
//                 )}

//                 <button
//                   type='submit'
//                   id='testscoresForm_submit'
//                   style={{ background: "linear-gradient(rgba(218, 248, 185, 1) 0%, rgba(183, 255, 181, 1) 100%)", border: 'transparent', maxWidth: '107px', maxHeight: '50px', fontSize: '16px' }}
//                   disabled={formik.isSubmitting || !formik.isValid || !formik.values}
//                 >
//                   {!loading && <span className='indicator-label'>Save Changes</span>}
//                   {loading && (
//                     <span className='indicator-progress' style={{ display: 'block' }}>
//                       Please wait...{' '}
//                       <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
//                     </span>
//                   )}
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
