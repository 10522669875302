const Summary = ({ modalData }) => {

  const testPolicyReturn = (val) => {
    switch (val) {
      case 1:
        return 'Required'
      case 2:
        return 'Optional'
      case 3:
        return 'Blind'
      default:
        return '-'
    }
  }

  const admissionReturn = (val) => {
    switch (val) {
      case 0:
        return 'Very Important'
      case 1:
        return 'Important'
      case 2:
        return 'Considered'
      case 3:
        return 'Not Considered'
      default:
        return '-'
    }
  }

  const scoreReturn = (val) => {
    if (val !== null) {
      return val
    }
    else {
      return '-'
    }
  }

  let testPolicy: any = 0
  let admission: any = 4
  let sat25: any = 0
  let sat50: any = 0
  let sat75: any = 0
  let act25: any = 0
  let act50: any = 0
  let act75: any = 0

  if (modalData && typeof modalData === 'object' && modalData.testPolicy && modalData.college_cds) {
    testPolicy = modalData.testPolicy
    admission = modalData.college_cds[0].testScores
    sat25 = modalData.college_cds[0].satComposite25
    sat50 = modalData.college_cds[0].satComposite50
    sat75 = modalData.college_cds[0].satComposite75
    act25 = modalData.college_cds[0].actComposite25
    act50 = modalData.college_cds[0].actComposite50
    act75 = modalData.college_cds[0].actComposite75
  }


  // return (
  //   <div className="card card-custom shadow p-4 d-flex flex-row" style={{ marginTop: '40px' }}>
  //     <div style={{ padding: '40px' }}>
  //       <div className="d-flex flex-column">
  //         <div>TEST POLICY</div>
  //         <div className='fw-bold'>{testPolicyReturn(testPolicy)}</div>
  //       </div>

  //       <div className="d-flex flex-column" style={{ marginTop: '45px' }}>
  //         <div>SAT COMPOSITE SCORES (25/50/75%)</div>
  //         <div className='fw-bold'>{scoreReturn(sat25)} / {scoreReturn(sat50)} / {scoreReturn(sat75)}</div>
  //       </div>
  //     </div>

  //     <div style={{ padding: '40px' }}>
  //       <div className="d-flex flex-column">
  //         <div>ADMISSION CONSIDERATION FOR TEST SCORES</div>
  //         <div className='fw-bold'>{admissionReturn(admission)}</div>
  //       </div>

  //       <div className="d-flex flex-column" style={{ marginTop: '45px' }}>
  //         <div>ACT COMPOSITE SCORES (25/50/75%)</div>
  //         <div className='fw-bold'>{scoreReturn(act25)} / {scoreReturn(act50)} / {scoreReturn(act75)}</div>
  //       </div>
  //     </div>
  //   </div>
  // )

  return (
    <div className="card card-custom shadow p-4 d-flex flex-row" style={{ marginTop: '40px' }}>
      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-flex flex-row'>
        <div style={{ padding: '40px' }}>
          <div className="d-flex flex-column">
            <div>TEST POLICY</div>
            <div className='fw-bold'>{testPolicyReturn(testPolicy)}</div>
          </div>

          <div className="d-flex flex-column" style={{ marginTop: '45px' }}>
            <div>SAT COMPOSITE SCORES (25/50/75%)</div>
            <div className='fw-bold'>{scoreReturn(sat25)} / {scoreReturn(sat50)} / {scoreReturn(sat75)}</div>
          </div>
        </div>

        <div style={{ padding: '40px' }}>
          <div className="d-flex flex-column">
            <div>ADMISSION CONSIDERATION FOR TEST SCORES</div>
            <div className='fw-bold'>{admissionReturn(admission)}</div>
          </div>

          <div className="d-flex flex-column" style={{ marginTop: '45px' }}>
            <div>ACT COMPOSITE SCORES (25/50/75%)</div>
            <div className='fw-bold'>{scoreReturn(act25)} / {scoreReturn(act50)} / {scoreReturn(act75)}</div>
          </div>
        </div>
      </div>
      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-flex flex-column d-md-none'>

        <div className="d-flex flex-column" style={{ marginTop: '10px' }}>
          <div>TEST POLICY</div>
          <div className='fw-bold'>{testPolicyReturn(testPolicy)}</div>
        </div>

        <div className="d-flex flex-column" style={{ marginTop: '40px' }}>
          <div>ADMISSION CONSIDERATION FOR TEST SCORES</div>
          <div className='fw-bold'>{admissionReturn(admission)}</div>
        </div>

        <div className="d-flex flex-column" style={{ marginTop: '40px' }}>
          <div>SAT COMPOSITE SCORES (25/50/75%)</div>
          <div className='fw-bold'>{scoreReturn(sat25)} / {scoreReturn(sat50)} / {scoreReturn(sat75)}</div>
        </div>

        <div className="d-flex flex-column" style={{ marginTop: '40px', marginBottom: '10px' }}>
          <div>ACT COMPOSITE SCORES (25/50/75%)</div>
          <div className='fw-bold'>{scoreReturn(act25)} / {scoreReturn(act50)} / {scoreReturn(act75)}</div>
        </div>
      </div>

    </div>
  )
}

export { Summary }