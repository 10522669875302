import Chart from 'react-apexcharts'

export const SATMathReadingChart = ({ seriesData }) => {
  return (
    <Chart
      options={{
        chart: {
          id: 'SAT Math vs SAT Reading/Writing',
		  toolbar: {
			show: true,
			tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          }         
        },
        },
        xaxis: {
          type: 'numeric',
          //decimalsInFloat: 2,
          max: 800,
          min: 540,
          tickAmount: 13,
          labels: {
            show: true,
            formatter: function (val: any, index) {
              return val.toFixed(0);
            }
          },
          title: {
            text: "Median SAT Math Score",
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          min: 540,
          max: 800,
          tickAmount: 13,
          labels: {
            show: true,
            formatter: function (val, index) {
              return val.toFixed(0);
            }
          },
          title: {
            text: "Median SAT Reading/Writing Score",
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        annotations: {
          texts: [{
            x: 25,
            y: 40,
            text: 'Tip: Hover on bubbles to see college-specific details',
          }],
        },
        title: {
          text: 'Median SAT Math vs. Median SAT Reading/Writing Scores',
          style: {
            fontSize: '18px',
          },
        },
        colors: ["#98F29B"],
        fill: {
          opacity: 0.6,
          type: 'fill',
        },
        plotOptions: {
          bubble: {
            zScaling: true,
            minBubbleRadius: 5,
            maxBubbleRadius: 12,
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          offsetY: 8,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        tooltip: {
          enabled: true,
          shared: false,
          custom: function ({ seriesIndex, dataPointIndex, w }) {

            if ((seriesIndex != null && seriesIndex != undefined) && (dataPointIndex != null && dataPointIndex != undefined)) {

              let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]

              return (
                '<div class="dca-apexcharts-tooltip">' +
                `<span>${data.name}</span>` +
                '<ul>' +
                `<li>Median SAT Math Score: ${data.x}</li>` +
                `<li>Median SAT EBRW Score: ${data.y}</li>` +
                `<li>Admit Rate: ${(data.z * 100).toFixed(1)}%</li>` +
                `<li>Metarank: #${data.metaRank ? data.metaRank : 'metaRank'}</li>` +
                '</ul>' +
                "</div>"
              )
            }

            else {
              return '<div>Error generating tooltip data</div>'
            }

          },
        }
      }}
      series={[
        {
          name: 'satMathReadingSeries',
          data: seriesData
        }
      ]}
      type='bubble'
      height={600}
    // width={800}
    />
  )
}

export const ACTMathEnglishChart = ({ seriesData }) => {
  return (
    <Chart
      options={{
        chart: {
          id: 'ACT Math vs ACT English',
		  toolbar: {
			show: true,
			tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          }         
        },
        },
        xaxis: {
          type: 'numeric',
          //decimalsInFloat: 2,
          max: 36,
          min: 20,
          tickAmount: 8,
          labels: {
            show: true,
            formatter: function (val: any, index) {
              return val.toFixed(0);
            }
          },
          title: {
            text: "Median ACT Math Score",
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          min: 20,
          max: 36,
          tickAmount: 8,
          labels: {
            show: true,
            formatter: function (val, index) {
              return val.toFixed(0);
            }
          },
          title: {
            text: "Median ACT English Score",
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        annotations: {
          texts: [{
            x: 25,
            y: 40,
            text: 'Tip: Hover on bubbles to see college-specific details',
          }],
        },
        title: {
          text: 'Median ACT Math vs. Median ACT English Scores',
          style: {
            fontSize: '18px',
          },
        },
        colors: ["#98F29B"],
        fill: {
          opacity: 0.6,
          type: 'fill',
        },
        plotOptions: {
          bubble: {
            zScaling: true,
            minBubbleRadius: 5,
            maxBubbleRadius: 12,
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          offsetY: 8,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        tooltip: {
          enabled: true,
          shared: false,
          custom: function ({ seriesIndex, dataPointIndex, w }) {

            if ((seriesIndex != null && seriesIndex != undefined) && (dataPointIndex != null && dataPointIndex != undefined)) {

              let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]

              return (
                '<div class="dca-apexcharts-tooltip">' +
                `<span>${data.name}</span>` +
                '<ul>' +
                `<li>Median ACT Math Score: ${data.x}</li>` +
                `<li>Median ACT English Score: ${data.y}</li>` +
                `<li>Admit Rate: ${(data.z * 100).toFixed(1)}%</li>` +
                `<li>Metarank: #${data.metaRank ? data.metaRank : 'metaRank'}</li>` +
                '</ul>' +
                "</div>"
              )
            }

            else {
              return '<div>Error generating tooltip data</div>'
            }

          },
        }
      }}
      series={[
        {
          name: 'ACT Math English Series',
          data: seriesData
        }
      ]}
      type='bubble'
      height={600}
    // width={800}
    />
  )
}
