import { useState, useEffect, useRef } from "react";
import { setting } from '../../dictionary';
import { useLocation } from 'react-router-dom';

const Setting = ({ setSettingFilter, setSettingParams, resetStateToggle }) => {

  const location = useLocation()
  const [settingSelect, setSettingSelect] = useState<any>('');
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setSettingSelect('');
  }, [resetStateToggle]);

  const handleSettingChange = (event) => {
    const selectedSetting = event.target.value
    setSettingSelect(selectedSetting)
    setSettingFilter(selectedSetting)
  }

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let settingParam = params.get('setting');

    if (settingParam) {
      setSettingSelect(settingParam)
      setSettingFilter(settingParam)
    }

    else {
      setSettingSelect('')
      setSettingFilter('')
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (settingSelect != '') {
      params.set('setting', settingSelect)
    } else {
      params.delete('setting')
    }

    setSettingParams(`${params.toString()}`)

  }, [settingSelect])

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>SETTING</div>
      <select
        className='form-select form-select-solid'
        data-control="select2"
        value={settingSelect}
        onChange={handleSettingChange}
        style={{ cursor: 'pointer' }}
      >
        <option value={''}>All</option>
        <option value={Object.keys(setting)[0]}>{Object.keys(setting)[0]}</option>
        <option value={Object.keys(setting)[1]}>{Object.keys(setting)[1]}</option>
        <option value={Object.keys(setting)[2]}>{Object.keys(setting)[2]}</option>
        <option value={Object.keys(setting)[3]}>{Object.keys(setting)[3]}</option>
      </select>
    </div>
  )
}

export { Setting }