export const zipcodeMap = [
    {
        "zipcode": "01720",
        "cityState": "Acton, MA"
    },
    {
        "zipcode": "01002",
        "cityState": "Amherst, MA"
    },
    {
        "zipcode": "01003",
        "cityState": "Amherst, MA"
    },
    {
        "zipcode": "01810",
        "cityState": "Andover, MA"
    },
    {
        "zipcode": "48109",
        "cityState": "Ann Arbor, MI"
    },
    {
        "zipcode": "02476",
        "cityState": "Arlington, MA"
    },
    {
        "zipcode": "22204",
        "cityState": "Arlington, VA"
    },
    {
        "zipcode": "20147",
        "cityState": "Ashburn, VA"
    },
    {
        "zipcode": "30602",
        "cityState": "Athens, GA"
    },
    {
        "zipcode": "30322",
        "cityState": "Atlanta, GA"
    },
    {
        "zipcode": "78712",
        "cityState": "Austin, TX"
    },
    {
        "zipcode": "78734",
        "cityState": "Austin, TX"
    },
    {
        "zipcode": "21205",
        "cityState": "Baltimore, MD"
    },
    {
        "zipcode": "21218",
        "cityState": "Baltimore, MD"
    },
    {
        "zipcode": "21281",
        "cityState": "Baltimore, MD"
    },
    {
        "zipcode": "04609",
        "cityState": "Bar Harbor, ME"
    },
    {
        "zipcode": "28516",
        "cityState": "Beaufort, NC"
    },
    {
        "zipcode": "98005",
        "cityState": "Bellevue, WA"
    },
    {
        "zipcode": "98007",
        "cityState": "Bellevue, WA"
    },
    {
        "zipcode": "02478",
        "cityState": "Belmont, MA"
    },
    {
        "zipcode": "94720",
        "cityState": "Berkeley, CA"
    },
    {
        "zipcode": "20892",
        "cityState": "Bethesda, MD"
    },
    {
        "zipcode": "18015",
        "cityState": "Bethlehem, PA"
    },
    {
        "zipcode": "47405",
        "cityState": "Bloomington, IN"
    },
    {
        "zipcode": "33431",
        "cityState": "Boca Raton, FL"
    },
    {
        "zipcode": "02115",
        "cityState": "Boston, MA"
    },
    {
        "zipcode": "02116",
        "cityState": "Boston, MA"
    },
    {
        "zipcode": "02215",
        "cityState": "Boston, MA"
    },
    {
        "zipcode": "80309",
        "cityState": "Boulder, CO"
    },
    {
        "zipcode": "37027",
        "cityState": "Brentwood, TN"
    },
    {
        "zipcode": "02446",
        "cityState": "Brookline, MA"
    },
    {
        "zipcode": "11201",
        "cityState": "Brooklyn, NY"
    },
    {
        "zipcode": "11229",
        "cityState": "Brooklyn, NY"
    },
    {
        "zipcode": "60089",
        "cityState": "Buffalo Grove, IL"
    },
    {
        "zipcode": "14203",
        "cityState": "Buffalo, NY"
    },
    {
        "zipcode": "94010",
        "cityState": "Burlingame, CA"
    },
    {
        "zipcode": "02138",
        "cityState": "Cambridge, MA"
    },
    {
        "zipcode": "02139",
        "cityState": "Cambridge, MA"
    },
    {
        "zipcode": "02142",
        "cityState": "Cambridge, MA"
    },
    {
        "zipcode": "08103",
        "cityState": "Camden, NJ"
    },
    {
        "zipcode": "20151",
        "cityState": "Chantilly, VA"
    },
    {
        "zipcode": "27514",
        "cityState": "Chapel Hill, FL"
    },
    {
        "zipcode": "27514",
        "cityState": "Chapel Hill, NC"
    },
    {
        "zipcode": "27599",
        "cityState": "Chapel Hill, NC"
    },
    {
        "zipcode": "02467",
        "cityState": "Chestnut Hill, MA"
    },
    {
        "zipcode": "20815",
        "cityState": "Chevy Chase, MD"
    },
    {
        "zipcode": "60605",
        "cityState": "Chicago, IL"
    },
    {
        "zipcode": "60637",
        "cityState": "Chicago, IL"
    },
    {
        "zipcode": "91711",
        "cityState": "Claremont, CA"
    },
    {
        "zipcode": "20742",
        "cityState": "College Park, MD"
    },
    {
        "zipcode": "75019",
        "cityState": "Coppell, TX"
    },
    {
        "zipcode": "33124",
        "cityState": "Coral Gables, FL"
    },
    {
        "zipcode": "95014",
        "cityState": "Cupertino, CA"
    },
    {
        "zipcode": "95616",
        "cityState": "Davis, CA"
    },
    {
        "zipcode": "48201",
        "cityState": "Detroit, MI"
    },
    {
        "zipcode": "94568",
        "cityState": "Dublin, CA"
    },
    {
        "zipcode": "27708",
        "cityState": "Durham, NC"
    },
    {
        "zipcode": "03824",
        "cityState": "Durham, NH"
    },
    {
        "zipcode": "48824",
        "cityState": "East Lansing, MI"
    },
    {
        "zipcode": "91316",
        "cityState": "Encino, CA"
    },
    {
        "zipcode": "60208",
        "cityState": "Evanston, IL"
    },
    {
        "zipcode": "22030",
        "cityState": "Fairfax, VA"
    },
    {
        "zipcode": "22043",
        "cityState": "Falls Church, VA"
    },
    {
        "zipcode": "01701",
        "cityState": "Framingham, MA"
    },
    {
        "zipcode": "02038",
        "cityState": "Franklin, MA"
    },
    {
        "zipcode": "94538",
        "cityState": "Fremont, CA"
    },
    {
        "zipcode": "94539",
        "cityState": "Fremont, CA"
    },
    {
        "zipcode": "32611",
        "cityState": "Gainesville, FL"
    },
    {
        "zipcode": "43022",
        "cityState": "Gambier, OH"
    },
    {
        "zipcode": "63039",
        "cityState": "Gray Summit, MO"
    },
    {
        "zipcode": "01230",
        "cityState": "Great Barrington, MA"
    },
    {
        "zipcode": "03755",
        "cityState": "Hanover, NH"
    },
    {
        "zipcode": "29550",
        "cityState": "Hartsville, SC"
    },
    {
        "zipcode": "20170",
        "cityState": "Herndon, VA"
    },
    {
        "zipcode": "60169",
        "cityState": "Hoffman Estates, IL"
    },
    {
        "zipcode": "77005",
        "cityState": "Houston, TX"
    },
    {
        "zipcode": "77027",
        "cityState": "Houston, TX"
    },
    {
        "zipcode": "20785",
        "cityState": "Hyattsville, MD"
    },
    {
        "zipcode": "52242",
        "cityState": "Iowa City, IA"
    },
    {
        "zipcode": "92606",
        "cityState": "Irvine, CA"
    },
    {
        "zipcode": "92617",
        "cityState": "Irvine, CA"
    },
    {
        "zipcode": "92620",
        "cityState": "Irvine, CA"
    },
    {
        "zipcode": "14850",
        "cityState": "Ithaca, NY"
    },
    {
        "zipcode": "14853",
        "cityState": "Ithaca, NY"
    },
    {
        "zipcode": "92037",
        "cityState": "La Jolla, CA"
    },
    {
        "zipcode": "92093",
        "cityState": "La Jolla, CA"
    },
    {
        "zipcode": "88003",
        "cityState": "Las Cruces, NM"
    },
    {
        "zipcode": "89154",
        "cityState": "Las Vegas, NV"
    },
    {
        "zipcode": "20723",
        "cityState": "Laurel, MD"
    },
    {
        "zipcode": "40506",
        "cityState": "Lexington, KY"
    },
    {
        "zipcode": "02421",
        "cityState": "Lexington, MA"
    },
    {
        "zipcode": "94550",
        "cityState": "Livermore, CA"
    },
    {
        "zipcode": "90001",
        "cityState": "Los Angeles, CA"
    },
    {
        "zipcode": "90004",
        "cityState": "Los Angeles, CA"
    },
    {
        "zipcode": "90007",
        "cityState": "Los Angeles, CA"
    },
    {
        "zipcode": "90024",
        "cityState": "Los Angeles, CA"
    },
    {
        "zipcode": "90025",
        "cityState": "Los Angeles, CA"
    },
    {
        "zipcode": "90048",
        "cityState": "Los Angeles, CA"
    },
    {
        "zipcode": "90089",
        "cityState": "Los Angeles, CA"
    },
    {
        "zipcode": "90095",
        "cityState": "Los Angeles, CA"
    },
    {
        "zipcode": "79409",
        "cityState": "Lubbock, TX"
    },
    {
        "zipcode": "07940",
        "cityState": "Madison, NJ"
    },
    {
        "zipcode": "53792",
        "cityState": "Madison, WI"
    },
    {
        "zipcode": "45056",
        "cityState": "McMinnville, OR"
    },
    {
        "zipcode": "02155",
        "cityState": "Medford, MA"
    },
    {
        "zipcode": "94025",
        "cityState": "Menlo Park, CA"
    },
    {
        "zipcode": "55455",
        "cityState": "Minnesota, MN"
    },
    {
        "zipcode": "92692",
        "cityState": "Mission Viejo, CA"
    },
    {
        "zipcode": "94041",
        "cityState": "Mountain View, CA"
    },
    {
        "zipcode": "60540",
        "cityState": "Naperville, IL"
    },
    {
        "zipcode": "03063",
        "cityState": "Nashua, NH"
    },
    {
        "zipcode": "37235",
        "cityState": "Nashville, TN"
    },
    {
        "zipcode": "06510",
        "cityState": "New Haven, CT"
    },
    {
        "zipcode": "06511",
        "cityState": "New Haven, CT"
    },
    {
        "zipcode": "10003",
        "cityState": "New York, NY"
    },
    {
        "zipcode": "10023",
        "cityState": "New York, NY"
    },
    {
        "zipcode": "10024",
        "cityState": "New York, NY"
    },
    {
        "zipcode": "10027",
        "cityState": "New York, NY"
    },
    {
        "zipcode": "10065",
        "cityState": "New York, NY"
    },
    {
        "zipcode": "10075",
        "cityState": "New York, NY"
    },
    {
        "zipcode": "10128",
        "cityState": "New York, NY"
    },
    {
        "zipcode": "10281",
        "cityState": "New York, NY"
    },
    {
        "zipcode": "23062",
        "cityState": "Newport News, VA"
    },
    {
        "zipcode": "02459",
        "cityState": "Newton, MA"
    },
    {
        "zipcode": "02460",
        "cityState": "Newtonville, MA"
    },
    {
        "zipcode": "73019",
        "cityState": "Norman, OK"
    },
    {
        "zipcode": "01360",
        "cityState": "Northfield, MA"
    },
    {
        "zipcode": "46556",
        "cityState": "Notre Dame, IN"
    },
    {
        "zipcode": "92868",
        "cityState": "Orange, CA"
    },
    {
        "zipcode": "94301",
        "cityState": "Palo Alto, CA"
    },
    {
        "zipcode": "91101",
        "cityState": "Pasadena, CA"
    },
    {
        "zipcode": "91107",
        "cityState": "Pasadena, CA"
    },
    {
        "zipcode": "91125",
        "cityState": "Pasadena, CA"
    },
    {
        "zipcode": "19019",
        "cityState": "Philadelphia, PA"
    },
    {
        "zipcode": "19104",
        "cityState": "Philadelphia, PA"
    },
    {
        "zipcode": "85004",
        "cityState": "Phoenix, AZ"
    },
    {
        "zipcode": "15213",
        "cityState": "Pittsburgh, PA"
    },
    {
        "zipcode": "15289",
        "cityState": "Pittsburgh, PA"
    },
    {
        "zipcode": "75024",
        "cityState": "Plano, TX"
    },
    {
        "zipcode": "75093",
        "cityState": "Plano, TX"
    },
    {
        "zipcode": "94588",
        "cityState": "Pleasanton, CA"
    },
    {
        "zipcode": "21853",
        "cityState": "Princess Anne, MD"
    },
    {
        "zipcode": "08550",
        "cityState": "Princeton Junction, NJ"
    },
    {
        "zipcode": "01267",
        "cityState": "Princeton, NJ"
    },
    {
        "zipcode": "08544",
        "cityState": "Princeton, NJ"
    },
    {
        "zipcode": "40059",
        "cityState": "Prospect, KY"
    },
    {
        "zipcode": "02912",
        "cityState": "Providence, RI"
    },
    {
        "zipcode": "02169",
        "cityState": "Quincy, MA"
    },
    {
        "zipcode": "27601",
        "cityState": "Raleigh, NC"
    },
    {
        "zipcode": "98052",
        "cityState": "Redmond, WA"
    },
    {
        "zipcode": "89521",
        "cityState": "Reno, NV"
    },
    {
        "zipcode": "24016",
        "cityState": "Roanoke, VA"
    },
    {
        "zipcode": "20850",
        "cityState": "Rockville, MD"
    },
    {
        "zipcode": "20852",
        "cityState": "Rockville, MD"
    },
    {
        "zipcode": "95826",
        "cityState": "Sacramento, CA"
    },
    {
        "zipcode": "63130",
        "cityState": "Saint Louis, MO"
    },
    {
        "zipcode": "92101",
        "cityState": "San Diego, CA"
    },
    {
        "zipcode": "92110",
        "cityState": "San Diego, CA"
    },
    {
        "zipcode": "92127",
        "cityState": "San Diego, CA"
    },
    {
        "zipcode": "94105",
        "cityState": "San Francisco, CA"
    },
    {
        "zipcode": "94127",
        "cityState": "San Francisco, CA"
    },
    {
        "zipcode": "95129",
        "cityState": "San Jose, CA"
    },
    {
        "zipcode": "95135",
        "cityState": "San Jose, CA"
    },
    {
        "zipcode": "78656",
        "cityState": "San Marcos, TX"
    },
    {
        "zipcode": "94403",
        "cityState": "San Mateo, CA"
    },
    {
        "zipcode": "94583",
        "cityState": "San Ramon, CA"
    },
    {
        "zipcode": "93106",
        "cityState": "Santa Barbara, CA"
    },
    {
        "zipcode": "95053",
        "cityState": "Santa Clara, CA"
    },
    {
        "zipcode": "95064",
        "cityState": "Santa Cruz, CA"
    },
    {
        "zipcode": "87505",
        "cityState": "Santa Fe, NM"
    },
    {
        "zipcode": "93060",
        "cityState": "Santa Paula, CA"
    },
    {
        "zipcode": "10583",
        "cityState": "Scarsdale, NY"
    },
    {
        "zipcode": "98109",
        "cityState": "Seattle, WA"
    },
    {
        "zipcode": "37383",
        "cityState": "Sewanee, TN"
    },
    {
        "zipcode": "01545",
        "cityState": "Shrewsbury, MA"
    },
    {
        "zipcode": "06070",
        "cityState": "Simsbury, CT"
    },
    {
        "zipcode": "60077",
        "cityState": "Skokie, IL"
    },
    {
        "zipcode": "06905",
        "cityState": "Stamford, CT"
    },
    {
        "zipcode": "94305",
        "cityState": "Stanford, CA"
    },
    {
        "zipcode": "11790",
        "cityState": "Stony Brook, NY"
    },
    {
        "zipcode": "77479",
        "cityState": "Sugar Land, TX"
    },
    {
        "zipcode": "94087",
        "cityState": "Sunnyvale, CA"
    },
    {
        "zipcode": "98416",
        "cityState": "Tacoma, WA"
    },
    {
        "zipcode": "32306",
        "cityState": "Tallahassee, FL"
    },
    {
        "zipcode": "47803",
        "cityState": "Terre Haute, IN"
    },
    {
        "zipcode": "12180",
        "cityState": "Troy, NY"
    },
    {
        "zipcode": "07083",
        "cityState": "Union, NJ"
    },
    {
        "zipcode": "16802",
        "cityState": "University Park, PA"
    },
    {
        "zipcode": "80840",
        "cityState": "USAF Academy, CO"
    },
    {
        "zipcode": "27587",
        "cityState": "Wake Forest, NC"
    },
    {
        "zipcode": "02081",
        "cityState": "Walpole, MA"
    },
    {
        "zipcode": "20001",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20002",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20003",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20004",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20005",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20007",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20052",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20057",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20202",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20310",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20560",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "20585",
        "cityState": "Washington DC"
    },
    {
        "zipcode": "02481",
        "cityState": "Wellesley Hills, MA"
    },
    {
        "zipcode": "47907",
        "cityState": "West Lafayette, IN"
    },
    {
        "zipcode": "10996",
        "cityState": "West Point, NY"
    },
    {
        "zipcode": "43081",
        "cityState": "Westerville, OH"
    },
    {
        "zipcode": "06109",
        "cityState": "Wethersfield, CT"
    },
    {
        "zipcode": "60090",
        "cityState": "Wheeling, IL"
    },
    {
        "zipcode": "01890",
        "cityState": "Winchester, MA"
    },
    {
        "zipcode": "27109",
        "cityState": "Winston Salem, NC"
    },
    {
        "zipcode": "10028",
        "cityState": "New York, NY"
    },
    {
        "zipcode": "30332",
        "cityState": "Atlanta, GA"
    },
    {
        "zipcode": "46556",
        "cityState": "Notre Dame, IN"
    },
    {
        "zipcode": "06039",
        "cityState": "Salisbury, CT"
    },
    {
        "zipcode": "20510",
        "cityState": "Washington DC"
    }
]