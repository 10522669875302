import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { MajorFieldOfStudy } from './components/MajorFieldOfStudySearch'
import { MajorNameSearch } from './components/MajorNameSearch'
import { MajorCollegeSearch } from './components/MajorCollegeSearch'
import { MajorCourseSearch } from './components/MajorCoursesSearch'
import { MajorCareerSearch } from './components/MajorCareersSearch'
import { MajorEarningsSearch, MajorEarningsSearchMobile } from './components/MajorEarningsSearch'
import { MajorPopularitySearch, MajorPopularitySearchMobile } from './components/MajorPopularitySearch'
import { MajorAreasSelect } from './components/MajorAreasSelect'
import { pageRoutes } from '../dictionary'
import { concatLocation, returnParameterString } from '../searchPageURLManager'
import { MajorOnlyInterests } from './components/MajorOnlyInterests'
import filterIcon from '../../../custom_assets/filter_icon.png'

const MajorSearchAside = ({ programData, interestCIP, careerOptions, careerCIP, fieldOptions, nameOptions, collegeOptions, courseOptions, setFieldFilter, setNameFilter, setCollegeFilter, setRelatedCoursesFilter, setRelatedCareersFilter, setEarningsFilter, setPopularityFilter, setAreasFilter, majors, setFilteredResults, majorSearchLocation, setMajorSearchLocation, setSortKey, setSortOrder, sortKey, sortOrder, dataLoaded, onlyInterestsSelect, setOnlyInterestsSelect, setGuestClickShowOnlyInterest, showOnlyMyInterestsFilter, setShowOnlyMyInterestsFilter, filtersActive, mobile = false }) => {

  const location = useLocation()
  const [fieldSelect, setFieldSelect] = useState<any>([])
  const [incAdjFieldSelect, setIncAdjFieldSelect] = useState(true)
  const [nameSelect, setNameSelect] = useState<any>([])
  const [collegeSelect, setCollegeSelect] = useState<any>([])
  const [coursesSelect, setCoursesSelect] = useState<any>([])
  const [careersSelect, setCareersSelect] = useState<any>([])
  const [earningsMin, setEarningsMin] = useState<any>(0)
  const [earningsMax, setEarningsMax] = useState<any>(250000)
  const [popularityMin, setPopularityMin] = useState<any>(0)
  const [popularityMax, setPopularityMax] = useState<any>(10)
  const [areasSelect, setAreasSelect] = useState<any>([])

  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)
  const [resetStateToggle, setResetStateToggle] = useState(false)

  const resetFilterStates = () => {

    setFieldSelect([])
    setIncAdjFieldSelect(true)
    setNameSelect([])
    setCollegeSelect([])
    setCoursesSelect([])
    setCareersSelect([])
    setEarningsMin(0)
    setEarningsMax(250000)
    setPopularityMin(0)
    setPopularityMax(10)
    setAreasSelect([])

    setResetStateToggle(!resetStateToggle)

  }

  const toggleFilterOpen = () => {
    setMobileFilterOpen(!mobileFilterOpen)
  }

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortParam = params.get('sort');

    if (sortParam) {
      const sorts = sortParam.split(',').map(res => res);
      setSortKey(sorts[0])
      setSortOrder(sorts[1])
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortDetails: any = [sortKey, sortOrder]

    sortDetails = sortDetails.map((res) => res).join(',')
    params.set('sort', sortDetails)

    setSortParams(`${params.toString()}`)

  }, [sortKey, sortOrder])

  //// URL and filter store states
  const [sortParams, setSortParams] = useState('')
  const [fieldParams, setFieldParams] = useState('')
  const [incAdjFieldParams, setIncAdjFieldParams] = useState('')
  const [majorNameParams, setMajorNameParams] = useState('')
  const [collegeNameParams, setCollegeNameParams] = useState('')
  const [hsCoursesParams, setHSCoursesParams] = useState('')
  const [careersParams, setCareersParams] = useState('')
  const [areasParams, setAreasParams] = useState('')
  const [earningsParams, setEarningsParams] = useState('')
  const [popularityParams, setPopularityParams] = useState('')
  const [onlyInterestsParams, setOnlyInterestsParams] = useState('')

  useEffect(() => {

    const returnFieldFilter = (fieldSelect) => {
      if (fieldSelect.length !== 0) {
        // If fieldSelect is not empty, grab the cipCodes and set the field filter depending on incAdjFieldSelect
        let fields: any[] = []
        fieldSelect.forEach(obj => {
          fields.push(obj.value)
        })

        incAdjFieldSelect ? setFieldFilter((item) => {
          return item => (fields.includes(item.cipt_code) || fields.some(field => item.secondaryCIPs?.includes(field)))
        }) : setFieldFilter((item) => {
          return item => fields.includes(item.cipt_code)
        })

      } else {
        // Else if fieldSelect is empty:
        setFieldFilter((item) => {
          return item => item
        })
      }
    }
    returnFieldFilter(fieldSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (fieldSelect.length > 0) {
      const fieldIDs = fieldSelect.map(obj => obj.value).join(',');
      params.set('fieldOfStudy', fieldIDs)
    } else {
      params.delete('fieldOfStudy')
    }

    setFieldParams(`${params.toString()}`)
  }, [fieldSelect, incAdjFieldSelect])

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (incAdjFieldSelect === false) {
      params.set('adjacentFields', 'false')
    }

    else {
      params.delete('adjacentFields')
    }

    setIncAdjFieldParams(`${params.toString()}`)

  }, [incAdjFieldSelect])

  useEffect(() => {
    const returnNameFilter = (nameSelect) => {
      if (nameSelect.length !== 0) {

        let names: any[] = []
        nameSelect.forEach(obj => {
          names.push(obj.label)
        });

        setNameFilter((item) => {
          return item => names.includes(item.name)
        })
      }
      else {
        setNameFilter((item) => {
          return item => item
        })
      }
    };
    returnNameFilter(nameSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (nameSelect.length > 0) {
      // const names = nameSelect.map((name) => name).join(',')
      // const ids: any[] = []
      // nameSelect.forEach(obj => {
      //   ids.push(obj.value)
      // });
      // const ids_string = ids.map((name) => name).join(',')
      const nameIDs = nameSelect.map(obj => obj.value).join(',');
      params.set('majorName', nameIDs)
    } else {
      params.delete('majorName')
    }

    setMajorNameParams(`${params.toString()}`)
  }, [nameSelect])

  useEffect(() => {
    const returnCollegeFilter = (collegeSelect) => {
      if (collegeSelect.length !== 0) {
        let programs: any = []
        programs = programData.filter((item) => collegeSelect.includes(item.college_id))

        let majorIds: any = []
        programs.map((res) => {
          majorIds.push(res.major_id)
        })

        majorIds = [...new Set(majorIds)]

        setCollegeFilter((item) => {
          return item => (item.id !== null && majorIds.includes(item.id))
        })

      }
      else {
        setCollegeFilter((item) => {
          return item => item
        })
      }
    };
    returnCollegeFilter(collegeSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (collegeSelect.length > 0) {
      const colleges = collegeSelect.map((name) => name).join(',')
      params.set('collegeName', colleges)
    } else {
      params.delete('collegeName')
    }

    setCollegeNameParams(`${params.toString()}`)

  }, [collegeSelect])

  useEffect(() => {

    // console.log('courses selected', coursesSelect)
    // Set URL params
    let params = new URLSearchParams(location.search);

    if (coursesSelect.length > 0) {

      const courses = coursesSelect.map((name) => name).join(',')
      params.set('highSchoolCourses', courses)

      let ref: any = []
      let ciptIds: any = []
      ref = interestCIP

      ref = ref.filter((item) => (item.affinityScore !== null && coursesSelect.includes(item.interest_id)))

      ref.map((res) => {
        ciptIds.push(res.cipt_code)
      })

      ciptIds = [...new Set(ciptIds)]

      console.log('ciptIds', ciptIds)

      if (ciptIds.length > 0) {
        setRelatedCoursesFilter((item) => {

          return item => (ciptIds.some((cip) => item?.cipt_code == cip || item.cipt_code?.startsWith(cip))) || (ciptIds.some((cip) => item?.secondaryCIPs?.some((secondaryCIP) => secondaryCIP == cip || secondaryCIP?.startsWith(cip))))

        })
      }

      else {
        setFilteredResults(false)
      }

    }

    else {
      setRelatedCoursesFilter((item) => {
        return item => item
      })

      params.delete('highSchoolCourses')
    }

    setHSCoursesParams(`${params.toString()}`)

  }, [coursesSelect, dataLoaded])

  useEffect(() => {

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (careersSelect.length > 0) {

      const careers = careersSelect.map((name) => name).join(',')
      params.set('careers', careers)

      let resA: any = []
      resA = careerCIP.filter((item) => (item.affinityScore !== null && careersSelect.includes(item.career_code)))

      let resB: any = []
      resA.map((res) => {
        resB.push(res.cipt_code)
      })

      resB = [...new Set(resB)]

      setRelatedCareersFilter((item) => {
        return item => (item.cipt_code !== null && resB.includes(item.cipt_code))
      })

    }

    else {
      setRelatedCareersFilter((item) => {
        return item => item
      })

      params.delete('careers')
    }

    setCareersParams(`${params.toString()}`)
  }, [careersSelect, dataLoaded])

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    const returnEarningsFilter = (earningsMin, earningsMax) => {

      if (earningsMin > 0 || earningsMax < 250000) {
        setEarningsFilter((item) => {
          return item => (
            item.averageEarnings !== null &&
            earningsMin <= item.averageEarnings &&
            earningsMax >= item.averageEarnings
          ) ||
            (item.averageEarnings === null)
        })

        let minMaxArr: any[] = []
        minMaxArr = [earningsMin, earningsMax]
        const minMax = minMaxArr.map((val) => val).join(',')
        params.set('earnings', minMax)
      }

      else {
        setEarningsFilter((item) => {
          return item => item
        })

        params.delete('earnings')
      }
    }

    returnEarningsFilter(earningsMin, earningsMax)

    setEarningsParams(`${params.toString()}`)

  }, [earningsMin, earningsMax])

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    const returnPopularityFilter = (popularityMin, popularityMax) => {
      if (popularityMin > 0 || popularityMax < 10) {
        setPopularityFilter((item) => {
          return item => (item.popularityIndex !== null && popularityMin <= item.popularityIndex && popularityMax >= item.popularityIndex) || item.popularityIndex === null
        })

        let minMaxArr: any[] = []
        minMaxArr = [popularityMin, popularityMax]
        const minMax = minMaxArr.map((val) => val).join(',')
        params.set('popularity', minMax)
      }

      else {
        setPopularityFilter((item) => {
          return item => item
        })

        params.delete('popularity')
      }
    }

    returnPopularityFilter(popularityMin, popularityMax)

    setPopularityParams(`${params.toString()}`)

  }, [popularityMin, popularityMax])

  useEffect(() => {

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (areasSelect.length > 0) {
      const areas = areasSelect.map((area) => area).join(',')
      params.set('areas', areas)
    } else {
      params.delete('areas')
    }

    setAreasParams(`${params.toString()}`)

    const returnAreasFilter = (areasSelect) => {

      if (areasSelect.length > 0) {
        setAreasFilter((item) => {

          if (areasSelect.length == 1) {
            return item => item.primaryGroup == areasSelect || item.secondaryGroup == areasSelect
          }

          else {
            return item => areasSelect.some((area) => area == item.primaryGroup || area == item.secondaryGroup)
          }

        })
      }

      else {
        setAreasFilter((item) => {
          return item => item
        })
      }
    }

    returnAreasFilter(areasSelect)

  }, [areasSelect])

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (onlyInterestsSelect === true) {
      params.set('onlyInterests', 'true')
    }

    else {
      params.delete('onlyInterests')
    }

    setOnlyInterestsParams(`${params.toString()}`)

  }, [onlyInterestsSelect])

  useEffect(() => {

    //  Create an array of the parameter strings
    let params: string[] = []
    // Use the returnParameterString function to fix the string if needed
    params = [
      returnParameterString(onlyInterestsParams, 'onlyInterests'),
      returnParameterString(fieldParams, 'fieldOfStudy'),
      returnParameterString(incAdjFieldParams, 'adjacentFields'),
      returnParameterString(majorNameParams, 'majorName'),
      returnParameterString(collegeNameParams, 'collegeName'),
      returnParameterString(hsCoursesParams, 'highSchoolCourses'),
      returnParameterString(careersParams, 'careers'),
      returnParameterString(areasParams, 'areas'),
      returnParameterString(earningsParams, 'earnings'),
      returnParameterString(popularityParams, 'popularity'),
      returnParameterString(sortParams, 'sort')
    ]

    // Set the location string using the base pageRoute and the return from the concat location function
    setMajorSearchLocation(`${pageRoutes.majorSearch}${concatLocation(params)}`)

  }, [fieldParams, incAdjFieldParams, majorNameParams, collegeNameParams, hsCoursesParams, careersParams, areasParams, earningsParams, popularityParams, sortParams, onlyInterestsParams])

  useEffect(() => {

    window.history.replaceState({}, '', majorSearchLocation)

  }, [majorSearchLocation])

  return mobile ? (
    <div className='dca-college-search-filter-drawer-container'>

      <button
        onClick={toggleFilterOpen}
        className={filtersActive > 0 ? 'dca-college-search-filters-button-bolded' : 'dca-college-search-filters-button'}><img src={filterIcon} alt="bars-filter" className="dca-college-filters-button-icon" />&nbsp;&nbsp;Filters {filtersActive > 0 && <span className='dca-filters-active-icon'>{filtersActive}</span>}</button>

      <div className={`dca-college-search-filter-drawer${mobileFilterOpen ? '-open' : ''}`} style={{ display: mobileFilterOpen ? 'flex' : 'none' }}>
        {/* <div className="dca-college-search-filter-drawer-header">
          <button className="dca-college-search-filter-drawer-close-button" onClick={toggleFilterOpen}>
            &times;
          </button>
        </div> */}

        <div className='dca-college-search-mobile-filters-wrapper'>
          <div className='dca-college-search-filter-drawer-header'>
            <div className='dca-college-search-mobile-filters-title'>Filters</div>

            <div
              className="dca-college-search-filter-drawer-close-button"
              onClick={toggleFilterOpen}>
              &times;
            </div>
          </div>
          <div style={{ padding: '0px 15px 25px 15px', overflowY: 'auto' }}>
            <div className="z-9" style={{ zIndex: 9 }}>
              <MajorFieldOfStudy
                fieldOptions={fieldOptions}
                setFieldSelect={setFieldSelect}
                incAdjFieldSelect={incAdjFieldSelect}
                setIncAdjFieldSelect={setIncAdjFieldSelect}
                resetStateToggle={resetStateToggle}
                mobile={true} />
            </div>

            <div className="z-8" style={{ zIndex: 8 }}>
              <MajorAreasSelect
                setAreasSelect={setAreasSelect}
                majors={majors}
                resetStateToggle={resetStateToggle}
                mobile={true} />
            </div>

            <div className="z-7" style={{ zIndex: 7 }}>
              <MajorNameSearch
                nameOptions={nameOptions}
                setNameSelect={setNameSelect}
                resetStateToggle={resetStateToggle}
                mobile={true} />
            </div>

            <div className="z-6" style={{ zIndex: 6 }}>
              <MajorCourseSearch
                setCourseSelect={setCoursesSelect}
                courseOptions={courseOptions}
                resetStateToggle={resetStateToggle} />
            </div>

            <div className="z-5" style={{ zIndex: 5 }}>
              <MajorCareerSearch
                setCareerSelect={setCareersSelect}
                careerOptions={careerOptions}
                resetStateToggle={resetStateToggle} />
            </div>

            <div className="z-4" style={{ zIndex: 4 }}>
              <MajorCollegeSearch
                collegeOptions={collegeOptions}
                setCollegeSelect={setCollegeSelect}
                resetStateToggle={resetStateToggle}
                mobile={true} />
            </div>

            <div className="z-3" style={{ zIndex: 3 }}>
              <MajorEarningsSearchMobile
                earningsMin={earningsMin}
                earningsMax={earningsMax}
                setEarningsMin={setEarningsMin}
                setEarningsMax={setEarningsMax}
                resetStateToggle={resetStateToggle} />
            </div>

            <div className="z-2" style={{ zIndex: 2 }}>
              <MajorPopularitySearchMobile
                popularityMin={popularityMin}
                popularityMax={popularityMax}
                setPopularityMin={setPopularityMin}
                setPopularityMax={setPopularityMax}
                resetStateToggle={resetStateToggle} />
            </div>

            <div style={{ zIndex: 1 }}>
              <MajorOnlyInterests
                onlyInterestsSelect={onlyInterestsSelect}
                setOnlyInterestsSelect={setOnlyInterestsSelect}
                resetStateToggle={resetStateToggle} />
            </div>
          </div>


          <div className='dca-college-search-mobile-filters-drawer-footer'>
            <span className='dca-college-search-mobile-filters-drawer-footer-clear'
              onClick={() => {
                resetFilterStates()
              }}>Clear all</span>

            <button onClick={toggleFilterOpen} className='dca-college-search-mobile-filters-drawer-footer-view-results'>View Results</button>
          </div>
        </div>


      </div>
    </div>
  ) : (
    <div style={{ width: '260px', maxWidth: '260px', whiteSpace: 'nowrap' }}>
      <div style={{ zIndex: 9 }}>
        <MajorFieldOfStudy
          fieldOptions={fieldOptions}
          setFieldSelect={setFieldSelect}
          incAdjFieldSelect={incAdjFieldSelect}
          setIncAdjFieldSelect={setIncAdjFieldSelect}
          resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 8 }}>
        <MajorAreasSelect
          setAreasSelect={setAreasSelect}
          majors={majors}
          resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 7 }}>
        <MajorNameSearch
          nameOptions={nameOptions}
          setNameSelect={setNameSelect}
          resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 6 }}>
        <MajorCourseSearch
          setCourseSelect={setCoursesSelect}
          courseOptions={courseOptions}
          resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 5 }}>
        <MajorCareerSearch
          setCareerSelect={setCareersSelect}
          careerOptions={careerOptions}
          resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 4 }}>
        <MajorCollegeSearch
          collegeOptions={collegeOptions}
          setCollegeSelect={setCollegeSelect}
          resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 3 }}>
        <MajorEarningsSearch
          earningsMin={earningsMin}
          earningsMax={earningsMax}
          setEarningsMin={setEarningsMin}
          setEarningsMax={setEarningsMax}
          resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 2 }}>
        <MajorPopularitySearch
          popularityMin={popularityMin}
          popularityMax={popularityMax}
          setPopularityMin={setPopularityMin}
          setPopularityMax={setPopularityMax}
          resetStateToggle={resetStateToggle} />
      </div>

      <div style={{ zIndex: 1 }}>
        <MajorOnlyInterests
          onlyInterestsSelect={onlyInterestsSelect}
          setOnlyInterestsSelect={setOnlyInterestsSelect}
          resetStateToggle={resetStateToggle} />
      </div>
    </div>
  )
}

export { MajorSearchAside }