import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { extracurricularMap } from '../components/extracurricularMap';
const excludedPaths = extracurricularMap.map(ec => `/extracurricular-insights-${ec.hyphenated}`);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {

    if (!excludedPaths.includes(pathname)) {
      window.scrollTo(0, 0);
    }

  }, [pathname]);

  return null;
}

export { ScrollToTop };