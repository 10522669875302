import { useState, useEffect, useRef } from "react";
import { collegeSize } from "../../dictionary"
import { useLocation } from 'react-router-dom';

const Size = ({ setSizeFilter, setSizeParams, resetStateToggle }) => {

  const location = useLocation()
  const [sizeSelect, setSizeSelect] = useState<any>('All');
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // Reset state when resetToggle changes, but skip the first render
    setSizeSelect('All');
  }, [resetStateToggle]);

  const handleSizeChange = (event) => {
    const selectedCollegeSize = event.target.value
    setSizeSelect(selectedCollegeSize)
    setSizeFilter(collegeSize[selectedCollegeSize])
  }

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sizeParam = params.get('size');

    if (sizeParam) {
      setSizeSelect(sizeParam)
      setSizeFilter(collegeSize[sizeParam])
    }

    else {
      setSizeSelect('All')
      setSizeFilter(0)
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (sizeSelect != 'All') {
      params.set('size', sizeSelect)
    } else {
      params.delete('size')
    }

    setSizeParams(`${params.toString()}`)

  }, [sizeSelect])

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>SIZE</div>
      <select className='form-select form-select-solid'
        data-control="select2"
        value={sizeSelect}
        onChange={handleSizeChange}
        style={{ cursor: 'pointer' }}
      >
        <option value={Object.keys(collegeSize)[0]}>{Object.keys(collegeSize)[0]}</option>
        <option value={Object.keys(collegeSize)[1]}>{Object.keys(collegeSize)[1]}</option>
        <option value={Object.keys(collegeSize)[2]}>{Object.keys(collegeSize)[2]}</option>
        <option value={Object.keys(collegeSize)[3]}>{Object.keys(collegeSize)[3]}</option>
        <option value={Object.keys(collegeSize)[4]}>{Object.keys(collegeSize)[4]}</option>
      </select>
    </div>
  )
}

export { Size }